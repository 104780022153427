import React, { useState, useEffect, forwardRef } from 'react'
import { useImperativeHandle } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { assetment_details, assetment_edit_data, assetment_list_data } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onChange }, ref) => {
    const quillRef = React.useRef();

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.focus();
        }
    }, []);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (quillRef.current) {
                quillRef.current.focus();
            }
        },
        getEditor: () => {
            return quillRef.current?.getEditor();
        }
    }));

    return (
        <ReactQuill
            ref={quillRef}
            value={value || ''}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
});

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];

export default function OEditAssessment({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate();
    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
    const [slot, setSlot] = useState([])

    const location = useLocation();
    var edit_id = location?.state;

    const schema = yup.object().shape({
        topic: yup.string().required("Please enter assessment topic")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Assessment topic cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),
        description: yup.string().required("Please enter assessment description"),
        due_date: yup.string().required('Please select due date'),
        due_time: yup.string().required('Please select due time'),
        score: yup.number()
            .typeError("Score must be a valid number")
            .required("Please enter score")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Score cannot consist of only spaces',
                (value) => value !== undefined && value !== null && value.toString().trim().length > 0
            )
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [assessmentData, setAssessmentData] = useState([])
    var [classTitleLength, setclassTitleLength] = useState(0);
    const [description, setDescription] = useState('');
    const [attachmentFileName, setAttachmentFileName] = useState('');
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const [isNewAttachment, setIsNewAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const assessment_data = async () => {
        try {
            const response = await assetment_details({ assetment_id: edit_id });
            console.log('assetment_details :', response);
            if (response?.code == 1) {
                const data = response?.data?.[0];
                // Set initial attachment if it exists
                if (data?.attachments?.length) {
                    const formattedAttachments = data.attachments.map(att => ({
                        attachments: att.attchments,
                        attachment_type: att.attachment_type
                    }));
                    setAttachments(formattedAttachments);
                }
                if (data?.attachments?.[0]) {
                    setCurrentAttachment({
                        attachments: data.attachments[0].attchments,
                        attachment_type: data.attachments[0].attachment_type
                    });
                    // Extract and set filename from URL
                    const fileName = data.attachments[0].attchments.split('/').pop();
                    setAttachmentFileName(fileName);
                }
                setIsNewAttachment(false); // Reset new attachment flag

                // ... rest of the data setting remains same
                setDescription(data?.description || '');
                reset({
                    topic: data?.topic,
                    description: data?.description,
                    due_date: data?.due_date,
                    due_time: data?.due_time,
                    hours: data?.duration?.split(' ')?.[0],
                    minutes: data?.duration?.split(' ')?.[2],
                    score: data?.total_score,
                });
                setSlot(data?.slot_id);
                setAssessmentData(data);
            } else {
                TOAST_ERROR(response?.message);
            }
        } catch (error) {
            TOAST_ERROR("An error occurred while fetching assessment details.");
        }
    };

    let classTitleData = (e) => {
        setclassTitleLength(0)
    }

    useEffect(() => {
        assessment_data();
    }, [])

    const handleImageChange = async (image, type) => {
        if (!image) return;
        try {
            TOAST_SUCCESS("Your attachment has been uploaded!");
            isLoader(true);
            const imageName = await uploadImageOnAWS(image, "assesment_attachements");
            isLoader(false);

            setAttachments(prev => [...prev, {
                attachments: imageName,
                attachment_type: type
            }]);

            clearErrors("attachments");
        } catch (error) {
            TOAST_ERROR("Failed to upload attachment");
        }
    };

    const handleFileInput = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            handleImageChange(file, type);
        }
    };

    const handleDescriptionChange = (value) => {
        const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
        const validatedValue = isEmptyDescription ? '' : value;
        setDescription(validatedValue);
        setValue('description', validatedValue);
        clearErrors('description');
    };

    let onSubmit = async (data) => {
        isLoader(true)
        let image = data?.attachments_data
        if (image instanceof Blob) image = await uploadImageOnAWS(image, "class_image");
        const formattedAttachments = attachments.map(att => ({
            attchments: att.attachments.split('/').pop(), // This gets just the filename
            attachment_type: att.attachment_type
        }));
        let assessment_data = {
            assetment_id: edit_id,
            topic: data.topic,
            description: description,
            // attachments: image,
            due_date: data.due_date,
            due_time: data.due_time,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
            total_score: data.score,
            attachment: {
                activity_type: "assetment",
                attachments: formattedAttachments
            }
        }

        assetment_edit_data(assessment_data).then((res) => {

            if (res?.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(res.message);
                navigate('/organization-tech-detail', {
                    state: { id: assessmentData.activity_id, class: assessmentData.activity_type, type_class: "Online", slot: slot }
                })
            } else {
                isLoader(false)
                TOAST_ERROR(res.message);
            }
        });
        reset();
    }

    const handleDeleteAttachment = (index) => {
        setAttachments(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                            <span>
                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                </svg>
                                <span className="ms-2">Back</span>
                            </span></a>
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="mb-4">
                                    <h2 className="mb-2 poppins fw-bold">Edit Assessment Information</h2>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Add Assessment Topic</label>
                                        <div className="position-relative class_title">

                                            <input type="text" {...register("topic")} maxLength={50} name="topic" className="form-control" placeholder id defaultValue={assessmentData.topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                            <span className="position-absolute word_count">
                                                {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                            </span>
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.topic?.message}</span></p>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Add Assesment Description</label>
                                        <div className="position-relative class_summary">
                                            <Editor
                                                value={description}
                                                onChange={handleDescriptionChange}
                                            />
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4 attach_box">
                                        <label className="form-label">Add Attachments</label>
                                        <div className="position-relative">
                                            <div className="textarea-wrapper">
                                                <textarea
                                                    className="form-control"
                                                    rows={7}
                                                    // value={attachmentFileName}
                                                    readOnly
                                                />
                                                <div className="attachments-preview p-2">
                                                    {attachments.map((attachment, index) => {
                                                        const fileName = attachment.attachments.split('/').pop();
                                                        return (
                                                            <div key={index} className="attachment-item">
                                                                <span>{fileName}</span>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleDeleteAttachment(index)}
                                                                    className="btn-close"
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </div>
                                            <div className="att_icon_list position-absolute">
                                                <ul className="d-flex align-items-center justify-content-end">
                                                    <li>
                                                        <label htmlFor={`attach1`}>
                                                            <a>
                                                                <img src="./assets/images/attach1.png" alt="attach1" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id={`attach1`}
                                                            onInput={(e) => {
                                                                const file = e.target.files[0];
                                                                const fileType = file.type.startsWith('image')
                                                                    ? 'image'
                                                                    : file.type.startsWith('video')
                                                                        ? 'video'
                                                                        : file.type === 'application/pdf'
                                                                            ? 'pdf'
                                                                            : file.type ===
                                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                                ? 'docx'
                                                                                : 'xlsx'; // For Excel
                                                                handleImageChange(file, fileType);
                                                            }}
                                                            {...register("attachments")}
                                                            accept="image/*,video/*,application/pdf,.docx,.xlsx"
                                                            className="file-input"
                                                            style={{ visibility: 'hidden', display: 'none' }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach2'>
                                                            <a>
                                                                <img src="./assets/images/attach2.png" alt="attach2" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach2'
                                                            onChange={(e) => handleFileInput(e, 'video')}
                                                            accept="video/*"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach3'>
                                                            <a>
                                                                <img src="./assets/images/attach3.png" alt="attach3" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach3'
                                                            onChange={(e) => handleFileInput(e, 'image')}
                                                            accept="image/*"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach4'>
                                                            <a>
                                                                <img src="./assets/images/attach4.png" alt="attach4" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach4'
                                                            onChange={(e) => handleFileInput(e, 'docx')}
                                                            accept=".docx"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach5'>
                                                            <a>
                                                                <img src="./assets/images/attach5.png" alt="attach5" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach5'
                                                            onChange={(e) => handleFileInput(e, 'xlsx')}
                                                            accept=".xlsx"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach6'>
                                                            <a>
                                                                <img src="./assets/images/attach6.png" alt="attach6" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach6'
                                                            onChange={(e) => handleFileInput(e, 'pdf')}
                                                            accept=".pdf"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Assessment Due Date</label>
                                                <input type="date" {...register("due_date")} name="due_date" min={currentDate} className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_date?.message}</span></p>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Assessment Due Time</label>
                                                <input type="time" {...register("due_time")} name="due_time" className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_time?.message}</span></p>

                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mb-3">Assesment duration</h3>
                                    <div className="d-md-flex mb-4 align-items-center">
                                        <p className="fs-6  mb-md-0 mb-3">Expected Time spent On Assesment Hours</p>
                                        <div className="d-md-flex ms-3">
                                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                    <option value={0}>0 </option>
                                                    <option value={1}>1 </option>
                                                    <option value={2}>2 </option>
                                                    <option value={3}>3 </option>
                                                    <option value={4}>4 </option>
                                                </select>
                                                <span className="ms-3">Minutes</span>
                                            </div>
                                            <div className="ms-md-3">
                                                <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                    <option value={0}>0 </option>
                                                    <option value={5}>5 </option>
                                                    <option value={10}>10 </option>
                                                    <option value={15}>15 </option>
                                                    <option value={20}>20 </option>
                                                    <option value={25}>25 </option>
                                                    <option value={30}>30 </option>
                                                    <option value={35}>35 </option>
                                                    <option value={40}>40 </option>
                                                    <option value={45}>45 </option>
                                                    <option value={45}>50 </option>
                                                    <option value={45}>55 </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {(errors.hours && errors.minutes) && (
                                        <p className="text-danger mb-3">Duration cannot be 0 hours and 0 minutes</p>
                                    )}
                                    <h3 className="mb-3">Assesment Score</h3>
                                    <div className="form-group mb-4">
                                        <label className="form-label">total score for this assesment</label>
                                        <input type="number" {...register("score")} name="score" className="ms-3" placeholder id />
                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.score?.message}</span></p>
                                    </div>
                                    <div className="button">
                                        <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                            Edit Assessment
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
