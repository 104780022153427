import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { assetment_details, classroom_lesson_list, disscussion_data, teacher_homework_view_details } from "../../../api/apiHandler";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import { formatDate } from "../../../utils/common.service";
import dayjs from 'dayjs'; // Import dayjs library for date and time formatting
import OHeader_Dashboard from "../OInclude/OHeader_Dashboard";
import OFooter_Dashboard from "../OInclude/OFooter_Dashboard";

export default function OViewDetailPage({ isLoader }) {
    window.scrollTo(0, 0)
    const [pageContent, setPageContent] = useState([]);
    console.log('pageContent :', pageContent);
    const location = useLocation();
    var id = location?.state;
    console.log('ViewDetailPage :', id);
    //   const { id } = useParams();
    const navigate = useNavigate();
    const type = id?.type;

    useEffect(() => {
        id && getPageContent();
    }, [id, type]);

    const getPageContent = async () => {
        try {
            let res;
            switch (type) {
                case "class":
                    isLoader(true)
                    res = await classroom_lesson_list({ classroom_id: id.id });
                    break;
                case "homework":
                    isLoader(true)
                    res = await teacher_homework_view_details({ homework_id: id.id });
                    break;
                case "assessment":
                    isLoader(true)
                    res = await assetment_details({ assetment_id: id.id });
                    break;
                case "discussion":
                    isLoader(true)
                    res = await disscussion_data({ discussion_id: id.id });
                    break;
                default:
            }
            console.log('res123 :', res);

            if (+res.code === 1) {
                isLoader(false)
                setPageContent(res.data?.[0]);
            } else {
                isLoader(false)
            }
        } catch (err) {
            console.log(err.message);
        }
    };

    const displayContent = pageContent?.attachments?.map((item, index) => {
        console.log('item :', item.attchments);
        return (
            <div >
                <div className="row mb-5 middle_contn px-3">
                    <div key={index} className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                        <div className="certi_box bg-transparent shadow-none">
                            <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                            <div className="" >
                                    {item?.attachment_type == "image" ? (
                                        <img src={item?.attachment || item?.attchments} alt="blog1" className="img-fluid    " style={{
                                            height: '400px',
                                            width: '500px',
                                            position: 'relative',
                                            marginRight: '224px'
                                        }} />
                                    ) : item.attachment_type == "video" ? (
                                        <video id="video" loop style={{
                                            height: '400px',
                                            width: '500px',
                                            position: 'relative',
                                            marginRight: '224px'
                                        }} controls="hide" poster="">
                                            <source src={item?.attachment || item?.attchments} type="video/mp4" />
                                        </video>

                                    ) : (item.attachment_type == "docx" || item.attachment_type == "xlsx") ? (<>
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={24} height={22} viewBox="0 0 48 48">
                                                <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                            </svg>

                                            <a
                                                href={item?.attachment || item?.attchments}
                                                download={item?.attachment || item?.attchments}
                                                target="_blank"
                                                className="border-radius-10"

                                            >
                                                 {item.attachment_type} Download</a>
                                        </div>
                                    </>
                                    ) : item.attachment_type == "pdf" ? (<>
                                        <div >
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={24} height={22} viewBox="0 0 48 48">
                                                <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                            </svg>

                                            <a
                                                href={item?.attachment || item?.attchments}
                                                download={item?.attachment || item?.attchments}
                                                target="_blank"
                                                className="border-radius-10"

                                            >
                                                Pdf Download</a>
                                        </div>
                                    </>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* <div className="">
                    <ul>


                        <li className="mb-2">
                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Description : <span className="fw-500 text-dif-black font-14">&nbsp;{item.description}</span></span>
                        </li>
                    </ul>
                </div> */}
            </div>
        );
    });

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div>
                            <Link to="#" onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block">
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span>
                            </Link>
                            {/* <div className="row blogs_detail">
                                <div className="col-lg-12">
                                    <div className="certi_box bg-transparent shadow-none">
                                        <div className="certi_ctn px-0">
                                            <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="dwnl_img">
                                                        <img src={pageContent?.teacher_profile_image} alt="center_div_img2" />
                                                    </div>
                                                    <div className="ms-3">
                                                        <span className="font-14 fw-bold">{pageContent?.teacher_name}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span>{formatDate(pageContent?.created_at, "MMM D, YYYY")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row blogs_detail">
                                <div className="col-lg-12">
                                    <div className="certi_box bg-transparent shadow-none">
                                        <div className="certi_ctn border-top border-dark px-0">
                                            <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="dwnl_img">
                                                        <h3 className="mb-4 poppins">{pageContent?.lession_topic || pageContent?.topic} </h3>

                                                    </div>

                                                </div>
                                                <div>
                                                    <span className="mb-4 poppins">Due Date :&nbsp;{dayjs(dayjs().format('YYYY-MM-DD') + pageContent?.due_time).format('hh:mm A')}, {formatDate(pageContent?.due_date, "MMM D, YYYY")} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="blogs_detail">
                                <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mb-1">
                                        <div className="dwnl_img ">
                                            <img src={pageContent?.teacher_profile_image} width={50} style={{ height: "50px" }} alt="center_div_img2" />
                                        </div>
                                        <div className="ms-3">
                                            <span className="font-14 fw-bold">{pageContent?.teacher_name}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span>{formatDate(pageContent?.created_at, "MMM D, YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="blogs_detail mb-4">
                                <div className="certi_box bg-transparent shadow-none">
                                    <div className="certi_ctn border-top border-dark px-0">
                                        <div className="dwnload_btm d-flex justify-content-between align-items-center">
                                            <h2 className="poppins fw-bold fs-3 mt-4 text-purple">{pageContent?.lession_topic || pageContent?.topic} </h2>
                                            {pageContent?.due_date && (
                                                <div>
                                                    <span className="poppins">
                                                        Due Date :&nbsp;{dayjs(dayjs().format("YYYY-MM-DD") + pageContent?.due_time).format("hh:mm A")},{" "}
                                                        {formatDate(pageContent?.due_date, "MMM D, YYYY")}{" "}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <div className="row blogs_detail border-top border-dark ">
                                <div className="mb-4 mt-2">  <h3 className="mb-4 poppins">{pageContent?.lession_topic || pageContent?.topic} Due Date :&nbsp;{dayjs(dayjs().format('YYYY-MM-DD') + pageContent?.due_time).format('hh:mm A')}, {formatDate(pageContent?.due_date, "MMM D, YYYY")} </h3></div>

                            </div> */}
                            {/* <h2 className="fw-500 font-14" style={{ color: '#570861' }}>Due Date : <span className="fw-500 text-dif-black font-14">&nbsp;{dayjs(dayjs().format('YYYY-MM-DD') + pageContent?.due_time).format('hh:mm A')}, {formatDate(pageContent?.due_date, "MMM D, YYYY")} </span></h2> */}
                            <div>

                                <div className="">
                                    {/* <ul>

                                        <li className="mb-2">
                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Topic Name : <span className="fw-500 text-dif-black font-14">&nbsp;{pageContent?.topic}</span></span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Due Date : <span className="fw-500 text-dif-black font-14">&nbsp;{formatDate(pageContent?.due_date, "MMM D, YYYY")}</span></span>
                                        </li>
                                        <li className="mb-2">
                                            <span className="fw-500 font-14" style={{ color: '#570861' }}>Due Time : <span className="fw-500 text-dif-black font-14">&nbsp; {dayjs(dayjs().format('YYYY-MM-DD') + pageContent?.due_time).format('hh:mm A')}</span></span>
                                        </li>
                                     

                                    </ul> */}
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: pageContent?.description }} className="mb-4 mt-2 p-3 lesson-details" />

                                {displayContent}
                                {/* <p className="mb-4 fs-6">{pageContent?.description}</p> */}

                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    );
}
