import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader from '../../Include/THeader'
import THeader_Dashboard from '../../Include/THeader_Dashboard'
import { read_meetings, teacher_club_details, teacher_competition_details, teacher_delete_meetings, teacher_edit_meeting_details, teacher_edit_meetings, teacher_meetings_list, xyz } from '../../../../api/apiHandler'
import dayjs from 'dayjs';
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from 'sweetalert2'
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Modals from 'react-modal';

var COMMON_STYLE_FOR_POPUP = {
  content: {
    background: 'none',
    border: '0px',
  },
}

export default function UpcomingMeeting({ isLoader }) {
  const navigate = useNavigate();
  const location = useLocation();

  var id = location?.state;
  console.log('UpcomingMeeting :', id);
  const [data, setData] = useState([])
  const [meetingArray, setMeetingArray] = useState([]);
  console.log('meetingArray :', meetingArray);
  const [StartDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [modalIsOpenEdit, setIsOpenEdit] = useState(false);
  const [StartHour, setStartHour] = useState('1');
  const [StartMin, setStartMin] = useState('00');
  const [EndHour, setEndHour] = useState('2');
  const [EndMin, setEndMin] = useState('00');
  const [StartMeredian, setStartMeredian] = useState('AM');
  const [EndMeredian, setEndMeredian] = useState('AM');
  const [TimePrint, setTimePrint] = useState('');
  const [timePrints, setTimePrints] = useState([]);
  const [isMultiday, setIsMultiday] = useState([]);
  const [sessionCount, setSessionCount] = useState(1);
  const [numberOfWeeks, setNumberOfWeeks] = useState(1);
  const [classData, setClassData] = useState([]);
  const [MeetingID, setMeetingID] = useState('')
  const [selectedRadio, setSelectedRadio] = useState("Public");
  const [StartDateValidation, setStartDateValidation] = useState(new Date().toISOString().split('T')[0]);
  const [isError, setIsError] = useState(0);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);

  console.log('meetingArray :', meetingArray);

  const teacherTimezone = classData?.time_zone

  const schema = yup.object().shape({
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const checkIfMultiday = async () => {
    if (id?.activity_type === "class") {
      isLoader(true)
      xyz({ classes_id: id?.id }).then((response) => {
        console.log('res123 :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response?.data[0]);
          setIsMultiday((response?.data[0]?.length === "Multi-Day" || response?.data[0]?.length === "Ongoing Weekly" || response?.data[0]?.length === "Ongoing Monthly") ? true : false)
        } else {
          isLoader(false);
          setClassData([]);
        }
      });
    } else if (id?.activity_type === "club") {
      isLoader(true)
      teacher_club_details({ club_id: id?.id }).then((response) => {
        console.log('response :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response?.data[0]);
          setIsMultiday((response?.data[0]?.length === "Multi-Day" || response?.data[0]?.length === "Ongoing Weekly" || response?.data[0]?.length === "Ongoing Monthly") ? true : false)
        } else {
          isLoader(false);
          setClassData([]);
        }
      });
    } else {
      isLoader(true)
      teacher_competition_details({ competition_id: id?.id }).then((response) => {
        console.log('response :', response);
        if (response?.code == 1) {
          isLoader(false)
          setClassData(response?.data[0]);
          setIsMultiday((response?.data[0]?.length === "Multi-Day" || response?.data[0]?.length === "Ongoing Weekly" || response?.data[0]?.length === "Ongoing Monthly") ? true : false)
        } else {
          isLoader(false);
          setClassData([]);
        }
      });
    }
  };

  useEffect(() => {
    if (modalIsOpenEdit) {
      checkIfMultiday();
    }
  }, [modalIsOpenEdit]);

  useEffect(() => {
    checkIfMultiday();
  }, [])


  useEffect(() => {
    // Check if classData is an array and has the expected structure before accessing properties
    if (classData?.length === "Multi-Day" || classData?.length === "Ongoing Weekly" || classData?.length === "Ongoing Monthly") {
      const sessionPerWeek = parseInt(classData.session_per_week, 10);
      console.log('sessionPerWeek :', sessionPerWeek);
      const weeks = parseInt(classData.number_of_week, 10);
      setSessionCount(sessionPerWeek || 1);
      setNumberOfWeeks(weeks || 1);
    } else {
      setSessionCount(1);
      setNumberOfWeeks(1);
    }
  }, [classData]);

  const Meeting_list = () => {
    let submit_data = {
      classes_id: id?.id,
      activity_type: id?.activity_type,
      user_type : localStorage.getItem("type")
    };

    console.log("submit_data:", submit_data);
    isLoader(true);

    teacher_meetings_list(submit_data)
      .then((response) => {
        console.log("teacher_meetings_list:", response);

        if (response?.code === "1") {
          isLoader(false);
          setData(response?.data);

          // Initialize an empty array to collect meeting IDs
          const newMeetingArray = [];

          // Check if the response contains valid data
          if (Array.isArray(response?.data)) {
            console.log("Response Data (Array):", response.data);

            // Loop through each slot and extract IDs from the nested dates array
            response?.data.forEach((slot) => {
              if (Array.isArray(slot?.dates)) {
                slot.dates.forEach((date) => {
                  if (date?.id) {
                    newMeetingArray.push(date.id); // Add the id to the array
                  } else {
                    console.log("Missing ID in date object:", date);
                  }
                });
              } else {
                console.log("No valid dates array in slot:", slot);
              }
            });

            // Update state with the collected IDs
            setMeetingArray(newMeetingArray);
            console.log("Updated Meeting IDs:", newMeetingArray); // Log the updated meeting array
          } else {
            console.log("No valid meeting data found.");
          }
        } else {
          isLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error during API call:", error); // Handle any errors
        isLoader(false);
      });
  };

  const readMeetings = () => {
    isLoader(true)
    read_meetings({ meeting_id: meetingArray }).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    Meeting_list();
  }, []);

  useEffect(() => {
    if (meetingArray.length > 0) {
      readMeetings();
    }
  }, [meetingArray]);

  const [editDetails, setEditDetails] = useState('')

  const EditMeetingDetails = (MeetingEdit_id) => {
    teacher_edit_meeting_details({ 'meeting_id': MeetingEdit_id }).then((response) => {
      console.log("teacher_edit_meeting_details", response)
      if (response?.code == 1) {
        setEditDetails(response?.data)
        setStartDate(response?.data[0]?.date)
        setIsOpenEdit(true)
        // setStartDate(response?.data[0]?.date)
        console.log("hello", response?.data[0]?.start_time?.split(':')[0])
        var start_time = dayjs().format('YYYY-MM-DD') + " " + response?.data[0]?.start_time
        var end_time = dayjs().format('YYYY-MM-DD') + " " + response?.data[0]?.end_time
        console.log('start_time :', start_time);
        reset({
          date: response?.data[0]?.date,
          start_time_hour: dayjs(start_time).format('h'),
          start_time_min: dayjs(start_time).format('mm'),
          start_time_AM_PM: dayjs(start_time).format('A'),
          end_time_hour: dayjs(end_time).format('h'),
          end_time_min: dayjs(end_time).format('mm'),
          end_time_AM_PM: dayjs(end_time).format('A'),
        });
        setStartHour(dayjs(start_time).format('h'))
        setStartMin(dayjs(start_time).format('mm'))
        setStartMeredian(dayjs(start_time).format('A'))
        setEndHour(dayjs(end_time).format('h'))
        setEndMin(dayjs(end_time).format('mm'))
        setEndMeredian(dayjs(end_time).format('A'))
        setTimePrint((dayjs(response?.data[0]?.date, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${dayjs(start_time).format('h')}:${dayjs(start_time).format('mm')} ${dayjs(start_time).format('A')} – ${dayjs(end_time).format('h')}:${dayjs(end_time).format('mm')} ${dayjs(end_time).format('A')} ${teacherTimezone}`))
      } else {

      }
    });
  }

  const delete_class = (index, id) => {

    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        teacher_delete_meetings({ meeting_id: id }).then((response) => {
          if (response?.code == 1) {
            setData(data.filter(o => o.id !== id));
            TOAST_SUCCESS(response?.message);

          } else {
            TOAST_ERROR(response?.message);
          }
        })
      }
    })
  }

  const onEditSubmit = async (data) => {
    // Get current time in teacher's timezone
    const currentDateTime = dayjs().tz(teacherTimezone);

    // Construct start and end times using the date and time input from the form in the teacher's timezone
    const startDateTime = dayjs.tz(
      `${data.date} ${StartHour}:${StartMin} ${StartMeredian}`,
      'YYYY-MM-DD h:mm A',
      teacherTimezone
    );

    const endDateTime = dayjs.tz(
      `${data.date} ${EndHour}:${EndMin} ${EndMeredian}`,
      'YYYY-MM-DD h:mm A',
      teacherTimezone
    );

    // Debugging logs
    console.log('Start DateTime:', startDateTime.format());
    console.log('End DateTime:', endDateTime.format());
    console.log('Current DateTime:', currentDateTime.format());

    // Get the maximum allowed duration from classData
    const maxAllowedDurationMinutes = getDurationInMinutes(classData.duration);
    console.log("Maximum allowed duration in minutes:", maxAllowedDurationMinutes);

    // Check if the meeting start time is in the past
    if (startDateTime.isBefore(currentDateTime)) {
      TOAST_ERROR("Meeting start time must not be in the past.");
      return;
    }

    // Check if end time is before or the same as start time
    if (endDateTime.isBefore(startDateTime) || endDateTime.isSame(startDateTime)) {
      TOAST_ERROR("End time must be after start time for the meeting.");
      return;
    }

    // Calculate duration in minutes
    const durationMinutes = endDateTime.diff(startDateTime, 'minute');
    console.log('Meeting duration in minutes:', durationMinutes);

    // Validate against maximum allowed duration
    if (durationMinutes > maxAllowedDurationMinutes) {
      TOAST_ERROR(
        `Meeting duration cannot exceed ${formatDuration(maxAllowedDurationMinutes)}. ` +
        `Your selected duration is ${formatDuration(durationMinutes)}. ` +
        `Please adjust the meeting time.`
      );
      return;
    }

    // If all validations pass, proceed with the meeting edit
    let Edit_meeting = {
      meeting_id: MeetingID,
      visibility_mode: selectedRadio,
      date: data.date,
      start_time: `${StartHour}:${StartMin} ${StartMeredian}`,
      end_time: `${EndHour}:${EndMin} ${EndMeredian}`,
      link: "https://www.google.com/",
    };

    try {
      const res = await teacher_edit_meetings(Edit_meeting);
      if (res?.code == 1) {
        await Meeting_list();
        setIsOpenEdit(false);
        TOAST_SUCCESS(res?.message);
        navigate('/upcoming_meeting', { state: id });
      } else {
        TOAST_ERROR(res?.message);
      }
    } catch (error) {
      console.error("Error editing meeting:", error);
      TOAST_ERROR("An error occurred while editing the meeting");
    }
  };

  // Improved helper function to parse duration string
  const getDurationInMinutes = (durationString) => {
    if (!durationString) return 60; // Default to 60 minutes if duration is missing

    // Handle both "Hours" and "Hour" in the string
    const match = durationString.match(/(\d+)\s*Hours?\s+(\d+)\s*Minutes?/i);
    if (!match) return 60;

    const [hours, minutes] = match.slice(1).map(Number);
    return hours * 60 + minutes;
  };

  // Helper function to format duration for display
  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    if (hours === 0) {
      return `${mins} minutes`;
    }
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} and ${mins} minutes`;
  };

  const handleEditDateChange = (e) => {
    const selectedDate = e.target.value;
    setStartDate(dayjs(selectedDate).format('YYYY-MM-DD'));
    setTimePrint(
      dayjs(selectedDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') +
      ", " +
      `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`
    );
  };

  const handleEditStartHourChange = (e) => {
    const selectedStartHour = e.target.value;
    setStartHour(selectedStartHour);

    // Logic to automatically change end time based on start time
    const selectedEndHour = parseInt(selectedStartHour) + 1;
    // alert(selectedEndHour)
    setEndHour(selectedEndHour.toString());
    if (StartMeredian == EndMeredian) {
      if (parseInt(selectedEndHour) > parseInt(selectedStartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${selectedStartHour}:${StartMin} ${StartMeredian} – ${selectedEndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
      else {
        setIsError(1)
        setTimePrint('Invalid date range')
      }
    } else {

      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)
      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${selectedStartHour}:${StartMin} ${StartMeredian} – ${selectedEndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndHourChange = (e) => {
    const selectedEndtHour = e.target.value;
    setEndHour(selectedEndtHour);

    if (StartMeredian == EndMeredian) {
      if (parseInt(selectedEndtHour) >= parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${selectedEndtHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {

        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${selectedEndtHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleStartMinChange = (e) => {
    const selectedStartMin = e.target.value;
    setStartMin(selectedStartMin);
    if (StartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${selectedStartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${selectedStartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndMinChange = (e) => {
    const selectedEndMin = e.target.value;
    setEndMin(selectedEndMin);
    if (StartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${selectedEndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${selectedEndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleStartMeredianChange = (e) => {
    const selectedStartMeredian = e.target.value;
    setStartMeredian(selectedStartMeredian);
    if (selectedStartMeredian == EndMeredian) {
      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${selectedStartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (selectedStartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${selectedStartMeredian} – ${EndHour}:${EndMin} ${EndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const handleEndMeredianChange = (e) => {
    const selectedEndMeredian = e.target.value;
    console.log('selectedEndMeredian :', selectedEndMeredian);
    setEndMeredian(selectedEndMeredian);

    console.log('StartMeredian1 :', StartMeredian);
    if (StartMeredian == selectedEndMeredian) {

      if (parseInt(EndHour) > parseInt(StartHour)) {

        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${selectedEndMeredian} ${teacherTimezone}`))
        setIsError(0)

      } else {
        setTimePrint('Invalid date range')
        setIsError(1)

      }
    } else {
      if (StartMeredian == 'PM') {
        setTimePrint('Invalid date range')
        setIsError(1)

      } else {
        setTimePrint((dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') + ", " + `${StartHour}:${StartMin} ${StartMeredian} – ${EndHour}:${EndMin} ${selectedEndMeredian} ${teacherTimezone}`))
        setIsError(0)

      }
    }
  };

  const updateTimePrint = (index, meetingData) => {
    const {
      date,
      start_time_hour,
      start_time_min,
      start_time_AM_PM,
      end_time_hour,
      end_time_min,
      end_time_AM_PM
    } = meetingData;
    const formattedDate = dayjs(date).format('ddd, MMM D, YYYY');
    const startTime = `${start_time_hour}:${start_time_min} ${start_time_AM_PM}`;
    const endTime = `${end_time_hour}:${end_time_min} ${end_time_AM_PM}`;

    const newTimePrint = `${formattedDate}, ${startTime} – ${endTime} ${teacherTimezone}`;
    setTimePrints(prevTimePrints => {
      const newTimePrints = [...prevTimePrints];
      newTimePrints[index] = newTimePrint;
      return newTimePrints;
    });
  };

  const parseDuration = (durationString) => {
    if (!durationString) return { hours: 0, minutes: 0 };

    const match = durationString.match(/(\d+)\s*Hours?\s+(\d+)\s*Minutes?|(\d+)\s*Hours?|(\d+)\s*Minutes?/i);
    if (!match) return { hours: 0, minutes: 0 };

    if (match[1] && match[2]) {
      // Format: "X Hours Y Minutes"
      return { hours: parseInt(match[1]), minutes: parseInt(match[2]) };
    } else if (match[3]) {
      // Format: "X Hours"
      return { hours: parseInt(match[3]), minutes: 0 };
    } else if (match[4]) {
      // Format: "X Minutes"
      return { hours: 0, minutes: parseInt(match[4]) };
    }

    return { hours: 0, minutes: 0 };
  };

  // Function to calculate end time based on start time and duration
  const calculateEndTime = (startHour, startMinute, startMeridian, duration) => {
    // Convert to 24-hour format for calculation
    let hour24 = parseInt(startHour);
    if (startMeridian === "PM" && hour24 !== 12) {
      hour24 += 12;
    } else if (startMeridian === "AM" && hour24 === 12) {
      hour24 = 0;
    }

    // Parse duration
    const { hours: durationHours, minutes: durationMinutes } = parseDuration(duration);

    // Calculate total minutes
    let totalMinutes = (hour24 * 60) + parseInt(startMinute) + (durationHours * 60) + durationMinutes;

    // Convert back to 12-hour format
    let endHour = Math.floor(totalMinutes / 60) % 24;
    let endMinute = totalMinutes % 60;
    let endMeridian = "AM";

    if (endHour >= 12) {
      endMeridian = "PM";
      if (endHour > 12) {
        endHour -= 12;
      }
    }
    if (endHour === 0) {
      endHour = 12;
    }

    return {
      hour: endHour.toString(),
      minute: endMinute < 10 ? `0${endMinute}` : endMinute.toString(),
      meridian: endMeridian
    };
  };

  const handleEditTimeChange = (e, field) => {
    const value = e.target.value;

    // Update the corresponding state based on the field
    switch (field) {
      case 'start_time_hour':
        setStartHour(value);
        // Calculate end time based on duration from classData
        const endTime = calculateEndTime(
          value,
          StartMin,
          StartMeredian,
          classData?.duration
        );
        setEndHour(endTime.hour);
        setEndMin(endTime.minute);
        setEndMeredian(endTime.meridian);
        break;
      case 'start_time_min':
        setStartMin(value);
        // Recalculate end time when start minutes change
        const endTimeMin = calculateEndTime(
          StartHour,
          value,
          StartMeredian,
          classData?.duration
        );
        setEndHour(endTimeMin.hour);
        setEndMin(endTimeMin.minute);
        setEndMeredian(endTimeMin.meridian);
        break;
      case 'start_time_AM_PM':
        setStartMeredian(value);
        // Recalculate end time when meridian changes
        const endTimeMeridian = calculateEndTime(
          StartHour,
          StartMin,
          value,
          classData?.duration
        );
        setEndHour(endTimeMeridian.hour);
        setEndMin(endTimeMeridian.minute);
        setEndMeredian(endTimeMeridian.meridian);
        break;
      case 'end_time_hour':
      case 'end_time_min':
      case 'end_time_AM_PM':
        // End time fields are disabled/read-only since they're calculated automatically
        break;
    }

    // Get the updated values for time print
    const updatedStartHour = field === 'start_time_hour' ? value : StartHour;
    const updatedStartMin = field === 'start_time_min' ? value : StartMin;
    const updatedStartMeridian = field === 'start_time_AM_PM' ? value : StartMeredian;

    // Calculate the end time values for time print
    const finalEndTime = calculateEndTime(
      updatedStartHour,
      updatedStartMin,
      updatedStartMeridian,
      classData?.duration
    );

    // Update time print
    setTimePrint(
      dayjs(StartDate, 'yyyy-mm-dd').format('ddd, MMM D, YYYY') +
      ", " +
      `${updatedStartHour}:${updatedStartMin} ${updatedStartMeridian} – ` +
      `${finalEndTime.hour}:${finalEndTime.minute} ${finalEndTime.meridian} ${teacherTimezone}`
    );
    setIsError(0);
  };

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <Link onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></Link>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className="row">
                  <div className="col-xl-7">
                    <form className="mb-4">
                      <div className="meeting_div">
                        <div className="d-flex mb-4 align-items-center justify-content-between">
                          <h3 className="poppins fw-600">Upcoming Meetings</h3>
                          <Link
                            to="/scheduling_visibility"
                            state={{ id: id?.id, duration: id?.duration, activity_type: id?.activity_type }}
                            className="btn-theme bg-yellow text-center"
                          >
                            Add Schedule
                          </Link>
                        </div>
                        <ul className="next_meets mb-4">
                          {data?.length > 0 ? (
                            data.map((slot, slotIndex) => (
                              <li key={slotIndex} className="card mb-3">
                                <div className="d-md-flex justify-content-between align-items-center">
                                  <div>
                                    <h4 className="fs-6 poppins fw-600 mb-2 text-purple">
                                      Next meets on {slot?.dates[0]?.datetime_range}
                                    </h4>
                                    <span className="mb-md-0 mb-3 d-inline-block font-14">
                                      StudentScholars, {slot?.dates[0]?.status}, {slot?.dates[0]?.spaces_available}
                                    </span>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <ul className="edit_delete d-flex me-3 align-items-center justify-content-end">
                                      <li className="me-0">
                                        <Link
                                          state={id}
                                          onClick={() => {
                                            EditMeetingDetails(slot?.dates[0]?.id);
                                            setMeetingID(slot?.dates[0]?.id);
                                          }}
                                        >
                                          <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M3.10763 18.8439C3.32301 18.8439 3.36609 18.8224 3.55993 18.7793L7.43685 18.0039C7.84608 17.8962 8.25532 17.7024 8.57839 17.3793L17.9692 7.98851C19.4122 6.54543 19.4122 4.06851 17.9692 2.62543L17.1722 1.78543C15.7292 0.342356 13.2307 0.342356 11.7876 1.78543L2.39686 11.1977C2.09532 11.4993 1.87993 11.93 1.77224 12.3393L0.953781 16.2593C0.846089 16.9916 1.06147 17.7024 1.5784 18.2193C1.98763 18.6285 2.5907 18.8439 3.10763 18.8439ZM3.83993 12.7485L13.2307 3.3362C13.8553 2.71159 14.9968 2.71159 15.5999 3.3362L16.4184 4.15466C17.1507 4.88697 17.1507 5.92082 16.4184 6.63159L7.04916 16.0439L3.06455 16.7116L3.83993 12.7485Z"
                                              fill="#570861"
                                            />
                                            <path
                                              d="M17.8829 20.1797H1.98746C1.36284 20.1797 0.953613 20.5889 0.953613 21.2135C0.953613 21.8382 1.47054 22.2474 1.98746 22.2474H17.7967C18.4213 22.2474 18.9382 21.8382 18.9382 21.2135C18.9167 20.5889 18.3998 20.1797 17.8829 20.1797Z"
                                              fill="#570861"
                                            />
                                          </svg>
                                        </Link>
                                      </li>
                                    </ul>
                                    <Link
                                      state={id}
                                      onClick={() => delete_class(slotIndex, slot?.dates[0]?.id)}
                                      className="btn-theme px-2 py-1"
                                    >
                                      DELETE
                                    </Link>
                                    <Link
                                      to="/teach-detail"
                                      state={{ id: id?.id, class: id?.activity_type, type_class: "Online", slot: slot?.slot }}
                                      className="ms-3 fs-6 btn-theme rounded-circle rounded-pill px-3 btn-classroom"
                                    >
                                      Go to Your Classroom
                                    </Link> 

                                  </div>
                                </div>
                                {slot.dates.length > 1 && (
                                  <div className="mt-3">
                                    <details>
                                      <summary className="cursor-pointer">
                                        Show next {slot.dates.length - 1} meetings
                                      </summary>
                                      <ul className="list-unstyled mt-2">
                                        {slot.dates.slice(1).map((meeting, meetingIndex) => (
                                          <li key={meeting.id} className="mb-2 d-flex justify-content-between align-items-center">
                                            <div>
                                              <strong>{meeting.datetime_range}</strong>
                                              {/* - {meeting.status},{" "} */}
                                              {/* {meeting.spaces_available} */}
                                            </div>

                                            <div className="edit_delete d-flex align-items-center">
                                              <Link
                                                state={id}
                                                onClick={() => {
                                                  EditMeetingDetails(meeting.id);  // Use the meeting's id here
                                                  setMeetingID(meeting.id);  // Set the meeting ID for further use
                                                }}
                                                className="me-3"
                                              >
                                                <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path
                                                    d="M3.10763 18.8439C3.32301 18.8439 3.36609 18.8224 3.55993 18.7793L7.43685 18.0039C7.84608 17.8962 8.25532 17.7024 8.57839 17.3793L17.9692 7.98851C19.4122 6.54543 19.4122 4.06851 17.9692 2.62543L17.1722 1.78543C15.7292 0.342356 13.2307 0.342356 11.7876 1.78543L2.39686 11.1977C2.09532 11.4993 1.87993 11.93 1.77224 12.3393L0.953781 16.2593C0.846089 16.9916 1.06147 17.7024 1.5784 18.2193C1.98763 18.6285 2.5907 18.8439 3.10763 18.8439ZM3.83993 12.7485L13.2307 3.3362C13.8553 2.71159 14.9968 2.71159 15.5999 3.3362L16.4184 4.15466C17.1507 4.88697 17.1507 5.92082 16.4184 6.63159L7.04916 16.0439L3.06455 16.7116L3.83993 12.7485Z"
                                                    fill="#570861"
                                                  />
                                                  <path
                                                    d="M17.8829 20.1797H1.98746C1.36284 20.1797 0.953613 20.5889 0.953613 21.2135C0.953613 21.8382 1.47054 22.2474 1.98746 22.2474H17.7967C18.4213 22.2474 18.9382 21.8382 18.9382 21.2135C18.9167 20.5889 18.3998 20.1797 17.8829 20.1797Z"
                                                    fill="#570861"
                                                  />
                                                </svg>
                                              </Link>

                                              <Link
                                                state={id}
                                                onClick={() => delete_class(meeting.id)}  // Use the meeting's id here for deletion
                                                className="btn-theme px-2 py-1"
                                              >
                                                DELETE
                                              </Link>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </details>
                                  </div>
                                )}
                              </li>
                            ))
                          ) : (
                            <p className="text-purple fw-bold fs-5 mt-5 ms-3">
                              After you setup the schedule this class will be automatically published.
                            </p>
                          )}
                        </ul>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>

      <Modals isOpen={modalIsOpenEdit} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal">
        {/* <div className="modal fade" id="add_meeting" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title poppins fw-600" id="staticBackdropLabel">Edit a meeting</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { setIsOpenEdit(false); clearErrors(); }} aria-label="Close" />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onEditSubmit)} className="custom_form">
                <div className="form-group mb-4">
                  <label className="form-label">Enter Date</label>
                  <input type="date" className="form-control" id min={StartDateValidation} value={StartDate}  {...register("date")} onChange={(e) => handleEditDateChange(e)} name='date' />
                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.date?.message}</span></p> */}
                </div>
                <label className="form-label" style={{ "color": "#666666" }}>Enter Start Time</label>
                <div className="row">

                  <div className="col w-5">
                    <div>
                      <select
                        className="w-3" style={{ "margin": "8px", "width": "60px" }}
                        value={StartHour}
                        onChange={(e) => handleEditTimeChange(e, 'start_time_hour')}
                        name="start_time_hour"

                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                      <span className='col' >:</span>

                      <select

                        className="col" style={{ "margin": "8px", "width": "60px" }}
                        {...register("start_time_min")}
                        value={StartMin}
                        onChange={(e) => handleEditTimeChange(e, 'start_time_min')}
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}

                      </select>

                      <select

                        className="col" style={{ "width": "60px" }}
                        {...register("start_time_AM_PM")} onChange={(e) => handleStartMeredianChange(e)}
                        name="start_time_AM_PM"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>

                      </select>
                    </div>

                  </div>

                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_time?.message}</span></p> */}
                </div>

                <label className="form-label" style={{ "color": "#666666" }}>Enter End Time</label>
                <div className="row">

                  <div className="col w-5">
                    <div>
                      <select
                        className="w-3"
                        style={{ margin: "8px", width: "60px", border: "1px solid black", backgroundColor: "white", color: "black" }}
                        {...register("end_time_hour")} value={EndHour} onChange={(e) => handleEndHourChange(e)}
                        name="end_time_hour"
                        disabled
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => (
                          <option key={number} value={number}>
                            {number}
                          </option>
                        ))}
                      </select>
                      <span className='col' >:</span>

                      <select
                        disabled
                        className="col"
                        style={{ margin: "8px", width: "60px", border: "1px solid black", backgroundColor: "white", color: "black" }}
                        {...register("end_time_min")} onChange={(e) => handleEndMinChange(e)}
                        name="end_time_min"
                      >
                        {Array.from({ length: 12 }, (_, i) => i * 5).map((number) => (
                          <option key={number} value={number < 10 ? `0${number}` : `${number}`}>
                            {number < 10 ? `0${number}` : `${number}`}
                          </option>
                        ))}

                      </select>
                      <select
                        disabled
                        className="col"
                        style={{ width: "60px", border: "1px solid black", backgroundColor: "white", color: "black" }}
                        {...register("end_time_AM_PM")} onChange={(e) => handleEndMeredianChange(e)}
                        name="end_time_AM_PM"
                      >
                        <option value="AM">AM</option>
                        <option value="PM">PM</option>

                      </select>
                    </div>

                  </div>

                  {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.start_time?.message}</span></p> */}
                </div>

                {/* <div className="form-group mb-4">
                  <label className="form-label">Enter End Time</label>
                  <input
                    type="time"
                    className="form-control"
                    {...register("end_time")}
                    name="end_time"
                  />
                  <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.end_time?.message}</span></p>
                </div> */}
                <div className="text-center mb-4">
                  {/* <p className="fs-6">Fri, Jun 2, 9:00 – 10:${teacherTimezone}0/p> */}
                  {/* <p className="fs-6">{TimePrint}</p> */}

                </div>
                <div className="button">
                  <button data-bs-dismiss="modal" style={{ "width": "100%" }} className="btn-theme border-0 text-dif-black fw-600 bg-yellow text-center d-block">Edit Meeting</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* </div> */}
      </Modals>
      <TFooter_Dashboard />
    </>
  )
}
