import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import JqueryLoad from "../../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../../Pinc/PHeader_Dashboard";
import * as API from "../../../../api/apiHandler";
import { formatDate } from "../../../../utils/common.service";
import dayjs from "dayjs";
import BackToPrevious from "../../../../components/backToPrevious";
import CommonTruncatedText from "../../../../utils/commonTruncatedText";

export default function EnrollmentCommonDetailPage({isLoader}) {
  const [pageContent, setPageContent] = useState([]);
  console.log("🚀 ~ EnrollmentCommonDetailPage ~ pageContent:", pageContent);
  
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const type = location.search.split("=")[1];

  console.log("id------------------", id);

  useEffect(() => {
    if (id) {
      isLoader(true); 
      getPageContent();
    }
  }, [id, type]);

  const getPageContent = async () => {
    try {
      let res;
      switch (type) {
        case "classroom":
          res = await API.classroom_view_details({ lession_id: id });
          break;
        case "homework":
          res = await API.homework_view_details({ homework_id: id });
          break;
        case "assessment":
          res = await API.assessment_view_details({ assesment_id: id });
          break;
        default:
      }
      if (+res.code === 1) setPageContent(res.data?.[0]);
    } catch (err) {
      console.error(err.message);
    } finally {
      isLoader(false);
    }
  };

  const downloadDocx = (url) => {
    let fileName = url.split("/");
    fileName = fileName[fileName.length - 1];

    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = url;
    anchor.target = "_blank";
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const displayContent = pageContent?.lessiondata ? (
    pageContent?.lessiondata?.map((item) => {
      return (
        <div key={item.des_id}>
          <div className="row mb-5 middle_contn">
            {item.attachments.map((file, i) => {
              return (
                <div key={i} className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                  <div className="certi_box bg-transparent shadow-none">
                    <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                      <div className="blog_img">
                        {file?.attachment_type === "image" ? (
                          <img src={file?.attachment || file?.attchments} alt="blog1" className="w-100 img-fluid border-radius-10" />
                        ) : file.attachment_type === "video" ? (
                          <video id="video" loop width="100%" controls="hide" poster="">
                            <source src={file?.attachment || file?.attchments} type="video/mp4" />
                          </video>
                        ) : (
                          <button
                            className="btn-theme py-2 ms-2 d-block text-dif-black text-center"
                            onClick={() => downloadDocx(file?.attachment || file?.attchments)}
                          >
                            Download {file?.attachment_type}
                          </button>
                        )}
                        {/* <a href="#" className="play_btn_op1" tabIndex={0}>
                        <img src="./assets/images/play.png" alt="play" /> 
                      </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
              <CommonTruncatedText text={item?.description} length={100000} className="fs-6" />

        </div>
      );
    })
  ) : (
    <div>
      <div className="row mb-5 middle_contn">
        {pageContent?.attachments?.map((file, i) => {
          return (
            <div key={i} className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
              <div className="certi_box bg-transparent shadow-none">
                <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                  <div className="blog_img">
                    {file?.attachment_type === "image" ? (
                      <img src={file?.attachment || file?.attchments} alt="blog1" className="w-100 img-fluid border-radius-10" />
                    ) : file.attachment_type === "video" ? (
                      <video id="video" loop width="100%" controls="hide" poster="">
                        <source src={file?.attachment || file?.attchments} type="video/mp4" />
                      </video>
                    ) : (
                      <button
                        className="btn-theme py-2 ms-2 d-block text-dif-black text-center"
                        onClick={() => downloadDocx(file?.attachment || file?.attchments)}
                      >
                        Download {file?.attachment_type}
                      </button>
                    )}
                    {/* <a href="#" className="play_btn_op1" tabIndex={0}>
                        <img src="./assets/images/play.png" alt="play" /> 
                      </a> */}
                  </div>
                </div>
              </div>
            </div>
          );
        })}{" "}
      </div>
      <CommonTruncatedText text={pageContent?.description} length={100000} className="fs-6" />
      {/* <p className="mb-4 fs-6">{pageContent?.description}</p> */}
    </div>
  );

  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div>
              <BackToPrevious />
              <div>
                <div className="blogs_detail">
                  <div className="dwnload_btm d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center mb-1">
                      <div className="dwnl_img ">
                        <img src={pageContent?.teacher_profile_image} width={50} style={{ height: "50px" }} alt="center_div_img2" />
                      </div>
                      <div className="ms-3">
                        <span className="font-14 fw-bold">{pageContent?.teacher_name}</span>
                      </div>
                    </div>
                    <div>
                      <span>{formatDate(pageContent?.created_at, "MMM D, YYYY")}</span>
                    </div>
                  </div>
                </div>
                <div className="blogs_detail mb-4">
                  <div className="certi_box bg-transparent shadow-none">
                    <div className="certi_ctn border-top border-dark px-0">
                      <div className="dwnload_btm d-flex justify-content-between align-items-center">
                        <h3 className="poppins">{pageContent?.lession_topic || pageContent?.topic} </h3>
                        {pageContent?.due_date && (
                          <div>
                            <span className="poppins">
                              Due Date :&nbsp;{dayjs(dayjs().format("YYYY-MM-DD") + pageContent?.due_time).format("hh:mm A")},{" "}
                              {formatDate(pageContent?.due_date, "MMM D, YYYY")}{" "}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row blogs_detail">
                  <div className="col-lg-12 mb-4">
                    <div className="certi_box bg-transparent shadow-none">
                      <div className="certi_ctn border-top border-dark px-0">
                        <div className="dwnload_btm d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <div className="dwnl_img">
                              <img src={pageContent?.teacher_profile_image} alt="center_div_img2" />
                            </div>
                            <div className="ms-3">
                              <span className="font-14 fw-bold">{pageContent?.teacher_name}</span>
                            </div>
                          </div>
                          <div>
                            <span>{formatDate(pageContent?.created_at, "MMM D, YYYY")}</span> • <span>{pageContent?.duration}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className="mb-2 poppins">{pageContent?.lession_topic || pageContent?.topic}</h2>
                </div> */}
                {displayContent}
                {/* <div>
                  <h3 className="mb-4 poppins text-dif-black">{pageContent?.lession_topic || pageContent?.topic}</h3>
                  <div className="row mb-5 middle_contn">
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                      <div className="certi_box bg-transparent shadow-none">
                        <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                          <div className="blog_img">
                            <img src={pageContent.attachment || pageContent.attachments} alt="blog1" className="w-100 img-fluid border-radius-10" />
                            <a href="#" className="play_btn_op1" tabIndex={0}>
                              <img src="./assets/images/play.png" alt="play" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="certi_box bg-transparent shadow-none">
                        <div className="certi_images text-center bg-transparent p-0 border-bottom-0">
                          <div className="blog_img">
                            <img src={pageContent.attachment || pageContent.attachments} alt="blog1" className="w-100 img-fluid border-radius-10" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mb-4 fs-6">{pageContent.description}</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <PFooter_Dashboard />
    </>
  );
}
