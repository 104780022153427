const CommonIntroVideo = ({type}) => {
console.log('type :', type);

  const userType = localStorage.getItem('userType')
  return (
    <div className="video_boxs_sec position-relative">
      <div>
        <div className="video_boxs">
        <video
  id="video"
  className="position-static rounded-3"
  loop
  width="100%"
  controls
  src={
    userType === 'learner' && type == "Online" 
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom - learners - Online.mp4"
      : userType === 'learner' && type == "In-Person"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom - learners - In-Person.mp4"
      : userType === 'learner' && type == "Offline Course"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom - learners - Offline course.mp4"
      : userType === 'above_18' && type == "Online"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -learners over 18-Online.mp4"
      : userType === 'above_18' && type == "In-Person"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -learners over 18-In-person.mp4"
      : userType === 'above_18' && type == "Offline Course"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom - learners - Offline course.mp4"
      : userType === 'below_18' && type == "Online"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -parents-Online (2).mp4"
      : userType === 'below_18' && type == "In-Person"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -parents-In-person.mp4"
      : userType === 'below_18' && type == "Offline Course"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -parents-offline.mp4"
      : userType === 'after_school' && type == "Online"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -Schools and afterschool-Online.mp4"
      : userType === 'after_school' && type == "In-Person"
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -schools and afterschool-In-person.mp4"
      : userType === 'after_school' && type == "Offline Course"    
      ? "https://studentscholars.s3.amazonaws.com/video/Classroom -schools and afterschools-offline.mp4"
      : "https://studentscholars.s3.amazonaws.com/video/Private School - Intro (1).mp4"
  }
/>

        </div>
      </div>
      {/* <div className="cancel_btn position-absolute">
        <a href="javascript:void(0)" className="colse_div_video">
          <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" height={25} viewBox="0 -960 960 960" width={25}>
            <path d="m249-207-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z" />
          </svg>
        </a>
      </div> */}
    </div>
  );
};

export default CommonIntroVideo;
