import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { get_faq_video } from '../../../api/apiHandler'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import BackToPrevious from '../../../components/backToPrevious'
import { encryptURL } from '../../../api/apiClient'

export default function LandingPageFaqVideo({isLoader}) {

    const location = useLocation();
    const navigate = useNavigate();

    var [FaqVideoData, setFaqVideoData] = useState([]);
    console.log("FaqVideoData :", FaqVideoData);
    var faq_id = location?.state;
    console.log(faq_id);
    const FaqVideoDetails = () => {
        get_faq_video({ id: faq_id }).then((resposnse) => {
            console.log("FaqVideo_details", resposnse);

            if (resposnse?.code == 1) {
                setFaqVideoData(resposnse?.data);
            } else {
                setFaqVideoData([]);
                console.log("Data Not Found");
            }
        });
    };
    const handleHeaderData = (data) => {
        console.log('data :', data);
        if (!localStorage.getItem('PAtoken')) {
          localStorage.setItem('isLandingPage', true)
        }
        const queryParams = new URLSearchParams({
          main_category: data.SelectedCategory,
          sub_category: data.subCategory,
          is_landingpage: true
        }).toString();
    
    
    
        const encryptedURL = encryptURL(`/search_class?${queryParams}`);
        console.log('encryptedURL :', encryptedURL);
        // return
        navigate(encryptedURL);
      };

    useEffect(() => {
        FaqVideoDetails();
		window.scrollTo(0, 0)

    }, []);
    const handleBack = () => {
        navigate(-1);
    };



    return (
        <>
            <Header sendDataToHome={handleHeaderData} isLoader={isLoader}/>
            <main>
                {/* <div className="dashboard-app d-lg-flex"> */}
                   
                    <div className="container mt-3">
                        <div className>
                            {/*start*/}
                            <BackToPrevious />
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className=" ">
                                    {/*start*/}
                                    <div className="mb-4">
                                        <h2 className="mb-2 poppins fw-bold">FAQ's - Training Videos</h2>
                                    </div>
                                    {/*end*/}
                                    {/*start*/}
                                    <div className="row mb-5 blogs_lists">
                                        {FaqVideoData.map((video) => (
                                            <div key={video.id} className="col-xl-4 col-lg-6 col-md-6 mb-4">
                                                <div className="certi_box bg-transparent shadow-none">
                                                    <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                                                        <div className="blog_img">
                                                            <video controls className="w-100 border-radius-10">
                                                                <source src={video.media} type="video/mp4" />
                                                            </video>
                                                        </div>
                                                    </div>
                                                    <Link to="/landing_page_faq_video_details" state={video.id} className="d-block certi_ctn px-0">
                                                        <h3 className="fw-600 mb-2 text-dif-black text-capitalize poppins">{video.title}</h3>
                                                        <p className="text-dif-black text-capitalize">{video.description}</p>
                                                        <div className="dwnload_btm d-flex mt-3 justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <div className="dwnl_img">
                                                                    <img src={video.profile_image} alt="center_div_img2" />
                                                                </div>
                                                                <div className="ms-3">
                                                                    <span className="font-14 fw-bold">{video.admin_name}</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span>{video.formatted_date}</span> • <span>{video.formatted_time} ago</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/*end*/}
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                {/* </div> */}
            </main>
            <Footer />
        </>
    )
}
