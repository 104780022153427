import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import { add_email_for_newsletter } from '../../api/apiHandler';
import { useForm } from 'react-hook-form';
import { encryptURL } from '../../api/apiClient';

export default function WhoWeAre({ isLoader }) {
	const navigate = useNavigate();
	let schema = yup.object().shape({
		email: yup.string().required("Please enter email id").email(),
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});
	let onSubmit = (data) => {
		isLoader(true)
		add_email_for_newsletter({ email: data?.email }).then((resposnse) => {
			if (resposnse?.code == 1) {
				TOAST_SUCCESS(resposnse?.message);

				reset();
				isLoader(false)
			} else {
				TOAST_ERROR(resposnse?.message)
				isLoader(false)
			}
		});
	}
	const handleHeaderData = (data) => {
		console.log('data :', data);
		if (!localStorage.getItem('PAtoken')) {
			localStorage.setItem('isLandingPage', true)
		}
		const queryParams = new URLSearchParams({
			main_category: data.SelectedCategory,
			sub_category: data.subCategory,
			is_landingpage: true
		}).toString();



		const encryptedURL = encryptURL(`/search_class?${queryParams}`);
		console.log('encryptedURL :', encryptedURL);
		// return
		navigate(encryptedURL);
	};
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	return (
		<>
			<Header sendDataToHome={handleHeaderData} isLoader={isLoader} />
			<main>
				<section>
					<div className="container">
						<div className="row pt-5 align-items-center">
							<div className="col-lg-6 mb-lg-0 mb-5 text-center">
								<h2 className="mb-3 text-purple">Who We are</h2>
								<h2 className="mb-3 text-purple">Our Passion is Education!</h2>
								<p className='fw-600'>We are a team of teachers from Australia, Canada, New Zealand, Singapore, UK and USA. We are school board certified, or subject qualified teachers with a degree in the area that we teach. </p><br />

								<h2 className="mb-3 text-purple">Our platform:</h2>

								<p className="fw-600"><span className='text-purple'>StudentScholars.Com Global Learning Marketplace </span>is an innovative education platform that offers a variety of engaging online; classes, clubs, quizzes, and competitions for all ages, from all over the world.</p>
							</div>
							<div className="col-lg-6 mb-lg-0 mb-5 ps-0">
								<img src="./assets/images/who_we_are/who_we_are_banner.jpg" alt="who_we_are_banner" />
							</div>
						</div>
						<div className="row pb-5 align-items-center">
							<div className="col-lg-6 pe-lg-0">
								<img src="./assets/images/who_we_are/who_we_are2.jpg" alt="who_we_are2" />
							</div>
							<div className="col-lg-6 mb-lg-0 mb-5 order-lg-last order-first text-center">
								<p className="fw-600 mb-2">Unlike traditional learning, StudentScholars offer the learner the  unique opportunity to explore their interests in-depth, grow their skills, knowledge and talents in an engaging, user friendly & state of the art platform. Learners also have the opportunity to learn from internationally qualified teachers, and engage with other learners from all over the world.</p>

								<div>
									<Link to={'/sign_in_step2'} className="btn-theme mb-2 d-inline-block mt-4 fw-bold text-purple" >
										Join as a Learner
										<svg className="ms-2 bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
										</svg>
									</Link>

									<Link to={'/sign_in_step2'} className="btn-theme mb-3 d-inline-block mt-4 fw-bold text-purple">
										Join as a School or Afterschool Center
										<svg className="ms-2 bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
										</svg>
									</Link>
									<div className="font14 ">
										<a
											href="https://www.facebook.com/share/g/wdU8uTQGHJVf7mVS/"
											target="_blank"
											rel="noopener noreferrer"
											style={{ cursor: "pointer" }}
										>
											<span>Connect with Our Learner Community</span>
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" className="bi bi-facebook" viewBox="0 0 16 16">
													<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
												</svg>
											</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="out_team_sec bg-gray py-5">
					<div className="container">
						<div className="row py-4 align-items-center">
							<div className="col-lg-5 mb-lg-0 mb-5">
								<div className="">
									<img src={'https://studentscholars.s3.amazonaws.com/static_images/academic.jpg'} alt="our_team" />
								</div>
							</div>
							<div className="col-lg-7 ps-lg-5">
								<h2 className="text-purple  mb-3">Our Team</h2>
								<p className="fw-600 mb-2 text-purple">Our Passion is Education!</p>
								<p className="fw-600 mb-2">We are a team of teachers from all over the world, who are school board certified, or subject qualified teachers with a degree in the area that we teach.</p>
								<p className="fw-600 mb-2">We are from Canada, USA, UK, Australia, New Zealand, and Singapore.</p>
								<p className="fw-600 mb-2">Further, our selected and specialized organizations offer in-person lessons at their on-site locations in the listed 6 countries.</p>
								<Link to={'/sign_up'} className="btn-theme mb-2 d-inline-block mt-4 fw-bold text-purple">
									Join Our Team Of Teachers
									<svg className="ms-2 bi bi-arrow-right" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
										<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
									</svg>
								</Link>
								<div className="font14">
									<a
										href="https://www.facebook.com/share/g/kQkreMBJekjuYKuE/"
										target="_blank"
										rel="noopener noreferrer"
										style={{ cursor: "pointer" }}
									>
										<span>Connect with Our Teacher Community</span>
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" className="bi bi-facebook" viewBox="0 0 16 16">
												<path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
											</svg>
										</span>
									</a>
								</div>
							</div>
						</div>
						<div className="row py-5 align-items-center">
							<div className="col-lg-7 mb-lg-0 mb-4 pe-lg-5">
								<h2 className="text-purple mb-3">Our Vision</h2>
								<p className="fw-600 mb-2">To become a top contributor in the world in education and to make education accessible to all learners irrespective of their country, gender, race, age and economic background. </p>
							</div>
							<div className="col-lg-5">
								<div className="">
									{/* <video id="video" className="position-static rounded-3" loop="" autoplay width="100%" controls="hide">
										<source src="https://drive.google.com/file/d/1lFj1S6ccGGGF1jnzY9KkfXtRbziFhlRe/view?usp=drive_link" type="video/mp4" />
									</video> */}
									<video
										id="video"
										className="position-static rounded-3"
										loop
										width="100%"
										controls
										src={"https://studentscholars.s3.amazonaws.com/video/Vision.mp4"}
									/>

								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="newslatter_sec py-5">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-4">
								<h2 className="text-white mb-3">Subscribe to our Newsletter</h2>
							</div>
							<div className="col-lg-8">
								<form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
									<div className="align-items-center d-flex flex-wrap form-group justify-content-end w-100">
										<input type="email" id="" className="form-control mb-2 rounded-pill me-2" placeholder="Your Email ID" name="" {...register("email")} />
										{errors?.email && <p className="me-5 font-bold col-red">
											<span style={{ color: "red" }}>{errors?.email?.message}</span>
										</p>}
										<button className="btn-theme bg-yellow d-inline-block">Subscribe</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Footer />
		</>
	)
}
