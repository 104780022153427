import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad';
import TFooter_Dashboard from '../../Include/TFooter_Dashboard';
import THeader_Dashboard from '../../Include/THeader_Dashboard';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { teacher_add_lession } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ content, onChange }) => {
  return (
    <div className="editor-wrapper w-full">
      <ReactQuill
        theme="snow"
        value={content}
        onChange={onChange}
        modules={Editor.modules}
        formats={Editor.formats}
        className="quill-editor"
        style={{
          marginBottom: '50px',
        }}
      />
    </div>
  );
};

Editor.modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
};

Editor.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'bullet',
  'link',
  'image',
];

export default function AddLesson({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  var id = location?.state;

  const schema = yup.object().shape({
    lesson_topic: yup.string().required('Please enter lesson topic')
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Lesson topic cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    hours: yup.string().test(
      'duration-validation',
      'Duration cannot be 0 hours and 0 minutes',
      function (value) {
        const minutes = this.parent.minutes;
        return !(value === "0" && minutes === "0");
      }
    ),
    minutes: yup.string().test(
      'duration-validation',
      'Duration cannot be 0 hours and 0 minutes',
      function (value) {
        const hours = this.parent.hours;
        return !(hours === "0" && value === "0");
      }
    )
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [profileImageState, setProfileImageState] = useState('');
  const [AttachmentData, setAttachmentData] = useState({});
  console.log('AttachmentData :', AttachmentData);

  const getVideoDuration = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof File)) {
        reject(new Error("Invalid file object provided for video duration check"));
        return;
      }
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src); // Free resources
        resolve(video.duration);
      };

      video.onerror = () => {
        reject(new Error("Failed to load video metadata"));
      };

      video.src = URL.createObjectURL(file);
    });
  }

  const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
    if (!image) {
      TOAST_ERROR("No file selected. Please select a valid file.");
      return;
    }
    if (type === "video" && image instanceof File) {
      try {
        const videoDuration = await getVideoDuration(image);
        if (videoDuration > 1800) {
          TOAST_ERROR("Please upload a video with a duration of 30 minutes or less.");
          return;
        }
      } catch (error) {
        TOAST_ERROR(error.message || "Error processing video file.");
        return;
      }
    }
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    const str = "attachment_" + Attachment_Index;
    const temp_arr = AttachmentData;
    isLoader(true)
    if (temp_arr[str]) {
      const image_name = await uploadImageOnAWS(image, "lesson_attachments");
      temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
      isLoader(false)
    } else {
      const image_name = await uploadImageOnAWS(image, "lesson_attachments");
      temp_arr[str] = ["", "", "", "", "", ""];
      temp_arr[str][fileIndex] = { attachments: image_name, attachment_type: type };
      isLoader(false)
    }

    setAttachmentData(temp_arr);
    setValue("attachments_data", setAttachmentData);

    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("attachments_data");
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  let onSubmit = async (data) => {
    isLoader(true);

    setIsSubmitted(true);

    // Validate before proceeding
    if (!validateDescriptionAndAttachments(data)) {
      return; // Early return if validation fails
    }

    // let duration = '';

    // if (data.hours > 0) {
    //   duration += data.hours + (data.hours === 1 ? ' Hour ' : ' Hours ');
    // }

    // if (data.minutes > 0) {
    //   duration += data.minutes + ' Minutes';
    // }

    // duration = duration.trim();

    // Proceed with the rest of your submission logic
    let coupon_data = {
      activity_id: id.id,
      slot_id: id?.slot,
      activity_type: id.class,
      lession_topic: data.lesson_topic,
      description: data.lesson_description,
      duration: data.hours + ' Hour' + (data.hours !== 1 ? 's ' : ' ') + data.minutes + ' Minutes',
      // duration: duration || undefined,
    };

    var description_arr = lessonDetails.map((lesson, index) => {
      var temp_obj = {};
      var temp_str = `lesson_description_${index}`;
      var attach_str = `attachment_${index}`;
      temp_obj.description = data[temp_str];

      if (AttachmentData[attach_str]) {
        var originalArray = AttachmentData[attach_str];
        var newArray = originalArray.filter(item => item !== '');
        temp_obj.attachments = newArray;
      } else {
        temp_obj.attachments = [];
      }
      return temp_obj;
    });

    coupon_data.lessiondata = description_arr;
    console.log('description_arr :', description_arr);
    console.log('coupon_data :', coupon_data);

    // Make the API call
    teacher_add_lession(coupon_data).then((res) => {
      if (res?.code == 1) {
        isLoader(false);
        TOAST_SUCCESS(res?.message);
        navigate('/teach-detail', {
          state: { id: id.id, class: id.class, type_class: "Online", slot: id?.slot }
        });
      } else {
        isLoader(false);
        TOAST_ERROR(res?.message);
      }
    });
    reset();
  };

  const validateDescriptionAndAttachments = (data) => {
    // Check for at least one description
    const hasDescription = lessonDetails.some((_, index) => {
      const descriptionKey = `lesson_description_${index}`;
      return data[descriptionKey] && data[descriptionKey].trim() !== '';
    });
    // Check for at least one attachment
    const hasAttachment = Object.keys(AttachmentData).some(key => {
      return AttachmentData[key] && AttachmentData[key].some(item => item !== '');
    });
    // If either validation fails, show error message
    if (!hasDescription || !hasAttachment) {
      TOAST_ERROR('Please add at least one description and one attachment');
      return false;
    }
    return true;
  };

  const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

  const handleDescriptionChange = (index, value) => {
    setLessonDetails(prevDetails => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        description: value
      };
      return updatedDetails;
    });

    // Clear any previous error messages if description is added
    if (value && value.trim() !== '') {
      clearErrors(`lesson_description_${index}`);
    }
  };

  const handleAddDescription = () => {
    setLessonDetails([...lessonDetails, { description: '', attachment: null }]);
  };

  const handleDeleteAttachment = (attachmentKey, attachmentIndex) => {

    setAttachmentData((prevState) => {
      console.log("Previous State:", prevState);
      const updatedAttachmentData = { ...prevState };

      if (updatedAttachmentData[attachmentKey]) {
        updatedAttachmentData[attachmentKey] = updatedAttachmentData[attachmentKey].filter(
          (_, idx) => idx !== attachmentIndex
        );
      }

      console.log("Updated State:", updatedAttachmentData);
      return updatedAttachmentData;
    });
  };

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <Helmet>
        <style>
          {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
          }
          `}
        </style>
      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span>
              </a>
              <div className>
                <div className="row">
                  <div className="col-xl-8">
                    <div className="mb-4">
                      <h2 className="mb-2 poppins fw-bold">Add Lesson Information</h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                      <div className="form-group mb-4">
                        <label className="form-label">Add Lesson Topic</label>
                        <div className="position-relative class_title">
                          <input
                            type="text"
                            {...register('lesson_topic')}
                            maxLength={50}
                            name="lesson_topic"
                            className="form-control"
                            placeholder="Lesson topic"
                          />
                          <span className="position-absolute word_count">
                            {50 - (watch('lesson_topic')?.split('')?.length ?? 0)} Words
                          </span>
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span>
                          </p>
                        </div>
                      </div>
                      {lessonDetails.map((lesson, index) => (
                        <div key={index}>
                          <div className="form-group">
                            <label className="form-label">Add Lesson Description</label>
                            <div className="position-relative class_summary">
                              <Controller
                                name={`lesson_description_${index}`}
                                control={control}
                                defaultValue=""
                                rules={{
                                  validate: {
                                    required: (value) => {
                                      // If this is the first description and it's empty
                                      if (index === 0 && (!value || value.trim() === '')) {
                                        return 'At least one lesson description is required';
                                      }
                                      return true;
                                    }
                                  }
                                }}
                                render={({ field }) => (
                                  <Editor
                                    content={field.value}
                                    onChange={(value) => {
                                      field.onChange(value);
                                      handleDescriptionChange(index, value);
                                    }}
                                  />
                                )}
                              />
                              {errors[`lesson_description_${index}`] && (
                                <p className="mt-2 text-danger">
                                  {errors[`lesson_description_${index}`].message}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="form-group mb-4 attach_box">
                            <label className="form-label">Add Attachments</label>
                            <div className="position-relative">
                              {/* <textarea type="text" className="form-control" name rows={7} value={AttachmentData[`attachment_${index}`]
                                ?.filter(item => item.attachments) // Filter out empty attachments
                                .map(item => item.attachments)
                                .join('\n')}
                              > </textarea> */}

                              <div className="textarea-wrapper">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  name={`attachments_${index}`}
                                  rows={7}
                                  // value={
                                  //     attachmentData[index]?.attachments?.map((item, idx) => (
                                  //         item.attachment ? item.attachment.split('/').pop() : ''
                                  //     )).join(', ') || ''
                                  // }
                                  readOnly
                                />
                                <div className="attachments-preview p-2">
                                  {AttachmentData[`attachment_${index}`]?.map((item, idx) =>
                                    item && item.attachments ? (
                                      <span key={idx} className="attachment-name">
                                        {item.attachments.split('/').pop()}
                                        <button
                                          type="button"
                                          className="btn-close"
                                          onClick={() => handleDeleteAttachment(`attachment_${index}`, idx)}
                                        >
                                          ×
                                        </button>
                                      </span>
                                    ) : null
                                  )}
                                </div>

                              </div>
                              <div className="att_icon_list position-absolute">
                                <ul className="d-flex align-items-center justify-content-end">
                                  {/* <li>
                                    <label htmlFor={`attach1_${index}`}>
                                      <a>
                                        <img src="./assets/images/attach1.png" alt="attach1" />
                                      </a>
                                    </label>
                                    <input
                                      type="file"
                                      id={`attach1_${index}`}
                                      onInput={(e) => {
                                        const file = e.target.files[0];
                                        const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                        handleImageChange(file, index, 0, fileType);
                                      }}
                                      {...register(`attachments_${index}`, {
                                        validate: {
                                          required: () => {
                                            // Check if this is the first attachment section and no attachments exist
                                            if (index === 0 && (!AttachmentData[`attachment_${index}`] ||
                                              !AttachmentData[`attachment_${index}`].some(item => item !== ''))) {
                                              return 'At least one attachment is required';
                                            }
                                            return true;
                                          }
                                        }
                                      })}
                                      accept="image/*,video/*"
                                      className="file-input"
                                      style={{ "visibility": "hidden", "display": "none" }}
                                    />
                                  </li> */}
                                  <li>
                                    <label htmlFor={`attach1_${index}`}>
                                      <a>
                                        <img src="./assets/images/attach1.png" alt="attach1" />
                                      </a>
                                    </label>
                                    <input
                                      type="file"
                                      id={`attach1_${index}`}
                                      onInput={(e) => {
                                        const file = e.target.files[0];
                                        const fileType = file.type.startsWith('image')
                                          ? 'image'
                                          : file.type.startsWith('video')
                                            ? 'video'
                                            : file.type === 'application/pdf'
                                              ? 'pdf'
                                              : file.type ===
                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                ? 'docx'
                                                : 'xlsx'; // For Excel
                                        handleImageChange(file, index, 0, fileType);
                                      }}
                                      {...register(`attachments_${index}`, {
                                        validate: {
                                          required: () => {
                                            // Check if this is the first attachment section and no attachments exist
                                            if (
                                              index === 0 &&
                                              (!AttachmentData[`attachment_${index}`] ||
                                                !AttachmentData[`attachment_${index}`].some(item => item !== ''))
                                            ) {
                                              return 'At least one attachment is required';
                                            }
                                            return true;
                                          },
                                        },
                                      })}
                                      accept="image/*,video/*,application/pdf,.docx,.xlsx"
                                      className="file-input"
                                      style={{ visibility: 'hidden', display: 'none' }}
                                    />
                                  </li>
                                  <li>
                                    <label htmlFor={`attach2_${index}`}>
                                      <a >
                                        <img src="./assets/images/attach2.png" alt="attach2" />
                                      </a>
                                    </label>
                                    <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                  </li>
                                  <li>
                                    <label htmlFor={`attach3_${index}`}>
                                      <a>
                                        <img src="./assets/images/attach3.png" alt="attach3" />
                                      </a>
                                      <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                    </label>
                                  </li>
                                  <li>
                                    <label htmlFor={`attach4_${index}`}>
                                      <a>
                                        <img src="./assets/images/attach4.png" alt="attach4" />
                                      </a>
                                      <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                    </label>
                                  </li>
                                  <li>
                                    <label htmlFor={`attach5_${index}`}>
                                      <a >
                                        <img src="./assets/images/attach5.png" alt="attach5" />
                                      </a>
                                      <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                    </label>
                                  </li>
                                  <li>
                                    <label htmlFor={`attach6_${index}`}>
                                      <a >
                                        <img src="./assets/images/attach6.png" alt="attach6" />
                                      </a>
                                      <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                    </label>
                                  </li>
                                </ul>
                              </div>
                              {errors[`attachments_${index}`] && (
                                <p className="mt-2 text-danger">
                                  {errors[`attachments_${index}`].message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="d-flex justify-content-end mt-3">
                        <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                          + Add Another Attachment and Description
                        </a>
                      </div>
                      <h3 className="mb-3">Lesson duration</h3>
                      <div className="d-md-flex mb-2 align-items-center">
                        <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
                        <div className="d-md-flex ms-3">
                          <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                            <select
                              className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill"
                              {...register("hours")}
                              name="hours"
                            >
                              <option selected >0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                            </select>
                            <span className="ms-3">Minutes</span>
                          </div>
                          <div className="ms-md-3">
                            <select
                              className="form-select w-auto pe-5 rounded-pill"
                              {...register("minutes")}
                              name="minutes"
                            >
                              <option selected>0 </option>
                              <option>5 </option>
                              <option>10 </option>
                              <option>15 </option>
                              <option>20 </option>
                              <option>25 </option>
                              <option>30 </option>
                              <option>35 </option>
                              <option>40 </option>
                              <option>45 </option>
                              <option>50 </option>
                              <option>55 </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {(errors.hours && errors.minutes) && (
                        <p className="text-danger mb-3">Duration cannot be 0 hours and 0 minutes</p>
                      )}
                      <div className="enviorment_div mb-5 align-items-center d-md-flex">
                        <div className="env_img">
                          <img src="./assets/images/enviorment.png" alt="enviorment" />
                        </div>
                        <div className="env_text">
                          <p className="text-500">Help save the environment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                        </div>
                      </div>
                      <div className="button">
                        <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                          Create Lesson
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  );
}
