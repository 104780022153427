import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad';
import THeader_Dashboard from '../../Include/THeader_Dashboard';
import TFooter_Dashboard from '../../Include/TFooter_Dashboard';
import { main_category_list, subject_category_list, subject_category_list_competition, teacher_competition_detail, teacher_edit_competition_detail } from '../../../../api/apiHandler';
import Modals from 'react-modal';
var COMMON_STYLE_FOR_POPUP = {
    content: {
        // backgroundColor: 'rgb(0 0 0 / 56%)',
        background: 'none',
        border: '0px',
    },
}

export default function EditCompetitation({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();

    // var id = location?.state;
    const navigate = useNavigate();

    const { class_edit_id } = useParams();

    // var id = location?.state;
    let id = null;
    if (location?.state) {
        id = location?.state
    } else {
        id = class_edit_id
    }

    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString('en-US', { hour12: false }).slice(0, -3));

    const schema = yup.object().shape({

        competition_title: yup.string()
            .required("Please enter competition title")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Competition title cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .min(20, 'The competition title must be at least 20 characters long'),
        competition_summary: yup.string()
            .required("Please enter competition summary")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Competition summary cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .min(50, 'The competition summary must be at least 50 characters long'),
        profileVideo: yup
            .mixed()
            .required("Please upload competition video"),
        class_description: yup.string()
            .required("Please enter competition description")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Competition description cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            )
            .min(250, 'Must be at least 250 characters'),
        // .max(500, 'Cannot exceed 500 characters'),
        com_end_date: yup.string().required("Please select competition end date"),
        com_end_time: yup.string().required("Please select competition end time"),
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [selectedRadio, setSelectedRadio] = useState("One-Time");
    const [modalIsOpen, setIsOpen] = useState(false);

    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.nextSibling.textContent)
    };

    const [profileImageState, setProfileImageState] = useState('')

    const handleImageChange = async (image) => {
        setValue("profileImage", image);
        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("profileImage");
    }

    const [profileVideoState, setProfileVideoState] = useState(null)
    const handleVideoChange = async (Video) => {
        setValue("profileVideo", Video);
        const bufferVideo = await convertToBase64(Video);

        setProfileVideoState(bufferVideo);
        clearErrors("profileVideo");
    }

    const [startageRanges, setstartAgeRanges] = useState(3)
    const [ageRanges, setAgeRanges] = useState(6)
    const [startLearner, setStartLearner] = useState(1)
    const [endLearner, setEndLearner] = useState(6)

    const startageRange = (data) => {
        setStartLearner(data)
        setstartAgeRanges(data);
    }

    const endageRange = (data) => {
        setAgeRanges(data);
    }

    const ageRange = (data) => {
        if (data < 16) {
            setAgeRanges(parseInt(data) + 3);
            setstartAgeRanges(data);
        } else if (data == 'adults') {
            setAgeRanges('adults');
            setstartAgeRanges(data);
        } else {
            setAgeRanges(18);
            setstartAgeRanges(data);
        }
    };

    const StartLearner = (data) => {
        setStartLearner(data);
    }

    const EndLearner = (data) => {
        setEndLearner(data);
    }

    const [subjectCategory, setSubjectCategory] = useState('');
    const [subjectCategoryId, setSubjectCategoryId] = useState('');
    var [MainCatdata, setMainCatdata] = useState([]);
    var [SelectedSubCategory, SetSelectedSubCategory] = useState('');
    var [SelectedSubCat, setSelectedSubCat] = useState([]);
    var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

    const handleSubjectCategoryChange = (e) => {
        setSubjectCategory((e.target.value)?.split(",")[1]);
        setSubjectCategoryId((e.target.value)?.split(",")[0])
    };

    const handleSubjectSubCategoryChange = (e) => {
        SetSelectedSubCategory(e.target.value)
    };

    const MainCategory = () => {
        isLoader(true)
        main_category_list().then((resposnse) => {
            if (resposnse.code == 1) {
                setMainCatdata(resposnse?.data)
                var category_id = resposnse?.data.find(item => item.category_name === subjectCategory);
                setSubjectCategoryId(category_id.category_id)
                isLoader(false)
            } else {
                setMainCatdata([])
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        if (subjectCategory) {
            MainCategory();
        }
    }, [subjectCategory]);

    const SubCategoryList = () => {
        isLoader(true)
        subject_category_list_competition({ category_id: subjectCategoryId }).then((resposnse) => {
            if (resposnse.code == 1) {
                setSelectedSubCat(resposnse?.data)
                isLoader(false)
            } else {
                isLoader(false)
            }
        });
    }

    useEffect(() => {
        if (subjectCategoryId) {
            SubCategoryList()
        }
    }, [subjectCategoryId]);

    const [price, setPrice] = useState('');
    const [Estimatedprice, setEstimatedprice] = useState(0);
    const [endEstimatedprice, setendEstimatedprice] = useState(0);

    const [pricingLevel, setPricingLevel] = useState('Very low');

    const handlePriceChange = (e) => {
        const inputPrice = e.target.value;
        setPrice(inputPrice);
        var start_est_ear = parseInt((startLearner * inputPrice) * 0.6);
        var end_est_ear = parseInt((endLearner * inputPrice) * 0.6);
        setEstimatedprice(start_est_ear)
        setendEstimatedprice(end_est_ear)
        if (inputPrice !== "" && !isNaN(inputPrice)) {
            const newPrice = parseFloat(inputPrice);
            if (newPrice > 50) {
                setPricingLevel('High');
            } else if (newPrice >= 20) {
                setPricingLevel('Medium');
            } else {
                setPricingLevel('Very low');
            }
        } else {
            setPricingLevel('Very low');
        }
    };

    const progressBarWidth = `${Math.max(0, Math.min((price - 20) / 50 * 100, 100))}%`;

    var [data, setData] = useState(null);
    var [classTitleLength, setclassTitleLength] = useState(0);
    var [classSummaryLength, setclassSummaryLength] = useState(0);
    var [classHoursData, setClassHoursData] = useState('0')
    console.log('classHoursData :', classHoursData);
    var [classMinutesData, setClassMinutesData] = useState('0')
    console.log('classMinutesData :', classMinutesData);
    var [printEstimation, setPrintEstimation] = useState("")

    const SetPriceData = () => {
        if (ageRanges <= 6) {
            if (classHoursData >= 1) {
                setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $15 to $45')
            } else {
                if (classMinutesData >= 45) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $10 to $35')
                } else {
                    if (classMinutesData >= 30) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $5 to $25')
                    }
                }
            }
        } else if (ageRanges <= 12) {
            if (classHoursData >= 1) {
                setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $15 to $45')
            } else {
                if (classMinutesData >= 45) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $10 to $35')
                } else {
                    if (classMinutesData >= 30) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $5 to $25')
                    }
                }
            }
        } else if (ageRanges <= 18) {
            if (classHoursData >= 1) {
                setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $15 to $45')
            } else {
                if (classMinutesData >= 45) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $10 to $35')
                } else {
                    if (classMinutesData >= 30) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $5 to $25')
                    }
                }
            }
        } else {
            if (classHoursData >= 1) {
                setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $30 to $60')
            } else {
                if (classMinutesData >= 45) {
                    setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $20 to $50')
                } else {
                    if (classMinutesData >= 30) {
                        setPrintEstimation('Suggestion : Pricing entered by other teachers for this type of competition is between $10 to $40')
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (classHoursData !== null && classMinutesData !== null) {
            SetPriceData();
        }
    }, [startageRanges]);

    const classHours = (data) => {
        setClassHoursData(data)
    }

    const classsMinutesData = (data) => {
        setClassMinutesData(data)
    }

    useEffect(() => {

        teacher_competition_detail({ "competition_id": id }).then((resposnse) => {

            if (resposnse.code == 1) {
                const inputPrice = resposnse.data[0].price;
                setPrice(inputPrice);
                var start_est_ear = parseInt((startLearner * inputPrice) * 0.6);
                var end_est_ear = parseInt((endLearner * inputPrice) * 0.6);
                setEstimatedprice(start_est_ear)
                setendEstimatedprice(end_est_ear)
                if (inputPrice !== "" && !isNaN(inputPrice)) {
                    const newPrice = parseFloat(inputPrice);
                    if (newPrice > 50) {
                        setPricingLevel('High');
                    } else if (newPrice >= 20) {
                        setPricingLevel('Medium');
                    } else {
                        setPricingLevel('Very low');
                    }
                } else {
                    setPricingLevel('Very low');
                }
                setProfileImageState(resposnse?.data?.[0]?.image != "" ? resposnse?.data?.[0]?.image_file : null);
                setValue("profileImage", resposnse?.data?.[0]?.image);
                setProfileVideoState(resposnse?.data?.[0]?.video_file);
                setSubjectCategory(resposnse.data[0]?.main_category)
                SetSelectedSubCategory(resposnse?.data[0]?.subject_category)
                setValue("profileVideo", resposnse?.data?.[0]?.video);
                setclassTitleLength(resposnse.data[0]?.title.length)
                setAgeRanges(resposnse?.data[0]?.max_age)
                setClassHoursData(resposnse.data[0]?.duration.split(' ')[0])
                setClassMinutesData(resposnse.data[0]?.duration.split(' ')[2])

                setData(resposnse.data[0])
            } else {

            }
        });
    }, []);

    let classTitleData = (e) => {
        setclassTitleLength(0)
    }

    let classSummaryData = (e) => {
        setclassSummaryLength(0)
    }

    let onSubmit = async (data) => {

        if (classHoursData == '0' && classMinutesData == '0') {
            TOAST_ERROR("Meeting duration cannot be 0 hours and 0 minutes, Please select a valid duration");
            return;
        }

        isLoader(true)
        if (data.profileImage != null) {
            var image = await uploadImageOnAWS(data?.profileImage, "class_image");
        }

        let video = data?.profileVideo
        if (video instanceof Blob) video = await uploadImageOnAWS(video, "class_image");
        data.start_age = startageRanges
        data.end_age = ageRanges
        let submitData = {
            competition_id: id,
            length: selectedRadio,
            title: data.competition_title,
            description: data.class_description,
            summary: data.competition_summary,
            main_category: subjectCategory,
            subject_category: SelectedSubCategory,
            min_age: data.start_age,
            max_age: data.end_age,
            end_date: data.com_end_date,
            end_time: data.com_end_time,
            language: data.lang,
            duration: data.class_hours + ' ' + "Hours" + " " + data.class_min + "Minutes",
            learning_goals: data.learning_goals,
            price: price,
            video: video,
            image: (image != undefined ? image : '')
        }

        teacher_edit_competition_detail(submitData).then((res) => {
            if (res.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(res.message);
                navigate('/teachers_my_class');
            } else {
                isLoader(false)
                TOAST_ERROR(res.message);
            }
        });
        reset();
    }

    if (data == null) return <></>

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <h3 className="text-purple mb-3">Setup Your Competition</h3>
                                {/* <div className='mb-4'>
                                    <h3 className="mb-2">Select the Competition Type</h3>
                                    <div className='row'>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio6" type="radio" defaultChecked={data.type == "Online" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio6" className="d-block text-center">Online</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio7" type="radio" defaultChecked={data.type == "In-Person" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio7" className="d-block text-center">In-Person</label>
                                            </div>
                                        </div>
                                        <div className='col-lg-2'>
                                            <div className="radio-item mb-4">
                                                <input name="radio" id="radio8" type="radio" defaultChecked={data.type == "Offline Course" ? true : false}
                                                    onChange={handleRadioChange1} />
                                                <label htmlFor="radio8" className="d-block text-center">Offline Course</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <h3 className="mb-2 text-capitalize">Select the Competition Length</h3>
                                <p className="fs-6 mb-4 text-capitalize">You cannot change this setting once the course is listed.</p>
                                <div className="row">
                                    <div className="col-xl-7">
                                        <form>
                                            <div className="row">
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio1" type="radio" defaultChecked={data.length == "One-Time" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio1" className="d-block text-center">One-Time</label>
                                                    </div>
                                                    <p className="fs-6">These competitions will be held over LIVE video a single time. Learners pay one time.
                                                    </p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio2" type="radio" defaultChecked={data.length == "Multi-Day" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio2" className="d-block text-center">Multi-Day</label>
                                                    </div>
                                                    <p className="fs-6">These competitions will be held over LIVE video over multiple days. It is sold as a package. Learners pay one time.
                                                    </p>
                                                </div>
                                                {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio3" type="radio" defaultChecked={data.length == "Ongoing Weekly" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio3" className="d-block text-center">Ongoing Weekly</label>
                                                    </div>
                                                    <p className="fs-6">These competitions will be held over LIVE video on a weekly schedule. Learners can join or leave at any time. Learners pay weekly.</p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio4" type="radio" defaultChecked={data.length == "Ongoing Monthly" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio4" className="d-block text-center">Ongoing Monthly</label>
                                                    </div>
                                                    <p className="fs-6">These competitions will be held over LIVE video on a weekly schedule. Learners can join at any time and leave at the end of the month. Learners pay monthly.
                                                    </p>
                                                </div>
                                                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-4">
                                                    <div className="radio-item mb-4">
                                                        <input name="radio" id="radio5" type="radio" defaultChecked={data.length == "offline course" ? true : false} onChange={handleRadioChange} />
                                                        <label htmlFor="radio5" className="d-block text-center">offline course</label>
                                                    </div>
                                                    <p className="fs-6">These competitions will not be held LIVE over video. Learners will get access to the competition material. Learners pay one time.</p>
                                                </div> */}
                                            </div>
                                        </form>
                                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                            <div className="form-group mb-4">
                                                <h3 className="mb-1 text-capitalize">Competition Title</h3>
                                                <p className="fs-6 mb-4 text-capitalize">  <p className="fs-6 mb-4 text-capitalize">Choose A Catchy And Descriptive Title. Eg. “Spelling Competition For Ages 12-14", No More Than 1 Exclamation Mark, And No Emojis Or Coupon Codes.</p></p>
                                                <label className="form-label">Class Title</label>
                                                <div className="position-relative class_title">
                                                    <textarea type="text" className="form-control" maxLength={50} {...register("competition_title")} name="competition_title" rows={2} defaultValue={data.title} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 50 - (((watch("competition_title")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.competition_title?.message}</span></p>

                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-1 text-capitalize">Competition summary</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Add a single sentence or phrase to describe your class to adults. The summary should be clear and descriptive rather than a teaser, and include keywords so parents can find your class easily. Use no more than one exclamation mark, and no emojis or coupon codes</p>
                                                <label className="form-label">Program summary</label>
                                                <div className="position-relative class_title">
                                                    <textarea type="text" className="form-control" maxLength={200} id {...register("competition_summary")} name="competition_summary" defaultValue={data.summary} onKeyUp={(e) => classSummaryData(e.target.value)} />
                                                    <span className="position-absolute word_count">
                                                        {Math.max((0, 200 - (((watch("competition_summary")?.split('')?.length ?? 0)))))} Words
                                                    </span>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.competition_summary?.message}</span></p>
                                                </div>
                                            </div>
                                            {/* <div className="form-group mb-4">
                                                <h3 className="mb-1 text-capitalize">Learner Summary(optional)</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Add a single sentence or phrase to describe your class to learners in your target age group. Remember to write in an age-appropriate tone and style.</p>
                                                <label className="form-label">Class Summary</label>
                                                <input type="text" className="form-control" defaultValue={data.learner_summary} placeholder id {...register("learner_summary")} name="learner_summary" />
                                            </div> */}
                                            {/* <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1">Class Image</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Choose an image that is engaging, free of words or text, age-appropriate and at least 600 pixels wide. Make sure you have permission to use your image (no copyrighted images, please).</p>
                                                <label className="form-label">Program Image</label>
                                                <Controller name="profileImage" control={control} defaultValue={null} render={({ field }) => (
                                                    <>


                                                        <div className="imageWrapper position-relative">
                                                            <div className="d-flex align-items-center justify-content-center">

                                                                <img className="image" src={profileImageState ? profileImageState : process.env.PUBLIC_URL + "/assets/images/image_upload.png"} alt="image_upload" style={{ "width": profileImageState ? "100%" : "" }} />
                                                            </div>

                                                            <label htmlFor='profileImage'>
                                                                <button className="file-upload">
                                                                    <input type="file" className="file-input" id="profileImage" accept="image/*" onInput={(e) => handleImageChange(e.target.files[0])} />Choose File
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </>
                                                )} />
                                            </div> */}
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1">Class Video</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Describe to parents and families what to expect from club. Recommended length: max 30 seconds limit</p>
                                                <label className="form-label">Program Video</label>

                                                <Controller name="profileVideo" control={control} defaultValue={null} rules={{ required: "Please upload video" }} render={({ field }) => (
                                                    <>
                                                        <div className="imageWrapper position-relative">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                {profileVideoState ?
                                                                    <video src={profileVideoState} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>
                                                                    :
                                                                    // <img className="video" src="./assets/images/video_upload.png" style={{ "width": profileVideoState ? "100%" : "" }} alt="video_upload" />}
                                                                    <video src={data.video_file} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls></video>}
                                                            </div>

                                                            <label htmlFor='profileVideo'>
                                                                <button className="file-upload">
                                                                    <input type="file" className="file-input" id="profileVideo" accept="video/*" onChange={(e) => handleVideoChange(e.target.files[0])} />Choose File
                                                                </button>
                                                            </label>
                                                        </div>
                                                    </>
                                                )} />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.profileVideo?.message}</span></p>

                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2">Sub-Category</h3>
                                                <label className="mb-2">Select the Main Category</label>
                                                <select className="form-select" aria-label="Default select example" name='subject_category' {...register("subject_category")} onChange={handleSubjectCategoryChange}>
                                                    {MainCatdata?.filter(mainCat => mainCat.category_name != "In-person Classes").map((mainCategory, index) => {
                                                        console.log('mainCategory :', mainCategory);
                                                        return (
                                                            <option
                                                                key={index}
                                                                selected={subjectCategoryId == mainCategory.category_id ? 'selected' : ''}
                                                                value={`${mainCategory.category_id},${mainCategory.category_name}`}
                                                            >
                                                                {mainCategory.category_name}
                                                            </option>

                                                        );
                                                    })}
                                                </select>

                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2">Select the Sub-Category</label>
                                                <select className="form-select fw-500" aria-label="Default select example" name='subject_sub_category' {...register("subject_sub_category")} onChange={handleSubjectSubCategoryChange}>
                                                    {SelectedSubCat[0]?.category_name ? (<>
                                                        {SelectedSubCat?.map((SubCat, index) => {
                                                            return (
                                                                <>
                                                                    <optgroup label={SubCat.category_name}>
                                                                        {SubCat.category?.map((SubCat1, index1) => {
                                                                            return (

                                                                                <option selected={SelectedSubCategory == SubCat1.subject_name ? 'selected' : ''} value={SubCat1.subject_name}>{SubCat1.subject_name}</option>
                                                                            )

                                                                        })}
                                                                    </optgroup>
                                                                </>)
                                                        })}
                                                    </>) : (<>
                                                        {SelectedSubCat?.map((SubCat, index) => {
                                                            return (
                                                                <>
                                                                    <option selected={SelectedSubCategory == SubCat.subject_name ? 'selected' : ''} value={SubCat.subject_name}>{SubCat.subject_name}</option>
                                                                </>)
                                                        })}
                                                    </>)}
                                                </select>
                                            </div>
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1 text-capitalize">Learner age range</h3>
                                                <p className="fs-6 mb-4 text-capitalize">Age Range Cannot Exceed 3 Years for Group Classes For Learners Under 18 Years Old. It is Because Learners Tend To Thrive In Classes With Others Who Match Their Developmental Age.
                                                </p>
                                                <div className="d-md-flex">
                                                    <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                        <select className="form-select fw-500 mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("start_age")} name="start_age" defaultValue={data.min_age} onChange={(e) => ageRange(e.target.value)} aria-label="Default select example">
                                                            <option selected={startageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                                                            <option selected={startageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                                                            <option selected={startageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                                                            <option selected={startageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                                                            <option selected={startageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                                                            <option selected={startageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                                                            <option selected={startageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                                                            <option selected={startageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                                                            <option selected={startageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                                                            <option selected={startageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                                                            <option selected={startageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                                                            <option selected={startageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                                                            <option selected={startageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                                                            <option selected={startageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                                                            <option selected={startageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                                                            <option selected={startageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                                                            <option selected={startageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                                                        </select>
                                                        <span className="ms-md-3">To</span>
                                                    </div>
                                                    <div className="d-flex ms-md-3 align-items-center">
                                                        <input type="hidden" {...register("end_age")} value={ageRanges} />

                                                        <select className="form-select fw-500 w-auto pe-5 rounded-pill" aria-label="Default select example"  {...register("end_age")} defaultValue={data.max_age} name='end_age' onChange={(e) => endageRange(e.target.value)}>
                                                            <option selected={ageRanges == '3' ? 'selected' : ''} value="3" >3 Years</option>
                                                            <option selected={ageRanges == '4' ? 'selected' : ''} value="4">4 Years</option>
                                                            <option selected={ageRanges == '5' ? 'selected' : ''} value="5">5 Years</option>
                                                            <option selected={ageRanges == '6' ? 'selected' : ''} value="6" >6 Years</option>
                                                            <option selected={ageRanges == '7' ? 'selected' : ''} value="7">7 Years</option>
                                                            <option selected={ageRanges == '8' ? 'selected' : ''} value="8">8 Years</option>
                                                            <option selected={ageRanges == '9' ? 'selected' : ''} value="9">9 Years</option>
                                                            <option selected={ageRanges == '10' ? 'selected' : ''} value="10">10 Years</option>
                                                            <option selected={ageRanges == '11' ? 'selected' : ''} value="11">11 Years</option>
                                                            <option selected={ageRanges == '12' ? 'selected' : ''} value="12">12 Years</option>
                                                            <option selected={ageRanges == '13' ? 'selected' : ''} value="13">13 Years</option>
                                                            <option selected={ageRanges == '14' ? 'selected' : ''} value="14">14 Years</option>
                                                            <option selected={ageRanges == '15' ? 'selected' : ''} value="15">15 Years</option>
                                                            <option selected={ageRanges == '16' ? 'selected' : ''} value="16">16 Years</option>
                                                            <option selected={ageRanges == '17' ? 'selected' : ''} value="17">17 Years</option>
                                                            <option selected={ageRanges == '18' ? 'selected' : ''} value="18">18 Years</option>
                                                            <option selected={ageRanges == 'adults' ? 'selected' : ''} value="adults">Adults</option>
                                                        </select>
                                                        <span className="ms-3 text-capitalize">years old</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Select Competition Size</h3>
                                                <label className="mb-2">Select Class Size</label>
                                                <select className="form-select fw-500 mb-4" defaultValue={data.size} aria-label="Default select example" {...register("class_size")} name='class_size'>
                                                    <option value="" disabled>Select the Class</option>
                                                    <option value="Group Classes 1">Group Classes 1</option>
                                                    <option value="Group Classes 2">Group Classes 2</option>
                                                    <option value="Group Classes 3">Group Classes 3</option>
                                                    <option value="Group Classes 4">Group Classes 4</option>
                                                </select>
                                                <p className="fs-6 mb-4 text-capitalize">Set the max learners for the following class sizes- For ages 6 and below : up to 6 learners,For ages 6-12 : up to 9 learners. For ages 12 and above : up to 12 learners.</p>
                                                <div className="d-md-flex">
                                                    <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                        <select className="form-select fw-500 mb-md-0 mb-3 w-auto pe-5 rounded-pill"  {...register("start_learner_age")} name='start_learner_age' defaultValue={data.min_learner} aria-label="Default select example" onChange={(e) => StartLearner(e.target.value)}>
                                                          
                                                            {ageRanges <= 6 ? (

                                                                <>
                                                                    <option value="1" selected>1 Learners</option>
                                                                    <option value="2">2 Learners</option>
                                                                    <option value="3">3 Learners</option>
                                                                    <option value="4">4 Learners</option>
                                                                    <option value="5">5 Learners</option>
                                                                    <option value="6">6 Learners</option>
                                                                </>
                                                            ) : ageRanges < 12 ? (
                                                                <>
                                                                    <option value="1" selected>1 Learners</option>
                                                                    <option value="2" >2 Learners</option>
                                                                    <option value="3" >3 Learners</option>
                                                                    <option value="4" >4 Learners</option>
                                                                    <option value="5" >5 Learners</option>
                                                                    <option value="6" >6 Learners</option>
                                                                    <option value="7" >7 Learners</option>
                                                                    <option value="8" >8 Learners</option>
                                                                    <option value="9" >9 Learners</option>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <option value="1" selected>1 Learners</option>
                                                                    <option value="2" >2 Learners</option>
                                                                    <option value="3">3 Learners</option>
                                                                    <option value="4">4 Learners</option>
                                                                    <option value="5" >5 Learners</option>
                                                                    <option value="6" >6 Learners</option>
                                                                    <option value="7" >7 Learners</option>
                                                                    <option value="8" >8 Learners</option>
                                                                    <option value="9" >9 Learners</option>
                                                                    <option value="10" >10 Learners</option>
                                                                    <option value="11" >11 Learners</option>
                                                                    <option value="12" >12 Learners</option>
                                                                </>
                                                            )}
                                                        </select>

                                                        <span className="ms-3">To</span>
                                                    </div>
                                                    <div className="ms-md-3">
                                                        <select className="form-select fw-500 w-auto pe-5 rounded-pill" {...register("end_learner_age")} name='end_learner_age' defaultValue={data.max_learner} aria-label="Default select example" onChange={(e) => EndLearner(e.target.value)}>

                                                            {ageRanges <= 6 ? (

                                                                <>
                                                                    <option value="1" >1 Learners</option>
                                                                    <option value="2" >2 Learners</option>
                                                                    <option value="3" >3 Learners</option>
                                                                    <option value="4" >4 Learners</option>
                                                                    <option value="5" >5 Learners</option>
                                                                    <option value="6" selected>6 Learners</option>
                                                                </>
                                                            ) : ageRanges < 12 ? (
                                                                <>
                                                                    <option value="1"  >1 Learners</option>
                                                                    <option value="2" >2 Learners</option>
                                                                    <option value="3" >3 Learners</option>
                                                                    <option value="4" >4 Learners</option>
                                                                    <option value="5" >5 Learners</option>
                                                                    <option value="6" >6 Learners</option>
                                                                    <option value="7" >7 Learners</option>
                                                                    <option value="8" >8 Learners</option>
                                                                    <option value="9" selected>9 Learners</option>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <option value="1" selected>1 Learners</option>
                                                                    <option value="2">2 Learners</option>
                                                                    <option value="3">3 Learners</option>
                                                                    <option value="4">4 Learners</option>
                                                                    <option value="5">5 Learners</option>
                                                                    <option value="6">6 Learners</option>
                                                                    <option value="7">7 Learners</option>
                                                                    <option value="8">8 Learners</option>
                                                                    <option value="9">9 Learners</option>
                                                                    <option value="10">10 Learners</option>
                                                                    <option value="11" > 11 Learners</option>
                                                                    <option value="12" selected>12 Learners</option>
                                                                </>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="form-group mb-4 class_image">
                                                <h3 className="mb-1 text-capitalize">Meeting duration</h3>
                                                <p className="fs-6 mb-4 text-capitalize">{selectedRadio === "One-Time" ? "Select How Long You Will Meet. Course Type :" : "Select how often you will meet and for how long. Course Type :"} <span className="fw-600">{selectedRadio}</span></p>
                                                <div className="d-md-flex align-items-center">
                                                    <p className="fs-6 mb-md-0 mb-3 text-capitalize">Length of the class in Hours </p>
                                                    <div className="d-md-flex ms-md-3">
                                                        <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                            <select className="form-select mb-md-0 mb-3 w-auto pe-5 fw-500 rounded-pill" {...register("class_hours")} onChange={(e) => classHours(e.target.value)} name='class_hours' aria-label="Default select example">
                                                                <option selected={classHoursData === '0' ? 'selected' : ''} value={0}>0</option>
                                                                <option selected={classHoursData === '1' ? 'selected' : ''} value={1}>1</option>
                                                                <option selected={classHoursData === '2' ? 'selected' : ''} value={2}>2</option>
                                                                <option selected={classHoursData === '3' ? 'selected' : ''} value={3}>3</option>
                                                                {/* <option selected={classHoursData === '4' ? 'selected' : ''} value={4}>4</option>
                                                                <option selected={classHoursData === '5' ? 'selected' : ''} value={5}>5</option>
                                                                <option selected={classHoursData === '6' ? 'selected' : ''} value={6}>6</option>
                                                                <option selected={classHoursData === '7' ? 'selected' : ''} value={7}>7</option>
                                                                <option selected={classHoursData === '8' ? 'selected' : ''} value={8}>8</option>
                                                                <option selected={classHoursData === '9' ? 'selected' : ''} value={9}>9</option> */}

                                                            </select>
                                                            <span className="ms-3 text-capitalize">and minutes</span>
                                                        </div>
                                                        {console.log('classMinutesData1 :', classMinutesData)}
                                                        <div className="ms-md-3">
                                                            <select className="form-select w-auto pe-5 rounded-pill fw-500" {...register("class_min")} onChange={(e) => classsMinutesData(e.target.value)} name='class_min' aria-label="Default select example">
                                                                <option selected={classMinutesData === '0' ? 'selected' : ''} value={0}>0</option>
                                                                {/* <option selected={classMinutesData === '5' ? 'selected' : ''} value={5}>5</option>
                                                                <option selected={classMinutesData === '10' ? 'selected' : ''} value={10}>10</option>
                                                                <option selected={classMinutesData === '15' ? 'selected' : ''} value={15}>15</option>
                                                                <option selected={classMinutesData === '20' ? 'selected' : ''} value={20}>20</option>
                                                                <option selected={classMinutesData === '25' ? 'selected' : ''} value={25}>25</option> */}
                                                                <option selected={classMinutesData === '30' ? 'selected' : ''} value={30}>30</option>
                                                                {/* <option selected={classMinutesData === '35' ? 'selected' : ''} value={35}>35</option>
                                                                <option selected={classMinutesData === '40' ? 'selected' : ''} value={40}>40</option> */}
                                                                <option selected={classMinutesData === '45' ? 'selected' : ''} value={45}>45</option>
                                                                {/* <option selected={classMinutesData === '50' ? 'selected' : ''} value={50}>50</option>
                                                                <option selected={classMinutesData === '55' ? 'selected' : ''} value={55}>55</option> */}

                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {classHoursData == '0' && classMinutesData == '0' && (
                                                    <p className="text-danger mt-1">Meeting duration cannot be 0 hours and 0 minutes. Please select a valid duration.</p>
                                                )}
                                            </div>

                                            <div className="row mb-4">
                                                <div className="col-md-6 mb-md-0 mb-3">
                                                    <div className="form-group">
                                                        <h3 className="mb-2 text-capitalize">competition End Date</h3>
                                                        <input type="date" className="form-control" {...register("com_end_date")} name='com_end_date' defaultValue={data.end_date} min={currentDate} />
                                                    </div>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.com_end_date?.message}</span></p>

                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <h3 className="mb-2 text-capitalize">competition End Time</h3>
                                                        <input type="time" className="form-control" {...register("com_end_time")} name='com_end_time' defaultValue={data.end_time > currentTime ? data.end_time : currentTime} min={currentTime} />
                                                    </div>
                                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.com_end_time?.message}</span></p>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Language of instruction</h3>
                                                <p className="fs-6 mb-4 text-capitalize">What is the main language this class will be taught in?</p>
                                                <label className="mb-2">Select the language</label>
                                                <select className="form-select fw-500 mb-4" defaultValue={data.class_language} {...register("lang")} name='lang' aria-label="Default select example">
                                                    <option selected>English</option>
                                                    <option>French</option>
                                                    <option>Mandarin</option>
                                                    <option>Cantonese</option>
                                                    <option>Spanish</option>
                                                    <option>Hindi</option>
                                                    <option>Japanese</option>
                                                    <option>Arabic</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Competition Description</h3>
                                                <p className="fs-6 mb-2 text-capitalize">Details to include:</p>
                                                <ol style={{ listStyleType: 'circle !important' }}>
                                                    <li>- What is covered?</li>
                                                    <li>- Who is it for?</li>
                                                    <li>- Why should they sign up?</li>
                                                    <li>- How will you teach?</li>
                                                    <li>- How is the competition is conducted ? (games, discussions, quizzes)</li>
                                                    <li>- How much will learners get to interact with you and each other</li>
                                                </ol>
                                                <p className="fs-6 mb-4 text-capitalize">Program Description –250 minimum</p>
                                                <label className="form-label">Class Description</label>
                                                <div className="position-relative class_title">
                                                    <textarea
                                                        className="form-control"
                                                        defaultValue={data.description}
                                                        name="class_description"
                                                        {...register("class_description")}
                                                        rows={6}
                                                    />
                                                    <span className="position-absolute word_count">
                                                        {watch("class_description")?.length || 0} Characters
                                                    </span>
                                                    <p className="mt-2 font-bold col-red">
                                                        <span style={{ color: "red" }}>
                                                            {errors.class_description?.message}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Pricing</h3>
                                                <p className="fs-6 mb-4 text-capitalize">How much will you charge for your Class?</p>
                                                <p className="fs-6 fw-600 mb-4 text-capitalize">{printEstimation}</p>

                                                <div className="row">
                                                    <div className="col-lg-6 mb-lg-0 mb-4">
                                                        <div className="usd_learner flex-wrap mb-4 d-flex align-items-center">
                                                            <span>$</span>
                                                            <input
                                                                type="number"
                                                                className="form-control mx-3 w-auto"
                                                                name="price"
                                                                defaultValue={data.price}
                                                                // value={price}
                                                                autoComplete="off"
                                                                onChange={handlePriceChange}
                                                                min={10}
                                                                max={70}
                                                            />
                                                            <span className="text-capitalize">USD per learner</span>
                                                        </div>
                                                        <div className="pricing_guide">
                                                            <span className="mb-2 d-block text-capitalize">{pricingLevel}</span>

                                                            <div className="progress rounded-pill">

                                                                <div className="progress-bar bg-warning rounded-pill" role="progressbar" style={{ width: progressBarWidth }} aria-valuenow={price} aria-valuemin={20} aria-valuemax={70} />

                                                            </div>
                                                            <div className="mt-1 mb-2 d-flex justify-content-between align-items-center ">
                                                                <span>(20 $)</span>
                                                                <span>(50 $)</span>
                                                                <span>(70 $)</span>
                                                            </div>
                                                            <p className="fs-6 text-capitalize">Pricing Guide: Please refer to similar class prices set by other teachers as per the subject category and student ages.</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6">
                                                        <div className="pricing_guide">
                                                            <span className="fs-6 fw-600 mb-2 text-capitalize">Estimated Earnings</span>
                                                            <h3 className="font-18 mb-2 text-capitalize">${Estimatedprice} - {endEstimatedprice} USD per week</h3>
                                                            <p className="fs-6 text-capitalize">How this is calculated: Price per learner, class size, course duration, and Studentscholar’s 40% service fee.</p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <h3 className="mb-2 text-capitalize">Refund & Subscription Policy</h3>
                                                <p className="fs-6 mb-1">
                                                    {selectedRadio === "One-Time" ? (
                                                        "One-time class - You may receive a full refund for a class, club or competition if you withdraw from the class: within 24 hours of purchase, or before 24 hours of the class start time."
                                                    ) : selectedRadio === "Multi-Day" ? (
                                                        "Multi-Day class - You may receive a full refund if you withdraw at least 7 days before the start of the first session for a multi-day class."
                                                    ) : selectedRadio === "Ongoing Weekly" ? (
                                                        "Ongoing Weekly Class - You may receive a full refund for a class, club or competition if you withdraw from the class: within 24 hours of purchase, or before 24 hours of the class start time."
                                                    ) : selectedRadio === "Ongoing Monthly" ? (
                                                        "Ongoing Monthly Class - You may receive a full refund for a class, club or competition if you withdraw from the class: within 24 hours of purchase, or before 24 hours of the class start time."
                                                    ) : selectedRadio === "offline course" ? (
                                                        "Offline Course - We do not offer refunds for offline courses."
                                                    ) : (
                                                        "Please select an option."
                                                    )}
                                                </p>

                                                <p>Please refer to our <span><a className="fw-600 text-purple" style={{ cursor: "pointer" }} onClick={(e) => setIsOpen(true)}>Refund & Subscriptions policies for more information</a></span></p>
                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-lg-6">

                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Competition
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div >
            </main >
            <Modals isOpen={modalIsOpen} style={COMMON_STYLE_FOR_POPUP} contentLabel="Example Modal" >

                {/* <div className="modal fade yello_left_border" id="safety_tips1" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
                <div className="modal-dialog modal-lg modal-dialog-scrollable yello_left_border">
                    <div className="modal-content">
                        <div className="modal-header border-bottom-0">
                            <h5 className="modal-title poppins fw_500" id="exampleModalLabel">
                                <span className="me-2">
                                    <svg width={23} height={30} viewBox="0 0 43 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.2886 15.4744C16.0354 15.4744 11.7617 19.748 11.7617 25.0007C11.7617 30.2541 16.0354 34.5276 21.2886 34.5276C26.5415 34.5276 30.8149 30.2541 30.8149 25.0008C30.8149 19.7479 26.5415 15.4744 21.2886 15.4744ZM26.9446 23.2068L20.1197 30.0316C19.9836 30.1681 19.8219 30.2763 19.6438 30.3501C19.4658 30.4239 19.2749 30.4618 19.0821 30.4616C18.8894 30.4618 18.6986 30.424 18.5206 30.3502C18.3426 30.2764 18.181 30.1681 18.045 30.0316L15.4368 27.4235C14.8639 26.8509 14.8639 25.922 15.4368 25.3491C16.0094 24.7761 16.9383 24.7761 17.5112 25.3491L19.0821 26.92L24.8702 21.1324C25.4428 20.5593 26.3717 20.5593 26.9446 21.1324C27.5176 21.7049 27.5176 22.6338 26.9446 23.2068Z" fill="#570861" />
                                        <path d="M42.528 13.5663L42.5266 13.5274C42.5038 13.0097 42.4888 12.4918 42.4815 11.9737C42.4407 9.21675 40.2491 6.93629 37.4919 6.78229C31.7432 6.46139 27.296 4.58688 23.4962 0.883177L23.4638 0.852318C22.2244 -0.284106 20.3548 -0.284106 19.115 0.852318L19.0826 0.883177C15.2828 4.58688 10.8356 6.46139 5.08688 6.78258C2.33005 6.93629 0.138066 9.21685 0.0972458 11.974C0.090081 12.492 0.0751053 13.0098 0.052324 13.5274L0.0499802 13.6177C-0.061836 19.4798 -0.200703 26.7754 2.23992 33.3973C3.582 37.0389 5.61442 40.2042 8.28053 42.8062C11.317 45.7695 15.2942 48.122 20.1015 49.7982C20.2597 49.8532 20.4216 49.8973 20.5859 49.9302C20.8194 49.9767 21.0544 50 21.2894 50C21.5244 50 21.7597 49.9767 21.9928 49.9302C22.1578 49.8971 22.3203 49.8527 22.4791 49.7973C27.2807 48.1181 31.2537 45.7645 34.2875 42.8017C36.9525 40.1989 38.985 37.0327 40.3281 33.3904C42.7779 26.7487 42.6394 19.4393 42.528 13.5663ZM21.2886 37.4614C14.4175 37.4614 8.82789 31.8718 8.82789 25.0008C8.82789 18.1297 14.4176 12.5401 21.2886 12.5401C28.1593 12.5401 33.7493 18.1297 33.7493 25.0008C33.7493 31.8719 28.1593 37.4614 21.2886 37.4614Z" fill="#570861" />
                                    </svg>
                                </span>
                                Refunds &amp; Subscription Policy:
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => setIsOpen(false)} />
                        </div>
                        <div className="modal-body">
                            <div className="safety_list">
                                <p >We care about your experience with StudentScholars. Therefore, we have introduced
                                    a variety of Refund and Subscription Policies to assist our learners.</p>
                                <h3 className="modal-title poppins fw_500 mt-2">Refund Policy</h3>
                                {/* <p className='mt-2'>To request a refund, please contact the teacher WITHIN FIVE DAYS (5) days after the day of the class.</p> */}
                                <h5 className="modal-title poppins fw_500 mt-2">Full Refunds</h5>
                                <p className='mt-2'>In the following situations, you are entitled to request for and to receive a full refund for a class or a session.</p>
                                <ol className='my-2'>
                                    <li className='mb-2'>
                                        <p>You may receive a full refund for a class, club or competition if you withdraw
                                            from the class: within 24 hours of purchase, or before 24 hours of the class
                                            start time for an on-going weekly, or monthly lesson or a one-time lesson.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>You may receive a full refund, if you withdraw at least 7 days before the start
                                            of the first session for a multi-day class.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>In the event that a teacher misses a one-time class, you may request a full
                                            refund for the class, or communicate with the teacher to reschedule the class.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>In the event that a teacher reschedules a session of the class before the first
                                            session starts, you may request a full refund for the class.</p>
                                    </li>
                                </ol>

                                <h5 className="modal-title poppins fw_500 mt-2">No Refunds</h5>
                                <ul className='my-2'>
                                    <li className='mb-2'>
                                        <p> - There will be no refunds for offline courses.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- There will be no refunds for multi-day classes after the 7-day period before the start
                                            of the 1st class.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- There will be no refunds if it falls within the 24-hour period before the start of the
                                            class for one-time classes.
                                        </p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- For ongoing weekly and monthly, we do not offer a refund for missed classes unless
                                            you inform the teacher, or cancel the class ; <span className='fw-600'>24 hours before the class start time</span>
                                            from the classroom options.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- You can only cancel 1 class a month for on-going weekly and monthly classes. For
                                            the rest of the classes, you will be charged. In a special circumstance and you cannot
                                            attend a class for a period of time, please pause the class or inform the teacher. If you
                                            pause the class, your spot will not be guaranteed.
                                        </p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- Refunds will be at our discretion; we may refuse to issue or disqualify you from
                                            receiving refunds if a learner requests an excessive number of refunds in a period of
                                            time.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- If the class was already held and you want to request a refund in a special case
                                            situation that is not listed here, please contact the teacher <span className='fw-600 text-decoration-underline'>WITHIN FIVE DAYS (5)
                                                days after the day of the class. Please note, after the 5 days of the class, you
                                                cannot request a refund.</span>
                                        </p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- If you pay for the class, and still miss 3 consecutive class meetings without
                                            communicating with the teacher, you forfeit the class fee and the teacher may cancel
                                            the class or withdraw the learner from the class.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p> - If you did not join the meeting for the first 10 minutes after the class’s start time, the
                                            meeting may be cancelled, at the teacher’s discretion. This is considered a missed
                                            meeting and you are not entitled to a refund.
                                        </p>
                                    </li>

                                </ul>

                                <h5 className="modal-title poppins fw_500 mt-2">Prorated Refunds For Multi-day Classes</h5>
                                <p className='mt-2 fw-500'>In the following situations, you will be entitled to request
                                    for and receive a prorated refund based on the number of sessions remaining in your class：</p>
                                <ol className='my-2'>
                                    <li className='mb-2'>
                                        <p>If the sessions purchased by you are rescheduled, you will receive, upon
                                            request, a prorated refund based on the number of sessions rescheduled that
                                            you (i) did not attend and (ii) did not consent to such rescheduling. However, if
                                            you did not attend the rescheduled session where you have consented to such
                                            rescheduling, you will not be eligible for a refund for such session.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>If the teacher misses some of the sessions listed in the class, you will receive a
                                            prorated refund based on the number of sessions which the teacher missed upon request.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>If the teacher cancels a class in which you have any uncompleted sessions, you
                                            are eligible to receive a prorated refund for the class according to the proportion of sessions that were canceled out of all sessions you have paid for in the class.</p>
                                    </li>
                                </ol>

                                <h5 className="modal-title poppins fw_500 mt-2">Additional Refund Policies</h5>
                                <ul className='my-2'>
                                    <li className='mb-2'>
                                        <p> - Refunds are not guaranteed in the event that you missed a past or future class
                                            meeting due to a schedule conflict, and will depend on the class-specific refund policy.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- Teachers may transfer you to another section of the same class with your consent.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>- Refunds can only be issued to the original form of payment used for your purchase
                                            on StudentScholars, and they cannot be issued to a different card. In the case that the
                                            original card is closed or no longer active for any reason, you may need to contact
                                            your bank or credit card company to retrieve the funds. In select cases, you may not
                                            be able to retrieve your refund, but we will offer you a gift card or apply a credit to
                                            your StudentScholars account instead. Our team will provide you with specific
                                            guidance in these cases.
                                        </p>
                                    </li>
                                </ul>

                                <h5 className="modal-title poppins fw_500 mt-2">Subscription Policy</h5>
                                <h5 className="modal-title poppins fw_500 mt-2">Weekly & Monthly Subscription Policy</h5>
                                <ol className='my-2'>
                                    <li className='mb-2'>
                                        <p className='fw-500'>A complete single subscription cycle is from 00:00:00（Canadian Eastern Time) every Monday to 23:59:59 the following Sunday (Subscription Cycle);</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>A subscription shall become effective once you make payment for the subscription
                                            fees of the first Subscription Cycle. Your payment for the first Subscription Cycle
                                            might be adjusted as the price reflected on the subscription page depending on the
                                            remaining session(s) in the current week.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p><span className='fw-500'>Deductions will be made from your designated payment
                                            method on a regular basis starts from every Sunday at
                                            23:50:00（Canadian Eastern Time）(Fixed Deduction Time)</span>  for the
                                            next Subscription Cycle, unless you cancel the subscription in accordance with
                                            provisions below before the Fixed Deduction Time. Should any deduction fail to pass

                                            through on the Fixed Deduction Time, the subscription will be automatically
                                            cancelled.</p>
                                    </li>
                                    <li className='mb-2'>
                                        <p>Subscriptions made by a subscriber are non-transferrable (to the next person).</p>
                                    </li>
                                </ol>

                                <h5 className="modal-title poppins fw_500 mt-2">Cancelling Subscriptions</h5>
                                <ol className='my-2'>
                                    <li className='mb-2'>
                                        <p> Subscribers may cancel their subscriptions at any time, subject to the payment of
                                            all outstanding fees for any Subscriptions Cycles that have already been redeemed by
                                            the subscriber. Please refer to our refund policy.
                                        </p>
                                    </li>
                                    <li className='mb-2'>
                                        <p className='mb-2'>We maintain the right to suspend or cancel any existing subscriptions in the event
                                            that:</p>

                                        <p className='mb-2'>(a) there is insufficient enrollment for a class, or the class is cancelled for
                                            whatever reason, we may automatically cancel your subscription for the class,
                                            and we will refund, prior to the commencement of the cancelled class, the full
                                            amount (including the used coupons) to the original form of payment used for
                                            your subscription;</p>
                                        <p className='mb-2'>(b) a class is cancelled by the conducting teacher (including any available classes
                                            in a study plan that is conducted by the teacher which you have subscribed for),
                                            we will refund the subscription fees already paid for the class that has not
                                            commenced;</p>
                                        <p className='mb-2'>(c) the subscription is automatically cancelled after the end of the last
                                            Subscription Cycle (including cancellation due to non-payment).</p>
                                        <p className='mb-2'>(d)the subscription payment order is unsuccessful (including payments to be
                                            made on the Fixed Deduction Time), the subscription will be automatically
                                            cancelled.</p>

                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </Modals>
            <TFooter_Dashboard />
        </>
    )
}
