import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { teacher_add_quiz_listing, teacher_delete_quiz_detail } from '../../../../api/apiHandler'
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import TFooter_Dashboard from '../../Include/TFooter_Dashboard'
import THeader_Dashboard from '../../Include/THeader_Dashboard'
import Swal from 'sweetalert2';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service'

export default function ALL_Quiz({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [quizData, setQuizData] = useState([]);

  const teacherQuizListing = () => {
    isLoader(true)
    teacher_add_quiz_listing().then((resposnse) => {
      console.log('teacher_add_quiz_listing :', resposnse);
      if (resposnse?.code == 1) {
        isLoader(false)
        setQuizData(resposnse?.data)
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    teacherQuizListing();
  }, []);

  const delete_quiz = (index, id) => {
    Swal.fire({
      title: 'Are you sure you want to delete ?',
      text: "You won't be able to revert this!",
      icon: 'question',
      background: 'white',
      showCancelButton: true,
      confirmButtonColor: '#570861',
      cancelButtonColor: '#FFC924',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonClass: 'swal-cancel-button',
    }).then((result) => {
      if (result.isConfirmed) {
        isLoader(true)
        teacher_delete_quiz_detail({ quiz_id: id }).then((response) => {
          if (response?.code == 1) {
            isLoader(false)
            setQuizData(quizData.filter(o => o.id !== id));
            TOAST_SUCCESS(response?.message);
          } else {
            isLoader(false)
            TOAST_ERROR(response?.message);
          }
        })
      }
    })
  }

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />

      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="d-flex align-items-center justify-content-start mt-4 ml-4">
            <Link to="/teachers_my_class" style={{ marginLeft: "44px" }} className="btn-theme bg-yellow ml-4">Back To History</Link>
          </div>
          <div className="class-content">
            <div className>

              {/* <ul className="nav custom_nav_bar nav-pills" id="pills-tab" role="tablist">
              </ul> */}
              <div className="tab-content" id="pills-tabContent">
                <div className="class_section teacher_class">
                  {quizData?.length > 0 ?
                    quizData?.map((quiz_data, index) => {
                      return (

                        <div key={index} className="class_box mb-md-5 mb-4">
                          <div className="single-box d-xl-flex">
                            <div className="class_image_box h-100">
                              <div className="class_img h-100 position-relative">
                                <div className="video-container">
                                  {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                  <video id="video" className loop width="100%" controls="hide" >
                                    <source src={quiz_data.video_file} type="video/mp4" />
                                  </video>
                                </div>
                                <div className="toggle like">
                                  <input type="checkbox" />
                                  <label />
                                </div>
                                {/* <div className="ribbon ribbon-pink">{quiz_data.spots_left ? quiz_data.spots_left : "Half spots left"}</div> */}
                              </div>
                              <div className>
                                <div className="d-sm-flex bottom-info position-static align-items-center justify-content-between">
                                  <Link to="/my_class_details" className="d-flex align-items-center">
                                    <img src={quiz_data.profile_image} alt="user1" />
                                    <div className="d-flex align-items-center">
                                      <span className="tech_nm ms-2">{quiz_data.full_name}</span>
                                      <img
                                        src={quiz_data?.teacher_country}
                                        alt={`${quiz_data?.teacher_country
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-3 flag-img"
                                      />
                                      {/* <img src={quiz_data.teacher_country} alt="canada" className="ms-2 det_flag_class" /> */}
                                    </div>
                                  </Link>
                                  <div className="star_rat">
                                    <span>
                                      <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                      </svg>
                                    </span>
                                    <span className="fw-600">{quiz_data.teacher_ratings}<span className="text-gray fw-500">({quiz_data.teacher_review})</span></span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between follow-btn-sec position-static pb-3 w-100">
                                  <div>
                                    {/* <a href="#" className="follow_btn">Follow Me</a> */}
                                  </div>
                                  <span className="bg-yellow px-3 py-1 rounded-3 border-radius-10">{quiz_data.subject_category}</span>
                                </div>
                              </div>
                            </div>
                            <div className="class_room_info bg-transparent ps-lg-5">
                              <div className="class_room_inner justify-content-between">
                                <div className="class_rm_text ">

                                  <div className="d-lg-flex justify-content-between mb-4 align-items-center">
                                    <div className="class_title mb-4">
                                      <p className='fs-5 fw-bold'>{quiz_data.title}</p>
                                    </div>
                                    {/* <a href="#" class="btn-theme shadow-none bg-diff-yellow border-color-yellow d-block px-3 mb-sm-0 mb-4 py-2 
													text-center text-dif-black fw-500">Select a Class Day &amp; Time</a> */}
                                    <ul className="edit_delete d-flex align-items-center justify-content-end px-sm-3">
                                      <li>
                                        <a onClick={() => (delete_quiz(index, quiz_data.id))} className>
                                          <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                          </svg>
                                        </a>
                                      </li>
                                      <li>
                                        <Link to={`/edit-quiz`} state={quiz_data.id} className>
                                          <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                            <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                          </svg>
                                        </Link>
                                      </li>
                                    </ul>


                                  </div>
                                  <Link >
                                    <ul className="class_tag d-flex flex-wrap">
                                      <li className="px-3 py-2 rounded-3 bg-gray">
                                        <span>Years</span>
                                        <span className="fw-600 d-block">{quiz_data.min_age}-{quiz_data.max_age} years</span>
                                      </li>

                                      {quiz_data.duration != "" ?
                                        <li className="px-3 py-2 rounded-3 bg-gray">
                                          <span>Quiz Duration</span>
                                          <span className="fw-600 d-block">{quiz_data.duration}</span>
                                        </li> : <></>
                                      }

                                      <li className="px-3 py-2 rounded-3 bg-gray">
                                        <span>Language</span>
                                        <span className="fw-600 d-block">{quiz_data.language} </span>
                                      </li>
                                      <li className="px-3 py-2 rounded-3 bg-gray">
                                        <span>Class Type</span>
                                        <span className="fw-600 d-block">Quiz</span>
                                      </li>
                                    </ul>
                                  </Link>
                                </div>
                                <div className="payment-week rounded-3">
                                  <div className="align-content-center align-items-center d-flex text-center flex-wrap h-100 justify-content-center pay_box px-4 py-2">
                                    {quiz_data.is_status == "listed" ?
                                      (
                                        <h3 className="mb-3 text-purple poppins fw-600"><a>{quiz_data.is_status}</a></h3>
                                      )
                                      : quiz_data.is_status == "unlisted" ? (
                                        <h3 className="mb-3 text-purple poppins fw-600"><a>{quiz_data.is_status}</a></h3>
                                      )
                                        :
                                        quiz_data.is_status == "pending" ? (
                                          <h3 className="mb-3 text-purple poppins fw-600 text-warning"><a>{quiz_data.is_status}</a></h3>
                                        )
                                          :
                                          quiz_data.is_status == "resubmit" ? (
                                            <h3 className="mb-3 text-purple poppins fw-600 text-info"><a>{quiz_data.is_status == "resubmit" ? "Request for resubmit" : "Request for resubmit"}</a></h3>
                                          ) : quiz_data.is_status == "Resubmitted" ? (
                                            <h3 className="mb-3 text-purple poppins fw-600 text-primary"><a>{quiz_data.is_status == "Resubmitted" ? "Resubmitted" : "Resubmitted"}</a></h3>
                                          )
                                            : null}
                                    <span className="payment_price fw-bold d-block w-100 text-center">${quiz_data.price}</span>
                                    <span className="d-block mb-4 w-100">Payment weekly </span>
                                    <div className="button w-100">
                                      <a href="#" className="btn-theme px-xl-4 px-3 bg-yellow d-inline-block">Subscribe now</a>
                                    </div>
                                    <a href="#" className="mt-4" data-bs-toggle="modal" data-bs-target="#share_link_modal">
                                      <span>
                                        <svg width={30} height={28} viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M17.3438 8.37423C18.123 8.37423 18.75 7.74727 18.75 6.96798V6.49923V5.56173V2.74923L28.125 11.1867L18.75 19.6242V16.8117V15.8742V15.4055C18.75 14.6262 18.123 13.9992 17.3438 13.9992H16.875H11.25C7.10742 13.9992 3.75 17.3566 3.75 21.4992C3.75 21.9856 3.79102 22.4426 3.86719 22.8586C2.80664 21.4816 1.875 19.5129 1.875 16.8117C1.875 12.1535 5.6543 8.37423 10.3125 8.37423H16.875H17.3438ZM16.875 16.8117V17.7492V19.6242C16.875 20.3625 17.3086 21.0363 17.9883 21.3352C18.668 21.634 19.4531 21.5109 20.0039 21.0188L29.3789 12.5813C29.7715 12.2238 30 11.7199 30 11.1867C30 10.6535 29.7773 10.1496 29.3789 9.7922L20.0039 1.3547C19.4531 0.856649 18.6621 0.733602 17.9883 1.03829C17.3145 1.34298 16.875 2.01095 16.875 2.74923V4.62423V5.56173V6.49923H15H10.3125C4.61719 6.49923 0 11.1164 0 16.8117C0 23.4504 4.77539 26.4152 5.87109 27.0129C6.01758 27.0949 6.18164 27.1242 6.3457 27.1242C6.98438 27.1242 7.5 26.6027 7.5 25.9699C7.5 25.5305 7.24805 25.1262 6.92578 24.8274C6.375 24.3059 5.625 23.2805 5.625 21.4992C5.625 18.3938 8.14453 15.8742 11.25 15.8742H15H16.875V16.8117Z" fill="#570861" />
                                        </svg>
                                      </span>
                                      <span className="ms-2 text-purple fw-500">
                                        Share
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    }) :
                    <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                  }
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  )
}
