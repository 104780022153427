import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { teacher_add_quiz } from '../../../../api/apiHandler'
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service'
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'quill-better-table/dist/quill-better-table.css';
import BetterTable from 'quill-better-table';
import { Helmet } from 'react-helmet';

Quill.register({
  'modules/better-table': BetterTable
}, true);

const Editor = ({ content, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['image'],
      ['clean'],
      ['better-table'] // Add the table module here
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    // 'link',
    'image',
    'better-table' // Add the table format here
  ];

  return (
    <ReactQuill
      value={content}
      onChange={onChange}
      modules={modules}
      formats={formats}
      theme="snow"
      placeholder="Write something..."
      style={{ borderRadius: '10px' }}
    />
  );
};

export default function OAddQue({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const [answerType, setAnswerType] = useState("Single");

  let schema;
  if (answerType == "Single") {
    schema = yup.object().shape({
      question: yup.string()
        .required("Please enter question")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer1: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer2: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer3: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer4: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      ans_exp: yup.string().required("Please enter answer explanation")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer explanation cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      que_points: yup.number()
        .typeError("Question Points must be a valid number")
        .required("Please enter question points")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question points cannot consist of only spaces',
          (value) => value !== undefined && value !== null && value.toString().trim().length > 0
        ),
    });
  } else if (answerType == "Multiple") {
    schema = yup.object().shape({
      question: yup.string()
        .required("Please enter question")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer1: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer2: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer3: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer4: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      ans_exp: yup.string().required("Please enter answer explanation")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer explanation cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      que_points: yup.number()
        .typeError("Question Points must be a valid number")
        .required("Please enter question points")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question points cannot consist of only spaces',
          (value) => value !== undefined && value !== null && value.toString().trim().length > 0
        ),
    });
  } else {
    schema = yup.object().shape({
      question: yup.string()
        .required("Please enter question")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer1: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      answer2: yup.string().required("Please enter answer")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      ans_exp: yup.string().required("Please enter answer explanation")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Answer explanation cannot consist of only spaces',
          (value) => value && value.trim().length > 0
        ),
      que_points: yup.number()
        .typeError("Question Points must be a valid number")
        .required("Please enter question points")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .test(
          'no-only-whitespace',
          'Question points cannot consist of only spaces',
          (value) => value !== undefined && value !== null && value.toString().trim().length > 0
        ),
    });
  }

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const handleAnswerTypeChange = (e) => {
    setAnswerType(e.target.value);
    // reset({
    //   answer1: '',
    //   answer2: '',
    //   answer3: '',
    //   answer4: ''
    // });
  };

  const [selectedRadio, setSelectedRadio] = useState('1');

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedRadio(value); // Set the selected radio value to the state
  };

  const answerValues = {
    answer1: selectedRadio === '1' ? '1' : '0',
    answer2: selectedRadio === '2' ? '1' : '0',
    answer3: selectedRadio === '3' ? '1' : '0',
    answer4: selectedRadio === '4' ? '1' : '0',
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
  });

  const handleCheckboxChange = (name) => {
    setSelectedCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [name]: !prevCheckboxes[name],
    }));
  };

  const answerValues1 = {
    answer1: selectedCheckboxes.checkbox1 ? '1' : '0',
    answer2: selectedCheckboxes.checkbox2 ? '1' : '0',
    answer3: selectedCheckboxes.checkbox3 ? '1' : '0',
    answer4: selectedCheckboxes.checkbox4 ? '1' : '0',
  };

  const [selectedRadio1, setSelectedRadio1] = useState('1');

  const handleRadioChange1 = (e) => {
    const value = e.target.value;
    setSelectedRadio1(value); // Set the selected radio value to the state
  };

  const answerValues2 = {
    answer1: selectedRadio1 === '1' ? '1' : '0',
    answer2: selectedRadio1 === '2' ? '1' : '0',
  };

  const [questionDetail, setQuestionDetail] = useState([]);
  const [questionCount, setQuestionCount] = useState([]);
  const [profileImageState, setProfileImageState] = useState('')
  const [desState, setDesState] = useState('')
  const [description, setDescription] = useState('');
  const [ansDescription, setAnsDescription] = useState('');
  const [tempState, setTempState] = useState('')
  const [tempDesState, setDesTempState] = useState('')

  const handleDescriptionChange = (value) => {
    console.log('handleDescriptionChange :', value);
    // Check if the value contains only whitespace characters
    const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
    // If it's empty, set the description to an empty string
    setDescription(isEmptyDescription ? '' : value);
    setValue('question', isEmptyDescription ? '' : value);
    clearErrors('question');
  };

  const handleAnsDescriptionChange = (value) => {
    console.log('handleDescriptionChange :', value);
    // Check if the value contains only whitespace characters
    const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
    // If it's empty, set the description to an empty string
    setAnsDescription(isEmptyDescription ? '' : value);
    setValue('ans_exp', isEmptyDescription ? '' : value);
    clearErrors('ans_exp');
  };

  const handleImageChange = async (image, fileIndex, type) => {
    setTempState(image?.name)

    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    setValue("attachments_data", image);
    setValue("attachments_type", type);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("attachments_data");
  }

  const handleAttachmentChange = async (image, fileIndex, type) => {
    setDesTempState(image?.name)

    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    setValue("attachments_des_data", image);
    setValue("attachments_des_type", type);
    const bufferImage = await convertToBase64(image);
    setDesState(bufferImage);
    clearErrors("attachments_des_data");
  }

  // let onSubmit = async (data) => {
  //   var image = await uploadImageOnAWS(data?.attachments_data, "Quiz_attachment");
  //   var des_image = await uploadImageOnAWS(data?.attachments_des_data, "Quiz_attachment_des");

  //   TOAST_SUCCESS("Your question is added.");

  //   let submitData = {
  //     // teacher_id : 
  //     question: data.question,
  //     attachment: image,
  //     attachment_type: data.attachments_type,
  //     que_type: answerType,
  //     answer_explanation: ansDescription,
  //     points: data.que_points,
  //     description_image: des_image,
  //     description_type: data.attachments_des_type,
  //   }

  //   if (answerType == "Single") {
  //     submitData.options = [
  //       {
  //         "name": data.answer1,
  //         "is_answer": answerValues.answer1
  //       },
  //       {
  //         "name": data.answer2,
  //         "is_answer": answerValues.answer2
  //       },
  //       {
  //         "name": data.answer3,
  //         "is_answer": answerValues.answer3
  //       },
  //       {
  //         "name": data.answer4,
  //         "is_answer": answerValues.answer4
  //       }
  //     ]
  //   } else if (answerType == "Multiple") {
  //     submitData.options = [
  //       {
  //         "name": data.answer1,
  //         "is_answer": answerValues1.answer1
  //       },
  //       {
  //         "name": data.answer2,
  //         "is_answer": answerValues1.answer2
  //       },
  //       {
  //         "name": data.answer3,
  //         "is_answer": answerValues1.answer3
  //       },
  //       {
  //         "name": data.answer4,
  //         "is_answer": answerValues1.answer4
  //       }
  //     ]
  //   } else {
  //     submitData.options = [
  //       {
  //         "name": data.answer1,
  //         "is_answer": answerValues2.answer1
  //       },
  //       {
  //         "name": data.answer2,
  //         "is_answer": answerValues2.answer2
  //       }
  //     ]
  //   }

  //   questionDetail.push(submitData)

  //   reset({
  //     ans_exp: '',
  //     attachment: '',
  //     que_points: '',
  //     que_type: '',
  //     question: '',
  //     answer1: '', // Reset individual answer fields as well if needed
  //     answer2: '',
  //     answer3: '',
  //     answer4: '',
  //   });
  //   setDescription('');
  //   setAnsDescription('')
  //   // localStorage.setItem("quiz_form",JSON.stringify(submitData))

  //   //  navigate ("/add_question");
  // };
  let onSubmit = async (data) => {
    try {
      // Ensure attachments_data and attachments_des_data are provided
      // if (!data?.attachments_data) {
      //   TOAST_ERROR("Please upload attachments");
      //   return;
      // }

      // if (!data?.attachments_des_data) {
      //   TOAST_ERROR("Please upload description attachments");
      //   return;
      // }

      // Upload images to AWS
      if (data?.attachments_data != null) {
        var image = await uploadImageOnAWS(data?.attachments_data, "Quiz_attachment");
      }
      if (data?.attachments_des_data != null) {
        var des_image = await uploadImageOnAWS(data?.attachments_des_data, "Quiz_attachment_des");
      }
      // console.log('des_image :', des_image);

      TOAST_SUCCESS("Your question is added");

      // Prepare the data for submission
      let submitData = {
        question: data.question,
        attachment: image,
        attachment_type: data.attachments_type,
        que_type: answerType,
        answer_explanation: ansDescription,
        points: data.que_points,
        description_image: des_image,
        description_type: data.attachments_des_type,
      };

      // Add options based on the answer type
      if (answerType === "Single") {
        submitData.options = [
          { "name": data.answer1, "is_answer": answerValues.answer1 },
          { "name": data.answer2, "is_answer": answerValues.answer2 },
          { "name": data.answer3, "is_answer": answerValues.answer3 },
          { "name": data.answer4, "is_answer": answerValues.answer4 }
        ];
      } else if (answerType === "Multiple") {
        submitData.options = [
          { "name": data.answer1, "is_answer": answerValues1.answer1 },
          { "name": data.answer2, "is_answer": answerValues1.answer2 },
          { "name": data.answer3, "is_answer": answerValues1.answer3 },
          { "name": data.answer4, "is_answer": answerValues1.answer4 }
        ];
      } else {
        submitData.options = [
          { "name": data.answer1, "is_answer": answerValues2.answer1 },
          { "name": data.answer2, "is_answer": answerValues2.answer2 }
        ];
      }

      console.log('questionDetail :', submitData);

      // Add the question details to the list
      questionDetail.push(submitData);

      // Reset the form
      reset({
        ans_exp: '',
        attachment: '',
        que_points: '',
        que_type: '',
        question: '',
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
      });
      setDescription('');
      setAnsDescription('');
      setTempState('');
      setDesTempState('');
      // Optional: Store the form data in local storage
      // localStorage.setItem("quiz_form", JSON.stringify(submitData));

      // Optional: Navigate to the add question page
      // navigate("/add_question");

    } catch (error) {
      console.error("Error in onSubmit:", error);
      TOAST_ERROR("An error occurred while submitting the question. Please try again.");
    }
  };

  var quizData = localStorage.getItem("quiz_form");
  var quiz_data = JSON.parse(quizData);
  const [quizId, setQuizId] = useState('');

  const addQuiz = () => {
    isLoader(true)
    let finalData = {
      "class_id": quiz_data.class_id ? quiz_data.class_id : '',
      "slot_id": quiz_data?.slot_id ? quiz_data?.slot_id : '',
      "class_type": quiz_data?.class_type ? quiz_data?.class_type : '',
      "title": quiz_data.title,
      "summary": quiz_data.summary,
      "image": quiz_data.image,
      "video": quiz_data.video,
      "main_category": quiz_data.main_category ? quiz_data.main_category : "",
      "subject_category": quiz_data.subject_category ? quiz_data.subject_category : "",
      "min_age": quiz_data.min_age,
      "max_age": quiz_data.max_age,
      "duration": quiz_data.duration,
      "language": quiz_data.language,
      "select_question": quiz_data.select_question,
      "price": quiz_data.price,
      "instruction": quiz_data.instruction,
      "quiz_questions": questionDetail
    }

    teacher_add_quiz(finalData).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message);
        localStorage.removeItem("quiz_form");
        // setQuizId(response.data)
        localStorage.setItem("quiz_id", response.data.quiz_id)
        // navigate('/question_list', { state: response.data.quiz_id });
        navigate('/organization-questions-list')
      } else {
        isLoader(false)
        TOAST_ERROR(response.message);
      }
      reset();
    });
  }

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
        </style>

      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <div className>
              {/*start*/}
              <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                <span>
                  <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                  </svg>
                  <span className="ms-2">Back</span>
                </span></a>
              {/*end*/}
              {/*start*/}
              <div className>
                <div className="row mb-4">
                  <div className="col-xl-7 d-sm-flex align-items-center justify-content-between">
                    <h3 className="text-purple mb-sm-0 mb-3">Setup Your Online Quiz</h3>
                    <span className="text-purple text-end d-block fw-600">question 2/2</span>
                  </div>
                </div>
                <h3 className="mb-4">Add Questions</h3>
                <div className="row">
                  <div className="col-xl-7">
                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                      <div className="form-group mb-4">
                        <label className="form-label">Type Your Question </label>
                        <textarea type="text" className="form-control" name="question" {...register("question")} rows={7} defaultValue={""} />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.question?.message}</span></p>
                      </div>

                      <div className="form-group mb-4 attach_box">
                        <label className="form-label">Attachments</label>
                        <div className="position-relative">
                          <textarea type="text" className="form-control" name rows={7} value={tempState} disabled />
                          <div className="att_icon_list position-absolute">
                            <ul className="d-flex align-items-center justify-content-end">
                              <li>
                                <label htmlFor="attach1">
                                  <a>
                                    <img src="./assets/images/attach1.png" alt="attach1" />
                                  </a>
                                </label>
                                <input type="file" id='attach1' onInput={(e) => {
                                  const file = e.target.files[0];
                                  const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                  handleImageChange(file, 0, fileType);
                                }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                              </li>
                              <li>
                                <label htmlFor='attach2'>
                                  <a >
                                    <img src="./assets/images/attach2.png" alt="attach2" />
                                  </a>
                                </label>
                                <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                              </li>
                              <li>
                                <label htmlFor='attach3'>
                                  <a>
                                    <img src="./assets/images/attach3.png" alt="attach3" />
                                  </a>
                                  <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                              <li>
                                <label htmlFor='attach4'>
                                  <a>
                                    <img src="./assets/images/attach4.png" alt="attach4" />
                                  </a>
                                  <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                              <li>

                                <label htmlFor='attach5'>

                                  <a >
                                    <img src="./assets/images/attach5.png" alt="attach5" />
                                  </a>
                                  <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                </label>
                              </li>
                              <li>
                                <label htmlFor='attach6'>

                                  <a >
                                    <img src="./assets/images/attach6.png" alt="attach6" />
                                  </a>
                                  <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-4">
                        <label className="mb-2">Answer Type: Single Answer, ,Multiple answers ,True or False</label>
                        <select onChange={handleAnswerTypeChange} className="form-select" aria-label="Default select example"  >
                          <option value="Single" selected>Single</option>
                          <option value="Multiple">Multiple</option>
                          <option value="TrueFalse">True/False</option>

                        </select>
                      </div>
                      {answerType === "Single" && (
                        <>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 1
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radio1' value="1" checked={selectedRadio === "1"}
                                  onChange={handleRadioChange} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>

                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 2
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radio2' value="2" checked={selectedRadio === "2"}
                                  onChange={handleRadioChange} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>

                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 3
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radio3' value="3" checked={selectedRadio === "3"}
                                  onChange={handleRadioChange} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer3")} name="answer3" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer3?.message}</span></p>

                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 4
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radio4' value="4" checked={selectedRadio === "4"}
                                  onChange={handleRadioChange} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer4")} name="answer4" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer4?.message}</span></p>

                          </div>
                        </>
                      )}


                      {answerType === "Multiple" && (
                        <>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 1
                              <label className="radio-button-container">Select As Correct answer
                                <input
                                  type="checkbox"
                                  name="checkbox1"
                                  checked={selectedCheckboxes.checkbox1}
                                  onChange={() => handleCheckboxChange('checkbox1')}
                                />                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>
                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 2
                              <label className="radio-button-container">Select As Correct answer
                                <input
                                  type="checkbox"
                                  name="checkbox2"
                                  checked={selectedCheckboxes.checkbox2}
                                  onChange={() => handleCheckboxChange('checkbox2')}
                                />                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>
                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 3
                              <label className="radio-button-container">Select As Correct answer
                                <input
                                  type="checkbox"
                                  name="checkbox3"
                                  checked={selectedCheckboxes.checkbox3}
                                  onChange={() => handleCheckboxChange('checkbox3')}
                                />                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer3")} name="answer3" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer3?.message}</span></p>

                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 4
                              <label className="radio-button-container">Select As Correct answer
                                <input
                                  type="checkbox"
                                  name="checkbox4"
                                  checked={selectedCheckboxes.checkbox4}
                                  onChange={() => handleCheckboxChange('checkbox4')}
                                />                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id  {...register("answer4")} name="answer4" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer4?.message}</span></p>
                          </div>
                        </>
                      )}

                      {answerType === "TrueFalse" && (
                        <>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 1
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radiotrue' value="1" checked={selectedRadio1 === "1"}
                                  onChange={handleRadioChange} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer1")} name="answer1" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer1?.message}</span></p>

                          </div>
                          <div className="form-group mb-4">
                            <label className="form-label">Ans 2
                              <label className="radio-button-container">Select As Correct answer
                                <input type="radio" name="radio" id='radiotrue1' value="2" checked={selectedRadio1 === "2"}
                                  onChange={handleRadioChange1} />
                                <span className="checkmarks" />
                              </label>
                            </label>
                            <input type="text" className="form-control" placeholder id {...register("answer2")} name="answer2" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.answer2?.message}</span></p>
                          </div>
                        </>
                      )}

                      <div className="form-group mb-4">
                        <label className="form-label">Answer explanation </label>
                        {/* <textarea type="text" className="form-control" {...register("ans_exp")} name="ans_exp" rows={8} defaultValue={""} /> */}
                        <Editor
                          {...register("ans_exp")}
                          content={ansDescription}
                          onChange={handleAnsDescriptionChange}
                        />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.ans_exp?.message}</span></p>

                      </div>
                      <div className="form-group mb-4 attach_box">
                        <label className="form-label">Description Attachments</label>
                        <div className="position-relative">
                          <textarea type="text" className="form-control" name rows={7} value={tempDesState} disabled />
                          <div className="att_icon_list position-absolute">
                            <ul className="d-flex align-items-center justify-content-end">
                              <li>
                                <label htmlFor="attach_des_1">
                                  <a>
                                    <img src="./assets/images/attach1.png" alt="attach_des_1" />
                                  </a>
                                </label>
                                <input type="file" id='attach_des_1' onInput={(e) => {
                                  const file_des = e.target.files[0];
                                  const fileType_des = file_des.type.startsWith('image') ? 'image' : 'video';
                                  handleAttachmentChange(file_des, 0, fileType_des);
                                }} {...register("attachments_des")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                              </li>
                              <li>
                                <label htmlFor='attach_des_2'>
                                  <a >
                                    <img src="./assets/images/attach2.png" alt="attach_des_2" />
                                  </a>
                                </label>
                                <input type="file" id='attach_des_2' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                              </li>
                              <li>
                                <label htmlFor='attach_des_3'>
                                  <a>
                                    <img src="./assets/images/attach3.png" alt="attach_des_3" />
                                  </a>
                                  <input type="file" id='attach_des_3'  {...register("attachments_des")} accept="image/*" onInput={(e) => handleAttachmentChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                              <li>
                                <label htmlFor='attach_des_4'>
                                  <a>
                                    <img src="./assets/images/attach4.png" alt="attach_des_4" />
                                  </a>
                                  <input type="file" id='attach_des_4' {...register("attachments_des")} accept=".docx" onInput={(e) => handleAttachmentChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                              <li>

                                <label htmlFor='attach_des_5'>

                                  <a >
                                    <img src="./assets/images/attach5.png" alt="attach_des_5" />
                                  </a>
                                  <input type="file" id='attach_des_5' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                </label>
                              </li>
                              <li>
                                <label htmlFor='attach_des_6'>

                                  <a >
                                    <img src="./assets/images/attach6.png" alt="attach_des_6" />
                                  </a>
                                  <input type="file" id='attach_des_6' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Question Points</label>
                        <input type="number" className="form-control" placeholder id {...register("que_points")} name="que_points" />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.que_points?.message}</span></p>

                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-4">
                          {/* <Link to="/question_list" className="btn-theme bg-yellow d-block text-center fw-600">Submit Question</Link> */}
                          <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Add Another Question
                          </button>
                        </div>
                        {/* <div className="col-lg-4">
                          {questionDetail && questionDetail.length >= 2 ?
                            <Link onClick={() => (addQuiz())} className="btn-theme bg-yellow text-center fw-600 d-block" >
                              Check Added Questions
                            </Link> : <></>
                          }
                        </div> */}
                      </div>

                    </form>
                    <div className="row mt-5">
                      <div className="col-lg-4">
                        {questionDetail && questionDetail?.length >= 2 ?
                          <button onClick={() => (addQuiz())} className="btn-theme bg-yellow text-center fw-600 d-block" >
                            Check Added Questions
                          </button> : <></>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
