import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2';
import { learner_name_list, notification_listing, teacher_delete_quiz_detail, teach_quiz_listing } from '../../../api/apiHandler';
import dayjs from 'dayjs';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import queryString from 'query-string';
import CryptoJS from 'crypto-js';
var key = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNfTmDXHWVkQWdanYOm');
var iv = CryptoJS.enc.Hex.parse('SuiCovwpMpoRQbNf');
export default function Quizzes() {

    const location = useLocation();
    console.log('location :', location);

    // var id = location?.state;
    // console.log('idQuiz :', id);
    var after = useParams();
    if (location?.search) {
        const queryParams = new URLSearchParams(location?.search);
        const encryptedData = queryParams.get('data');

        // Define your key and IV (must be the same as used for encryption)
        // const key = CryptoJS.enc.Utf8.parse('your-key-here');
        // const iv = CryptoJS.enc.Utf8.parse('your-iv-here');

        // Decode and decrypt the data
        const decryptedData1 = CryptoJS.AES.decrypt(decodeURIComponent(encryptedData), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
        console.log('decryptedData1 :', decryptedData1);
        const params = new URLSearchParams(decryptedData1);
        console.log('params :', params);

        id = {
            id: parseInt(params?.get('activityId'), 10),  // Assuming id is a number
            class: params?.get('typeClass'),
            type_class: params?.get('type'),
            slot: params?.get('slot')
        };;
        console.log('idxyz1 :', id);
        var edit_class_id = id?.id;
        // setDataType(id?.type)
    } else if (location?.state) {
        var id = location?.state;
        console.log('idxyz :', id);
        var edit_class_id = id?.id;
    } else {
        // const { id } = useParams();
        // var type = location?.search?.split("=")[1];
        // console.log('type :', type);
        // var id = after
        // id.class = type
        // console.log('idtype :', id);

        // const queryParams = queryString.parse(location.search);

        // // Extract specific query parameters
        // const type = queryParams.type;

        // const typeClass = queryParams?.type_class;

        // var id = after
        // id.class = type
        // id.type_class = typeClass

        var decryptedData = CryptoJS.AES.decrypt(decodeURIComponent(location?.pathname?.split('/')[2]), key, { iv: iv }).toString(CryptoJS.enc.Utf8);
        var descryptedJson = decryptedData.split('?')
        const queryParams = queryString.parse(descryptedJson[1]);
        console.log('queryParams :', queryParams);
        const type = queryParams?.type;
        const typeClass = queryParams?.type_class;
        const slot = queryParams?.slot;
        var id = {}
        var edit_class_id = descryptedJson[0]
        id.id = descryptedJson[0]
        id.class = type
        id.type_class = typeClass
        id.slot = slot
    }


    const [data, setData] = useState([]);
    console.log('data43435 :', data);
    const [learnerData, setLearnerData] = useState([]);
    const [currentDate, setCurrentDate] = useState(dayjs());
    const [nextMonth, setNextMonth] = useState(currentDate.add(1, 'month'));
    const [previousMonth, setPreviousMonth] = useState(currentDate.subtract(1, 'month'));
    const [selectedMonth, setSelectedMonth] = useState(currentDate);
    const [UpdatedData, setUpdatedData] = useState(currentDate.format("MMM YYYY"));

    const NextMonthYear = (e) => {
        e.preventDefault();
        setCurrentDate((prevCurrentDate) => prevCurrentDate.add(1, 'month'));
        setNextMonth((prevNextMonth) => prevNextMonth.add(1, 'month'));
        setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.add(1, 'month'));
        setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.add(1, 'month'));
    };

    const PreviousMonthYear = (e) => {
        e.preventDefault();
        setCurrentDate((prevCurrentDate) => prevCurrentDate.subtract(1, 'month'));
        setNextMonth((prevNextMonth) => prevNextMonth.subtract(1, 'month'));
        setPreviousMonth((prevPreviousMonth) => prevPreviousMonth.subtract(1, 'month'));
        setSelectedMonth((prevSelectedMonth) => prevSelectedMonth.subtract(1, 'month'));
    }

    const PMonth = (e) => {
        setUpdatedData(e)
        console.log("XYZ", e)
    }

    const learner_listing = () => {
        // isLoader(true)

        learner_name_list({ activity_id: id?.id, activity_type: id?.class, slot_id: id?.slot }).then((resposnse) => {
            console.log("learner_name_list", resposnse)
            // isLoader(false)

            if (resposnse?.code == 1) {
                setLearnerData(resposnse.data)
            } else {

            }
        });
    }

    const handleSelectedLearner = (data) => {
        console.log('data123 :', data);
        var main = JSON.parse(data);
        console.log('main :', main);
        if (main != 0) {
            quiz_listing(main)

        } else {
            quiz_listing(0)

        }
    }

    const quiz_listing = (main) => {
        // isLoader(true)
        let Quiz_Data = {
            class_id: id.id,
            class_type: id.class,
            month_wise: UpdatedData.toLowerCase(),
            slot_id: id?.slot
        }

        if (main != 0) {
            if (main.type == 'user') {
                Quiz_Data.userId = main?.id
            } else {
                Quiz_Data.learnerId = main?.id
            }
        }
        console.log('Quiz_Data :', Quiz_Data);


        teach_quiz_listing(Quiz_Data).then((resposnse) => {
            console.log("teach_quiz_listing", resposnse)
            // isLoader(false)

            if (resposnse.code == 1) {
                setData(resposnse.data)
            } else {
                setData([])

            }
        });
    }

    useEffect(() => {

        quiz_listing(0);
        learner_listing();

    }, [UpdatedData]);

    const delete_quiz = (index, id) => {

        Swal.fire({
            title: 'Are you sure you want to delete ?',
            text: "You won't be able to revert this!",
            icon: 'question',
            background: 'white',
            showCancelButton: true,
            confirmButtonColor: '#570861',
            cancelButtonColor: '#FFC924',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonClass: 'swal-cancel-button',
        }).then((result) => {
            if (result.isConfirmed) {
                teacher_delete_quiz_detail({ quiz_id: id }).then((response) => {
                    if (response?.code == 1) {
                        setData(data.filter(o => o.id !== id));
                        quiz_listing();

                        TOAST_SUCCESS(response?.message);


                    } else {
                        TOAST_ERROR(response?.message);
                    }
                })
            }
        })

    }

    const [notificationData, setNotificationData] = useState('');

    console.log('notificationData :', notificationData);

    const NotificationList = async () => {
        try {
            let send_data = {
                action_id: id?.id,
                action_type: id.class,
                teach_type: "quiz",
                slot_id: id?.slot
            }
            console.log('send_data :', send_data);

            const response = await notification_listing(send_data);
            console.log('response123 :', response);
            if (response?.code == 1) {
                setNotificationData(response?.data)
            } else {
                setNotificationData([])
            }

        } catch (error) {
            console.log('error :', error);
        }
    }
    useEffect(() => {
        NotificationList();
    }, [])

    return (
        <>
            {/* <div className="tab-pane fade" id="pills-quizzes" role="tabpanel" aria-labelledby="pills-quizzes-tab"> */}
            <div className="row">
                <div className="col-xl-9 mb-xl-0 mb-4">
                    <div className="d-flex mb-4 justify-content-between align-items-center">
                        <Link to="/setup_my_quiz" state={id} onClick={() => { localStorage.setItem("location", JSON.stringify(location)); }}
                            className="btn-theme fw-500 text-dif-black border-dark">+ Add Quiz</Link>
                        <select onChange={(e) => handleSelectedLearner(e.target.value)} className="bg-purple text-white border-purple fs-6 rounded-cricle rounded-pill ms-sm-3 px-2 py-2">

                            <option value='0' selected >Learner’s Name</option>

                            {learnerData?.map((student, index) => {
                                const type = student?.learner_id ? 'learner' : 'user';
                                const id = student?.learner_id || student?.user_id;
                                const valueObj = JSON.stringify({ type, id });
                                return (
                                    <option key={index} value={valueObj}>{student?.learner_name || student?.user_name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <p className='mb-4 text-purple fw-bold fs-5 ms-2'>Note : The most recent Quizes you add will be shown at the top. </p>
                    <div className="review_dack mt-4">
                        {data?.length > 0 ?
                            data?.map((quiz_data, index) => {
                                const quizNumber = data.length - index;
                                return (

                                    <div key={index} className="card review_box mb-4">
                                        <div className="review_top">
                                            <div className="d-flex flex-wrap justify-content-between align-items-satrt">
                                                <div className="list_btw_dots mb-3">
                                                    <ul className="d-xl-flex align-items-center">
                                                        <span className="pe-4 fw-600 position-relative">Quiz {quizNumber} <span className="green_dots position-absolute" /><span className="green_dots position-absolute" /> </span>
                                                        <span className="pe-sm-4 fw-600 position-relative ms-3"> {quiz_data.title} </span>
                                                        {/* <li>
                                                            <span className="text-purple fw-500">Score : 25/30 </span>
                                                        </li> */}
                                                        <li>
                                                            <span className="text-purple fw-500">Total Duration: {quiz_data.duration}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="d-lg-flex d-block font-14 mb-3 fw-600 align-items-center">
                                                    <span>Created By</span>:&nbsp;<span>{quiz_data.Teacher_name}</span>
                                                </div>
                                            </div>
                                            <div className="list_btw_dots mb-3">
                                                <ul className="d-xl-flex align-items-center">
                                                    Description : <p className="fs-6">{quiz_data.summary}</p>
                                                </ul>
                                            </div>
                                            <div className="d-flex flex-xl-row  flex-column-reverse justify-content-between align-items-xl-center">
                                                <div className="button d-sm-flex align-items-center">
                                                    <Link to="/view-details-quiz" state={{ id: quiz_data?.id, class_id: quiz_data.class_id }} className="btn-theme px-3 mb-sm-0 mb-3  d-inline-block">View Details</Link>
                                                    <ul className="ms-3 edit_delete d-flex align-items-center justify-content-end">
                                                        <li>
                                                            <a onClick={() => (delete_quiz(index, quiz_data.id))} className>
                                                                <svg width={20} height={20} viewBox="0 0 23 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1.80679 25.6355C1.80679 27.406 3.24075 28.84 5.01126 28.84H17.8291C19.5995 28.84 21.0335 27.406 21.0335 25.6355V6.40886H1.80679V25.6355ZM17.0279 1.6022L15.4257 0H7.41459L5.81232 1.6022H0.20459V4.80667H22.6357V1.6022H17.0279Z" fill="#FF2929" />
                                                                </svg>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <Link to="/edit-quiz" state={{ id: quiz_data?.id, class_id: quiz_data.class_id }} className>
                                                                <svg width={20} height={23} viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M3.10738 18.8439C3.32277 18.8439 3.36584 18.8224 3.55969 18.7793L7.43661 18.0039C7.84584 17.8962 8.25507 17.7024 8.57815 17.3793L17.9689 7.98851C19.412 6.54543 19.412 4.06851 17.9689 2.62543L17.172 1.78543C15.7289 0.342356 13.2305 0.342356 11.7874 1.78543L2.39661 11.1977C2.09508 11.4993 1.87969 11.93 1.772 12.3393L0.953537 16.2593C0.845845 16.9916 1.06123 17.7024 1.57815 18.2193C1.98738 18.6285 2.59046 18.8439 3.10738 18.8439V18.8439ZM3.83969 12.7485L13.2305 3.3362C13.8551 2.71159 14.9966 2.71159 15.5997 3.3362L16.4181 4.15466C17.1505 4.88697 17.1505 5.92082 16.4181 6.63159L7.04892 16.0439L3.06431 16.7116L3.83969 12.7485Z" fill="#570861" />
                                                                    <path d="M17.8827 20.1797H1.98734C1.36272 20.1797 0.953491 20.5889 0.953491 21.2135C0.953491 21.8382 1.47041 22.2474 1.98734 22.2474H17.7966C18.4212 22.2474 18.9381 21.8382 18.9381 21.2135C18.9166 20.5889 18.3997 20.1797 17.8827 20.1797Z" fill="#570861" />
                                                                </svg>
                                                            </Link>
                                                        </li>
                                                    </ul>

                                                    {/* <a className="btn-theme px-3 ms-sm-3 bg-yellow d-inline-block text-center">Show Certificate</a> */}
                                                </div>
                                                <div className="list_btw_dots mb-xl-0 mb-4">
                                                    <ul className="d-sm-flex align-items-center flex-wrap justify-content-end">
                                                        <li>
                                                            <span className="text-purple fw-500"> <span className="text-dif-black">Posted On:</span> {quiz_data.Posted_on}</span>
                                                        </li>
                                                        {/* <li>
                                                            <span className="text-purple fw-500"><span className="text-dif-black">Deadline:</span> 25 March 2022   5:00 PM</span>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                            <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>Data Not Found</h4>
                        }
                    </div>
                    {/*start-pagination*/}
                    <nav aria-label="Page navigation example" className="mt-5 custom-pagination">
                        <ul className="pagination justify-content-evenly align-items-center border-radius-10">

                            <li className="page-item">
                                <button className="page-link text-dark border-start-0" style={{ "border": "0 !important" }} aria-label="Previous" onClick={(e) => PreviousMonthYear(e)}>
                                    <span className>
                                        <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="M561-240 320-481l241-241 43 43-198 198 198 198-43 43Z" /></svg>
                                    </span>
                                </button>
                            </li>
                            <li className={`page-item ${UpdatedData == previousMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(previousMonth.format("MMM YYYY"))}>{previousMonth.format("MMM YYYY")}</a></li>
                            <li className={`page-item ${UpdatedData == selectedMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(selectedMonth.format("MMM YYYY"))}>{selectedMonth.format("MMM YYYY")}</a></li>
                            <li className={`page-item ${UpdatedData == nextMonth.format("MMM YYYY") ? 'active' : ''}`}><a className="page-link text-dark border-start-0 border-end-0" onClick={(e) => PMonth(nextMonth.format("MMM YYYY"))}>{nextMonth.format("MMM YYYY")}</a></li>
                            <li className="page-item">
                                <button className="page-link border-start-0 " style={{ "border": "0 !important" }} aria-label="Next" onClick={(e) => NextMonthYear(e)}>
                                    <span>
                                        <span>
                                            <svg xmlns="http://www.w3.org/2000/svg" height={22} viewBox="0 -960 960 960" width={25}><path d="m375-240-43-43 198-198-198-198 43-43 241 241-241 241Z" /></svg>
                                        </span>
                                    </span>
                                </button>
                            </li>

                        </ul>
                    </nav>
                    {/*end*/}
                </div>
                <div className="col-xl-3">
                    <div className>
                        <div className="accordion border-radius-10 " id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header poppins" id="headingOne">
                                    <button className="accordion-button fs-5 text-dif-black bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">New Notifications</button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className="list_notification">
                                            {Array.isArray(notificationData) && notificationData.map((dateItem, index) => (
                                                <div key={index}>
                                                    {/* <span className="mb-4 d-block">{dateItem.date}</span> */}
                                                    {dayjs(dateItem.date).isSame(dayjs(), 'day') && (
                                                        <span className="mb-4 d-block">Today</span>
                                                    )}
                                                    {/* Check if the date is yesterday */}
                                                    {dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                                                        <span className="mb-4 d-block">Yesterday</span>
                                                    )}
                                                    {/* Render the date */}
                                                    {!dayjs(dateItem.date).isSame(dayjs(), 'day') &&
                                                        !dayjs(dateItem.date).isSame(dayjs().subtract(1, 'day'), 'day') && (
                                                            <span className="mb-4 d-block">{dateItem.date}</span>
                                                        )}

                                                    <ul className="">
                                                        {dateItem.notification_data.map((notification, index) => (
                                                            <li className="d-flex align-items-center mb-4">
                                                                <div className="notif_left d-flex align-items-center">
                                                                    <div className={`noti_img bg-${notification.tag === 'add_class' ? 'pink' : notification.tag === 'quiz' ? 'diff-yellow-op-1' : 'sky-blue-op-1'} position-relative`}>
                                                                        <span className={`text-uppercase text-${notification.tag === 'add_class' ? 'danger' : notification.tag === 'quiz' ? 'yellow' : 'blue'}`}>{notification.message.charAt(0)}</span>
                                                                        <span className="notifi_logs">
                                                                            <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M10 4.5V9C10 9.13261 9.94732 9.25979 9.85355 9.35355C9.75979 9.44732 9.63261 9.5 9.5 9.5H0.5C0.367392 9.5 0.240215 9.44732 0.146447 9.35355C0.0526784 9.25979 0 9.13261 0 9V4.5H10ZM10 2.5H0V1C0 0.867392 0.0526784 0.740215 0.146447 0.646447C0.240215 0.552678 0.367392 0.5 0.5 0.5H9.5C9.63261 0.5 9.75979 0.552678 9.85355 0.646447C9.94732 0.740215 10 0.867392 10 1V2.5Z" fill="#3F3F44" />
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                    <p className="ms-3 noti_text">{notification.message}</p>
                                                                </div>
                                                                <span className="noti_timer">
                                                                    {dayjs(notification.insert_datetime).format('hh:mm A')}
                                                                </span>
                                                            </li>
                                                        ))}
                                                        {/* <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-diff-yellow-op-1 position-relative">
                              <span className="text-uppercase text-yellow">q</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">New assignments.</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li>
                        <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-sky-blue-op-1 position-relative">
                              <span className="text-uppercase text-blue">e</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">Any upcoming events</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li>
                        <li className="d-flex align-items-center mb-4">
                          <div className="notif_left d-flex align-items-center">
                            <div className="noti_img bg-sky-blue-op-1 position-relative">
                              <span className="text-uppercase text-blue">e</span>
                              <span className="notifi_logs">
                                <svg width={10} height={10} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M4.99914 7.5L2.06014 9.295L2.85914 5.945L0.244141 3.705L3.67664 3.43L4.99914 0.25L6.32164 3.43L9.75464 3.705L7.13914 5.945L7.93814 9.295L4.99914 7.5Z" fill="#3F3F44" />
                                </svg>
                              </span>
                            </div>
                            <p className="ms-3 noti_text">Any upcoming events</p>
                          </div>
                          <span className="noti_timer">10.12 PM</span>
                        </li> */}
                                                    </ul>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    )
}
