import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TOAST_ERROR, TOAST_SUCCESS } from "../../../../utils/common.service";
import { BASE_NAME } from "../../../../Config";
import { add_card, available_classes, create_payment, meeting_details, subscribe_class } from "../../../../api/apiHandler";
import dayjs from "dayjs";
import Swal from "sweetalert2";

const SubscribeModal3 = ({ data, totalSubscriptionAmount, activity_type, learnerData, isLoader }) => {
  console.log('data :', data);
  console.log('totalSubscriptionAmount :', totalSubscriptionAmount);
  const navigate = useNavigate();
  const modalRef = useRef();

  // class schedule list
  // console.log(data, 'SubscribeModal3');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const USER_NAME = localStorage.getItem("MAname");
  const USER_ID = localStorage.getItem("PAid");
  const USER_TYPE = localStorage.getItem("userType");
  const [available_Classes, set_available_Classes] = useState([]);

  const available_classes_Details = () => {
    // console.log(data.id,'newid');

    meeting_details({ activity_id: data?.id, activity_type: activity_type, length: data?.length }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_available_Classes(resposnse?.data);
      } else {
        set_available_Classes([]);
      }
    });
  };

  useEffect(() => {
    available_classes_Details()
  }, [])




  const onSubmit = async (value) => {
    
    let paymentBody = {
      activity_id: data?.id,
      meeting_id: data?.meeting_id,
      activity_type,
      total_amount: totalSubscriptionAmount?.amount,
      tax_charge: totalSubscriptionAmount?.tax_charge.toFixed(2),
      is_recurring: value.isChecked ? 1 : 0,
      teacher_id: data.teacher_id,
      learnerData: learnerData,
      user_currency_code: data?.currency_code,
      coupan_id: totalSubscriptionAmount?.coupon_id,
      amount:data.price_per_meeting,
      slot_id:data?.slot_id
    };


    console.log('paymentBody', paymentBody);
    // return
    isLoader(true)
      if (USER_TYPE === "above_18") paymentBody.user_id = USER_ID;
      else paymentBody.learner_id = totalSubscriptionAmount?.learners[0];
      console.log(paymentBody);
      const { code: payment_code, message: payment_message, data: payment_data } = await subscribe_class(paymentBody);
      console.log('payment_data :', payment_data?.session);
      console.log('payment_data :', payment_data);
      console.log('payment_message :', payment_message);
      console.log('payment_code :', payment_code);


      console.log(payment_message, 'errorr');
 
      if (payment_code == 1) {
        isLoader(false)
        // TOAST_SUCCESS("Transaction Successfull!");
        reset();
        window.open(payment_data?.session, '_blank')
        if (modalRef.current) {
          modalRef.current.setAttribute("data-bs-dismiss", "modal");
          modalRef.current.click();
          setTimeout(() => {
            modalRef.current.removeAttribute("data-bs-dismiss");
          }, 100);
        }
        Swal.close();
        // if (activity_type != 'quiz') {

        //   navigate('/class-requirement', { state: { activity_id: data?.id, activity_type: activity_type, title: data?.title, learnerData: USER_TYPE === "above_18" ? [{ id: USER_ID, learner_name: USER_NAME }] : learnerData, data: payment_data[0] } });
        // }
      } else {
        TOAST_ERROR(payment_message)
        isLoader(false);
      }
    
  
    try {
    } catch (err) {
      TOAST_ERROR(err.message);
      // isLoader(false);

    }
  };

  const closeModals = () => {
    // Close payment modal
    if (modalRef.current) {
      modalRef.current.click();
    }

    // Close any active Swal modal
    if (Swal.isVisible()) {
      Swal.close();
    }
  };

  const handleCancel = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to cancel the payment?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: 'btn-theme space',
        cancelButton: 'btn-theme bg-yellow space'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {

        reset();

        if (modalRef.current) {
          modalRef.current.setAttribute("data-bs-dismiss", "modal");
          modalRef.current.click();
          setTimeout(() => {
            modalRef.current.removeAttribute("data-bs-dismiss");
          }, 100);
        }
        Swal.close();

      }
    });
  };



  return (
    <div className="modal fade subscription_modal_1" id="subscription_modal_3" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
            <div className="modal-body">
              <div className="class_box subscription_first border-bottom pb-4">
                <div className="single-box mx-0 border-0 d-xl-flex">
                  <div className="class_image_box h-100">
                    <div className="class_img border-radius-10 h-100 position-relative">
                      {data?.video_file ? (
                        <video className="img-fluid w-100 h-100" controls>
                          <source src={data.video_file} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <img src={`./assets/images/knowledge.png`} alt="class1" className="img-fluid w-100 h-100" />
                      )}
                      <div className="d-sm-flex bottom-info align-items-center justify-content-between pointer">
                        <div
                          onClick={() => {
                            navigate("/teachers_details", { state: data?.teacher_id });
                            modalRef.current.click();
                          }}
                          className="d-flex mb-sm-0 align-items-center"
                        >
                          <img src={data?.profile_image || "./assets/images/NicePng_watsapp-icon-png_9332131.png"} alt="user1" />
                          <span className="tech_nm ms-2">{data?.full_name}</span>
                        </div>
                        <div className="star_rat">
                          <span>
                            <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                                fill="#FDC840"
                              />
                            </svg>
                          </span>

                          <span className="fw-600">
                            {data?.teacher_rating ? `${data?.teacher_rating}` : "0"}
                            <span className="text-gray fw-500">({data?.teacher_review ? `${data?.teacher_review}` : "0"})</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="class_room_info bg-transparent ps-lg-5">
                    <div className="class_room_inner">
                      <div className="class_rm_text ">
                        <div className="mb-4">
                          <h2 className="fs-4 mb-3">
                            {data?.title}
                          </h2>
                          {available_Classes?.length > 0 ? (
                            <p className="text-gray text-capitalize">Next meets on {available_Classes[0]?.date}, {available_Classes[0]?.end_time}</p>

                          ) : (
                            <p className="text-gray text-capitalize">Next meets on Sun, Apr 30, 6:40 AM</p>

                          )}

                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="button safety_btn mt-4 d-flex justify-content-end">
                  <a href="#!" data-bs-toggle="modal" data-bs-target="#secure_payment" className="btn-theme mb-3 bg-yellow d-inline-block ">
                    <span className="me-3">
                      <svg width={26} height={31} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.1731 9.78467C10.0212 9.78467 7.45703 12.3488 7.45703 15.5005C7.45703 18.6525 10.0212 21.2166 13.1731 21.2166C16.3249 21.2166 18.889 18.6525 18.889 15.5005C18.889 12.3488 16.3249 9.78467 13.1731 9.78467ZM16.5668 14.4241L12.4718 18.519C12.3902 18.6009 12.2931 18.6658 12.1863 18.7101C12.0795 18.7544 11.9649 18.7771 11.8493 18.777C11.7337 18.7771 11.6192 18.7544 11.5124 18.7102C11.4056 18.6659 11.3086 18.6009 11.227 18.519L9.66209 16.9541C9.31832 16.6106 9.31832 16.0533 9.66209 15.7095C10.0056 15.3657 10.563 15.3657 10.9067 15.7095L11.8493 16.652L15.3221 13.1795C15.6657 12.8356 16.223 12.8356 16.5668 13.1795C16.9105 13.523 16.9105 14.0804 16.5668 14.4241Z"
                          fill="#570861"
                        />
                        <path
                          d="M25.9172 8.63975L25.9163 8.61643C25.9027 8.30584 25.8937 7.99507 25.8893 7.68421C25.8648 6.03005 24.5499 4.66177 22.8955 4.56937C19.4463 4.37683 16.778 3.25213 14.4981 1.02991L14.4787 1.01139C13.735 0.329536 12.6133 0.329536 11.8694 1.01139L11.8499 1.02991C9.57005 3.25213 6.90175 4.37683 3.45252 4.56955C1.79842 4.66177 0.48323 6.03011 0.458738 7.68439C0.454439 7.99519 0.445454 8.3059 0.431785 8.61643L0.430379 8.67063C0.363289 12.1879 0.279969 16.5652 1.74434 20.5384C2.54959 22.7233 3.76904 24.6225 5.36871 26.1837C7.19062 27.9617 9.5769 29.3732 12.4613 30.3789C12.5562 30.4119 12.6533 30.4384 12.7519 30.4581C12.892 30.486 13.033 30.5 13.174 30.5C13.315 30.5 13.4562 30.486 13.5961 30.4581C13.6951 30.4383 13.7926 30.4116 13.8879 30.3784C16.7688 29.3709 19.1526 27.9587 20.9729 26.181C22.5719 24.6194 23.7914 22.7196 24.5973 20.5343C26.0671 16.5492 25.9841 12.1636 25.9172 8.63975ZM13.1736 22.9769C9.05091 22.9769 5.69713 19.6231 5.69713 15.5005C5.69713 11.3778 9.05097 8.02405 13.1736 8.02405C17.296 8.02405 20.65 11.3778 20.65 15.5005C20.65 19.6231 17.296 22.9769 13.1736 22.9769Z"
                          fill="#570861"
                        />
                      </svg>
                    </span>
                    Secure Payment
                  </a>
                </div>
              </div>
            
            </div>
            <div className="modal-footer d-flex flex-column justify-content-center text-center">
              <button className="btn-theme bg-yellow mb-3 d-block">
                {/* Pay CA $22.00 CAD Pay */}Pay {data?.currency_symbol ? data.currency_symbol : '$'}{totalSubscriptionAmount?.amount ? parseFloat(totalSubscriptionAmount.amount).toFixed(2) : '0.00'} {data?.currency_code ? data.currency_code : 'USD'}
              </button>
              <a ref={modalRef} className="text-purple d-block pointer" onClick={handleCancel} >
                Cancel
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SubscribeModal3;
