import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { country_list, org_location_approval_application, state_province_list } from '../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { uploadImageOnAWS } from '../../aws/Service';
import { Video_URL1 } from '../../../Config';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetailsApiCall } from '../../../Store/slices/MasterSlice';

export default function OnsiteApplication({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();

    const handleGetUserDetailsApiCall = () => {
        dispatch(getUserDetailsApiCall({ userId: localStorage.getItem("Tid"), type: localStorage.getItem('type') }));
    }

    useEffect(() => {
        if (localStorage.getItem("Tid")) {
            handleGetUserDetailsApiCall();
        }
    }, []);
    const { userDetails: { data: userDetails } } = useSelector((state) => state.master);

  const schema = yup.object().shape({
    full_name: yup.string().required("Please enter your full legal name")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'Full name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    org_position: yup.string().required("Please enter your position")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Position must contain at least 2 characters long"),

    street_number: yup
      .string()
      .required("Please enter street number")
      .test(
        'no-only-whitespace',
        'Street number cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .max(50, "Street number can't exceed 50 characters"),
    street_name: yup
      .string()
      .required("Please enter street name")
      .test(
        'no-only-whitespace',
        'Street name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Street name must have at least 2 or 3 characters")
      .max(200, "Street name can't exceed 200 characters"),
    country: yup.string().required("Please select country"),
    state_province: yup.string().required("Please select state/province"),
    city: yup
      .string()
      .required("Please enter city")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s'!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'City cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(2, "City must have at least 2 characters")
      .max(200, "City can't exceed 200 characters"),

    zip_code: yup
      .string()
      .required("Please enter zip code")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z0-9\s\-]+$/, "Special characters are not allowed")
      .test(
        'no-only-whitespace',
        'Zip code cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .matches(
        /^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|\d{4}|\d{6})$/,
        "Please enter valid zip code"
      ),
    authorization: yup.string().required('Please confirm if you are authorized to represent the organization'),
    organzation_meeting: yup.string().required('Please confirm this option'),
    hasGoogleMyBusiness: yup.string().required('Please confirm if you have google my business account'),
    // business_account_url: yup.string().required('Please select this'), 

    insurance_doc: yup
      .mixed()
      .required("Please upload insurance document"),

    // checkbox: yup.boolean()
    //   .oneOf([true], 'You must agree to the your information is accurate or not')
    //   .required('You must agree to the your information is accurate or not'),
    checkbox1: yup.boolean()
      .oneOf([true], 'Please confirm this guidlines')
      .required('Please confirm this guidlines'),
    checkbox2: yup.boolean()
      .oneOf([true], 'Please confirm this guidlines')
      .required('Please confirm this guidlines'),
    checkbox3: yup.boolean()
      .oneOf([true], 'Please confirm this guidlines')
      .required('Please confirm this guidlines'),
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  console.log('errors :', errors);

  var [CountryListdata, setCountryListdata] = useState([]);
  var [CountryID, setCountryID] = useState([]);
  var [stateListdata, setstateListdata] = useState([]);
  var [StateID, setStateID] = useState([]);
  // console.log('StateID :', StateID);

  // console.log('stateListdata :', stateListdata);
  console.log('CountryID :', CountryID);
  // console.log('CountryListdata :', CountryListdata);

  const countryListing = () => {
    isLoader(true)
    country_list().then((response) => {
      console.log("country_list", response)

      if (response?.code == 1) {
        isLoader(false)
        setCountryListdata(response.data)

      } else {
        isLoader(false)
      }
    });
  }

  const StateListing = () => {
    console.log(CountryID)
    isLoader(true)
    state_province_list({ country_id: CountryID }).then((response) => {
      console.log("state_province_list", response)
      if (response?.code == 1) {
        isLoader(false)
        setstateListdata(response.data)
      } else {
        isLoader(false)
      }
    });
  }

  useEffect(() => {
    countryListing();
  }, []);
  useEffect(() => {
    StateListing()
  }, [CountryID]);

  const handleCountryChange = (e) => {
    setstateListdata([])
    setCountryID(e)
    clearErrors('country')
  }

  const handleStateChange = (e) => {
    setStateID(e)
    clearErrors('state_province')
  }

  const [profileImageState, setProfileImageState] = useState('')
  const [profileImageState1, setProfileImageState1] = useState(['', '', '', '', '', ''])
  const [profileImageState2, setProfileImageState2] = useState(['', '', ''])

  const [docExtension, setDocExtension] = useState('')
  const [docExtension1, setDocExtension1] = useState(['', '', '', '', '', ''])
  const [docExtension2, setDocExtension2] = useState(['', '', ''])

  console.log('docExtension1 :', docExtension1);

  // const handleImage1Change = async (image, numImage) => {
  //   console.log('numImage :', numImage)
  //   console.log('image :', image.type);
  //   // setDocExtension1(image.type)
  //   if (image != null) {
  //     TOAST_SUCCESS("Your attachment has been uploaded!");
  //   }
  //   // console.log('image :', image);
  //   setValue("inside_upload_" + numImage, image);
  //   const bufferImage = await convertToBase64(image);
  //   var temp_arr = profileImageState1
  //   temp_arr[numImage] = bufferImage
  //   var temp_arr_ext = docExtension1
  //   temp_arr_ext[numImage] = image.type
  //   setProfileImageState1(temp_arr);
  //   setDocExtension1(temp_arr_ext)

  //   clearErrors("");
  // }

  const handleImage1Change = (file, index) => {
    if (!file) return;  // Exit if no file is selected

    const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg'];

    // Check for allowed file types
    if (!allowedExtensions.includes(file.type)) {
      TOAST_ERROR("Please upload a valid image (JPEG, PNG , JPG)");
      return;
    }

    if (file != null) {
      TOAST_SUCCESS("Your attachment has been uploaded");
    }

    const reader = new FileReader();

    reader.onload = () => {
      // Update form values using setValue
      setValue(`attach${index + 1}`, file);

      // Update state if needed
      const updatedProfileImages = [...profileImageState1];
      updatedProfileImages[index] = reader.result;
      setProfileImageState1(updatedProfileImages);

      const updatedDocExtensions = [...docExtension1];
      updatedDocExtensions[index] = file.type;
      setDocExtension1(updatedDocExtensions);
    };

    reader.readAsDataURL(file);
  };

  // const handleImage2Change = async (image, numImage) => {
  //   console.log('numImage :', numImage)
  //   console.log('image :', image.type);
  //   // setDocExtension1(image.type)
  //   if (image != null) {
  //     TOAST_SUCCESS("Your attachment has been uploaded!");
  //   }
  //   // console.log('image :', image);
  //   setValue("outside_upload_" + numImage, image);
  //   const bufferImage = await convertToBase64(image);
  //   var temp_arr2 = profileImageState2
  //   temp_arr2[numImage] = bufferImage
  //   var temp_arr_ext2 = docExtension2
  //   temp_arr_ext2[numImage] = image.type
  //   setProfileImageState2(temp_arr2);
  //   setDocExtension2(temp_arr_ext2)

  //   clearErrors("");
  // }

  // const handleImageChange = async (image) => {
  //   console.log('image :', image.type);
  //   setDocExtension(image.type)
  //   if (image != null) {
  //     TOAST_SUCCESS("Your attachment has been uploaded!");
  //   }
  //   // console.log('image :', image);
  //   setValue("insurance_doc", image);
  //   const bufferImage = await convertToBase64(image);
  //   setProfileImageState(bufferImage);
  //   clearErrors("insurance_doc");
  // }

  const handleImage2Change = async (image, numImage) => {
    console.log('numImage :', numImage);
    console.log('image :', image.type);

    // Allow only image types (jpeg, png, etc.)
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    if (!allowedTypes.includes(image.type)) {
      TOAST_ERROR("Please upload a valid image (JPEG, PNG , JPG)");
      return; // Exit the function if the file is not an image
    }

    // If the image is valid
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded");
    }

    setValue("outside_upload_" + numImage, image);
    const bufferImage = await convertToBase64(image);

    // Update the state arrays
    var temp_arr2 = profileImageState2;
    temp_arr2[numImage] = bufferImage;
    var temp_arr_ext2 = docExtension2;
    temp_arr_ext2[numImage] = image.type;

    setProfileImageState2(temp_arr2);
    setDocExtension2(temp_arr_ext2);

    clearErrors("");
  };

  const handleImageChange = async (image) => {
    if (!image) return;

    const allowedFileTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    if (!allowedFileTypes.includes(image.type)) {
      TOAST_ERROR("Please upload a valid file (jpg, jpeg, png, pdf, doc, docx)");
      return; // Stop further execution if file type is invalid
    }

    console.log('image type:', image.type);
    setDocExtension(image.type);

    if (image) {
      TOAST_SUCCESS("Your attachment has been uploaded");
    }

    setValue("insurance_doc", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("insurance_doc");
  };

  const handleDocumentRemove = (index) => {
    const newState = [...profileImageState1];
    newState[index] = '';
    setProfileImageState1(newState);
    document.getElementById(`attach${index + 1}`).value = null;
  };

  const handleDocumentRemove1 = (index) => {
    const newState = [...profileImageState2];
    newState[index] = '';
    setProfileImageState2(newState);
    document.getElementById(`attach${index + 7}`).value = null;
  };

  let onSubmit = async (data) => {

    isLoader(true)
    console.log('data :', data);

    let submitData = {
      org_id: localStorage.getItem("Tid"),
      reg_full_name: data.full_name,
      position: data.org_position,
      authorized: data.authorization,
      street_number: data.street_number,
      street_name: data.street_name,
      country: data.country,
      state: data.state_province,
      city: data.city,
      zip_code: data.zip_code,
      physical_location: data.organzation_meeting,
      google_business: data.hasGoogleMyBusiness,
      business_url: data.business_account_url,
      social_media_account: data.social_media_accounts,
      email : userDetails.email
      // insuarance_doc : 
    }

    let attachment1 = data?.insurance_doc

    if (data?.insurance_doc) {
      attachment1 = await uploadImageOnAWS(attachment1, "organization_onsite_application");
      submitData.insuarance_doc = attachment1;
    }

    var inside_attachments = [];
   
    var outside_attachments = [];

    for (let i = 0; i <= 5; i++) {
      if (data[`attach${i + 1}`]) {
        var inside_upload = await uploadImageOnAWS(data[`attach${i + 1}`], "organization_onsite_application");
        inside_attachments.push({ "attachment": inside_upload });
      }
    }
    for (let i = 0; i <= 2; i++) {
      if (data[`outside_upload_${i}`]) {
        var outside_upload = await uploadImageOnAWS(data[`outside_upload_${i}`], "organization_onsite_application");
        outside_attachments.push({ "attachment": outside_upload });
      }
    }
    console.log('inside_attachments :', inside_attachments.length);
    console.log('outside_attachments :', outside_attachments.length);
    if (inside_attachments.length < 3) {
      isLoader(false);
      TOAST_ERROR("Please upload at least 3 inside attachments.");
      return;
    }

    if (outside_attachments.length < 1) {
      isLoader(false);
      TOAST_ERROR("Please upload at least 1 outside attachment.");
      return;
    }

    var attachment_inside = [{
      "activity_type": "inside",
      "attachments": inside_attachments
    }]
    var attachment_outside = [{
      "activity_type": "outside",
      "attachments": outside_attachments
    }]
    var main_attachment = {
      "attachment_inside": attachment_inside,
      "attachment_outside": attachment_outside
    }
    submitData.attachments = main_attachment
    console.log('submitData :', submitData);
    org_location_approval_application(submitData).then((res) => {
      // console.log("submitData",coupon_data)
      if (res.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(res.message);
        navigate("/organization-step")
      } else {
        isLoader(false)
        TOAST_ERROR(res.message);
      }
    });
  }

  return (
    <>

      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row my-4 justify-content-center">
              <div className="col-lg-8 right card">
                <div className="top">
                  <div className="arrow_back mb-3">
                    <a onClick={() => navigate(-1)} onclick="history.back()" className="d-flex align-items-center">
                      <span>
                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                        </svg>
                      </span>
                      <span className="ps-3">Back</span>
                    </a>
                  </div>
                  <h2 className="mb-3">APPLICATION FOR IN-PERSON CLASSES</h2>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 mx-auto" style={{ textAlign: "center" }}>
                        <div className="video_section position-relative dash_video py-3">
                          <div className="video_box">
                            <video
                              id="video"
                              loop
                              height={564}
                              controls="controls"
                              style={{ display: "block", margin: "0 auto" }}
                            >
                              <source
                                src={`${Video_URL1}/In-person Lessons Introduction.mp4`}
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">

                    <p className="mb-2">We StudentScholars offer programs to be conducted at your own onsite location and we StudentScholars will find you the Students too.</p>
                    <p className="mb-3">Please note in this case you as the Application needs to have your registered Commercial Center. This would mean that the ‘Application Form’ should be filled  ‘Offering Programs’ by those who are listed on Google as an Education Centre and not those who  intend to apply from home.</p>
                    <p className="mb-3">When your location is approved please follow the following procedures :</p>
                    <ol className="list-group-numbered">
                      <li className="mb-2">You will receive a Location Identifier via email- It is a poster of 8.5x 11 inches. Please put it up at the entrance of your location to let our  students know that your location is an ‘Authorized Location’ by StudentScholars’. Please click to see a SAMPLE  of how the <a href="#" className="text-purple">poster</a> looks like.</li>
                      <li className="mb-2">Directions: when you receive the poster, please print it on a glossy (8x11 inch) white paper.</li>
                      <li className="mb-2">Please complete the ‘Application Form’ provided below to get started.</li>
                    </ol>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                    <h2 className="mb-4">APPLICATION FORM</h2>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Your Full Name</label>
                          <input type="text" className="form-control" {...register("full_name")} name="full_name" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.full_name?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please confirm if you are authorized to represent the organization.</label>
                          <div className="d-flex">
                            <div className="form-check me-3">
                              <input className="form-check-input" type="radio" {...register('authorization')} id="flexRadioDefault22" value="Yes" />
                              <label className="form-check-label m-1" htmlFor="flexRadioDefault22">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" {...register('authorization')} id="flexRadioDefault2" value="No" />
                              <label className="form-check-label m-1" htmlFor="flexRadioDefault2">
                                No
                              </label>
                            </div>
                          </div>
                          {errors.authorization && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.authorization.message}</span></p>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">What is your position at the organization</label>
                          <input type="text" className="form-control" {...register("org_position")} name="org_position" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_position?.message}</span></p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-3">The address of the legally authorized location of your organization in which you will provide lessons at.</label>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Street Number</label>
                                <input type="text" className="form-control" {...register("street_number")} name="street_number" />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_number?.message}</span></p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Street Name</label>
                                <input type="text" className="form-control"  {...register("street_name")} name="street_name" />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_name?.message}</span></p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Country</label>

                                <select className="form-select" {...register("country")} onChange={(e) => handleCountryChange(e.target.value)} name="country" aria-label="Default select example">
                                  <option value='' selected disabled>select country</option>
                                  {CountryListdata?.map((countrylistdetail, index) => {
                                    return (
                                      <option key={index} value={countrylistdetail?.country_id}>{countrylistdetail?.country_name}</option>
                                    )
                                  })}
                                </select>

                                <p className='mt-2 font-bold col-red'>
                                  <span style={{ color: 'red' }}>{errors.country?.message}</span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Province/State</label>
                                <select className="form-select" {...register("state_province")} onChange={(e) => handleStateChange(e.target.value)} name="state_province" aria-label="Default select example">
                                  <option value='' selected disabled>select state/province</option>
                                  {stateListdata?.map((countrylistdetail, index) => {
                                    return (
                                      <option key={index} value={countrylistdetail?.state_id}>{countrylistdetail?.state_province}</option>
                                    )
                                  })}
                                </select>

                                <p className='mt-2 font-bold col-red'>
                                  <span style={{ color: 'red' }}>{errors.state_province?.message}</span>
                                </p>

                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label className="mb-2">City</label>
                              <input type="text" className="form-control" {...register("city")} name="city" />
                              <p className='mt-2 font-bold col-red'>
                                <span style={{ color: 'red' }}>{errors.city?.message}</span>
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <label className="mb-2">Zip Code</label>
                              <input type="text" {...register("zip_code")} className="form-control" name="zip_code" />
                              <p className='mt-2 font-bold col-red'>
                                <span style={{ color: 'red' }}>{errors.zip_code?.message}</span>
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please confirm if the physical location/business is operating meeting the guidelines as per the laws and regulations of your City/Country.</label>
                          <div className="d-flex">
                            <div className="form-check me-3">
                              <input className="form-check-input" type="radio" {...register('organzation_meeting')} id="organzation_meeting2" value="Yes" />
                              <label className="form-check-label m-1" htmlFor="organzation_meeting2">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" {...register('organzation_meeting')} id="organzation_meeting" value="No" />
                              <label className="form-check-label m-1" htmlFor="organzation_meeting">
                                No
                              </label>
                            </div>
                          </div>
                          {errors.organzation_meeting && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.organzation_meeting.message}</span></p>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Do you have a Google My Business Account ?</label>
                          <div className="d-flex">
                            <div className="form-check me-3">
                              <input className="form-check-input" type="radio" {...register('hasGoogleMyBusiness')} id="hasGoogleMyBusinessYes" value="Yes" />
                              <label className="form-check-label m-1" htmlFor="hasGoogleMyBusinessYes">
                                Yes
                              </label>
                            </div>
                            <div className="form-check">
                              <input className="form-check-input" type="radio" {...register('hasGoogleMyBusiness')} id="hasGoogleMyBusinessNo" value="No" />
                              <label className="form-check-label m-1" htmlFor="hasGoogleMyBusinessNo">
                                No
                              </label>
                            </div>
                          </div>
                          {errors.hasGoogleMyBusiness && <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.hasGoogleMyBusiness.message}</span></p>}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please share URL of Business Account
                            (Optional)</label>
                          <input type="text" {...register('business_account_url')} className="form-control" name="business_account_url" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please share any social media accounts that shows your business location (Optional)</label>
                          <input type="text" {...register('social_media_accounts')} className="form-control" name="social_media_accounts" />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please upload a liability insurance document to confirm that your organization is insured <span className="text-danger">*</span></label>
                          <div className="row">
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="insurance_doc" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {(docExtension === 'image/jpeg' || docExtension === 'image/png') && profileImageState && (
                                          <img onClick={() => {
                                            document.getElementById("insurance_doc")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState ? profileImageState : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState ? "100%" : "",
                                              height: profileImageState ? "166px" : "",
                                            }}
                                          />
                                        )}

                                        {docExtension === 'application/pdf' && profileImageState && (
                                          <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                              document.getElementById("insurance_doc")?.click()
                                            }}>
                                              <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                            </svg>
                                          </>
                                        )}

                                        {(docExtension === 'application/msword' || docExtension === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState && (
                                          <>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                              document.getElementById("insurance_doc")?.click()
                                            }}>
                                              <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                            </svg>
                                          </>
                                        )}
                                      </div>

                                      <input type="file" id='insurance_doc' name="insurance_doc" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImageChange(e.target.files[0])} className="form-control" style={{ display: profileImageState ? "none" : "" }} />
                                      {profileImageState ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => {
                                            setProfileImageState(null); // Reset the uploaded file state
                                            document.getElementById("insurance_doc").value = null; // Reset the input field
                                          }}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.insurance_doc?.message}</span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Please upload 3 minimum up to 6 maximum pictures to show the inside space of where the classes will be held.</label>
                          <div className="row">
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {(docExtension1[0] === 'image/jpeg' || docExtension1[0] === 'image/png') && profileImageState1[0] && (
                                          <img onClick={() => {
                                            document.getElementById("attach1")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[0] !== '' ? profileImageState1[0] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[0] !== '' ? "100%" : "",
                                              height: profileImageState1[0] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach1' name="attach1" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 0)} className="form-control" style={{ display: profileImageState1[0] !== "" ? "none" : "" }} />
                                      {profileImageState1[0] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(0)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                                {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach1?.message}</span></p> */}
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach2" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {(docExtension1[1] === 'image/jpeg' || docExtension1[1] === 'image/png') && profileImageState1[1] && (
                                          <img onClick={() => {
                                            document.getElementById("attach2")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[1] !== '' ? profileImageState1[1] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[1] !== '' ? "100%" : "",
                                              height: profileImageState1[1] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach2' name="attach2" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 1)} className="form-control" style={{ display: profileImageState1[1] !== "" ? "none" : "" }} />
                                      {profileImageState1[1] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(1)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach3" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension1[2] === 'image/jpeg' || docExtension1[2] === 'image/png') && profileImageState1[2] && (
                                          <img onClick={() => {
                                            document.getElementById("attach3")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[2] !== '' ? profileImageState1[2] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[2] !== '' ? "100%" : "",
                                              height: profileImageState1[2] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach3' name="attach3" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 2)} className="form-control" style={{ display: profileImageState1[2] !== "" ? "none" : "" }} />
                                      {profileImageState1[2] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(2)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach4" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension1[3] === 'image/jpeg' || docExtension1[3] === 'image/png') && profileImageState1[3] && (
                                          <img onClick={() => {
                                            document.getElementById("attach4")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[3] !== '' ? profileImageState1[3] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[3] !== '' ? "100%" : "",
                                              height: profileImageState1[3] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach4' name="attach4" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 3)} className="form-control" style={{ display: profileImageState1[3] !== "" ? "none" : "" }} />
                                      {profileImageState1[3] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(3)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach5" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension1[4] === 'image/jpeg' || docExtension1[4] === 'image/png') && profileImageState1[4] && (
                                          <img onClick={() => {
                                            document.getElementById("attach5")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[4] !== '' ? profileImageState1[4] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[4] !== '' ? "100%" : "",
                                              height: profileImageState1[4] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach5' name="attach5" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 4)} className="form-control" style={{ display: profileImageState1[4] !== "" ? "none" : "" }} />
                                      {profileImageState1[4] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(4)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach6" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension1[5] === 'image/jpeg' || docExtension1[5] === 'image/png') && profileImageState1[5] && (
                                          <img onClick={() => {
                                            document.getElementById("attach6")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1[5] !== '' ? profileImageState1[5] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1[5] !== '' ? "100%" : "",
                                              height: profileImageState1[5] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach6' name="attach6" accept="image/*" onInput={(e) => handleImage1Change(e.target.files[0], 5)} className="form-control" style={{ display: profileImageState1[5] !== "" ? "none" : "" }} />
                                      {profileImageState1[5] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove(5)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2"> Please upload 1 minimum up to 3 maximum pictures to show the outside space/ outside entrance of where the classes will be held. </label>
                          <div className="row">
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach7" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension2[0] === 'image/jpeg' || docExtension2[0] === 'image/png') && profileImageState2[0] && (
                                          <img onClick={() => {
                                            document.getElementById("attach7")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState2[0] !== '' ? profileImageState2[0] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState2[0] !== '' ? "100%" : "",
                                              height: profileImageState2[0] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach7' name="attach7" accept="image/*" onInput={(e) => handleImage2Change(e.target.files[0], 0)} className="form-control" style={{ display: profileImageState2[0] !== "" ? "none" : "" }} />
                                      {profileImageState2[0] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove1(0)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach8" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">


                                        {(docExtension2[1] === 'image/jpeg' || docExtension2[1] === 'image/png') && profileImageState2[1] && (
                                          <img onClick={() => {
                                            document.getElementById("attach8")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState2[1] !== '' ? profileImageState2[1] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState2[1] !== '' ? "100%" : "",
                                              height: profileImageState2[1] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach8' name="attach8" accept="image/*" onInput={(e) => handleImage2Change(e.target.files[0], 1)} className="form-control" style={{ display: profileImageState2[1] !== "" ? "none" : "" }} />
                                      {profileImageState2[1] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove1(1)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                            <div className="col-lg-4 mb-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach9" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {(docExtension2[2] === 'image/jpeg' || docExtension2[2] === 'image/png') && profileImageState2[2] && (
                                          <img onClick={() => {
                                            document.getElementById("attach9")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState2[2] !== '' ? profileImageState2[2] : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState2[2] !== '' ? "100%" : "",
                                              height: profileImageState2[2] !== '' ? "166px" : "",
                                            }}
                                          />
                                        )}

                                      </div>
                                      <input type="file" id='attach9' name="attach9" accept="image/*" onInput={(e) => handleImage2Change(e.target.files[0], 2)} className="form-control" style={{ display: profileImageState2[2] !== "" ? "none" : "" }} />
                                      {profileImageState2[2] !== "" ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => handleDocumentRemove1(2)}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3 className="mb-3">Disclosure Acknowledgement</h3>
                    <div className="form-group d-flex gap-4 align-items-center">
                      <input type="checkbox" id="checkbox1" {...register('checkbox1')} name="checkbox1" />
                      <label htmlFor="checkbox1" className="d-block">
                        I, as the legally appointed representative of the organization confirm our organization's location at the above address is legally established and compliant by the city & country laws to offer lessons
                      </label>
                    </div>
                    <p className='font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox1?.message}</span></p>

                    <div className="form-group d-flex gap-4 align-items-center mt-3">
                      <input type="checkbox" id="checkbox2" {...register('checkbox2')} name="checkbox2" />
                      <label htmlFor="checkbox2" className="d-block">
                        I confirm that our organization has valid liability insurance for students/learners, teachers, parents, guardians and visitors at the location.
                      </label>
                    </div>
                    <p className='font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox2?.message}</span></p>

                    <div className="form-group d-flex gap-4 align-items-center mt-3">
                      <input type="checkbox" id="checkbox3" {...register('checkbox3')} name="checkbox3" />
                      <label htmlFor="checkbox3" className="d-block">
                        I confirm that I understand our organization is only authorized to operate with valid insurance. I am aware that it is our organization's responsibility to renew the insurance on time  and we will accept any liability and waive absolutely no claim on StudentScholars from any liability that occurs at our organization location.
                      </label>
                    </div>
                    <p className='font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox3?.message}</span></p>

                    <div className="button mt-4">
                      <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
