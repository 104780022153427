import React, { useEffect, useState } from "react";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import PFooter_Dashboard from "../Pinc/PFooter_Dashboard";
import PHeader_Dashboard from "../Pinc/PHeader_Dashboard";
import BackToPrevious from "../../../components/backToPrevious";
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import { credits_listing, enrollment_payments_details, get_user_credits_earnings, teacher_subscribed_and_payments_made } from "../../../api/apiHandler";
import Select from "react-select";
import { encryptURL } from "../../../api/apiClient";
import { useLocation, useNavigate } from "react-router-dom";


export default function PEwalletDetails({ isLoader }) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
const location = useLocation()
console.log('location :', location.state);

  const [data, setData] = useState();



  const PaymentMade = async () => {
   

    isLoader(true)

    try {
      var response;      
        response = await enrollment_payments_details({ enrolled_id:location?.state?.enrolled_id, learner_id:location?.state?.learner_id });
     
      console.log('response123 :', response);
      if (response?.code == 1) {
        const dataWithRowCount = response?.data.map((row, index) => ({ ...row, count: index + 1 }));

        setData(dataWithRowCount)
        isLoader(false)

      } else {
        setData([])
        isLoader(false)
      }
    } catch (error) {
      console.log(error)
      isLoader(false)

    }
  }
  


  useEffect(() => {
    PaymentMade();

  }, [])


  const columns = [
    {
      name: 'Sr.No',
      selector: 'count',

      sortable: true,

    },
    {
      name: 'Details',
      selector: row => (
        <button className="bg-success border-0 text-light px-2 fw-500" style={{ borderRadius: "5px" }}>
          Details
        </button>
      ),

      sortable: true,

    },
    {
      name: 'Payment Date',
      selector: row => {
        const date = new Date(row.enrolled_at);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const year = date.getFullYear();
        return `${formattedDay}-${formattedMonth}-${year}`;
      },
      sortable: true,
      // width: "150px"
    },
    {
      name: 'Transaction Id',
      selector: row => row.transaction_id,
      sortable: true,
      cellExport: row => row.transaction_id,
      width: "250px"
    },
    {
      name: 'Class Name',
      selector: row => row.activity_title,
      sortable: true,
      cellExport: row => row.activity_title,
      width: "200px"

    },
    {
      name: 'Teacher Name',
      selector: row => row.teacher_name,
      sortable: true,
      cellExport: row => row.teacher_name,
      width: "160px"

    },
    {
      name: 'Platform',
      selector: row => row.platform_type,
      sortable: true,
      cellExport: row => row.platform_type,
    },
    {
      name: 'Class Duration',
      selector: row => row.duration,
      sortable: true,
      cellExport: row => row.duration,
      width: "160px"

    },

    {
      name: 'Coupon Number',
      selector: row => row.coupan_number ? row.coupan_number : '-',
      sortable: true,
      cellExport: row => row.coupan_number ? row.coupan_number : '-',
      width: "170px"

    },

    {
      name: 'Payment Status',
      selector: row => (
        <button className="bg-success border-0 text-light px-2 fw-500" style={{ borderRadius: "5px" }}>
          {row.payment_status}
        </button>
      ),
      sortable: true,
      cellExport: row => row.payment_status,
      width: "170px"

    },
    {
      name: 'Amount',
      selector: row => `${row.currency_symbol} ${row.amount}`,
      sortable: true,
      cellExport: row => row.amount,
    },






  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
  };


  return (
    <>
      <JqueryLoad />
      <PHeader_Dashboard />
      <Helmet>
        <style>{`
  
  .card-container {
  border: 1px solid #e1dfdf;
  box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 5px;
  border-radius: 10px;
  background-color: #570861;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-content {
  transition: color 0.3s ease;
}

.card-container:hover .card-content {
  color: #ffffffcc; / Lighter shade of white /
}

  `}</style>
      </Helmet>
      <main>

        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>

          <div className="class-content">
            <div className>
              {/*start*/}
              <BackToPrevious />
              {/*end*/}
              {/*start*/}
              <div className>
                <div className=" ">
                  {/*start*/}
                  <div className="mb-4 d-md-flex align-items-center justify-content-between">
                    <h2 className="mb-md-0 mb-4 poppins fw-600">E Wallet Details</h2>


                    {/* <button onClick={() => navigate("/teacher-balance")} className="btn-theme bg-yellow m-2">E Wallet Balance</button> */}

                    <div className="fliter_list">
                      <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">

                      </ul>
                    </div>
                  </div>

              
                 
                  <div className="row">
                 
                      <DataTableExtensions
                        columns={columns}
                        data={data}
                        print={false}
                        export={false}
                        filter={false}
                      >
                        <DataTable
                          columns={columns}
                          data={data}
                          responsive
                          pagination
                          customStyles={customStyles}
                   
                        />
                      </DataTableExtensions>
              
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <PFooter_Dashboard /> */}
    </>
  );
}


