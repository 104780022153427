import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import JqueryLoad from "../../../JqueryLoad/JqueryLoad";
import BackToPrevious from "../../../components/backToPrevious";
import CommonChatBox from "./commonChatBox";
import { TOAST_ERROR } from "../../../utils/common.service";
import { chat_list, chat_list_t, org_teacher_permission_listing } from "../../../api/apiHandler";
import useDebounce from "../../../utils/useDebounce";
import THeader_Dashboard from "../Include/THeader_Dashboard";
import TFooter_Dashboard from "../Include/TFooter_Dashboard";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const CommonChatListData = ({ room, typingusers, getTeacherData, userData }) => {
  console.log('userData34356 :', userData);
  console.log('room :', room);
  const timestampToDate = dayjs.unix(room.insert_datetime);
  const time = timestampToDate.format("h:mm A");
  // setUserData({ full_name: userData?.learner_name, profile_image: userData?.learner_profile_image, id: userData?.learner_id, status : userData?.status })
  return (

    <div onClick={() => getTeacherData(room)} className="d-flex align-items-center my-3 pointer">
      <div className="flex-shrink-0 position-relative">
        <img className="img-fluid chat_user rounded-circle" src={room?.profile_image != null ? room?.profile_image : room?.learner_profile_image} alt="user img" />
        {room?.status === "online" && <span className="active" />}
      </div>
      <div className="flex-grow-1 ms-2">
        <div className="d-flex justify-content-between">
          <h3 className="poppins">{room?.full_name != null ? room?.full_name : room?.learner_name}</h3>
          <span className="chat_times">{time}</span>
        </div>
        <div className="d-flex justify-content-between">
          {/* <p>
            {room?.message &&
              room.message
                .split(' ')
                .slice(0, 3) // Take only the first two words
                .join(' ')
            }
            {room.message.split(' ').length > 3 && ' ...'}
          </p> */}
          <p>
            {room?.message && room.message.length > 15
              ? `${room.message.slice(0, 15)} ...`
              : room.message
            }
          </p>

          {typingusers.includes(room.chat_id) && room.is_block != 1 ?
            <p className="text-yellow">typing... </p> : <></>}
          {room?.unread_count && room?.chat_id != userData?.chat_id ? (
            <div className="msg_of_num">
              <span>{room?.unread_count}</span>
            </div>
          ) : (
            <span className="mesg_checked">
              <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                  fill="#570861"
                />
                <path
                  d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                  fill="#570861"
                />
              </svg>
            </span>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between"></div>
    </div>
  );
};

export default function TMessage({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [chatList, setChatList] = useState(null);
  // console.log('chatList :', chatList);
  const [userData, setUserData] = useState(null);
  // console.log('userDatatwo :', userData);
  const [pinnedChatList, setPinnedChatList] = useState(null);
  const [typingusers, setTypingUsers] = useState([]);

  // console.log('pinnedChatList :', pinnedChatList);
  const [search, setSearch] = useState("");
  const debounce = useDebounce(search, 1000);
  const [page, setPage] = useState(1);
  const USER_TYPE = localStorage.getItem("userType");
  const navigate = useNavigate();

  useEffect(() => {
    getChatList();
  }, [page, debounce, typingusers, userData?.id, userData?.chat_id]);

  const getChatList = async () => {
    try {
      const body = {
        page,
        user_type1: "user",
        is_pin: "0",
        search: debounce,
      };
      // console.log('chat_listdata111111 :', body);
      isLoader(true)
      const { data, code, message } = await chat_list_t(body);
      console.log('chat_listdata111 :', data);
      // if (!userData) getTeacherData(data?.result?.[0]);
      if (code == 1) {
        setChatList(data);
        isLoader(false)
      }
      else {
        setChatList(null)
        isLoader(false)
      };
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  useEffect(() => {
    getPinnedChatList();
  }, []);

  const getPinnedChatList = async () => {
    try {
      const body = {
        page,
        user_type1: "user",
        is_pin: "1",
      };
      isLoader(true)
      const { data, code, message } = await chat_list_t(body);
      if (code == 1) {
        setPinnedChatList(data);
        isLoader(false)
      }
      else {
        setPinnedChatList(null);
        isLoader(false)
      }
    } catch (err) {
      // TOAST_ERROR(err.message);
    }
  };

  const getTeacherData = (userData) => {
    console.log('getTeacherData :', userData);
    if (userData.user_id != null) {
      setUserData({ full_name: userData?.full_name, profile_image: userData?.profile_image, id: userData?.user_id, status: userData?.status, user_type: 'user', activity_type: userData?.activity_type, activity_id: userData?.activity_id, is_block: userData?.is_block, chat_id: userData?.chat_id, activity_title: userData?.activity_title, category: userData?.category,slot_id:userData?.slot_id,firstMeetingDate:userData?.firstMeetingDate,
      firstMeetingStartTime:userData?.firstMeetingStartTime,firstMeetingEndTime:userData?.firstMeetingEndTime, time_zone : userData?.time_zone })
    } else {
      setUserData({ full_name: userData?.learner_name, profile_image: userData?.learner_profile_img, id: userData?.learner_id, status: userData?.status, user_type: 'learner', activity_type: userData?.activity_type, activity_id: userData?.activity_id, is_block: userData?.is_block, chat_id: userData?.chat_id, activity_title: userData?.activity_title,slot_id:userData?.slot_id,firstMeetingDate:userData?.firstMeetingDate,
      firstMeetingStartTime:userData?.firstMeetingStartTime,firstMeetingEndTime:userData?.firstMeetingEndTime,time_zone : userData?.time_zone })
    }
  };


  const handleChatList = () => {
    getChatList();
    getPinnedChatList();
  };

  const showChatList = useMemo(() => {
    dayjs.extend(utc);
    if (!chatList || chatList?.result?.length === 0) return <p>No chat found</p>;
    return chatList?.result?.map((room) => {
      return <CommonChatListData userData={userData} typingusers={typingusers} key={room.chat_id} room={room} getTeacherData={getTeacherData} />;
    });
  }, [chatList?.result, userData]);

  const showPinnedChatList = useMemo(() => {
    dayjs.extend(utc);
    if (!pinnedChatList || pinnedChatList?.result?.length === 0) return <p>No chat found</p>;
    return pinnedChatList?.result?.map((room) => {
      return <CommonChatListData userData={userData} typingusers={typingusers} key={room.chat_id} room={room} getTeacherData={getTeacherData} />;
    });
  }, [pinnedChatList?.result, userData]);

  return (
    <>
      <JqueryLoad />
      <THeader_Dashboard />
      <Helmet>
        <style>{`
        jQuery(document).ready(function() {

          $(".chat-list a").click(function() {
              $(".chatbox").addClass('showbox');
              return false;
          });
      
          // $(".chat-icon").click(function() {
          //     $(".chatbox").removeClass('showbox');
          // });
      
      
      });
        `}</style>
      </Helmet>
      <main className="dashboard-app d-lg-flex">
        <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
          <div className="align-items-center justify-content-between d-flex">
            <div className="d-flex align-items-center">
              <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                <i className="fa fa-bars" />
              </a>
              <a href="#" className="d-lg-none d-block brand-logo">
                <img src="./assets/images/logo.png" alt="logo" />
              </a>
            </div>
          </div>
        </header>
        <div className="class-content">
          <BackToPrevious />
          <section className="message-area">
            <div className=" ">
              <h2 className="mb-5 poppins fw-600 text-capitalize">Messages</h2>
              <div className="row">
                <div className="col-12">
                  <div className="chat-area">
                    {/* chatlist */}
                    <div className="chatlist">
                      <div>
                        <div className="modal-content">
                          <div className="chat-header">
                            <div className="msg-search rounded-pill position-relative d-flex">
                              <span className="me-2">
                                <svg width={14} height={15} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    d="M11.375 6.1875C11.375 7.44258 10.9676 8.60195 10.2812 9.54258L13.743 13.007C14.0848 13.3488 14.0848 13.9039 13.743 14.2457C13.4012 14.5875 12.8461 14.5875 12.5043 14.2457L9.04258 10.7812C8.10195 11.4703 6.94258 11.875 5.6875 11.875C2.5457 11.875 0 9.3293 0 6.1875C0 3.0457 2.5457 0.5 5.6875 0.5C8.8293 0.5 11.375 3.0457 11.375 6.1875ZM5.6875 10.125C7.86133 10.125 9.625 8.36133 9.625 6.1875C9.625 4.01367 7.86133 2.25 5.6875 2.25C3.51367 2.25 1.75 4.01367 1.75 6.1875C1.75 8.36133 3.51367 10.125 5.6875 10.125Z"
                                    fill="#868484"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                                className="form-control border-0 p-0"
                                id="inlineFormInputGroup"
                                placeholder="Search chat..."
                                aria-label="search"
                              />
                            </div>
                          </div>
                          <div className="scrollbar" id="style-3">
                            {/* chat-list */}
                            <div className="chat-lists">
                              <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                                  {/* chat-list */}
                                  <div className="chat-list">
                                    {pinnedChatList?.result?.length > 0 && (
                                      <>
                                        <div className="d-flex mb-4">
                                          <span className="me-2">
                                            <svg width={10} height={16} viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M8 7L9.5 8.5V10H5.75V14.5L5 15.25L4.25 14.5V10H0.5V8.5L2 7V1.75H1.25V0.25H8.75V1.75H8V7Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                          <span className="text-purple">PINNED</span>
                                        </div>
                                        {showPinnedChatList}
                                      </>
                                    )}
                                    {/* <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                        <span className="active" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Mehedi Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p className="text-yellow">Typing....</p>
                                          <div className="msg_of_num">
                                            <span>2</span>
                                          </div>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user1.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Ryhan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>Hello! Everyone</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Malek Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>Hello! Everyone</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a> */}
                                    <div className="d-flex mt-4 mb-4">
                                      <span className="me-2">
                                        <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M8.99987 14.75C13.971 14.75 17.9999 11.477 17.9999 7.4375C17.9999 3.39805 13.971 0.125 8.99987 0.125C4.02878 0.125 -0.000126625 3.39805 -0.000126625 7.4375C-0.000126625 9.02305 0.622139 10.4891 1.67683 11.6879C1.61003 12.5492 1.27605 13.3156 0.924483 13.8992C0.731123 14.2227 0.534248 14.4828 0.390108 14.6586C0.31628 14.7465 0.26003 14.8133 0.217842 14.859C0.196748 14.8801 0.182686 14.8977 0.172139 14.9082L0.161592 14.9187C-0.000126624 15.0805 -0.0458297 15.3195 0.0420609 15.5305C0.129952 15.7414 0.333858 15.8785 0.562373 15.8785C1.57136 15.8785 2.58737 15.5656 3.43112 15.2C4.2362 14.8484 4.92175 14.4301 5.34011 14.1242C6.45808 14.5285 7.69558 14.7535 8.99987 14.7535V14.75ZM4.49987 8.5625C3.87761 8.5625 3.37487 8.05977 3.37487 7.4375C3.37487 6.81523 3.87761 6.3125 4.49987 6.3125C5.12214 6.3125 5.62487 6.81523 5.62487 7.4375C5.62487 8.05977 5.12214 8.5625 4.49987 8.5625ZM8.99987 8.5625C8.37761 8.5625 7.87487 8.05977 7.87487 7.4375C7.87487 6.81523 8.37761 6.3125 8.99987 6.3125C9.62214 6.3125 10.1249 6.81523 10.1249 7.4375C10.1249 8.05977 9.62214 8.5625 8.99987 8.5625ZM14.6249 7.4375C14.6249 8.05977 14.1221 8.5625 13.4999 8.5625C12.8776 8.5625 12.3749 8.05977 12.3749 7.4375C12.3749 6.81523 12.8776 6.3125 13.4999 6.3125C14.1221 6.3125 14.6249 6.81523 14.6249 7.4375Z"
                                            fill="#570861"
                                          />
                                        </svg>
                                      </span>
                                      <span className="text-purple">All Message</span>
                                    </div>
                                    {showChatList}
                                    {/* <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user3.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Sadik Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user4.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Bulu </h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>Talk back with you</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user5.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Maria SK</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user6.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Dipa Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Jhon Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Tumpa Moni</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user3.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Payel Akter</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user4.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Baby Akter</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user5.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Zuwel Rana</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user6.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Habib </h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Jalal Ahmed</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user1.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Hasan Ali</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Mehedi Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a> */}
                                  </div>
                                </div>
                                {/* <div className="tab-pane fade" id="Closed" role="tabpanel" aria-labelledby="Closed-tab">
                                  <div className="chat-list">
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user3.png" alt="user img" />
                                        <span className="active" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Mehedi Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user4.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Ryhan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user5.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Malek Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user6.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Sadik Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Bulu </h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user1.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Maria SK</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user2.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Dipa Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user3.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Jhon Hasan</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user4.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Tumpa Moni</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user5.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Payel Akter</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user6.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Baby Akter</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Zuwel Rana</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Habib </h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Jalal Ahmed</h3>
                                          <span className="chat_times">4:30 PM</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Hasan Ali</h3>
                                          <span className="chat_times">Yesterday</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                    <a href="#" className="d-flex align-items-center">
                                      <div className="flex-shrink-0">
                                        <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img" />
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <div className="d-flex justify-content-between">
                                          <h3 className="poppins">Mehedi Hasan</h3>
                                          <span className="chat_times">Yesterday</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p>front end developer</p>
                                          <span className="mesg_checked">
                                            <svg width={16} height={8} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path
                                                d="M9.77093 0.231252C9.70478 0.157976 9.62608 0.0998154 9.53938 0.0601249C9.45267 0.0204345 9.35966 0 9.26573 0C9.1718 0 9.07879 0.0204345 8.99208 0.0601249C8.90538 0.0998154 8.82668 0.157976 8.76053 0.231252L3.45949 6.06341L1.23235 3.60859C1.16367 3.5357 1.08259 3.47838 0.993752 3.43991C0.904911 3.40145 0.810045 3.38258 0.714569 3.3844C0.619093 3.38621 0.524877 3.40867 0.437301 3.45049C0.349725 3.49231 0.270503 3.55267 0.204159 3.62813C0.137815 3.70359 0.085649 3.79267 0.050638 3.89028C0.015627 3.98789 -0.00154303 4.09212 0.000108809 4.19703C0.00176064 4.30193 0.0222019 4.40544 0.0602651 4.50166C0.0983283 4.59789 0.153268 4.68493 0.221948 4.75782L2.95429 7.7599C3.02044 7.83317 3.09914 7.89134 3.18585 7.93103C3.27256 7.97072 3.36556 7.99115 3.45949 7.99115C3.55343 7.99115 3.64643 7.97072 3.73314 7.93103C3.81985 7.89134 3.89855 7.83317 3.96469 7.7599L9.77093 1.38049C9.84315 1.30728 9.9008 1.21842 9.94022 1.11953C9.97965 1.02063 10 0.913836 10 0.805869C10 0.697902 9.97965 0.591105 9.94022 0.492209C9.9008 0.393313 9.84315 0.304461 9.77093 0.231252Z"
                                                fill="#570861"
                                              />
                                              <path
                                                d="M15.7709 0.231252C15.7048 0.157976 15.6261 0.0998154 15.5394 0.0601249C15.4527 0.0204345 15.3597 0 15.2657 0C15.1718 0 15.0788 0.0204345 14.9921 0.0601249C14.9054 0.0998154 14.8267 0.157976 14.7605 0.231252L9.45949 6.06341L7.23235 3.60859C7.16367 3.5357 7.08259 3.47838 6.99375 3.43991C6.90491 3.40145 6.81004 3.38258 6.71457 3.3844C6.61909 3.38621 6.52488 3.40867 6.4373 3.45049C6.34972 3.49231 6.2705 3.55267 6.20416 3.62813C6.13782 3.70359 6.08565 3.79267 6.05064 3.89028C6.01563 3.98789 5.99846 4.09212 6.00011 4.19703C6.00176 4.30193 6.0222 4.40544 6.06027 4.50166C6.09833 4.59789 6.15327 4.68493 6.22195 4.75782L8.95429 7.7599C9.02044 7.83317 9.09914 7.89134 9.18585 7.93103C9.27256 7.97072 9.36556 7.99115 9.45949 7.99115C9.55343 7.99115 9.64643 7.97072 9.73314 7.93103C9.81985 7.89134 9.89855 7.83317 9.96469 7.7599L15.7709 1.38049C15.8432 1.30728 15.9008 1.21842 15.9402 1.11953C15.9796 1.02063 16 0.913836 16 0.805869C16 0.697902 15.9796 0.591105 15.9402 0.492209C15.9008 0.393313 15.8432 0.304461 15.7709 0.231252Z"
                                                fill="#570861"
                                              />
                                            </svg>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {userData ? (
                      <div className="chatbox">
                        <CommonChatBox userData={userData} typingusers={typingusers} setTypingUsers={setTypingUsers} handleChatList={handleChatList} getChatList={getChatList} />
                        {/* <div className="modal-dialog-scrollable">
                        <div className="modal-content">
                          <div className="msg-head">
                            <div className="row">
                              <div className="col-8">
                                <div className="d-flex align-items-center">
                                  <span className="chat-icon">
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path
                                        d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
                                        fill="#131313"
                                      />
                                    </svg>
                                  </span>
                                  <div className="flex-shrink-0 position-relative">
                                    <img className="img-fluid chat_user" src="./assets/images/user/user.png" alt="user img " />
                                    <span className="active" />
                                  </div>
                                  <div className="flex-grow-1 ms-3">
                                    <h3 className="poppins">Mehedi Hasan</h3>
                                    <p>Active Now</p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <ul className="moreoption">
                                  <li className="navbar nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i className="fa fa-ellipsis-v" aria-hidden="true" />
                                    </a>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Action
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Another action
                                        </a>
                                      </li>
                                      <li>
                                        <hr className="dropdown-divider" />
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          Something else here
                                        </a>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="modal-body">
                            <div className="msg-body">
                              <ul>
                                <li className="sender">
                                  <div className="d-flex align-items-start">
                                    <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                    <div>
                                      <p className="text-dif-black">
                                        {" "}
                                        Hi, I hope you are doing well, yesterday you have gave a pen This very nice, i am very happy for
                                        this.yesterday you have gave a pen This very nice{" "}
                                      </p>
                                      <span className="time text-end">10:06 am</span>
                                    </div>
                                  </div>
                                </li>
                                <li className="sender">
                                  <div className="d-flex align-items-start">
                                    <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                    <div>
                                      <p className="text-dif-black"> Hey, Are you there? </p>
                                      <span className="time text-end">10:16 am</span>
                                    </div>
                                  </div>
                                </li>
                                <li className="repaly">
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      <p className="text-dif-black">
                                        yea I’m well, Thank you, i am very happy for this.yesterday you have gave a pen This very nice
                                      </p>
                                      <span className="time text-start">10:20 am</span>
                                    </div>
                                    <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                  </div>
                                </li>
                                <li className="sender">
                                  <div className="d-flex align-items-start">
                                    <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                    <div>
                                      <p className="text-dif-black"> Hey, Are you there? </p>
                                      <span className="time text-end">10:26 am</span>
                                    </div>
                                  </div>
                                </li>
                                <li className="sender">
                                  <div className="d-flex align-items-start">
                                    <img className="img-fluid chat_user me-2" src="./assets/images/user/user.png" alt="user img" />
                                    <div>
                                      <p className="text-dif-black"> Hey, Are you there? </p>
                                      <span className="time text-end">10:32 am</span>
                                    </div>
                                  </div>
                                </li>
                                <li className="repaly">
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      <p className="text-dif-black">How are you?</p>
                                      <span className="time text-start">10:35 am</span>
                                    </div>
                                    <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                  </div>
                                </li>
                                <li>
                                  <div className="divider">
                                    <h6>Today</h6>
                                  </div>
                                </li>
                                <li className="repaly">
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      <p className="text-dif-black"> yes, tell me</p>
                                      <span className="time text-start">10:36 am</span>
                                    </div>
                                    <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                  </div>
                                </li>
                                <li className="repaly">
                                  <div className="d-flex justify-content-end">
                                    <div>
                                      <p className="text-dif-black">yes... on it</p>
                                      <span className="time text-start">just now</span>
                                    </div>
                                    <img className="img-fluid chat_user ms-2" src="./assets/images/user/user.png" alt="user img" />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="send-box">
                            <form action className="position-relative">
                              <input
                                type="text"
                                onChange={handleMessage}
                                className="form-control rounded-pill"
                                aria-label="message…"
                                placeholder="Enter text"
                              />
                              <button onClick={sendMessage} className="send_icons btn">
                                <i className="fa fa-paper-plane" aria-hidden="true" />
                              </button>
                              <div className="send-btns">
                                <div className="attach">
                                  <div className="button-wrapper position-relative">
                                    <span className="label">
                                      <svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M12.9297 2.94612C12.0719 2.08831 10.6797 2.08831 9.82187 2.94612L3.35313 9.41487C1.87305 10.8949 1.87305 13.2926 3.35313 14.7727C4.8332 16.2528 7.23086 16.2528 8.71094 14.7727L14.0547 9.42893C14.4379 9.04573 15.0637 9.04573 15.4469 9.42893C15.8301 9.81213 15.8301 10.4379 15.4469 10.8211L10.1031 16.1649C7.85312 18.4149 4.21094 18.4149 1.96094 16.1649C-0.289062 13.9149 -0.289062 10.2727 1.96094 8.02268L8.42969 1.55393C10.0574 -0.0738037 12.6941 -0.0738037 14.3219 1.55393C15.9496 3.18166 15.9496 5.81838 14.3219 7.44612L8.13437 13.6336C7.12891 14.6391 5.49766 14.6391 4.49219 13.6336C3.48672 12.6281 3.48672 10.9969 4.49219 9.99143L9.55469 4.92893C9.93789 4.54573 10.5637 4.54573 10.9469 4.92893C11.3301 5.31213 11.3301 5.93791 10.9469 6.32112L5.88438 11.3836C5.64883 11.6192 5.64883 12.0059 5.88438 12.2414C6.11992 12.477 6.50664 12.477 6.74219 12.2414L12.9297 6.05393C13.7875 5.19612 13.7875 3.80393 12.9297 2.94612Z"
                                          fill="#131313"
                                        />
                                      </svg>
                                    </span>
                                    <input
                                      type="file"
                                      onChange={handleMessage}
                                      name="upload"
                                      id="upload"
                                      className="upload-box"
                                      placeholder="Upload File"
                                      aria-label="Upload File"
                                    />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    ) : (
                      <div className="chatbox" style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        fontSize: '22px',
                        color: '#570861'
                      }}>Data Not Found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
      <TFooter_Dashboard />
    </>
  );
}
