export function loginRedirectCall() {
  let path = window.location.protocol + "//" + window.location.host + "/";
  window.location.href = path;
}

export function homeRedirectCall() {
  const userType = localStorage.getItem("type");

  if (userType === "teacher" || userType === "org_teacher") {
    console.log('teacher123');
    const path = window.location.protocol + "//" + window.location.host + "/teacher_dashboard";
    setTimeout(() => {
      window.location.href = path;
    }, 0);
    console.log(path,'path');
  }
  else if (userType === "organization") {
    const path = window.location.protocol + "//" + window.location.host + "/organization_dashboard";
    setTimeout(() => {
      window.location.href = path;
    }, 0);
  } else {
    const userCategory = localStorage.getItem("userType");
    if (
      userCategory === "below_18" ||
      userCategory === "above_18" ||
      userCategory === "after_school" ||
      userCategory === "learner"
    ) {
      const path = window.location.protocol + "//" + window.location.host + "/dashboard";  
      window.location.href = path;
    } else {  
      const path = window.location.protocol + "//" + window.location.host + "/";
      window.location.href = path;
    }
  }
}


export function logOutRedirectCall() {
  localStorage.clear();
  loginRedirectCall()
}
export function loginRedirectCallWithDataStore(data) {
  console.log('data :', data);

  if (data?.category == "teacher" || data?.category == "org_teacher" || data?.category == "organization") {
    console.log(data?.category);
    localStorage.setItem("TisLogin", true);
    localStorage.setItem("Tid", data.id);
    localStorage.setItem("Ttoken", data.token);
    localStorage.setItem("Tname", data.full_name);
    localStorage.setItem("Temail", data.email);
    homeRedirectCall()
  } else {
    localStorage.setItem("MAisLogin", true);
    localStorage.setItem("PAid", data.id);
    localStorage.setItem("PAtoken", data.token);
    localStorage.setItem("MAname", data.category == "below_18" || data.category == "above_18" || data.category == "after_school" ? data.full_name : data.learner_name);
    localStorage.setItem("MAemail", data.email);
    localStorage.setItem("userType", data.category);
    homeRedirectCall()
  }
}