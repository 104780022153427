import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../utils/common.service';
import { uploadImageOnAWS } from '../../aws/Service';
import { country_list, org_application, state_province_list, teacherViewProfile } from '../../../api/apiHandler';

export default function Oapplication({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  const [orgMode, setOrgMode] = useState('Online')
  var [CountryOrgID, setCountryOrgID] = useState([]);
  const [companyGST, setCompanyGST] = useState('');
  console.log('companyGST :', companyGST);

  var schema = yup.object().shape({
    legal_name: yup.string().required("Please enter full legal name")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'Full name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    full_name: yup.string().required("Please enter your organization name")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Organization name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(2, "Organization name must contain at least 2 or 3 characters long")
      .max(50, "Organization name must not exceed 50 characters"),
    street_number: yup
      .string()
      .required("Please enter street number")
      .test(
        'no-only-whitespace',
        'Street number cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .max(50, "Street number can't exceed 50 characters"),
    street_name: yup
      .string()
      .required("Please enter street name")
      .test(
        'no-only-whitespace',
        'Street name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .min(2, "Street name must have at least 2 or 3 characters")
      .max(200, "Street name can't exceed 200 characters"),
    country: yup.string().required("Please select country"),
    country_org: yup.string().required("Please select your organization registered country"),
    state_province: yup.string().required("Please select state/province"),
    city: yup
      .string()
      .required("Please enter city")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s'!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'City cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(2, "City must have at least 2 characters")
      .max(200, "City can't exceed 200 characters"),

    zip_code: yup
      .string()
      .required("Please enter zip code")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z0-9\s\-]+$/, "Special characters are not allowed")
      .test(
        'no-only-whitespace',
        'Zip code cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .matches(
        /^(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d|\d{4}|\d{6})$/,
        "Please enter valid zip code"
      ),

    business_number: yup
      .string()
      .required("Please enter Registration / Business number")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Business number cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),

    primary_category: yup.string().required("Please enter primary category of your business")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Primary category cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      )
      .min(3, "Primary category must contain at least 3 or 4 characters long")
      .max(50, "Primary category must not exceed 50 characters"),
    teacher_count_field: yup
      .number()
      .required("Please enter teacher count")
      .typeError("Teacher count must be a number")
      .min(1, "Teacher count cannot be 0 or less") // Ensures the count is at least 1
      .max(100, "Teacher count cannot exceed 100") // Ensures the count does not exceed 100
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value.toString()))
      .test(
        'no-only-whitespace',
        'Teacher count cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    org_founded: yup
      .date()
      .typeError("Please select date") // Custom error for invalid date type
      .required("Please select a date") // Custom message for required field
      .max(new Date(), "Date cannot be in the future"),

    email: yup.string().required("Please enter organization email").matches(/^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/, "Please enter valid email"),
    // phone: yup
    //   .string()
    //   .required("Please enter organization phone number"),
    // .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
    // .matches(
    //   /^\+?(1|44|61|64|65)( ?\d{1,4}){2,3}( ?\d{3,4})$/,
    //   "Phone number must be in the format +[Country Code] [Area Code] [Number]. E.g., +1 123 456 7890 (US/Canada), +61 2 1234 5678 (Australia), +44 20 1234 5678 (UK), +65 1234 5678 (Singapore), +64 9 1234567 (New Zealand)"
    // ),
    website: yup
      .string()
      .required("Please enter your company URL")
      .test(
        'no-only-whitespace',
        'Company URL cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      )
      .matches(
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([\/\w .-]*)*\/?$/,
        "Please enter a valid URL (e.g., http://www.example.com)"
      )
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value)),

    attach1: yup
      .mixed()
      .required("Please upload your organization registration document"),
    // checkbox: yup.boolean()
    //   .oneOf([true], 'Please read & confirm this guidlines')
    //   .required('Please confirm this'),
    org_primary_contact: yup.string().required("Please enter primary contact for your organization")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Primary contact cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    primary_contact_title: yup.string().required("Please enter primary contact's title")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Primary contact title cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    org_phone_number: yup.string().required("Please enter primary contact's phone number"),
    // .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
    // .matches(
    //   /^\+?(1|44|61|64|65)( ?\d{1,4}){2,3}( ?\d{3,4})$/,
    //   "Phone number must be in the format +[Country Code] [Area Code] [Number]. E.g., +1 123 456 7890 (US/Canada), +61 2 1234 5678 (Australia), +44 20 1234 5678 (UK), +65 1234 5678 (Singapore), +64 9 1234567 (New Zealand)"
    // ),
    child_adults: yup.string().required("Please enter your requirement's information")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Requirements information cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    size_class: yup.string().required("Please enter age group's information")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'age groups information cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    topic_cover: yup.string().required("Please enter information regarding topics you cover")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Information regarding topics you cover cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    hear_about: yup.string().required("Please enter information regarding how did you hear about StudentScholars")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Information regarding how did you hear about StudentScholars cannot consist of only spaces',
        (value) => value !== undefined && value.toString().trim().length > 0
      ),
    testimonial_link: yup.string().required("Please enter link ")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([\/\w .-]*)*\/?$/,
        "Please enter a valid URL (e.g., http://www.example.com)"
      ),
    first_name: yup
      .string()
      .required("Please enter full name")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'Full name cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    country_of_residence: yup.string().required("Please enter your country of residence")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .matches(/^[a-zA-Z\s!]+$/, "Special characters and numbers are not allowed")
      .test(
        'no-only-whitespace',
        'Country cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    checkbox1: yup.boolean()
      .oneOf([true], 'Please confirm this guidlines')
      .required('Please confirm this guidlines'),
    attach4: yup
      .mixed()
      .required("Please upload video"),
  });

  if (orgMode === 'In-person') {
    schema = schema.shape({
      exp_month: yup
        .string()
        .required("Please enter expiry month and year of the insurance")
        .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
        .matches(
          /^(0[1-9]|1[0-2])[-\/](\d{4})$/,
          "Expiry date must be in the format MM/YYYY or MM-YYYY"
        )
        .test('is-future', 'Expiry date must be in the future', (value) => {
          if (!value) return false; // Handle null or undefined values
          const [month, year] = value.split(/[-\/]/); // Split by hyphen or slash
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11

          // Convert month and year to integers
          const expiryMonth = parseInt(month, 10);
          const expiryYear = parseInt(year, 10);

          // Check if the expiry year is greater than the current year
          if (expiryYear > currentYear) {
            return true;
          }
          // Check if the expiry year is the current year and month is greater than or equal to current month
          if (expiryYear === currentYear && expiryMonth >= currentMonth) {
            return true;
          }
          return false; // Not a valid future date
        }),
      attach2: yup
        .mixed()
        .required("Please upload liability insurance document"),
      attach3: yup
        .mixed()
        .required("Please upload your insurance details"),
      continue_hold_valid_insurance: yup.string().required('Please select this option'),
    });
  }

  if (CountryOrgID == '1') {
    schema = schema.shape({
      companyGst: yup
        .string()
        .oneOf(
          ['yes', 'no'], // Valid options corresponding to the radio button values
          'Please select your company required to have a GST/HST number or not'
        )
        .required("Please select your company required to have a GST/HST number or not"),
    });
  }

  if (companyGST == "yes" && CountryOrgID == "1") {
    schema = schema.shape({
      gst_hst: yup.string().required("Please enter your GST/HST number")
        .matches(/^[A-Za-z0-9]*$/, 'Only alphanumeric characters are allowed')
        .test(
          'len',
          'GST/HST must be between 3 and 20 characters',
          (value) => !value || (value.length >= 3 && value.length <= 20)
        ),
      province: yup.string().required("Please select state/province"),
    });


  }

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  console.log('errors :', errors);

  const today = new Date().toISOString().split('T')[0];
  const [profileImageState1, setProfileImageState1] = useState('')
  const [profileImageState2, setProfileImageState2] = useState('')
  const [profileImageState3, setProfileImageState3] = useState('')
  const [profileImageState4, setProfileImageState4] = useState('')
  const [docExtension1, setDocExtension1] = useState('')
  const [docExtension2, setDocExtension2] = useState('')
  const [docExtension3, setDocExtension3] = useState('')
  const [docExtension4, setDocExtension4] = useState('')
  var [CountryListdata, setCountryListdata] = useState([])
  var [CountryID, setCountryID] = useState([]);
  const [data, setData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const [phone, setPhone] = useState('');
  console.log('phone :', phone);
  const [country, setCountry] = useState('ca');
  const [CountryCode, setCountryCode] = useState('');

  const allowedCountries = ['au', 'ca', 'nz', 'sg', 'gb', 'us'];

  var [countryCode, setCountrycode] = useState();
  const [isoCode, setIsoCode] = useState('')
  const [country1, setCountry1] = useState('ca');
  var [phoneCode, setPhoneCode] = useState();
  const [phone1, setPhone1] = useState('');

  // Country code mapping for validation
  const countryConfigs = {
    au: { minLength: 9, maxLength: 9, dialCode: '61' },
    ca: { minLength: 10, maxLength: 10, dialCode: '1' },
    nz: { minLength: 9, maxLength: 9, dialCode: '64' },
    sg: { minLength: 8, maxLength: 8, dialCode: '65' },
    gb: { minLength: 10, maxLength: 10, dialCode: '44' },
    us: { minLength: 10, maxLength: 10, dialCode: '1' }
  };

  const handleOnChange1 = (value, country) => {
    console.log('country :', country);
    // console.log('country35453 :', country.dialCode);
    console.log('value :', value);
    const formattedValue = value.substring(country.dialCode.length);
    setPhoneCode(country?.countryCode)
    setPhone1(formattedValue);
    setCountry1(country.dialCode);
    setValue('org_phone_number', value)
    clearErrors('org_phone_number')
  };


  console.log('CountryOrgID :', CountryOrgID);
  var [stateListdata, setstateListdata] = useState([]);
  var [StateID, setStateID] = useState([]);

  const [continuetoHold, setContinuetoHold] = useState('');


  const countryListing = () => {
    isLoader(true)
    country_list().then((resposnse) => {

      if (resposnse?.code == 1) {
        isLoader(false)
        setCountryListdata(resposnse?.data)
      } else {
        isLoader(false)

      }
    });
  }

  const StateListing = () => {
    isLoader(true)
    state_province_list({ country_id: CountryID }).then((resposnse) => {

      if (resposnse?.code == 1) {
        isLoader(false)
        setstateListdata(resposnse?.data)

      } else {
        isLoader(false)

      }
    });
  }

  useEffect(() => {
    countryListing();
  }, []);

  useEffect(() => {
    StateListing()
  }, [CountryID]);

  const handleCountryChange = (e) => {
    setstateListdata([])

    setCountryID(e)
    clearErrors('country')
  }

  const handleCountryOrgChange = (e) => {
    setCountryOrgID(e)
    clearErrors('country_org')
  }

  const handleStateChange = (e) => {

    setStateID(e)
    clearErrors('state_province')
  }

  const handleImage1Change = async (image) => {
    // Allowed file types for attachments
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    // Check if the uploaded file is of an allowed type
    if (!allowedFileTypes.includes(image?.type)) {
      TOAST_ERROR('Please upload a valid file (JPG, JPEG, PNG, PDF, DOC, DOCX).');
      setProfileImageState1(null);  // Reset the state if invalid file is uploaded
      setValue("attach1", null);    // Clear the form value for invalid file
      return;  // Stop further execution
    }

    console.log('image :', image.type);
    setDocExtension1(image.type);

    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }

    setValue("attach1", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState1(bufferImage);
    clearErrors("attach1");
  }

  const handleImage2Change = async (image) => {
    // Allowed file types for attachments
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    // Check if the uploaded file is of an allowed type
    if (!allowedFileTypes.includes(image?.type)) {
      TOAST_ERROR('Please upload a valid file (JPG, JPEG, PNG, PDF, DOC, DOCX).');
      setProfileImageState2(null);  // Reset the state if invalid file is uploaded
      setValue("attach2", null);    // Clear the form value for invalid file
      return;  // Stop further execution
    }

    console.log('image :', image.type);
    setDocExtension2(image.type);

    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }

    setValue("attach2", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState2(bufferImage);
    clearErrors("attach2");
  }

  const handleImage3Change = async (image) => {
    // Allowed file types for attachments
    const allowedFileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    // Check if the uploaded file is of an allowed type
    if (!allowedFileTypes.includes(image?.type)) {
      TOAST_ERROR('Please upload a valid file (JPG, JPEG, PNG, PDF, DOC, DOCX).');
      setProfileImageState3(null);  // Reset the state if invalid file is uploaded
      setValue("attach3", null);    // Clear the form value for invalid file
      return;  // Stop further execution
    }

    console.log('image :', image.type);
    setDocExtension3(image.type);

    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }

    setValue("attach3", image);
    const bufferImage = await convertToBase64(image);
    setProfileImageState3(bufferImage);
    clearErrors("attach3");
  }

  const handleImage4Change = async (image) => {
    // Allowed video file types
    const allowedFileTypes = [
      'video/mp4', // .mp4
      'video/x-msvideo', // .avi
      'video/x-m4v', // .m4v
      'video/quicktime' // .mov
    ];

    // Check if the uploaded file is of an allowed video type
    if (!allowedFileTypes.includes(image?.type)) {
      TOAST_ERROR('Please attach a valid video file (MP4, AVI, M4V, MOV)');
      setProfileImageState4(null);  // Reset the state if invalid file is uploaded
      setValue("attach4", null);    // Clear the form value for invalid file
      return;  // Stop further execution
    }

    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';

    videoElement.onloadedmetadata = async () => {
      window.URL.revokeObjectURL(videoElement.src);
      const duration = videoElement.duration;

      // If duration exceeds 3 minutes (180 seconds), reject the upload
      if (duration > 180) {
        TOAST_ERROR('Please upload a video that is 3 minutes or shorter in duration.');
        setProfileImageState4(null);
        setValue("teacherVideo", null);
        return;
      }

      console.log('image :', image.type);
      setDocExtension4(image.type);

      if (image != null) {
        TOAST_SUCCESS("Your attachment has been uploaded");
      }

      setValue("attach4", image);

      // Convert to Base64 and set the state
      const bufferImage = await convertToBase64(image);
      setProfileImageState4(bufferImage);

      clearErrors("attach4");
    };

    videoElement.src = URL.createObjectURL(image);
  };

  // Function to fetch teacher profile
  const teacherProfile = async () => {
    try {
      const response = await teacherViewProfile({
        userId: localStorage.getItem("Tid")
      });

      console.log('teacherViewProfile :', response);
      if (response?.code == 1 && response?.data) {
        // const { phone, country_code, iso_code } = response?.data;

        // if (iso_code) {
        //   const normalizedIsoCode = iso_code.toLowerCase();
        //   localStorage.setItem('isoCode', normalizedIsoCode);
        // }

        // // Handle phone number formatting based on country
        // const cleanPhone = phone?.replace(/\D/g, '');
        // let formattedCountryCode = country_code;

        // // Ensure country code is properly formatted
        // if (formattedCountryCode) {
        //   formattedCountryCode = formattedCountryCode.startsWith('+')
        //     ? formattedCountryCode
        //     : `+${formattedCountryCode}`;
        // }

        // updatePhoneData(cleanPhone, formattedCountryCode, iso_code);

        setPhone(response.data.phone);
        setCountryCode(response.data.country_code);

        // Update other form fields
        reset({
          email: response?.data?.email,
          country: response?.data?.country_name,
          full_name: response?.data?.full_name,
          phone: response?.data?.phone,
          phone: response?.data?.country_code + response?.data?.phone,
        });
        setValue('phone', response.data.phone);

        // Update other states
        setData(response?.data);
        // SetCountryData(response?.data?.country_name);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const classDescriptionValue = watch('phone');

  useEffect(() => {
    teacherProfile();
  }, []);

  const OrganizationMode = (data) => {
    setOrgMode(data)
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleOnChange = (value, country) => {
    console.log('country :', country);
    const formattedValue = value.substring(country.dialCode.length);
    setCountryCode(country.dialCode)
    setPhone(formattedValue);
    setCountry(country.dialCode);
    setValue('phone', value)
  };

  let onSubmit = async (data) => {

    isLoader(true)
    console.log('data :', data);

    let submit_data = {
      org_id: localStorage.getItem("Tid"),
      legal_name: data.legal_name,
      org_name: data.full_name,
      org_email: data.email,
      email : data.email,
      org_telephone: CountryCode + phone,
      street_number: data.street_number,
      street_name: data.street_name,
      country: data.country,
      state_province: data.state_province,
      city: data.city,
      zip_code: data.zip_code,
      org_url: data.website,
      org_founder: formatDate(data.org_founded),
      reg_number: data.business_number,
      country_org: data.country_org,
      company_required_gst_hst: data.companyGst,
      gst_hst_number: data.gst_hst,
      province: data.province,
      primary_category_one: data.primary_category,
      org_types: orgMode,
      ecp_insurance_date: data.exp_month,
      continue_to_hold: data.continue_hold_valid_insurance,
      online_profiles: data.online_profiles,
      many_org: data.teacher_count_field,
      info_requirements_one: data.child_adults,
      group_size: data.size_class,
      offer_classes: data.topic_cover,
      hear_student_scholar: data.hear_about,
      public_review: data.testimonial_link,
      who_primary_contact: data.org_primary_contact,
      primary_title: data.primary_contact_title,
      primary_phone_number: "+" + country1 + phone1,
      comments: data.Comments,
      full_name: data.first_name,
      country_residence: data.country_of_residence
    }

    let attachment1 = data?.attach1
    let attachment2 = data?.attach2
    let attachment3 = data?.attach3
    let attachment4 = data?.attach4


    if (data?.attach1) {
      attachment1 = await uploadImageOnAWS(attachment1, "organization_application");
      submit_data.reg_doc = attachment1;
    }

    if (data?.attach2) {
      attachment2 = await uploadImageOnAWS(attachment2, "organization_application");
      submit_data.org_insurance = attachment2;
    }

    if (data?.attach3) {
      attachment3 = await uploadImageOnAWS(attachment3, "organization_application");
      submit_data.insurance_details = attachment3;
    }

    if (data?.attach4) {
      attachment4 = await uploadImageOnAWS(attachment4, "organization_application");
      submit_data.org_intro_video = attachment4;
    }
    console.log('submit_data :', submit_data);
    org_application(submit_data).then((response) => {
      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response?.message);
        navigate("/organization-step")
        reset();
      } else {
        isLoader(false)
        TOAST_ERROR(response?.message);
      }
    })
  }

  return (
    <>
      <main>
        <div className="login_sec">
          <div className="container-fluid">
            <div className="row my-4 justify-content-center">
              <div className="col-lg-8 right card">
                <div className="top">
                  <div className="arrow_back mb-3">
                    <a onClick={() => navigate(-1)} onclick="history.back()" className="d-flex align-items-center">
                      <span>
                        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.86245 2.225L8.37912 0.75L0.137451 9L8.38745 17.25L9.86245 15.775L3.08745 9L9.86245 2.225Z" fill="#131313" />
                        </svg>
                      </span>
                      <span className="ps-3">Back</span>
                    </a>
                  </div>
                  <h2 className="mb-4">Organization Application</h2>
                  <form onSubmit={handleSubmit(onSubmit)} className="custom_form mt-4" action id>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Legal Name : Please enter your full legal name. This will only be
                            visible
                            to our team, and cannot be edited later.
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register("legal_name")} name="legal_name" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.legal_name?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            What is the name of your organization
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  {...register("full_name")} name="full_name" c disabled />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.full_name?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Organization Email:
                            <span className="text-danger">*</span>
                          </label>
                          <input type="email" className="form-control" {...register("email")} name="email" disabled />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.email?.message}</span></p>
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Organization Telephone:
                            <span className="text-danger">*</span>
                          </label>
                          <input type="tel" className="form-control" {...register("telephone")} name="telephone" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.telephone?.message}</span></p>
                        </div>
                      </div> */}
                      <div className="form-group mb-4">
                        <label className="mb-2 d-block"> Organization Telephone:<span className="text-danger">*</span></label>
                        {/* <input type="text" id="mobile_code" className="form-control w-100" name="phone" {...register("phone")} disabled /> */}
                        <PhoneInput placeholder="Enter phone number" name="phone" {...register("phone")} value={`${CountryCode}${phone}`}
                          id="phone" onChange={(value, country) => handleOnChange(value, country)} onlyCountries={allowedCountries} disabled={isDisabled}
                        />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.phone?.message}</span></p>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-3">
                            Full address of your Company-
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Street Number</label>
                                <input type="text" className="form-control" {...register("street_number")} name="street_number" />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_number?.message}</span></p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Street Name</label>
                                <input type="text" className="form-control"  {...register("street_name")} name="street_name" />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.street_name?.message}</span></p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Country</label>

                                <select className="form-select" {...register("country")} onChange={(e) => handleCountryChange(e.target.value)} name="country" aria-label="Default select example">
                                  <option value='' selected disabled>select country</option>
                                  {CountryListdata?.map((countrylistdetail, index) => {
                                    return (
                                      <option key={index} value={countrylistdetail?.country_id}>{countrylistdetail?.country_name}</option>
                                    )
                                  })}
                                </select>

                                <p className='mt-2 font-bold col-red'>
                                  <span style={{ color: 'red' }}>{errors.country?.message}</span>
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group mb-4">
                                <label className="mb-2">Province/State</label>
                                <select className="form-select" {...register("state_province")} onChange={(e) => handleStateChange(e.target.value)} name="state_province" aria-label="Default select example">
                                  <option value='' selected disabled>select state/province</option>
                                  {stateListdata?.map((countrylistdetail, index) => {
                                    return (
                                      <option key={index} value={countrylistdetail?.state_id}>{countrylistdetail?.state_province}</option>
                                    )
                                  })}
                                </select>

                                <p className='mt-2 font-bold col-red'>
                                  <span style={{ color: 'red' }}>{errors.state_province?.message}</span>
                                </p>

                              </div>
                            </div>
                            <div className="col-lg-6">
                              <label className="mb-2">City</label>
                              <input type="text" className="form-control" {...register("city")} name="city" />
                              <p className='mt-2 font-bold col-red'>
                                <span style={{ color: 'red' }}>{errors.city?.message}</span>
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <label className="mb-2">Zip Code</label>
                              <input type="text" {...register("zip_code")} className="form-control" name="zip_code" />
                              <p className='mt-2 font-bold col-red'>
                                <span style={{ color: 'red' }}>{errors.zip_code?.message}</span>
                              </p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Your Company Website / URL
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register("website")} name="website" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.website?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            When was your organization founded?
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            {...register("org_founded", { required: "This field is required" })}
                            name="org_founded"
                            max={new Date().toISOString().split("T")[0]}
                          />
                          <p className="mt-2 font-bold col-red">
                            <span style={{ color: 'red' }}>{errors.org_founded?.message}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Your Company Registration / Business number
                            <span className="text-danger">*</span>
                          </label>
                          <input type="tel" className="form-control"  {...register("business_number")} name="business_number" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.business_number?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Please upload your organization registration document
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach1" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {(docExtension1 === 'image/jpeg' || docExtension1 === 'image/png') && profileImageState1 && (
                                          <img onClick={() => {
                                            document.getElementById("attach1")?.click()
                                          }}
                                            className="image"
                                            src={profileImageState1 ? profileImageState1 : ""}
                                            alt=""
                                            style={{
                                              width: profileImageState1 ? "100%" : "",
                                              height: profileImageState1 ? "166px" : "",
                                            }}
                                          />
                                        )}

                                        {docExtension1 === 'application/pdf' && profileImageState1 && (
                                          <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                              document.getElementById("attach1")?.click()
                                            }}>
                                              <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                            </svg>
                                          </>
                                        )}

                                        {(docExtension1 === 'application/msword' || docExtension1 === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState1 && (
                                          <>
                                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                              document.getElementById("attach1")?.click()
                                            }}>
                                              <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                            </svg>
                                          </>
                                        )}

                                      </div>
                                      <input type="file" id='attach1' name="attach1" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage1Change(e.target.files[0])} className="form-control" style={{ display: profileImageState1 ? "none" : "" }} />
                                      {profileImageState1 ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => {
                                            setProfileImageState1(null);
                                            document.getElementById("attach1").value = null;
                                          }}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />

                              </div>
                            </div>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach1?.message}</span></p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Which country is your organization registered in ?</label>

                          <select className="form-select" {...register("country_org")} onChange={(e) => handleCountryOrgChange(e.target.value)} name="country_org" aria-label="Default select example">
                            <option value='' selected disabled>select country</option>
                            {CountryListdata?.map((countrylistdetail, index) => {
                              return (
                                <option key={index} value={countrylistdetail?.country_id}>{countrylistdetail?.country_name}</option>
                              )
                            })}
                          </select>

                          <p className='mt-2 font-bold col-red'>
                            <span style={{ color: 'red' }}>{errors.country_org?.message}</span>
                          </p>
                        </div>
                      </div>

                      {CountryOrgID == "1" &&
                        <>
                          <div className="form-group mb-4">
                            <label className="mb-2">
                              Is your company required to have a GST/HST number?
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <input className="form-check-input" type="radio" id="yes" value="yes"  {...register("companyGst")} onClick={(e) => setCompanyGST('yes')} name="companyGst" />
                              <label htmlFor="yes" className="mt-1 ms-1">
                                Yes
                              </label>
                              <input className="form-check-input ms-2" type="radio" id="no" value="no" {...register("companyGst")} onClick={(e) => setCompanyGST('no')} name="companyGst" />
                              <label htmlFor="no" className="mt-1 ms-1">
                                No
                              </label>
                            </div>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.companyGst?.message}</span></p>

                          </div>
                        </>}

                      {companyGST == "yes" && CountryOrgID == "1" &&
                        <>
                          <div className="form-group mb-4">
                            <label className="mb-2">Please add your GST/HST number.</label>
                            <input type="text" className="form-control"  {...register("gst_hst")} name="gst_hst" />
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.gst_hst?.message}</span></p>
                          </div>

                          <div className="form-group mb-4">
                            <label className="mb-2">Which Province are you residing in?</label>
                            <select className="form-select" {...register("province")}
                              name="province" aria-label="Default select example">
                              <option value='' selected disabled>select state/province</option>
                              <option value="Alberta - GST -5%">Alberta - GST - 5%</option>
                              <option value="British Columbia - GST -5%">British Columbia - GST - 5%</option>
                              <option value="Manitoba - GST -5%">Manitoba - GST - 5%</option>
                              <option value="Northwest Territories - GST -5%">
                                Northwest Territories - GST - 5%
                              </option>
                              <option value="Nunavut - GST -5%">Nunavut - GST - 5%</option>
                              <option value="Quebec - GST -5%">Quebec - GST - 5%</option>
                              <option value="Saskatchewan - GST -5%">Saskatchewan - GST - 5%</option>
                              <option value="Yukon - GST -5%">Yukon - GST - 5%</option>
                              <option value="Ontario - HST - 13%">Ontario - HST - 13%</option>
                              <option value="New Brunswick - HST - 15%">New Brunswick - HST - 15%</option>
                              <option value="Newfoundland and Labrador - HST - 15%">
                                Newfoundland and Labrador - HST - 15%
                              </option>
                              <option value="Nova Scotia - HST - 15%">Nova Scotia - HST - 15%</option>
                              <option value="Prince Edward Island - HST - 15%">
                                Prince Edward Island - HST - 15%
                              </option>
                            </select>

                            <p className='mt-2 font-bold col-red'>
                              <span style={{ color: 'red' }}>{errors.province?.message}</span>
                            </p>

                          </div>
                        </>}

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            What is the primary category of your business. IE: Education, Tutoring,
                            Montessori etc.
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register("primary_category")} name="primary_category" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.primary_category?.message}</span></p>

                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            What type of modes of classes will your organization provide
                            <span className="text-danger">*</span>
                          </label>
                          <select className="form-select"  {...register("org_modes")} onChange={(e) => OrganizationMode(e.target.value)} aria-label="Default select example">
                            <option value={"Online"}>
                              Online
                            </option>
                            <option value={"In-person"}>
                              In-person - At your Organization Location
                            </option>
                          </select>
                        </div>
                      </div>

                      {orgMode == "In-person" ?
                        <>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <p className="mb-2">
                                Do you have a valid liability insurance that covers against third party injury of any kind arising from your premises and provision of any Class and/or Content, including teacher abuse claims coverage? Required  for In-person classes.
                              </p>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="form-group mb-lg-0 mb-4  file_upload">
                                    <Controller name="attach2" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                      <>
                                        <div className="file_update position-relative">
                                          <div className="d-flex align-items-center justify-content-center">


                                            {(docExtension2 === 'image/jpeg' || docExtension2 === 'image/png') && profileImageState2 && (
                                              <img onClick={() => {
                                                document.getElementById("attach2")?.click()
                                              }}
                                                className="image"
                                                src={profileImageState2 ? profileImageState2 : ""}
                                                alt=""
                                                style={{
                                                  width: profileImageState2 ? "100%" : "",
                                                  height: profileImageState2 ? "166px" : "",
                                                }}
                                              />
                                            )}


                                            {docExtension2 === 'application/pdf' && profileImageState2 && (

                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                  document.getElementById("attach2")?.click()
                                                }}>
                                                  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                </svg>

                                              </>
                                            )}

                                            {(docExtension2 === 'application/msword' || docExtension2 === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState2 && (
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                  document.getElementById("attach2")?.click()
                                                }}>
                                                  <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                </svg>
                                              </>
                                            )}

                                          </div>
                                          <input type="file" id='attach2' name="attach2" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage2Change(e.target.files[0])} className="form-control" style={{ display: profileImageState2 ? "none" : "" }} />
                                          {profileImageState2 ?
                                            <button
                                              type="button"
                                              className="btn-close position-absolute"
                                              data-bs-dismiss="modal"
                                              onClick={() => {
                                                setProfileImageState2(null);
                                                document.getElementById("attach2").value = null;
                                              }}
                                              aria-label="Close"
                                              style={{
                                                top: "10px",
                                                right: "10px",
                                                zIndex: "10",
                                              }}
                                            ></button> :
                                            <span className="file_icons position-absolute">
                                              <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                              </svg>
                                            </span>
                                          }
                                        </div>
                                      </>
                                    )} />
                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach2?.message}</span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="mb-2">
                                What is the expiry of the insurance ? Month and year
                                <span className="text-danger">*</span>
                              </label>
                              <input type="text" className="form-control" {...register("exp_month")} name="exp_month" />
                              <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.exp_month?.message}</span></p>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group mb-4">
                              <label className="mb-2">
                                Please upload your insurance details. Required for In-person classes
                                <span className="text-danger">*</span>
                              </label>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="form-group mb-lg-0 mb-4  file_upload">
                                    <Controller name="attach3" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                      <>
                                        <div className="file_update position-relative">
                                          <div className="d-flex align-items-center justify-content-center">


                                            {(docExtension3 === 'image/jpeg' || docExtension3 === 'image/png') && profileImageState3 && (
                                              <img onClick={() => {
                                                document.getElementById("attach1")?.click()
                                              }}
                                                className="image"
                                                src={profileImageState3 ? profileImageState3 : ""}
                                                alt=""
                                                style={{
                                                  width: profileImageState3 ? "100%" : "",
                                                  height: profileImageState3 ? "166px" : "",
                                                }}
                                              />
                                            )}

                                            {docExtension3 === 'application/pdf' && profileImageState3 && (

                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width={100} height={100} fill="currentColor" style={{ color: "red", margin: "30px" }} class="bi bi-filetype-pdf" viewBox="0 0 16 16" onClick={() => {
                                                  document.getElementById("attach3")?.click()
                                                }}>
                                                  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z" />
                                                </svg>

                                              </>
                                            )}

                                            {(docExtension3 === 'application/msword' || docExtension3 === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && profileImageState3 && (
                                              <>
                                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={100} height={100} style={{ margin: "30px" }} viewBox="0 0 48 48" onClick={() => {
                                                  document.getElementById("attach3")?.click()
                                                }}>
                                                  <path fill="#90CAF9" d="M40 45L8 45 8 3 30 3 40 13z"></path><path fill="#E1F5FE" d="M38.5 14L29 14 29 4.5z"></path><path fill="#1976D2" d="M16 21H33V23H16zM16 25H29V27H16zM16 29H33V31H16zM16 33H29V35H16z"></path>
                                                </svg>
                                              </>
                                            )}

                                          </div>
                                          <input type="file" id='attach3' name="attach3" accept=".jpg, .jpeg, .png, .pdf, .doc, .docx" onInput={(e) => handleImage3Change(e.target.files[0])} className="form-control" style={{ display: profileImageState3 ? "none" : "" }} />
                                          {profileImageState3 ?
                                            <button
                                              type="button"
                                              className="btn-close position-absolute"
                                              data-bs-dismiss="modal"
                                              onClick={() => {
                                                setProfileImageState3(null);
                                                document.getElementById("attach3").value = null;
                                              }}
                                              aria-label="Close"
                                              style={{
                                                top: "10px",
                                                right: "10px",
                                                zIndex: "10",
                                              }}
                                            ></button> :
                                            <span className="file_icons position-absolute">
                                              <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                              </svg>
                                            </span>
                                          }
                                        </div>
                                      </>
                                    )} />
                                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach3?.message}</span></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-4">
                            <label className="mb-2">
                              Please confirm that you will continue to hold valid insurance to
                              protect the students, parents/guardians visiting your location.
                              StudentScholars will not be held liable for any incidents at your
                              location. Do you agree? Required for in-person classes
                              <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                              <input className="form-check-input" type="radio" id="yes" value="yes"  {...register("continue_hold_valid_insurance")} onClick={(e) => setContinuetoHold('yes')} name="continue_hold_valid_insurance" />
                              <label htmlFor="yes" className="mt-1 ms-1">
                                Yes
                              </label>
                              <input className="form-check-input ms-2" type="radio" id="no" value="no" {...register("continue_hold_valid_insurance")} onClick={(e) => setContinuetoHold('no')} name="continue_hold_valid_insurance" />
                              <label htmlFor="no" className="mt-1 ms-1">
                                No
                              </label>
                            </div>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.continue_hold_valid_insurance?.message}</span></p>
                          </div>
                        </>
                        : <></>}

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <p className="mb-2">
                            (Optional) Online profiles: does your organization have a Facebook page, or LinkedIn profile that helps describe your experience and expertise? Share it with us here!
                          </p>
                          <input type="text" className="form-control" {...register("online_profiles")} name="online_profiles" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.online_profiles?.message}</span></p>
                        </div>
                      </div>


                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            How many teachers from your organization do you anticipate will begin
                            teaching on
                            StudentScholars within the next month? If you are the only teacher in
                            your
                            organization, please apply as an independent teacher instead.
                            <span className="text-danger">*</span>
                          </label>
                          <input type="number" className="form-control" {...register("teacher_count_field")} name="teacher_count_field" onInput={(e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                          }} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.teacher_count_field?.message}</span></p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Please include any information around requirements for your teachers and
                            any training you do, as well as how long your educators have been
                            working with children or adults.
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register('child_adults')} name="child_adults" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.child_adults?.message}</span></p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            What ages do you work with? Are you targeting special interest groups?
                            What is the group size for most of your classes?
                            <span className="text-danger">*</span>
                          </label>
                          <textarea type="text" className="form-control" {...register('size_class')} name="size_class" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.size_class?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Tell us about the types of classes you offer. Please be specific
                            regarding the topics you cover, the length of programming, and where/how
                            you offer classes.
                            <span className="text-danger">*</span>
                          </label>
                          <textarea className="form-control" {...register('topic_cover')} name="topic_cover" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.topic_cover?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            How did you hear about StudentScholars
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control"  {...register('hear_about')} name="hear_about" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.hear_about?.message}</span></p>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Can you point us to any public reviews or testimonials about your
                            programs? (Please provide links.)
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register('testimonial_link')} name="testimonial_link" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.testimonial_link?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Record a 2-3 minute video to let us know about your organization. This
                            video will stay private and will not be visible to families. You should
                            let us know why your organization should join StudentScholars and what
                            your organization could offer the students.
                            <span className="text-danger">*</span>
                          </label>
                          <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group mb-lg-0 mb-4  file_upload">
                                <Controller name="attach4" control={control} defaultValue={null} rules={{ required: "Profile image is required" }} render={({ field }) => (
                                  <>
                                    <div className="file_update position-relative">
                                      <div className="d-flex align-items-center justify-content-center">

                                        {docExtension4 === 'video/mp4' && profileImageState4 && (

                                          <video onClick={() => {
                                            document.getElementById("attach4")?.click()
                                          }} src={profileImageState4} width="100%" height="100%" className="video" loop muted autoPlay playsInline controls style={{
                                            width: profileImageState4 ? "100%" : "",
                                            height: profileImageState4 ? "166px" : "",
                                          }}></video>
                                        )}

                                      </div>
                                      <input type="file" id='attach4' name="attach4" accept="video/mp4,video/x-msvideo,video/x-m4v,video/quicktime" onInput={(e) => handleImage4Change(e.target.files[0])} className="form-control" style={{ display: profileImageState4 ? "none" : "" }} />
                                      {profileImageState4 ?
                                        <button
                                          type="button"
                                          className="btn-close position-absolute"
                                          data-bs-dismiss="modal"
                                          onClick={() => {
                                            setProfileImageState4(null); // Reset the uploaded file state
                                            document.getElementById("attach4").value = null; // Reset the input field
                                          }}
                                          aria-label="Close"
                                          style={{
                                            top: "10px",
                                            right: "10px",
                                            zIndex: "10",
                                          }}
                                        ></button> :
                                        <span className="file_icons position-absolute">
                                          <svg width={22} height={24} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.325 0C14.675 0 16.025 0.6 16.85 1.725L20.525 6.3C21.125 7.125 21.5 8.1 21.5 9.075V19.5C21.5 21.975 19.475 24 17 24H5C2.525 24 0.5 21.975 0.5 19.5V4.5C0.5 2.025 2.525 0 5 0H13.325ZM15.65 8.475C14.3 7.125 12.2 6.975 10.7 8.175C10.4 8.4 10.325 8.925 10.55 9.225C10.775 9.525 11.3 9.6 11.6 9.375C12.5 8.7 13.775 8.775 14.525 9.6C15.425 10.5 15.425 11.925 14.525 12.75L11.9 15.375L10.625 16.65L10.55 16.725C9.65 17.55 8.3 17.475 7.475 16.65C6.65 15.825 6.575 14.475 7.4 13.575L7.475 13.5L9.2 11.775L9.275 11.7C9.575 11.475 10.025 11.475 10.25 11.775C10.55 12.075 10.55 12.525 10.25 12.825L8.525 14.55L8.45 14.625C8.225 14.925 8.225 15.375 8.525 15.6C8.825 15.9 9.275 15.9 9.575 15.6L11.3 13.875L11.375 13.8C12.125 12.9 12.125 11.55 11.3 10.725C10.4 9.825 8.975 9.825 8.15 10.725L7.475 11.4L7.4 11.475L6.95 11.925L6.425 12.45C4.925 13.95 4.925 16.275 6.425 17.775C7.925 19.275 10.25 19.275 11.75 17.775L13.475 16.05L13.55 15.975L15.725 13.8L15.875 13.65C17.075 12.225 17.075 9.9 15.65 8.475Z" fill="#131313" fillOpacity="0.4" />
                                          </svg>
                                        </span>
                                      }
                                    </div>
                                  </>
                                )} />
                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.attach4?.message}</span></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Who is the primary contact for your organization?
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register('org_primary_contact')} name="org_primary_contact" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_primary_contact?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            What is your primary contact's title?
                            <span className="text-danger">*</span>
                          </label>
                          <input type="text" className="form-control" {...register('primary_contact_title')} name="primary_contact_title" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.primary_contact_title?.message}</span></p>

                        </div>
                      </div>
                      <div className="col-lg-12">
                        {/* <div className="form-group mb-4">
                          <label className="mb-2">
                            What is primary contact's phone number?
                            <span className="text-danger">*</span>
                          </label>
                          <input type="tel" className="form-control" {...register('org_phone_number')} name="org_phone_number" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_phone_number?.message}</span></p>
                        </div> */}

                        <div className="form-group mb-4">
                          <label className="mb-2 d-block">Phone Number</label>
                          <PhoneInput placeholder="  Enter phone number" name="org_phone_number" {...register("org_phone_number")}
                            id="org_phone_number" country="ca" onChange={(value, country) => handleOnChange1(value, country)} onlyCountries={allowedCountries} />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.org_phone_number?.message}</span></p>
                        </div>
                      </div>


                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">
                            Comments/ Any Additional Information You Would Like Us To Know
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <textarea className="form-control" {...register('Comments')} name="Comments" />
                          {/* <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.testimonial_link?.message}</span></p> */}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Your Full Name:<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" {...register("first_name")} name="first_name" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.first_name?.message}</span></p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2 mx-2">
                            Date :
                          </label>
                          <input
                            type="date"
                            className="form-control w-25 d-inline-block"
                            {...register("date1")}
                            name="date1"
                            value={today}
                            disabled
                            onFocus={(e) => e.target.blur()}
                          />
                          <p className='mt-2 font-bold col-red'>
                            <span style={{ color: 'red' }}>{errors.date1?.message}</span>
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-4">
                          <label className="mb-2">Your Country of Residence :<span className="text-danger">*</span></label>
                          <input type="text" className="form-control" {...register("country_of_residence")} name="country_of_residence" />
                          <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.country_of_residence?.message}</span></p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group d-flex gap-4 align-items-center ">
                        <input type="checkbox" id="checkbox1" {...register('checkbox1')} name="checkbox1" />
                        <label htmlFor="checkbox1" className="d-block">
                          I declare that the above information provided by me is accurate and
                          truthful.
                        </label>
                      </div>
                      <br></br> <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.checkbox1?.message}</span></p>

                      <div className="button mt-5">
                        {/* <a href="dashboard.php" className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block">Submit</a> */}
                        <button className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block" >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

    </>
  )
}
