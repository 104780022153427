import { Link, useNavigate } from "react-router-dom";
import BackToPrevious from "../../../../components/backToPrevious";
import { useEffect, useState } from "react";
import { follow_unfollow } from "../../../../api/apiHandler";
import { TOAST_ERROR } from "../../../../utils/common.service";

const CommonSection = ({ teacherData,data }) => {
console.log('teacherData :', teacherData);
  const [isFollow, setIsFollow] = useState(false);
  const userType = localStorage.getItem('userType') 


  useEffect(() => {
    teacherData && setIsFollow(teacherData?.is_follow === 1 ? true : false);
  }, [teacherData]);

  const handleFollowUnfollow = async () => {
    try {
      const { code, message } = await follow_unfollow({ teacher_id: teacherData?.id });
      console.log("🚀 ~ handleFollowUnfollow ~ message:", message);
      if (code == 1) {
        setIsFollow(!isFollow);
      }
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };

  return (
    <div className="class-content  bg-dark-gray">
      {/*start*/}
      <BackToPrevious />
      {/* <a href="#" className="mb-4 arrow_left mb-3 d-block" onClick="window.history.back()">
      <span>
        <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
        </svg>
        <span className="ms-2">Back</span>
      </span></a> */}
      {/*end*/}
      {/*start*/}
      <section>
        <div className="class_dtls_sec pb-0">
          <div className="row align-items-center">
            <div className="class_image_box h-auto col-xl-7 mb-xl-0 mb-4">
              <div className="class_img position-relative">
                <div className="pb-0 position-static video-container">
                  {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                  <video id="video" className="position-static rounded-3" loop width="100%" src={data?.video_file} controls />

                </div>
              </div>
              <div>
                <div className="align-items-center bottom-info d-flex justify-content-between position-static rounded-3">
                  <Link to="/teachers_details" state={teacherData?.id} className="d-flex align-items-center">
                    {/* <img src="./assets/images/user1.png" alt="user1" /> */}
                    <img
                      src={teacherData?.profile_image ? teacherData?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                      alt="user1"
                    />
                    <div className="d-flex align-items-center">
                      <span className="tech_nm ms-2">{teacherData?.full_name}</span>
                      {/* <img src="./assets/images/flag/canada.png" alt="canada" className="ms-2 det_flag_class" /> */}
                    </div>
                  </Link>
                  <div className="star_rat">
                    <span>
                      <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z"
                          fill="#FDC840"
                        />
                      </svg>
                    </span>
                    <span className="fw-600">
                      {teacherData?.teacher_rating ? `${teacherData?.teacher_rating}` : "0"}
                      <span className="text-gray fw-500">({teacherData?.teacher_review ? `${teacherData?.teacher_review}` : "0"})</span>
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between follow-btn-sec position-static py-2 w-100">
                  <button className="follow_btn" onClick={() => handleFollowUnfollow()}>
                    {!isFollow ? "Follow Me" : "Following"}
                  </button>
                  {teacherData?.subject_category && (
                    <span className="bg-yellow px-3 py-1 rounded-3 border-radius-10">{teacherData?.subject_category}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-5 ps-xl-5 ps-3">
              <div className="justify-content-between">
                <div>
                  <h2 className="mb-4 poppins fw-600">Welcome To</h2>
                  <p className="fw-500 text-capitalize">
                   {data?.title}
                  </p>
                  <div className="button safety_btn mt-4">
                    <a href="#!" data-bs-toggle="modal" data-bs-target="#safety_tips" className="btn-theme mb-3 bg-yellow d-inline-block ">
                      <span className="me-3">
                        <svg width={26} height={31} viewBox="0 0 26 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M13.1731 9.78467C10.0212 9.78467 7.45703 12.3488 7.45703 15.5005C7.45703 18.6525 10.0212 21.2166 13.1731 21.2166C16.3249 21.2166 18.889 18.6525 18.889 15.5005C18.889 12.3488 16.3249 9.78467 13.1731 9.78467ZM16.5668 14.4241L12.4718 18.519C12.3902 18.6009 12.2931 18.6658 12.1863 18.7101C12.0795 18.7544 11.9649 18.7771 11.8493 18.777C11.7337 18.7771 11.6192 18.7544 11.5124 18.7102C11.4056 18.6659 11.3086 18.6009 11.227 18.519L9.66209 16.9541C9.31832 16.6106 9.31832 16.0533 9.66209 15.7095C10.0056 15.3657 10.563 15.3657 10.9067 15.7095L11.8493 16.652L15.3221 13.1795C15.6657 12.8356 16.223 12.8356 16.5668 13.1795C16.9105 13.523 16.9105 14.0804 16.5668 14.4241Z"
                            fill="#570861"
                          />
                          <path
                            d="M25.9172 8.63975L25.9163 8.61643C25.9027 8.30584 25.8937 7.99507 25.8893 7.68421C25.8648 6.03005 24.5499 4.66177 22.8955 4.56937C19.4463 4.37683 16.778 3.25213 14.4981 1.02991L14.4787 1.01139C13.735 0.329536 12.6133 0.329536 11.8694 1.01139L11.8499 1.02991C9.57005 3.25213 6.90175 4.37683 3.45252 4.56955C1.79842 4.66177 0.48323 6.03011 0.458738 7.68439C0.454439 7.99519 0.445454 8.3059 0.431785 8.61643L0.430379 8.67063C0.363289 12.1879 0.279969 16.5652 1.74434 20.5384C2.54959 22.7233 3.76904 24.6225 5.36871 26.1837C7.19062 27.9617 9.5769 29.3732 12.4613 30.3789C12.5562 30.4119 12.6533 30.4384 12.7519 30.4581C12.892 30.486 13.033 30.5 13.174 30.5C13.315 30.5 13.4562 30.486 13.5961 30.4581C13.6951 30.4383 13.7926 30.4116 13.8879 30.3784C16.7688 29.3709 19.1526 27.9587 20.9729 26.181C22.5719 24.6194 23.7914 22.7196 24.5973 20.5343C26.0671 16.5492 25.9841 12.1636 25.9172 8.63975ZM13.1736 22.9769C9.05091 22.9769 5.69713 19.6231 5.69713 15.5005C5.69713 11.3778 9.05097 8.02405 13.1736 8.02405C17.296 8.02405 20.65 11.3778 20.65 15.5005C20.65 19.6231 17.296 22.9769 13.1736 22.9769Z"
                            fill="#570861"
                          />
                        </svg>
                      </span>
                      Safety Tips
                    </a>
                    {userType !== 'learner' ? 
                    <a href="#!" data-bs-toggle="modal" data-bs-target="#class_option" className="btn-theme bg-yellow d-inline-block mx-2">
                      <span className="me-3">
                        <svg width={30} height={27} viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M27.2385 0.5H14.0584C12.5331 0.5 11.2969 1.73645 11.2969 3.26147V9.75365C11.9936 10.0999 12.7465 10.4744 13.4491 10.8237L14.5242 8.67272C15.1751 7.37193 16.7619 6.84342 18.0622 7.49322C19.3652 8.14537 19.8933 9.72781 19.241 11.0311L17.3245 14.8649C17.3759 15.3737 17.3292 15.8876 17.1872 16.3789H18.4525L15.1154 25.5553C14.9173 26.0995 15.3001 26.7346 15.9416 26.7346C16.3003 26.7346 16.6377 26.5133 16.7672 26.1558L20.3227 16.3789H20.9386L24.5302 26.1588C24.6608 26.5145 24.9973 26.7346 25.3557 26.7346C25.9552 26.7346 26.3946 26.1359 26.1802 25.5529L22.8111 16.3789H27.2386C28.7636 16.3789 30 15.1425 30 13.6174V3.26152C30 1.73645 28.7636 0.5 27.2385 0.5ZM25.3553 12.6136H21.4119C20.9266 12.6136 20.533 12.2199 20.533 11.7346C20.533 11.249 20.9266 10.8557 21.4119 10.8557H25.3553C25.841 10.8557 26.2342 11.249 26.2342 11.7346C26.2342 12.2199 25.8411 12.6136 25.3553 12.6136ZM25.3553 9.31836H21.4119C20.9266 9.31836 20.533 8.9252 20.533 8.43945C20.533 7.95424 20.9266 7.56055 21.4119 7.56055H25.3553C25.841 7.56055 26.2342 7.95424 26.2342 8.43945C26.2342 8.9252 25.8411 9.31836 25.3553 9.31836ZM25.3553 6.02363H15.9416C15.4559 6.02363 15.0627 5.62988 15.0627 5.14473C15.0627 4.65957 15.4559 4.26582 15.9416 4.26582H25.3553C25.841 4.26582 26.2342 4.65945 26.2342 5.14473C26.2342 5.63 25.841 6.02363 25.3553 6.02363Z"
                            fill="#570861"
                          />
                          <path
                            d="M15.4642 16.0019C15.623 15.5366 15.6219 15.0505 15.4877 14.6069L17.669 10.2448C17.886 9.81066 17.7102 9.28268 17.276 9.06564C16.8421 8.84844 16.314 9.02445 16.0968 9.45869L14.2365 13.1789C13.1245 12.6252 11.4352 11.7853 10.0743 11.1085C8.80805 10.4788 7.90441 9.91414 6.3627 9.91414H5.58609C6.78656 9.91414 7.85795 9.3616 8.55926 8.49687C9.0944 7.83887 9.41438 7.00021 9.41438 6.08633C9.41438 3.97168 7.70051 2.25781 5.58563 2.25781C3.47168 2.25781 1.75781 3.97168 1.75781 6.08633C1.75781 7.10187 2.15332 8.02473 2.79855 8.70957C3.49617 9.45137 4.48699 9.91414 5.58539 9.91437C4.11275 9.91437 2.67311 10.511 1.6374 11.5495C0.581543 12.6026 0 14.0056 0 15.5002V18.3245C0 19.5403 0.789434 20.5747 1.88279 20.9428V25.8555C1.88279 26.341 2.27625 26.7344 2.7617 26.7344H8.41002C8.89547 26.7344 9.28893 26.341 9.28893 25.8555V15.9805C10.1223 16.3971 11.2171 16.9443 12.1028 17.3874C12.7238 17.6978 13.4493 17.7257 14.0964 17.4625C14.7416 17.197 15.2389 16.6685 15.4607 16.0123L15.4642 16.0019Z"
                            fill="#570861"
                          />
                        </svg>
                      </span>
                      Class Options
                    </a>
                   : null  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*end*/}
    </div>
  );
};

export default CommonSection;
