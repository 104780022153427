import { toast } from "react-toastify";
import dayjs from 'dayjs';
// import 'react-toastify/dist/ReactToastify.css';

const TOAST_SUCCESS = (message) => {
  toast.dismiss();
  return toast.success(message);
};

const TOAST_INFO = (message) => {
  return toast.info(message);
};

const TOAST_ERROR = (message) => {
  toast.dismiss();
  return toast.error(message);
};

const TOAST_WARNING = (message) => {
  return toast.warning(message);
};


// const convertToBase64 = async (file) => {
//   console.log(file)
//   console.log('video/mp4',file.type.includes("video"))

//     if (file.type.includes("video"))  return URL.createObjectURL(file);

//     return new Promise((resolve, reject) => {
//       const fileReader = new FileReader();
//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };
//       fileReader.onerror = reject;
//       file && fileReader.readAsDataURL(file);
//     });
//   };

// const convertToBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     if (file.type.includes("video")) {
//       const videoElement = document.createElement("video");
//       videoElement.src = URL.createObjectURL(file);

//       videoElement.onloadedmetadata = () => {
//         if (videoElement.duration <= 30) {
//           const fileReader = new FileReader();
//           fileReader.onload = () => {
//             resolve(fileReader.result);
//           };
//           fileReader.onerror = reject;
//           fileReader.readAsDataURL(file);
//         } else {
//           toast.error("Video duration must be 30 seconds or less.");
//           URL.revokeObjectURL(videoElement.src);
//           // reject(new Error("Video duration exceeds 30 seconds."));
//           reject(null);


//         }
//       };
//     } else {
//       const fileReader = new FileReader();
//       fileReader.onload = () => {
//         resolve(fileReader.result);
//       };
//       fileReader.onerror = reject;
//       fileReader.readAsDataURL(file);
//     }
//   });
// };
const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (file?.type?.includes("video")) {
      const videoElement = document.createElement("video");
      videoElement.src = URL.createObjectURL(file);

      videoElement.onloadedmetadata = () => {
        try {
          if (videoElement.duration <= 1800) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(file);
          } else {
            toast.error("Video duration must be 30 minutes or less.");
            URL.revokeObjectURL(videoElement.src);
            // reject(new Error("Video duration exceeds 30 seconds."));
          }
        } catch (error) {
          toast.error("Error processing video.");
          // reject(error);
        }
      };
    } else {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsDataURL(file);
    }
  });
};

const formatDate = (date, format) => {
  return dayjs(date).format(format);
};

const NoDataComponent = () => (
  <div style={{ color: '#570861', fontSize: '18px', textAlign: 'center', padding: '20px' }}>
    Data Not Found
  </div>
);

// timezoneUtils.js
const getTimezoneAbbreviation = (timezone) => {
  return dayjs().tz(timezone).format('z');
};


export {
  TOAST_SUCCESS,
  TOAST_INFO,
  TOAST_ERROR,
  TOAST_WARNING,
  convertToBase64,
  formatDate,
  NoDataComponent,
  getTimezoneAbbreviation
};


