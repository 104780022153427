import React, { useState, useEffect, useRef } from 'react'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Helmet } from 'react-helmet'
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css";
import dayjs from 'dayjs';
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker";
import { country_list_class, main_category_list, price_converted, state_listing_classwise, subject_category_list } from '../../../api/apiHandler';
import { Link } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import moment from 'moment-timezone';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FilterBySearch from './tfilterBySearch';

export default function Search_Filter({ step, search, setSearch, classSize, setclassSize, classType, setclassType, classPrice, setclassPrice, classAge, setclassAge, classLength, setclassLength, classLanguage, setclassLanguage, selectedDay, setSelectedDay, setDateApplydata, DateApply, setDateApply, DateApplydata, SortData, setSortData, setselectedDaysSend, setStartTimeSend, setEndTimeSend, CategoriesData, setCategoriesData, subCategoriesData, setSubCategoriesData, CountryName, setCountryName, stateList, setstateList }) {
    console.log('classAge :', classAge);

    const currentDate = dayjs();
    // // Set the default value to the current year, month, and day
    // const defaultValue = {
    //     year: currentDate.year(),
    //     month: currentDate.month() + 1,  // Month in Day.js starts from 0
    //     day: currentDate.date(),
    // };

    const handleSelectedsize = (data) => {
        setclassSize(data)
    }
    const handleSelectedtype = (data) => {
        setclassType(data)
    }
    const handleSelectedPrice = (data) => {
        setclassPrice(data)
    }


    const handleSelectedAge = (data) => {
        if (classAge.includes(data)) {
            let filteredArray = classAge.filter(item => item !== data);
            setclassAge(filteredArray)
        } else {
            setclassAge(prevSelectSubject => [...new Set([...prevSelectSubject, data])]);
        }
    }

    const handleSelectedLength = (data) => {
        if (classLength.includes(data)) {
            let filteredArray = classLength.filter(item => item !== data);
            setclassLength(filteredArray)
        } else {
            setclassLength(prevSelectSubject => [...new Set([...prevSelectSubject, data])]);
        }
    }

    const handleSelectedLanguage = (data) => {
        if (classLanguage.includes(data)) {
            let filteredArray = classLanguage.filter(item => item !== data);
            setclassLanguage(filteredArray)
        } else {
            setclassLanguage(prevSelectSubject => [...new Set([...prevSelectSubject, data])]);
        }
    }

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpenDayTime, setIsDropdownOpenDayTime] = useState(false);
    const [selectedRadio, setRadioButtonCheck] = useState('Today');

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const dropdownRefDayTime = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRefDayTime.current && !dropdownRefDayTime.current.contains(event.target)) {
                setIsDropdownOpenDayTime(false);
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleDropdownToggle = () => {
        // alert(!isDropdownOpen)
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownToggleDayTime = () => {
        // alert(!isDropdownOpenDayTime)
        setIsDropdownOpenDayTime(!isDropdownOpenDayTime);
    };

    const currentDate7 = dayjs();

    // Set the default 'from' date 7 days ago from the current date
    const defaultFrom = {
        year: currentDate7.year(),
        month: currentDate7.month() + 1,  // Month in Day.js starts from 0
        day: currentDate7.date(),  // Subtract 7 days from the current date
    };

    // Set the default 'to' date as the current date
    const defaultTo = {
        year: currentDate7.year(),
        month: currentDate7.month() + 1,  // Month in Day.js starts from 0
        day: currentDate.date() + 7,
    };

    const defaultTo14 = {
        year: currentDate7.year(),
        month: currentDate7.month() + 1,  // Month in Day.js starts from 0
        day: currentDate.date() + 14,
    };

    var defaultRange = {
        from: defaultFrom,
        to: defaultTo,
    };

    var defaultRange14 = {
        from: defaultFrom,
        to: defaultTo14,
    };

    const defaultToupcoming = {
        year: 2099,  // Setting a distant future year
        month: 12,   // December
        day: 31,     // 31st day of December
    };

    var defaultRangeupcoming = {
        from: defaultFrom,
        to: defaultToupcoming,
    };

    const handleCalendarSelect = (day) => {
        var defaultRangeupcomingcustom = {
            from: day,
            to: day,
        };
        if (selectedRadio == "Today") {
            setSelectedDay(defaultRangeupcomingcustom);
        } else {
            setSelectedDay(day);
        }
        setRadioButtonCheck("Custom dates");

        // Do any other required actions when a date is selected
    };
    const handleRadio1days = () => {
        setRadioButtonCheck("Today");
        setSelectedDay(defaultFrom)
    }

    const handleRadio7days = () => {
        setRadioButtonCheck("Next 7 days");
        setSelectedDay(defaultRange)
    }

    const handleRadio14days = () => {
        setRadioButtonCheck("Next 14 days");
        setSelectedDay(defaultRange14)
    }

    const handleRadioupcomingdays = () => {
        setRadioButtonCheck("All upcoming dates");
        setSelectedDay(defaultRangeupcoming)
    }

    const handleRadioCustomdays = () => {
        setRadioButtonCheck("Custom dates");
        setSelectedDay(defaultRangeupcoming)
    }

    const [selectedDays, setselectedDays] = useState([]);
    const [weekdays, setWeekDays] = useState(['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']);
    const [weekends, setWeekEnds] = useState(['Saturday', 'Sunday']);
    const [isWeekdays, setIsWeekDays] = useState(false);
    const [isWeekends, setIsWeekEnds] = useState(false);

    const toggleDay = (day) => {
        let updatedDays = [];
        if (selectedDays.includes(day)) {
            updatedDays = selectedDays.filter(d => d !== day);
        } else {
            updatedDays = [...selectedDays, day];
        }
        setselectedDays(updatedDays);
        setIsWeekDays(weekdays.every(d => updatedDays.includes(d)));
        setIsWeekEnds(weekends.every(d => updatedDays.includes(d)));
    }

    const setWeekdayData = () => {
        setIsWeekDays(!isWeekdays);
        if (!isWeekdays) {
            var remainingDays = weekdays.filter(day => !selectedDays.includes(day));
            var mergedDays = [...selectedDays, ...remainingDays];
            setselectedDays(mergedDays);
        } else {
            var remainingDays = selectedDays.filter(day => !weekdays.includes(day));
            setselectedDays(remainingDays);
        }
    }

    const setWeekendData = () => {
        setIsWeekEnds(!isWeekends);
        setselectedDays(weekends);
        if (!isWeekends) {
            var remainingDays = weekends.filter(day => !selectedDays.includes(day));
            var mergedDays = [...selectedDays, ...remainingDays];
            setselectedDays(mergedDays);
        } else {
            var remainingDays = selectedDays.filter(day => !weekends.includes(day));
            setselectedDays(remainingDays);
        }
    }

    const sendData = () => {
        setDateApply(selectedRadio)
        setDateApplydata(selectedDay)
    }

    const [selectedTimeRange, setSelectedTimeRange] = useState("anytime");
    const [lowerValue, setLowerValue] = useState(0);
    const [upperValue, setUpperValue] = useState(32); // Set upper limit to 10:00 PM (32 represents 10 PM)

    const RadioData = (type, low, high) => {
        setSelectedTimeRange(type)
        setLowerValue(low)
        setUpperValue(high)
    }

    const LowerValueData = (value) => {
        // setSelectedTimeRange(type)
        setLowerValue(value)
        if (value == 0 && upperValue == 32) {
            setSelectedTimeRange("anytime")
        } else if (value == 0 && upperValue == 12) {
            setSelectedTimeRange("morning")
        } else if (value == 12 && upperValue == 20) {
            setSelectedTimeRange("afternoon")
        } else if (value == 20 && upperValue == 32) {
            setSelectedTimeRange("evening")
        } else {
            setSelectedTimeRange("")
        }
    }

    const UpperValueData = (value) => {
        setUpperValue(value)
        if (lowerValue == 0 && value == 32) {
            setSelectedTimeRange("anytime")
        } else if (lowerValue == 0 && value == 12) {
            setSelectedTimeRange("morning")
        } else if (lowerValue == 12 && value == 20) {
            setSelectedTimeRange("afternoon")
        } else if (lowerValue == 20 && value == 32) {
            setSelectedTimeRange("evening")
        } else {
            setSelectedTimeRange("")
        }
    }

    const [StartTime, setStartTime] = useState('06:00 AM');
    const [EndTime, setEndTime] = useState('10:00 PM');

    const convertToTime = (value, typeav) => {
        const totalMinutes = 360 + (value * 30);
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const displayHour = hours >= 12 ? (hours === 12 ? 12 : hours - 12) : (hours === 0 ? 12 : hours);
        const amPm = hours >= 12 ? 'PM' : 'AM';

        const formattedTime = `${String(displayHour).padStart(2, '0')}:${String(minutes).padStart(2, '0')} ${amPm}`;

        if (typeav === 'lower') {
            if (formattedTime !== StartTime) { // Check if the new time is different from the current state
                setStartTime(formattedTime); // Update state with functional update
            }
        } else if (typeav === 'upper') {
            if (formattedTime !== EndTime) { // Check if the new time is different from the current state
                setEndTime(formattedTime); // Update state with functional update
            }
        }
        return formattedTime;
    };

    const SortByFilter = (data) => {
        setSortData(data)
    }

    const sendDataDayorTime = () => {
        setselectedDaysSend(selectedDays)
        setStartTimeSend(StartTime)
        setEndTimeSend(EndTime)
    }





    const handleSearch = (val) => setSearch(val);

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver()
    });

    var [MainCatdata, setMainCatdata] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    var [SelectedCatID, setSelectedCatID] = useState([]);
    var [SelectedCategory, SetSelectedCategory] = useState([]);
    var [SelectedSubCat, setSelectedSubCat] = useState([]);
    var [SelectedSubCatErr, setSelectedSubCatErr] = useState(null);

    var [SelectedSubCatID, setSelectedSubCatID] = useState([]);
    var [SelectedSubCategory, SetSelectedSubCategory] = useState([]);

    const MainCategory = () => {
        main_category_list().then((resposnse) => {
            console.log('main_category_list :', resposnse);
            if (resposnse?.code == 1) {
                setMainCatdata(resposnse?.data)
                setSelectedCatID([resposnse?.data[0]?.category_id])
                SetSelectedCategory([resposnse?.data[0]?.category_name])
                setIsOpen(true)
            } else {
                 
                setMainCatdata([])
            }
        });
    }

    useEffect(() => {
        MainCategory();
    }, []);

    const SubCategoryList = () => {
        subject_category_list({ category_id: SelectedCatID }).then((resposnse) => {

            if (resposnse?.code == 1) {
                setSelectedSubCat(resposnse.data)
                if (SelectedSubCatID.length > 0) {
                    setSelectedSubCatErr('abcd')
                    // alert(SelectedSubCatErr)

                }
                // setSelectedSubCatID([resposnse.data[0].subject_category_id])
                // SetSelectedSubCategory([resposnse.data[0].subject_name])

                setIsOpen(true)

            } else {
                 
            }
        });
    }

    useEffect(() => {
        SubCategoryList()
    }, [SelectedCatID]);

    const handleItemClick = (id, cat_name) => {
        if (SelectedCatID != id) {
            setSelectedSubCat([])
            setSelectedSubCatID([])
            SetSelectedSubCategory([])
            setSubCategoriesData([])
        }

        setSelectedCatID(id)
        SetSelectedCategory(cat_name)
        setCategoriesData(cat_name)
    }


    var [subCategoriesDataId, setSubCategoriesDataId] = useState([])


    const handleSelectedIngredients = (Categories_data, subCatId) => {

        if (subCategoriesData.includes(Categories_data)) {
            let filteredArray = subCategoriesData.filter(item => item !== Categories_data);
            setSubCategoriesData(filteredArray)
        } else {
            setSubCategoriesData(prevSelectSubject => [...new Set([...prevSelectSubject, Categories_data])]);
        }


        if (subCategoriesDataId.includes(subCatId)) {
            let filteredArray = subCategoriesDataId.filter(item => item !== subCatId);
            setSubCategoriesDataId(filteredArray)
        } else {
            setSubCategoriesDataId(prevSelectSubject => [...new Set([...prevSelectSubject, subCatId])]);
        }
    }

    const [CountryList, setCountryList] = useState('');
    const [CountryId, setCountryId] = useState('');

    const CountryListing = () => {

        country_list_class().then((resposnse) => {

            // isLoader(false)

            if (resposnse?.code == 1) {
                setCountryList(resposnse?.data)
            } else {
                 
            }
        });
    }

    useEffect(() => {
        CountryListing();
    }, []);

    const [priceOptions, setPriceOptions] = useState([]);

    var [Statedata, setStatedata] = useState([]);

    const StateListing = () => {

        state_listing_classwise({ country_id: CountryId }).then((resposnse) => {

            if (resposnse?.code == 1) {
                setStatedata(resposnse.data)
            } else {
                setStatedata([])
            }
        });
    }

    const priceList = () => {
        price_converted().then((resposnse) => {
            console.log('resposnse :', resposnse);
            if (resposnse?.code == 1) {
                setPriceOptions(resposnse?.data?.prices);
                // setCountryCurrency(resposnse?.data?.currencies?.currency_code)
            } else {
                setPriceOptions([]);
            }
        });
    };

    useEffect(() => {
        StateListing();
        priceList();
    }, [CountryId, classPrice]);



    const ResetFilter = () => {
        setclassSize('')
        setclassType('')
        setclassPrice('')
        setclassAge('')
        setclassLength('')
        setclassLength('')
        setCountryName('')
        setstateList('')
        setDateApply('')
        setDateApplydata('')
        setStartTime('')
        setEndTime('')
        setSelectedTimeRange('')
        setSubCategoriesData('')
        setselectedDaysSend('')
        setStartTimeSend('')
        setEndTimeSend('')
        setclassLanguage('')
        setSearch('')

        setSubCategoriesDataId('')
        setselectedDays("")
        setIsWeekDays(false)
        setIsWeekEnds(false)
        setLowerValue(0)
        setUpperValue(32)
        setRadioButtonCheck("Today")
        setSelectedDay(defaultFrom)
    }

    const handleDayorTimeCancel = () => {
        setselectedDays("")
        setIsWeekDays(false)
        setIsWeekEnds(false)
        setLowerValue(0)
        setUpperValue(32)
        setIsDropdownOpenDayTime(false)
    }

    const handleDateCancel = () => {
        setRadioButtonCheck("Today")
        setSelectedDay(defaultFrom)
        setIsDropdownOpen(false)
    }

    return (
        <>
            <Helmet>
                {/* <script src="./assets/js/custom_calendar.js"></script> */}
                <style>
                    {`
                                    .drop_calendar.filter_box {
                                        min-width: 523px;
                                    }
                                    .Calendar__weekDays {
                                        color : #570861
                                    }
                                    .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
                                        background: #FFC924;
                                        color: #212529;
                                    }
                                    .Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
                                        color: #212529;
                                    }
                                    .Calendar__day.-selectedBetween {
                                        background: rgba(241, 241, 241, 0.65);
                                        color: #060706;
                                        border-radius: 0
                                    }
                                    .scrollable-content {
                                        max-height: 360px; /* Set your preferred max height */
                                        overflow-y: auto; /* Add vertical scroll */
                                        margin-right: -100px; /* Add right margin to the scrollbar */
                                        width: 290px;
                                      }
                                    .dropdown-menu.subjects_drp.filter_box {
                                        min-width: 496px;
                                    }
                                    .scrollable-content::-webkit-scrollbar {
                                        width: 5px; /* Width of the scrollbar */
                                      }
                                      
                                      .scrollable-content::-webkit-scrollbar-thumb {
                                        background-color: #570861; /* Color of the thumb */
                                        border-radius: 10px; /* Rounded corners for the thumb */
                                      }
                                      
                                      .scrollable-content::-webkit-scrollbar-track {
                                        background-color: #f0f0f0; /* Color of the track */
                                      }
                                      
                                      #inputPlaceholderBlack::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-moz-placeholder { /* Firefox 19+ */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-ms-input-placeholder { /* IE 10+ */
                                            color: #212529;
                                            }
                                            #inputPlaceholderBlack::-moz-placeholder { /* Firefox 18- */
                                            color: #212529;
                                            }

                                            .card {
                                                position: relative;
                                                display: flex;
                                                flex-direction: column;
                                                /* min-width: 0; */
                                                word-wrap: break-word;
                                                background-color: #fff;
                                                background-clip: border-box;
                                                border: 1px solid rgba(0,0,0,.125);
                                                border-radius: 0.25rem;
                                            }

                                            .plan-details {
                                                border: 1px solid #ffefbd;
                                                border-radius: 0.5em;
                                                cursor: pointer;
                                                height: 100%;
                                                padding: 14px;
                                                transition: border-color 0.2s ease-out;
                                            }
                                            .pb-5 {
                                                padding-bottom: 0rem!important;
                                            }
                                    `}
                </style>
            </Helmet>
            <div className="class_lsit_section">
                {/* <div className="search_box mb-4 position-relative">
                    <input
                        type="text"
                        name="search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Type any topic or teacher"
                        id="inputPlaceholderBlack"
                    />
                    <span className="ms-3 position-absolute">
                        <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                fill="#666666"
                                fillOpacity="0.4"
                            />
                        </svg>
                    </span>
                    <img
                        src="./assets/images/rec_icon.png"
                        onClick={handleSearchWithSpeech}
                        alt="rec_icon"
                        className="position-absolute rec_search_icon"
                    />
                </div> */}
                {/*start-search*/}
                {/* <div className="search_box mb-4 position-relative" >
                    <input type="text" name="search" value={searchTerm}
                        onChange={handleInputChange} placeholder="Type any topic or teacher" id="inputPlaceholderBlack"
                    />
                    <span className="ms-3 position-absolute">
                        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z" fill="#666666" fillOpacity="0.4" />
                        </svg>
                    </span>
                    <img src="./assets/images/rec_icon.png" onClick={listening ? stopListening : startListening} alt="rec_icon" className="position-absolute rec_search_icon" />
                </div> */}
                <FilterBySearch search={search} handleSearch={handleSearch} />
                {/* {error && <div>Error: {error}</div>}
                <div className="search_box mb-4 position-relative">
                    <input
                        type="text"
                        name="search"
                        value={searchTerm}
                        onChange={handleInputChange}
                        placeholder="Type any topic or teacher"
                        id="inputPlaceholderBlack"
                    />
                    <span className="ms-3 position-absolute">
                        <svg
                            width={18}
                            height={18}
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                fill="#666666"
                                fillOpacity="0.4"
                            />
                        </svg>
                    </span>
                    <img
                        src="./assets/images/rec_icon.png"
                        onClick={listening ? stopListening : startListening}
                        alt="rec_icon"
                        className="position-absolute rec_search_icon"
                    />
                </div> */}
                {/* <button onClick={handleSearch}>Search</button> */}
                {/*end-search*/}
            </div>
            {/*start-filter*/}
            <div className="fliter_list">
                <ul className="list-unstyled slider_filter_list d-flex flex-wrap align-items-center">
                    <li className="ms-2 mb-2 d-md-none d-inline-block">
                        <button className="btn border-purple rounded-cricle rounded-pill" type="button" data-bs-toggle="offcanvas" data-bs-target="#rightOffcanvas" aria-controls="rightOffcanvas">
                            Categories
                        </button>
                    </li>
                    <li className="ms-2 mb-2 d-md-inline-block d-none">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                Categories
                            </button>
                            <div className="dropdown-menu subjects_drp filter_box">
                                <div className="d-flex align-items-start">
                                    <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                        {/*Academic*/}
                                        {MainCatdata?.map((mainCat, index) => {
                                            return (
                                                <button className={`nav-link ${SelectedCatID == mainCat.category_id ? 'active' : ''}`} onClick={(e) => handleItemClick(mainCat.category_id, mainCat.category_name)} id="v-pills-Academic-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Academic" type="button" role="tab" aria-controls="v-pills-Academic" aria-selected="true">{mainCat.category_name}</button>
                                            );
                                        })}
                                    </div>
                                    <div className="tab-content" id="v-pills-tabContent">

                                        <div className="tab-pane fade show active" id="v-pills-Academic" role="tabpanel" aria-labelledby="v-pills-Academic-tab" tabIndex={0}>
                                            <div className="row">
                                                <ul className="col-md-12">
                                                    <div className="scrollable-content" >
                                                        {SelectedSubCat[0]?.category_name ? (
                                                            <>
                                                                {SelectedSubCat?.map((SubCat, index) => {
                                                                    return (
                                                                        <>
                                                                            <li className="headings"><span className="text-purple fw-600">{SubCat.category_name}</span></li>
                                                                            {SubCat.category?.map((SubCat1, index1) => {
                                                                                return (
                                                                                    <li>
                                                                                        <a>
                                                                                            <div className="form-check">
                                                                                                <input onChange={(e) => handleSelectedIngredients(SubCat1.subject_name, SubCat1.subject_category_id, e.target.checked)} checked={subCategoriesDataId.indexOf(SubCat1.subject_category_id) > -1} className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault_${index1}`} />
                                                                                                <label className="form-check-label" htmlFor={`flexCheckDefault_${index1}`}>{SubCat1.subject_name}</label>
                                                                                            </div>
                                                                                        </a>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ul>
                                                                    {SelectedSubCat?.map((SubCat, index) => {
                                                                        return (
                                                                            <>
                                                                                <li>
                                                                                    <a>
                                                                                        <div className="form-check">
                                                                                            <input onChange={(e) => handleSelectedIngredients(SubCat.subject_name, SubCat.subject_category_id, e.target.checked)} checked={subCategoriesDataId.indexOf(SubCat.subject_category_id) > -1} className="form-check-input" type="checkbox" defaultValue id={`flexCheckDefault_${index}`} />
                                                                                            <label className="form-check-label" htmlFor={`flexCheckDefault_${index}`}>{SubCat.subject_name}</label>
                                                                                        </div>
                                                                                    </a>
                                                                                </li>
                                                                            </>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </div>


                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {step <= 1 ?
                        <li className="ms-2 mb-2">
                            <div className="dropdown">
                                <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {step == 1 ? "Class" : "Club"} Type
                                </button>
                                <div className="dropdown-menu class_size_start filter_box">
                                    <h3 className="fs-5 mb-3">{step == 1 ? "Class" : "Club"} Type</h3>
                                    <form className action id>
                                        <div className="form-check">
                                            <input className="form-check-input" checked={classSize === "Online"} onChange={(e) => handleSelectedsize("Online", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault1" defaultChecked />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Online
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" checked={classSize === "In-Person"} onChange={(e) => handleSelectedsize("In-Person", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                In-Person
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" checked={classSize === "Offline Course"} onChange={(e) => handleSelectedsize("Offline Course", e.target.check)} type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                Offline Course
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </li> : <></>
                    }
                    {step != 3 ?
                        <>
                            <li className="ms-2 mb-2">
                                <div className={`dropdown ${isDropdownOpenDayTime ? 'show' : ''}`} ref={dropdownRefDayTime}>
                                    <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" data-bs-auto-close="outside" type="button" onClick={handleDropdownToggleDayTime}
                                        aria-expanded={isDropdownOpenDayTime} >
                                        Day Or Time
                                    </button>


                                    <div className={`dropdown-menu drop_calendar filter_box ${isDropdownOpenDayTime ? 'show' : ''}`}>
                                        <h3 className="mb-3 poppins">Day of the week</h3>
                                        <ul className="days_box d-flex list-unstyled">
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Sunday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Sunday')}
                                                    style={selectedDays.includes('Sunday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Su</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Monday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Monday')}
                                                    style={selectedDays.includes('Monday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Mo</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Tuesday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Tuesday')}
                                                    style={selectedDays.includes('Tuesday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Tu</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Wednesday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Wednesday')}
                                                    style={selectedDays.includes('Wednesday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >We</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Thursday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Thursday')}
                                                    style={selectedDays.includes('Thursday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Th</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Friday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Friday')}
                                                    style={selectedDays.includes('Friday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Fr</a>
                                            </li>
                                            <li>
                                                <a
                                                    className={selectedDays.includes('Saturday') ? 'active' : ''}
                                                    onClick={() => toggleDay('Saturday')}
                                                    style={selectedDays.includes('Saturday') ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}}
                                                >Sa</a>
                                            </li>
                                        </ul>


                                        <ul className="days_box d-flex list-unstyled">
                                            <li>
                                                <a className={isWeekdays ? 'active' : ''} style={isWeekdays ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}} onClick={setWeekdayData}>Weekdays</a>
                                            </li>
                                            <li>
                                                <a className={isWeekends ? 'active' : ''} style={isWeekends ? { borderColor: '#570861', backgroundColor: '#570861', color: 'white' } : {}} onClick={setWeekendData}>Weekends</a>
                                            </li>
                                        </ul>
                                        <h3 className="mb-3 poppins">Time of day (Eastern)</h3>
                                        <div className="d-md-flex mb-3">
                                            <label className="card">
                                                <input name="plan" className="radio" type="radio" checked={selectedTimeRange === "anytime"}
                                                    onChange={() => RadioData("anytime", 0, 32)} />
                                                <span className="plan-details">
                                                    <span className="d-block mb-2">
                                                        <svg aria-hidden="true" width={18} focusable="false" data-prefix="far" data-icon="cloud-rainbow" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-cloud-rainbow outschool-1smue61"><path fill="currentColor" d="M560.6 48.01C569.3 47.64 576 40.89 576 32.26V16.13c0-8.1-7.625-16.49-16.62-16.12c-140.1 5.1-260.9 87.25-323.5 204.2C220 196.4 202.4 192 184 192c-64 0-116.4 50.25-119.8 113.4C25.62 322.4 0 360.5 0 404C0 463.5 48.38 512 108 512h296C463.6 512 512 463.5 512 404c0-38.5-20.38-71.87-50.75-91.12c23.63-30.62 59-51.1 99.63-56.12C569.3 255.8 576 249.3 576 240.8V224.8c0-9.25-7.75-16.1-16.75-16.12c-61 5.25-113.9 39.12-145.5 87.87c-1-.125-2-.375-3-.5c-5.625-17.75-16-33.12-29.38-45.25c41.25-55.1 105.8-93.87 179.4-98.5C569.3 151.8 576 145.1 576 136.5V120.5c0-9.125-7.625-16.75-16.75-16.25c-91 5.25-170.6 52.1-220 123.5C330.5 225.3 321.4 223.8 312 223.8c-12 0-23.75 2.5-35 6.5C331.2 126.5 436.9 53.64 560.6 48.01zM312 272c28.62 0 52.38 21.75 55.25 50.37l2.25 21.62H404c33.13 0 60 26.87 60 59.1s-26.88 59.1-60 59.1h-296c-33.12 0-60-26.87-60-59.1c0-27.1 19.12-51.1 46.38-58.25l20.87-4.875L112.4 316C112.2 314.8 112 313.4 112 312c0-39.75 32.25-71.1 72-71.1c25.25 0 48.25 13.12 61.38 35.12l13.38 22.12l21-14.1C289.4 275.6 300.5 272 312 272z" /></svg>
                                                    </span>
                                                    <span className="plan-type d-block">Anytime</span>
                                                    <span className="font-14" style={{ "width": "90px", "display": "block" }}>6AM-10PM</span>
                                                </span>
                                            </label>
                                            <label className="card">
                                                <input name="plan" className="radio" type="radio" checked={selectedTimeRange === "morning"}
                                                    onChange={() => RadioData("morning", 0, 12)} />
                                                <span className="plan-details" aria-hidden="true">
                                                    <span className="d-block mb-2">
                                                        <svg aria-hidden="true" width={18} focusable="false" data-prefix="far" data-icon="sunrise" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-sunrise outschool-18iryjl"><path fill="currentColor" d="M551.1 463.1L491.5 464l29.38-44.75c7.375-11.12 8.75-25.25 3.625-37.75c-5.25-12.38-16.12-21.5-29.25-24.25l-70.75-14.5L410 272c-2.75-13.25-11.75-24.25-24.25-29.38c-12.5-5.25-26.62-3.75-37.63 3.625L287.8 286L227.6 246.1c-22.5-14.88-56.5-.875-61.88 25.62l-14.5 70.88L80.63 357c-13.38 2.75-24.12 11.75-29.38 24.25c-5 12.5-3.75 26.5 3.75 37.75L84.75 464l-60.75-.0065C10.8 463.1 0 474.8 0 487.1S10.8 512 24 512H551.1C565.2 512 576 501.2 576 487.1S565.2 463.1 551.1 463.1zM234.6 464c4.625-15.62 16-29 32.13-35.75c28.5-11.75 61.5 1.875 73.25 30.38c.75 1.75 1.125 3.625 1.625 5.375H234.6zM434 464h-43.25c-1.25-8-3.125-16-6.375-23.75c-21.88-53-82.88-78.25-135.9-56.38C213.8 398.3 191.1 429.4 185.8 464h-43.5l-40.88-62.13l90.88-18.62l18.5-90.88l77.38 51.25l77.25-51.13l18.75 90.88l90.75 18.75L434 464zM224.1 120.1L264 81.94V200C264 213.3 274.8 224 288 224s24-10.75 24-24V81.94l39.03 39.03C355.7 125.7 361.9 128 368 128s12.28-2.344 16.97-7.031c9.375-9.375 9.375-24.56 0-33.94l-80-80c-9.375-9.375-24.56-9.375-33.94 0l-80 80c-9.375 9.375-9.375 24.56 0 33.94S215.6 130.3 224.1 120.1z" /></svg>
                                                    </span>
                                                    <span className='d-block'>Morning</span>
                                                    <span className="font-14" style={{ "width": "90px", "display": "block" }}>6AM-12PM</span>
                                                </span>
                                            </label>
                                            <label className="card">
                                                <input name="plan" className="radio" type="radio" checked={selectedTimeRange === "afternoon"}
                                                    onChange={() => RadioData("afternoon", 12, 20)} />
                                                <span className="plan-details" aria-hidden="true">
                                                    <span className="d-block mb-2">
                                                        <svg aria-hidden="true" width={18} focusable="false" data-prefix="far" data-icon="sun" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-sun outschool-18iryjl"><path fill="currentColor" d="M505.2 324.8l-47.73-68.78l47.75-68.81c7.359-10.62 8.797-24.12 3.844-36.06c-4.969-11.94-15.52-20.44-28.22-22.72l-82.39-14.88l-14.89-82.41c-2.281-12.72-10.76-23.25-22.69-28.22c-11.97-4.936-25.42-3.498-36.12 3.844L256 54.49L187.2 6.709C176.5-.6016 163.1-2.039 151.1 2.896c-11.92 4.971-20.4 15.5-22.7 28.19l-14.89 82.44L31.15 128.4C18.42 130.7 7.854 139.2 2.9 151.2C-2.051 163.1-.5996 176.6 6.775 187.2l47.73 68.78l-47.75 68.81c-7.359 10.62-8.795 24.12-3.844 36.06c4.969 11.94 15.52 20.44 28.22 22.72l82.39 14.88l14.89 82.41c2.297 12.72 10.78 23.25 22.7 28.22c11.95 4.906 25.44 3.531 36.09-3.844L256 457.5l68.83 47.78C331.3 509.7 338.8 512 346.3 512c4.906 0 9.859-.9687 14.56-2.906c11.92-4.969 20.4-15.5 22.7-28.19l14.89-82.44l82.37-14.88c12.73-2.281 23.3-10.78 28.25-22.75C514.1 348.9 512.6 335.4 505.2 324.8zM456.8 339.2l-99.61 18l-18 99.63L256 399.1L172.8 456.8l-18-99.63l-99.61-18L112.9 255.1L55.23 172.8l99.61-18l18-99.63L256 112.9l83.15-57.75l18.02 99.66l99.61 18L399.1 255.1L456.8 339.2zM256 143.1c-61.85 0-111.1 50.14-111.1 111.1c0 61.85 50.15 111.1 111.1 111.1s111.1-50.14 111.1-111.1C367.1 194.1 317.8 143.1 256 143.1zM256 319.1c-35.28 0-63.99-28.71-63.99-63.99S220.7 192 256 192s63.99 28.71 63.99 63.1S291.3 319.1 256 319.1z" /></svg>
                                                    </span>
                                                    <span className="plan-type d-block">AfterNoon</span>
                                                    <span className="font-14" style={{ "width": "90px", "display": "block" }}>12PM-4PM</span>
                                                </span>
                                            </label>
                                            <label className="card">
                                                <input name="plan" className="radio" type="radio" checked={selectedTimeRange === "evening"}
                                                    onChange={() => RadioData("evening", 20, 32)} />
                                                <span className="plan-details" aria-hidden="true">
                                                    <span className="d-block mb-2">
                                                        <svg aria-hidden="true" width={18} focusable="false" data-prefix="far" data-icon="bus-school" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="svg-inline--fa fa-bus-school outschool-18iryjl"><path fill="currentColor" d="M176 336C176 353.7 161.7 368 144 368C126.3 368 112 353.7 112 336C112 318.3 126.3 304 144 304C161.7 304 176 318.3 176 336zM400 336C400 353.7 385.7 368 368 368C350.3 368 336 353.7 336 336C336 318.3 350.3 304 368 304C385.7 304 400 318.3 400 336zM464 94.98V128H480C497.7 128 512 142.3 512 160V224C512 241.7 497.7 256 480 256H467C484.1 274.7 496 300.1 496 328V384C496 413.8 475.6 438.9 448 445.1V488C448 501.3 437.3 512 424 512C410.7 512 400 501.3 400 488V448H112V488C112 501.3 101.3 512 88 512C74.75 512 64 501.3 64 488V445.1C36.4 438.9 16 413.8 16 384V328C16 300.1 27.02 274.7 44.95 256H32C14.33 256 0 241.7 0 224V160C0 142.3 14.33 128 32 128H48V94.98C48 86.18 50.01 74.43 58.41 64.42C82.89 35.23 145.9 0 256 0C373.7 0 430.9 35.09 454.3 64.96C461.1 74.8 464 86.1 464 94.98zM96 96H180.3C185.8 86.44 196.2 80 208 80H304C315.8 80 326.2 86.44 331.7 96H416V94.98C416 94.54 415.1 94.19 415.9 93.93L415.9 93.87C404.2 79.44 363.5 48 255.1 48C156.9 48 109.6 79.11 96.02 94.3C96.01 94.48 95.1 94.71 95.1 94.98L96 96zM96 144V226.8C103.7 224.1 111.7 224 120 224H232V144H96zM416 144H280V224H392C400.3 224 408.3 224.1 416 226.8V144zM120 272C89.07 272 64 297.1 64 328V384C64 392.8 71.16 400 80 400H432C440.8 400 448 392.8 448 384V328C448 297.1 422.9 272 392 272H120z" /></svg>
                                                    </span>
                                                    <span className="plan-type d-block">Evening</span>
                                                    <span className="font-14" style={{ "width": "90px", "display": "block" }}>4PM-10PM</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div id="time-range" className="pb-5">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="slider-time">{convertToTime(lowerValue, "lower")}</span>
                                                <span className="slider-time2">{convertToTime(upperValue, "upper")}</span>
                                            </div>
                                            <div className="multi-range">
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={32} // Adjusted max value to represent up to 10:00 PM
                                                    value={lowerValue}
                                                    onChange={(e) => LowerValueData(e.target.value)}
                                                    step={1}
                                                />
                                                <input
                                                    type="range"
                                                    min={0}
                                                    max={32} // Adjusted max value to represent up to 10:00 PM
                                                    value={upperValue}
                                                    onChange={(e) => UpperValueData(e.target.value)}
                                                    step={1}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className='row'>
                                            <div className='col-lg-2'>
                                                <button className="btn" style={{ backgroundColor: '#570861', color: 'white' }} onClick={() => handleDayorTimeCancel()} type="button">Cancel</button>
                                            </div>
                                            <div className='col-lg-2 offset-lg-8'>
                                                <button className="btn" style={{ backgroundColor: '#570861', color: 'white' }} onClick={() => { setIsDropdownOpenDayTime(false); sendDataDayorTime(); }} type="button">Apply</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="ms-2 mb-2">
                                <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`} ref={dropdownRef}>

                                    <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" data-bs-auto-close="outside" type="button" onClick={handleDropdownToggle}
                                        aria-expanded={isDropdownOpen}>
                                        Date
                                    </button>
                                    <div className={`dropdown-menu drop_calendar filter_box ${isDropdownOpen ? 'show' : ''}`}>
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <form>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onClick={(e) => handleRadio1days(e)} checked={selectedRadio === "Today"} name="exampleRadios" id="exampleRadios1" defaultValue="option1" defaultChecked />
                                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                                            Today
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onClick={(e) => handleRadio7days(e)} checked={selectedRadio === "Next 7 days"} name="exampleRadios" id="exampleRadios2" defaultValue="option2" />
                                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                                            Next 7 days
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onClick={(e) => handleRadio14days(e)} checked={selectedRadio === "Next 14 days"} name="exampleRadios" id="exampleRadios3" defaultValue="option3" />
                                                        <label className="form-check-label" htmlFor="exampleRadios3">
                                                            Next 14 days
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onClick={(e) => handleRadioupcomingdays(e)} checked={selectedRadio === "All upcoming dates"} name="exampleRadios" id="exampleRadios4" defaultValue="option5" />
                                                        <label className="form-check-label" htmlFor="exampleRadios4">
                                                            All upcoming dates
                                                        </label>
                                                    </div>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" onClick={(e) => handleRadioCustomdays(e)} checked={selectedRadio === "Custom dates"} name="exampleRadios" id="exampleRadios5" defaultValue="option5" />
                                                        <label className="form-check-label" htmlFor="exampleRadios5">
                                                            Custom dates
                                                        </label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="wrappers">

                                                    <Calendar
                                                        value={selectedDay}
                                                        onChange={(day) => {
                                                            handleCalendarSelect(day);
                                                            // Close the dropdown if needed
                                                            setIsDropdownOpen(true);
                                                        }}
                                                        shouldHighlightWeekends
                                                    />

                                                </div>


                                            </div>

                                        </div>
                                        <hr />
                                        <div className='row'>
                                            <div className='col-lg-2'>
                                                <button className="btn" style={{ backgroundColor: '#570861', color: 'white' }} onClick={() => handleDateCancel()} type="button">Cancel</button>
                                            </div>
                                            <div className='col-lg-2 offset-lg-8'>
                                                <button className="btn" style={{ backgroundColor: '#570861', color: 'white' }} onClick={() => { setIsDropdownOpen(false); sendData(); }} type="button">Apply</button>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </li> </> : <></>
                    }
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Size
                            </button>
                            <div className="dropdown-menu class_size_start filter_box">
                                {/* <h3 className="fs-5 mb-3"> {step == 1 ? "Class" : "Club"} Size</h3> */}
                                <h3 className="fs-5 mb-3">
                                    {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Size
                                </h3>

                                <form className action id>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" checked={classType == ""} onChange={(e) => handleSelectedtype("", e.target.check)} name="flexRadioDefault" id="flexRadioDefaultAny" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultAny">
                                            Any
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" checked={classType == "private"} onChange={(e) => handleSelectedtype("private", e.target.check)} name="flexRadioDefault" id="flexRadioDefaultPrivate" />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultPrivate">
                                            1-on-1 Private Lesson
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" checked={classType == "group"} onChange={(e) => handleSelectedtype("group", e.target.check)} name="flexRadioDefault" id="flexRadioDefaultGroup" />
                                        <label className="form-check-label" htmlFor="flexRadioDefaultGroup">
                                            Group Lesson
                                        </label>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button
                                className="btn border-purple rounded-cricle rounded-pill dropdown-toggle"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Price Range
                            </button>
                            <div className="dropdown-menu price_per_class filter_box">
                                <h3 className="fs-5 mb-3">Price per class</h3>
                                <form className="action" id="priceFilterForm">
                                    {/* Default "Any" option */}
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            checked={classPrice === ""}
                                            onChange={() => handleSelectedPrice("")}
                                            name="flexRadioDefault"
                                            id="any1"
                                            defaultChecked
                                        />
                                        <label className="form-check-label" htmlFor="any1">
                                            Any
                                        </label>
                                    </div>

                                    {/* Dynamically render options based on response data */}
                                    {priceOptions?.map((price, index) => (
                                        <div className="form-check" key={index}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                checked={classPrice === String(price)}
                                                onChange={() => handleSelectedPrice(String(price))}
                                                name="flexRadioDefault"
                                                id={`flexRadioDefault${price}`}
                                            />
                                            <label className="form-check-label" htmlFor={`flexRadioDefault${price}`}>
                                                {price} or less
                                            </label>
                                        </div>
                                    ))}

                                    {/* Option for more than the max value */}
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            checked={classPrice === `${Math.max(...priceOptions)}_more`}
                                            onChange={() => handleSelectedPrice(`${Math.max(...priceOptions)}_more`)}
                                            name="flexRadioDefault"
                                            id={`flexRadioDefaultMoreThan${Math.max(...priceOptions)}`}
                                        />
                                        <label className="form-check-label" htmlFor={`flexRadioDefaultMoreThan${Math.max(...priceOptions)}`}>
                                            More than {Math.max(...priceOptions)}
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                Age Range
                            </button>
                            <div className="dropdown-menu subjects_drp filter_box">
                                <h3 className="fs-5 mb-3">Age Range</h3>
                                <form className action id>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("3", e.target.check)} defaultValue id="3" checked={classAge.indexOf("3") > -1} />
                                                <label className="form-check-label" htmlFor={3}>
                                                    3
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("4", e.target.check)} defaultValue id="4" checked={classAge.indexOf("4") > -1} />
                                                <label className="form-check-label" htmlFor={4}>
                                                    4
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    onChange={(e) => handleSelectedAge("5", e.target.checked)}
                                                    id="5"
                                                    checked={classAge.indexOf("5") > -1}
                                                />
                                                <label className="form-check-label" htmlFor={5}>
                                                    5
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("6", e.target.check)} defaultValue id="6" checked={classAge.indexOf("6") > -1} />
                                                <label className="form-check-label" htmlFor={6}>
                                                    6
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("7", e.target.check)} defaultValue id="7" checked={classAge.indexOf("7") > -1} />
                                                <label className="form-check-label" htmlFor={7}>
                                                    7
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("8", e.target.check)} defaultValue id="8" checked={classAge.indexOf("8") > -1} />
                                                <label className="form-check-label" htmlFor={8}>
                                                    8
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("9", e.target.check)} defaultValue id="9" checked={classAge.indexOf("9") > -1} />
                                                <label className="form-check-label" htmlFor={9}>
                                                    9
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("10", e.target.check)} defaultValue id="10" checked={classAge.indexOf("10") > -1} />
                                                <label className="form-check-label" htmlFor={10}>
                                                    10
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("11", e.target.check)} defaultValue id="11" checked={classAge.indexOf("11") > -1} />
                                                <label className="form-check-label" htmlFor={11}>
                                                    11
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("12", e.target.check)} defaultValue id="12" checked={classAge.indexOf("12") > -1} />
                                                <label className="form-check-label" htmlFor={12}>
                                                    12
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("13", e.target.check)} defaultValue id="13" checked={classAge.indexOf("13") > -1} />
                                                <label className="form-check-label" htmlFor={13}>
                                                    13
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("14", e.target.check)} defaultValue id="14" checked={classAge.indexOf("14") > -1} />
                                                <label className="form-check-label" htmlFor={14}>
                                                    14
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("15", e.target.check)} defaultValue id="15" checked={classAge.indexOf("15") > -1} />
                                                <label className="form-check-label" htmlFor={15}>
                                                    15
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("16", e.target.check)} defaultValue id="16" checked={classAge.indexOf("16") > -1} />
                                                <label className="form-check-label" htmlFor={16}>
                                                    16
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("17", e.target.check)} defaultValue id="17" checked={classAge.indexOf("17") > -1} />
                                                <label className="form-check-label" htmlFor={17}>
                                                    17
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("18", e.target.check)} defaultValue id="18" checked={classAge.indexOf("18") > -1} />
                                                <label className="form-check-label" htmlFor={18}>
                                                    18
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedAge("adults", e.target.check)} defaultValue id="adults" checked={classAge.indexOf("adults") > -1} />
                                                <label className="form-check-label" htmlFor={"adults"}>
                                                    Adults
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Length
                            </button>
                            <div className="dropdown-menu filter_box">
                                <h3 className="fs-5 mb-3"> {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Length</h3>
                                <form className action id>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLength("One-Time", e.target.check)} defaultValue id="One-Time" checked={classLength.indexOf("One-Time") > -1} />
                                        <label className="form-check-label" htmlFor="One-Time">
                                            one time
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLength("Multi-Day", e.target.check)} defaultValue id="Multi-Day" checked={classLength.indexOf("Multi-Day") > -1} />
                                        <label className="form-check-label" htmlFor="Multi-Day">
                                            multi day course
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLength("Ongoing Weekly", e.target.check)} defaultValue id="Ongoing Weekly" checked={classLength.indexOf("Ongoing Weekly") > -1} />
                                        <label className="form-check-label" htmlFor="Ongoing Weekly">
                                            ongoing weekly
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLength("Ongoing Monthly", e.target.check)} defaultValue id="Ongoing Monthly" checked={classLength.indexOf("Ongoing Monthly") > -1} />
                                        <label className="form-check-label" htmlFor="Ongoing Monthly">
                                            ongoing monthly
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLength("offline course", e.target.check)} defaultValue id="offline course" checked={classLength.indexOf("offline course") > -1} />
                                        <label className="form-check-label" htmlFor="offline course">
                                            offline course
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                                {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Language
                            </button>
                            <div className="dropdown-menu filter_box">
                                <h3 className="fs-5 mb-3"> {step === 1 ? "Class" : step === 2 ? "Club" : step === 3 ? "Quiz" : "Competition"} Language</h3>
                                <form className action id>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("English", e.target.check)} defaultValue id="English" checked={classLanguage.indexOf("English") > -1} />
                                        <label className="form-check-label" htmlFor="English">
                                            English
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("French", e.target.check)} defaultValue id="French" checked={classLanguage.indexOf("French") > -1} />
                                        <label className="form-check-label" htmlFor="French">
                                            French
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Mandarin", e.target.check)} defaultValue id="Mandarin" checked={classLanguage.indexOf("Mandarin") > -1} />
                                        <label className="form-check-label" htmlFor="Mandarin">
                                            Mandarin
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Cantonese", e.target.check)} defaultValue id="Cantonese" checked={classLanguage.indexOf("Cantonese") > -1} />
                                        <label className="form-check-label" htmlFor="Cantonese">
                                            Cantonese
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Spanish", e.target.check)} defaultValue id="Spanish" checked={classLanguage.indexOf("Spanish") > -1} />
                                        <label className="form-check-label" htmlFor="Spanish">
                                            Spanish
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Hindi", e.target.check)} defaultValue id="Hindi" checked={classLanguage.indexOf("Hindi") > -1} />
                                        <label className="form-check-label" htmlFor="Hindi">
                                            Hindi
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Japanese", e.target.check)} defaultValue id="Japanese" checked={classLanguage.indexOf("Japanese") > -1} />
                                        <label className="form-check-label" htmlFor="Japanese">
                                            Japanese
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" onChange={(e) => handleSelectedLanguage("Arabic", e.target.check)} defaultValue id="Arabic" checked={classLanguage.indexOf("Arabic") > -1} />
                                        <label className="form-check-label" htmlFor="Arabic">
                                            Arabic
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Country
                            </button>
                            <div className="dropdown-menu price_per_class filter_box">
                                <h3 className="fs-5 mb-3">Country</h3>
                                <form className action id>
                                    {CountryList?.length > 0 ?
                                        CountryList?.map((class_data, index) => {
                                            return (
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" checked={CountryName == class_data.country_name} onChange={() => { setCountryName(class_data.country_name); setCountryId(class_data.country_id) }} id={class_data.country_id} />
                                                    <label className="form-check-label" htmlFor={class_data.country_id}>
                                                        {class_data.country_name}
                                                    </label>
                                                </div>
                                            )
                                        }) :
                                        <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": "15px" }}>Data Not Found</h4>
                                    }

                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-2 mb-2">
                        <div className="dropdown">
                            <button className="btn border-purple rounded-cricle rounded-pill dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                In-Person
                            </button>
                            <div className="dropdown-menu price_per_class filter_box">
                                <h3 className="fs-5 mb-3">States/ Provinces</h3>
                                <form className action id>
                                    {Statedata?.length > 0 ?
                                        Statedata?.map((state_list, index) => {
                                            return (
                                                <div className="form-check">
                                                    <input className="form-check-input" checked={stateList == state_list.state_name} onChange={() => setstateList(state_list.state_name)} type="radio" name="flexRadioDefault" id="any" />
                                                    <label className="form-check-label" htmlFor="any">
                                                        {state_list.state_name}
                                                    </label>
                                                </div>
                                            )
                                        }) :
                                        <h4 className="d-flex justify-content-center" style={{ "color": "#570861", "fontSize": "15px" }}>Data Not Found</h4>
                                    }
                                </form>
                            </div>
                        </div>
                    </li>
                    <li className="ms-4"><Link onClick={() => ResetFilter()} className="text-purple fw-500">Reset Filters</Link></li>
                </ul>
            </div>
            {/*end-filter*/}

            <div className="sort_by d-flex align-items-center justify-content-end my-4">
                <label>Sort By:</label>

                <span className='custom-select'>
                    <select onChange={(e) => SortByFilter(e.target.value)} value={SortData} className="border-purple bg-purple fs-6 rounded-cricle rounded-pill ms-3">
                        <option value="">Select Option</option>
                        <option value="Relevance">Relevance</option>
                        <option value="New Classes">New Classes</option>
                        <option value="Starting soon">Starting soon</option>
                    </select>
                </span>

            </div>
        </>
    )
}
