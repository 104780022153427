import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { classroom_edit_lesson, classroom_lesson_list } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onChange }, ref) => {
    const quillRef = React.useRef();

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.focus();
        }
    }, []);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (quillRef.current) {
                quillRef.current.focus();
            }
        },
        getEditor: () => {
            return quillRef.current?.getEditor();
        }
    }));

    return (
        <ReactQuill
            ref={quillRef}
            value={value}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
});

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];

export default function OEditLesson({ isLoader }) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const location = useLocation();
    const navigate = useNavigate();

    var edit_id = location?.state;
    console.log('edit_id :', edit_id);

    const schema = yup.object().shape({
        lesson_topic: yup.string().required('Please enter lesson topic')
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Lesson topic cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),
        hours: yup.string().test(
            'duration-validation',
            'Duration cannot be 0 hours and 0 minutes',
            function (value) {
                const minutes = this.parent.minutes;
                return !(value === "0" && minutes === "0");
            }
        ),
        minutes: yup.string().test(
            'duration-validation',
            'Duration cannot be 0 hours and 0 minutes',
            function (value) {
                const hours = this.parent.hours;
                return !(hours === "0" && value === "0");
            }
        )
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [lessonData, setLessonData] = useState([]);
    const [slot, setSlot] = useState([])
    const [attachmentData, setAttachmentData] = useState([]);
    const [attachmentFiles, setAttachmentFiles] = useState({});
    var [classTitleLength, setclassTitleLength] = useState(0);
    var [classHoursData, setClassHoursData] = useState('0');
    var [classMinutesData, setClassMinutesData] = useState('0');

    const classroom_lessonlist = () => {
        isLoader(true)
        classroom_lesson_list({ classroom_id: edit_id }).then((response) => {
            console.log('classroom_lesson_list :', response);
            if (response?.code == 1) {
                isLoader(false)
                const lessonData = response?.data?.[0];
                const descriptions = lessonData?.lessiondata.map((item) => ({
                    description: item.description,
                    attachment: item.attachments[0]?.attachment ?? null,
                })) ?? [];

                setLessonDetails(descriptions);
                setSlot(response?.data[0]?.slot_id)
                setclassTitleLength(response?.data?.[0]?.lession_topic.length);
                setClassHoursData(response?.data?.[0]?.duration?.split(' ')?.[0]);
                setClassMinutesData(response?.data?.[0]?.duration?.split(' ')?.[2]);
                reset({
                    hours: response?.data?.[0]?.duration?.split(' ')?.[0],
                    minutes: response?.data?.[0]?.duration?.split(' ')?.[2],
                    attachments_data: response?.data?.[0]?.attachments
                });
                setLessonData(response?.data?.[0]);
                setAttachmentData(response?.data?.[0]?.lessiondata);
            } else {
                isLoader(false)
            }
        });
    }

    let classTitleData = (e) => {
        setclassTitleLength(0);
    }

    useEffect(() => {
        classroom_lessonlist();
    }, []);

    const [lessonDetails, setLessonDetails] = useState([{ description: '', attachment: null }]);

    const handleAddDescription = () => {
        setAttachmentData([...attachmentData, { description: '', attachment: null }]);
    };

    const [profileImageState, setProfileImageState] = useState('');
    const [AttachmentData1, setAttachmentData1] = useState({});

    const getVideoDuration = (file) => {
        return new Promise((resolve, reject) => {
            if (!(file instanceof File)) {
                reject(new Error("Invalid file object provided for video duration check"));
                return;
            }

            const video = document.createElement("video");
            video.preload = "metadata";

            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src); // Free resources
                resolve(video.duration);
            };

            video.onerror = () => {
                reject(new Error("Failed to load video metadata"));
            };

            video.src = URL.createObjectURL(file);
        });
    }

    const handleImageChange = async (image, Attachment_Index, fileIndex, type) => {
        if (!image) {
            TOAST_ERROR("No file selected. Please select a valid file.");
            return;
        }

        if (type === "video" && image instanceof File) {
            try {
                const videoDuration = await getVideoDuration(image);
                if (videoDuration > 1800) {
                    TOAST_ERROR("Please upload a video with a duration of 30 minutes or less.");
                    return;
                }
            } catch (error) {
                TOAST_ERROR(error.message || "Error processing video file.");
                return;
            }
        }

        isLoader(true);
        try {
            const image_name = await uploadImageOnAWS(image, "lesson_attachments");
            const fileName = image.name;

            // Update attachmentFiles state
            setAttachmentFiles((prev) => {
                const updatedFiles = { ...prev };
                updatedFiles[`attachment_${Attachment_Index}`] = {
                    [fileIndex]: {
                        attachments: image_name,
                        attachment_type: type,
                        file_name: fileName,
                    },
                };
                return updatedFiles;
            });

            // Update the attachmentData for this index
            setAttachmentData((prev) => {
                const updatedAttachmentData = [...prev];
                if (!updatedAttachmentData[Attachment_Index]) {
                    updatedAttachmentData[Attachment_Index] = { attachments: [] };
                }
                updatedAttachmentData[Attachment_Index].attachments = [
                    ...updatedAttachmentData[Attachment_Index].attachments,
                    {
                        attachment: image_name,
                        attachment_type: type,
                    },
                ];
                return updatedAttachmentData;
            });

            // Update form value for validation
            setValue(`attachments_${Attachment_Index}_${fileIndex}`, image_name);

            clearErrors("attachments_data");
            isLoader(false);
        } catch (error) {
            isLoader(false);
            TOAST_ERROR("Error uploading attachment");
        }
    };

    const handleDeleteAttachment = (index, attachmentIndex) => {
        const updatedAttachmentData = [...attachmentData];
        updatedAttachmentData[index].attachments.splice(attachmentIndex, 1); // Remove the attachment
        setAttachmentData(updatedAttachmentData); // Update state
    };

    let onSubmit = async (data) => {
        isLoader(true)
        let edit_lesson = {
            classroom_id: edit_id.toString(),
            lession_topic: data.lesson_topic,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
        }
        var description_arr = [];
        attachmentData.map((lesson, index) => {
            var temp_obj = {};
            var temp_str = "lesson_description_" + index;
            temp_obj.description = data[temp_str];
            temp_obj.des_id = lesson.des_id === undefined ? "0" : lesson.des_id.toString();

            // Get attachments for this lesson
            const lessonAttachments = lesson.attachments || [];
            temp_obj.attachments = lessonAttachments.map(attachment => {
                const attachmentName = attachment.attachment.split('/').pop(); // Extract the file name from the URL
                return {
                    attachment: attachmentName,  // Send only the file name
                    attachment_type: attachment.attachment_type,
                };
            });

            description_arr.push(temp_obj);
        });
        edit_lesson.lessiondata = description_arr;
        classroom_edit_lesson(edit_lesson).then((res) => {
            if (res?.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(res?.message);
                navigate('/organization-tech-detail', {
                    state: { id: lessonData.activity_id, class: lessonData.activity_type, type_class: "Online", slot: slot }
                });
            } else {
                isLoader(false)
                TOAST_ERROR(res?.message);
            }
        });
        reset();
    }

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className
                                        ="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span></a>
                                <div className="row">
                                <div className="col-xl-8">
                                    <div className="mb-4">
                                        <h2 className="mb-2 poppins fw-bold">Edit Lesson Information</h2>
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                        <div className="form-group mb-4">
                                            <label className="form-label">Add Lesson Topic</label>
                                            <div className="position-relative class_title">
                                                <input type="text" {...register("lesson_topic")} maxLength={50} name="lesson_topic" className="form-control" placeholder id defaultValue={lessonData.lession_topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                                <span className="position-absolute word_count">
                                                    {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                                </span>
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.lesson_topic?.message}</span></p>
                                            </div>
                                        </div>
                                        {attachmentData?.map((des_data, index) => {
                                            return (<>
                                                <div key={index}>
                                                    {console.log('des_data :', des_data)}
                                                    <div className="form-group mb-4">
                                                        <label className="form-label">Add Lesson Description</label>
                                                        <Controller
                                                            name={`lesson_description_${index}`}
                                                            control={control}
                                                            defaultValue={des_data.description || ""}
                                                            render={({ field }) => {
                                                                console.log("Default Value:", des_data.description); // Log default value
                                                                console.log("Field Value:", field.value); // Log field value
                                                                return (
                                                                    <Editor
                                                                        value={field.value || ""}
                                                                        onChange={field.onChange}
                                                                    />
                                                                );
                                                            }}
                                                        />

                                                        {errors[`lesson_description_${index}`] && (
                                                            <p className='mt-2 font-bold col-red'>
                                                                <span style={{ color: 'red' }}>
                                                                    {errors[`lesson_description_${index}`]?.message}
                                                                </span>
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="form-group mb-4 attach_box">
                                                        <label className="form-label">Add Attachments</label>
                                                        <div className="position-relative">
                                                            <div className="textarea-wrapper">
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={`attachments_${index}`}
                                                                    rows={7}
                                                                    // value={
                                                                    //     attachmentData[index]?.attachments?.map((item, idx) => (
                                                                    //         item.attachment ? item.attachment.split('/').pop() : ''
                                                                    //     )).join(', ') || ''
                                                                    // }
                                                                    readOnly
                                                                />
                                                                <div className="attachments-preview p-2">
                                                                    {attachmentData[index]?.attachments?.map((item, idx) => (
                                                                        <span key={idx} className="attachment-name">
                                                                            {item.attachment.split('/').pop()}
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                onClick={() => handleDeleteAttachment(index, idx)}
                                                                            >
                                                                            </button>
                                                                        </span>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className="att_icon_list position-absolute">
                                                                <ul className="d-flex align-items-center justify-content-end">

                                                                    <li>
                                                                        <label htmlFor={`attach1_${index}`}>
                                                                            <a>
                                                                                <img src="./assets/images/attach1.png" alt="attach1" />
                                                                            </a>
                                                                        </label>
                                                                        <input
                                                                            type="file"
                                                                            id={`attach1_${index}`}
                                                                            onInput={(e) => {
                                                                                const file = e.target.files[0];
                                                                                const fileType = file.type.startsWith('image')
                                                                                    ? 'image'
                                                                                    : file.type.startsWith('video')
                                                                                        ? 'video'
                                                                                        : file.type === 'application/pdf'
                                                                                            ? 'pdf'
                                                                                            : file.type ===
                                                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                                                ? 'docx'
                                                                                                : 'xlsx'; // For Excel
                                                                                handleImageChange(file, index, 0, fileType);
                                                                            }}
                                                                            {...register("attachments")}
                                                                            accept="image/*,video/*,application/pdf,.docx,.xlsx"
                                                                            className="file-input"
                                                                            style={{ visibility: 'hidden', display: 'none' }}
                                                                        />
                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach2_${index}`}>
                                                                            <a >
                                                                                <img src="./assets/images/attach2.png" alt="attach2" />
                                                                            </a>
                                                                        </label>
                                                                        <input type="file" id={`attach2_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach3_${index}`}>
                                                                            <a>
                                                                                <img src="./assets/images/attach3.png" alt="attach3" />
                                                                            </a>
                                                                            <input type="file" id={`attach3_${index}`}  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], index, 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                        </label>
                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach4_${index}`}>
                                                                            <a>
                                                                                <img src="./assets/images/attach4.png" alt="attach4" />
                                                                            </a>
                                                                            <input type="file" id={`attach4_${index}`} {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], index, 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                        </label>
                                                                    </li>
                                                                    <li>

                                                                        <label htmlFor={`attach5_${index}`}>

                                                                            <a >
                                                                                <img src="./assets/images/attach5.png" alt="attach5" />
                                                                            </a>
                                                                            <input type="file" id={`attach5_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                        </label>
                                                                    </li>
                                                                    <li>
                                                                        <label htmlFor={`attach6_${index}`}>

                                                                            <a >
                                                                                <img src="./assets/images/attach6.png" alt="attach6" />
                                                                            </a>
                                                                            <input type="file" id={`attach6_${index}`} {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], index, 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-3">
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                        <div className="d-flex justify-content-end mt-3">
                                            <a className="text-decoration-underline text-purple" onClick={handleAddDescription}>
                                                + Add another Description
                                            </a>
                                        </div>

                                        <h3 className="mb-3">Lesson duration</h3>
                                        <div className="d-md-flex mb-4 align-items-center">
                                            <p className="fs-6  mb-md-0 mb-3">Expected Time Spent On Lesson Hours </p>
                                            <div className="d-md-flex ms-3">
                                                <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                    <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                        <option selected={classHoursData === '0' ? 'selected' : ''} value={0}>0 </option>
                                                        <option selected={classHoursData === '1' ? 'selected' : ''} value={1}>1 </option>
                                                        <option selected={classHoursData === '2' ? 'selected' : ''} value={2}>2 </option>
                                                        <option selected={classHoursData === '3' ? 'selected' : ''} value={3}>3 </option>
                                                        <option selected={classHoursData === '4' ? 'selected' : ''} value={4}>4 </option>
                                                    </select>
                                                    <span className="ms-3">Minutes</span>
                                                </div>
                                                <div className="ms-md-3">
                                                    <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                        <option selected={classMinutesData === '0' ? 'selected' : null} value={0}>0</option>
                                                        <option selected={classMinutesData === '10' ? 'selected' : null} value={10}>10</option>
                                                        <option selected={classMinutesData === '15' ? 'selected' : null} value={15}>15</option>
                                                        <option selected={classMinutesData === '20' ? 'selected' : null} value={20}>20</option>
                                                        <option selected={classMinutesData === '25' ? 'selected' : null} value={25}>25</option>
                                                        <option selected={classMinutesData === '30' ? 'selected' : null} value={30}>30</option>
                                                        <option selected={classMinutesData === '35' ? 'selected' : null} value={35}>35</option>
                                                        <option selected={classMinutesData === '40' ? 'selected' : null} value={40}>40</option>
                                                        <option selected={classMinutesData === '45' ? 'selected' : null} value={45}>45</option>
                                                        <option selected={classMinutesData === '50' ? 'selected' : null} value={50}>50</option>
                                                        <option selected={classMinutesData === '55' ? 'selected' : null} value={55}>55</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        {(errors.hours && errors.minutes) && (
                                            <p className="text-danger mb-3">Duration cannot be 0 hours and 0 minutes</p>
                                        )}
                                        <div className="enviorment_div mb-5 align-items-center d-md-flex">
                                            <div className="env_img">
                                                <img src="./assets/images/enviorment.png" alt="enviorment" />
                                            </div>
                                            <div className="env_text">
                                                <p className="text-500">Help save the environment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                                            </div>
                                        </div>
                                        <div className="button">
                                            <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                Edit Lesson
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
