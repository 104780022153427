import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
// import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import Footer_dashboard from '../../components/Footer_dashboard';
import Header from '../../components/Header'
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import { add_email_for_newsletter, expand_your_career, explore_new_classes, favorites, follow_unfollow, landing_latest_news, main_category_list, meet_our_global_team, sub_category_request_class, subject_category_list, under_25_dollor_cls, upcoming_events, within_24_hours_cls } from '../../api/apiHandler';
import { Link, useNavigate } from 'react-router-dom';
import { TOAST_ERROR, TOAST_SUCCESS } from '../../utils/common.service';
import SubscribeModal1 from '../Parents/Pinc/SubscribeModals/SubscribeModal1';
import SubscribeModal2 from '../Parents/Pinc/SubscribeModals/SubscribeModal2';
import SubscribeModal3 from '../Parents/Pinc/SubscribeModals/SubscribeModal3';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import CommonClassSubject from './commonClassSubject';
import FilterBySearch from '../Parents/SearchClass/filterBySearch';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { encryptURL } from '../../api/apiClient';
import CommonVideoPlayer from '../../utils/fdshjfh';



export default function Home({isLoader}) {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // voice search
  //start
  const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  };


  const [search, setSearch] = useState("");
  const handleSearch = (val) => setSearch(val);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const debouncedSearch = useDebounce(search, 1000);

  useEffect(() => {
    handleSearch(transcript);
  }, [transcript]);

  useEffect(() => {
    if (debouncedSearch && !listening) {
      redirectToSearch();
    }
  }, [debouncedSearch, listening]);

  const encryptedURL = encryptURL(`/search_class?query=${search}`);
  // return

  const redirectToSearch = () => {
    navigate(encryptedURL);

    // navigate(`/search_class?query=${encodeURIComponent(search)}`);
  };

  //end


  var token = localStorage.getItem('Ttoken') || localStorage.getItem("PAtoken");
  var type = localStorage.getItem('type') || localStorage.getItem("userType")


  // const [data, setData] = useState([]);
  const [subscribeModal1Data, setSubscribeModal1Data] = useState(null);
  const [totalSubscriptionAmount, setTotalSubscriptionAmount] = useState("");


  const handleSubscribe = (data) => {

    if (token && type) {
      setSubscribeModal1Data(data)
    } else {
      navigate('/login')
    }

  }



  const handleFollow = () => {
    if (!token) {
      navigate("/login")
    }

  }

  const navigateToQuiz = () => {

    if (token && (type == "above_18" || type == "below_18" || type == "learner" || type == "after_school")) {
      localStorage.setItem("Search_Tab", 3)
      navigate("/search_class");
    }
    else if (token && type == "teacher") {
      // alert('test')

      navigate("/view-details-quiz");

    }
    else if (token && type == "organization") {

      navigate("/quiz_info");

    }
    else {
      navigate("/login")
    }

  }

  const navigateToClub = (searchtab) => {

    if (token && (type == "above_18" || type == "below_18" || type == "learner" || type == "after_school")) {
      localStorage.setItem("Search_Tab", searchtab)
      navigate("/search_class");
    }
    else if (token && type == "teacher") {
      // alert('test')

      navigate("/teacher_searchClass");

    }
    else if (token && type == "organization") {

      navigate("/organization-search-classes");

    }
    else {
      navigate("/login")
    }

  }

  const navigateToJoin = () => {
    navigate('/sign_in_step1')
  }

  const navigateToRequestClass = () => {

    if (token && (type == "above_18" || type == "below_18" || type == "after_school")) {
      navigate("request_class_form")
    }
    else {
      navigate("/login")
    }

  }





  let settings = {
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  let reviews = {
    autoplay: true,
    arrows: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    
  };
  let career = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  var explore_classes = {
    // dots: false,
    // arrows: true,
    // infinite: true,
    // speed: 300,
    // slidesToShow: 4,
    // slidesToScroll: 1,
    // autoplay: false,
    // autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    infinite: false, // Change this to false by default
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  var within_24_hours_classes = {
    dots: false,
    arrows: true,
    infinite: false, // Change this to false by default
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  var under_25_dollor_classes = {
    dots: false,
    arrows: true,
    infinite: false, // Change this to false by default
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  var expand_carrer_opportuninty = {
    // dots: false,
    // arrows: true,
    // infinite: true,
    // speed: 300,
    // slidesToShow: 4,
    // slidesToScroll: 1,
    // autoplay: false,
    // autoplaySpeed: 3000,
    dots: false,
    arrows: true,
    infinite: false, // Change this to false by default
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,

        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,


        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,

        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,

        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  let competitions = {
    dots: false,
    arrows: true,
    infinite: false, // Change this to false by default
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  let news = {
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: false,
    // infinite: true,
    // autoplaySpeed: 2000, 
    adaptiveHeight: false,
    // arrows:true, 
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,

        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  };
  var meet_our_team = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };



  var [exploreClass, setexploreClass] = useState([]);
  var [hoursClass, sethoursClass] = useState([]);
  var [under25Dollor, setunder25Dollor] = useState([]);
  var [expandCareer, setexpandCareer] = useState([]);
  var [upcomingEvent, setupcomingEvent] = useState([]);
  var [meetTeam, setmeetTeam] = useState([]);
  var [latestNews, setlatestNews] = useState([]);
  const [showEmail, setShowEmail] = useState(false);


  // handle header category 

  const handleHeaderData = (data) => {
    console.log('data :', data);
    if (!token) {
      localStorage.setItem('isLandingPage', true)
    }
    const queryParams = new URLSearchParams({
      main_category: data.SelectedCategory,
      sub_category: data.subCategory,
      is_landingpage: true
    }).toString();



    const encryptedURL = encryptURL(`/search_class?${queryParams}`);
    console.log('encryptedURL :', encryptedURL);
    // return
    navigate(encryptedURL);
  };



  let schema = yup.object().shape({
    email: yup.string().required("Please enter email id").email(),


  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  let onSubmit = (data) => {
    add_email_for_newsletter({ email: data?.email }).then((resposnse) => {
      if (resposnse?.code == 1) {
        TOAST_SUCCESS(resposnse?.message);
        setShowEmail(false)
        reset();
      } else {
        TOAST_ERROR(resposnse?.message)
      }
    });

    console.log(data, 'transfer');
  }

  const handleShow = () => {
    setShowEmail(!showEmail);
  };




  // console.log('exploreClass :', exploreClass);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  //for explore new classes

  const exploreNewClass = () => {

    explore_new_classes().then((resposnse) => {
      console.log("new classes", resposnse)

      if (resposnse?.code == 1) {
        const shuffledData = shuffleArray(resposnse?.data);
        setexploreClass(shuffledData);
      } else {
        setexploreClass([]);
        ;
      }
    });
  }



  //Within 24 hourse classes 

  const Within24HoursClasses = () => {

    within_24_hours_cls().then((resposnse) => {
      console.log("24hours classes", resposnse)

      if (resposnse?.code == 1) {
        const shuffledData = shuffleArray(resposnse?.data);

        sethoursClass(shuffledData)
      } else {
        sethoursClass([]);
        ;
      }
    });
  }

  //Classes under 25 dollor 

  const classesUnder25Dollor = () => {

    under_25_dollor_cls().then((resposnse) => {
      console.log("under 25 dollor", resposnse)

      if (resposnse?.code == 1) {
        const shuffledData = shuffleArray(resposnse?.data);

        setunder25Dollor(shuffledData)
      } else {
        setunder25Dollor([]);
        ;
      }
    });
  }

  //Exapnd your career 

  const expandYourCareer = () => {

    expand_your_career().then((resposnse) => {
      console.log("expand your career", resposnse)

      if (resposnse?.code == 1) {
        const shuffledData = shuffleArray(resposnse?.data);

        setexpandCareer(shuffledData)
      } else {
        setexpandCareer([]);
        ;
      }
    });
  }

  //Upcoming events and compititon

  const UpcomingEventAndCompitition = () => {

    upcoming_events().then((resposnse) => {
      console.log("upcomming event", resposnse)

      if (resposnse?.code == 1) {
        const shuffledData = shuffleArray(resposnse?.data);

        setupcomingEvent(shuffledData)
      } else {
        setupcomingEvent([]);
        ;
      }
    });
  }


  //Meet our globle team

  const MeetOurGlobalTeam = () => {

    meet_our_global_team().then((resposnse) => {
      console.log("details", resposnse)

      if (resposnse?.code == 1) {
        setmeetTeam(resposnse?.data)
      } else {
        setmeetTeam([]);
        ;
      }
    });
  }

  //Latest news

  const LatestNews = () => {

    landing_latest_news().then((resposnse) => {
      console.log("latest news", resposnse)

      if (resposnse?.code == 1) {
        setlatestNews(resposnse?.data)
      } else {
        setlatestNews([]);
        ;
      }
    });
  }


  const handleFavorite = (id) => {
    favorites({ classes_id: id, activity_type: "class" }).then(({ code, message }) => {
      if (code == 1) {
        exploreNewClass();
        Within24HoursClasses();
        classesUnder25Dollor();
        expandYourCareer();
        UpcomingEventAndCompitition();
      } else TOAST_ERROR(message);
    });
  };


  const handleFollowUnfollow = async (teacher_id) => {
    try {
      const { code, message } = await follow_unfollow({ teacher_id });
      if (code == 1) {
        exploreNewClass();
        Within24HoursClasses();
        classesUnder25Dollor();
        expandYourCareer();
        UpcomingEventAndCompitition();
      } else TOAST_ERROR(message);
    } catch (err) {
      TOAST_ERROR(err.message);
    }
  };


  const handleNewsDetails = (contentId) => {
    // console.log(contentId);
    const content = latestNews.find(data => data.id === contentId);
    // console.log('content data',content);
    if (content) {
      navigate('/news_letter_details', { state: content });
    }
  }

  useEffect(() => {
    LatestNews();
    MeetOurGlobalTeam();
    UpcomingEventAndCompitition();
    expandYourCareer();
    classesUnder25Dollor();
    Within24HoursClasses();
    exploreNewClass();
    window.scrollTo(0, 0)

  }, []);

  return (
    <>

      <Header sendDataToHome={handleHeaderData} isLoader={isLoader}/>

      <main>
        {/*start-video-section*/}
        <section className="video_section ">
          <div className="container-fluid px-lg-5">
            <div className="video_box" style={{ display: 'none' }}>
              {/* <video id="video" className loop width="100%" height controls="controls" poster="./assets/images/video_image.png">
                <source src="./assets/video/videoplayback.mp4" type="video/mp4" />
              </video> */}


              <video id="video" className="position-static rounded-3" loop width="100%" src={'https://studentscholars.s3.amazonaws.com/video/StudentScholars - Homepage -FINAL-FEB 24 (1).mp4'} controls />

            </div>
            <div className="row justify-content-end position-relative">
              <div className="col-lg-2">
                <div className="gobal_learn">
                  <div className="mb-4">
                    <h1 className>The Global Learning<br /> Marketplace For<br /> <span className="text-purple">All Ages</span></h1>
                  </div>
                  {/* <div className="inter_btn position-relative"> */}
                  {/* <p className="text-gray">Tell me the class you are Interested in ?</p>
                    <span className="position-absolute">
                      <img src="./assets/images/rec_icon.png" alt="rec_icon" />
                    </span> */}
                  <div className="class_lsit_section">
                    <div className="search_box mb-4 position-relative">
                      <input
                        onChange={(e) => handleSearch(e.target.value)}
                        value={search}
                        type="text"
                        placeholder={listening ? "Listening..." : "Type any topic or teacher"}
                      />
                      <span className="ms-3 position-absolute">
                        <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                            fill="#666666"
                            fillOpacity="0.4"
                          />
                        </svg>
                      </span>
                      <img
                        onClick={listening ? SpeechRecognition.stopListening : SpeechRecognition.startListening}
                        src="./assets/images/rec_icon.png"
                        alt="rec_icon"
                        className="position-absolute rec_search_icon pointer"
                      />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className="col-lg-10 order-md-last order-first">
                <div className="image_box">
                  <img src="./assets/images/video_image.png" alt="video_images" id="play-video" className="img-fluid w-100" />
                </div>
              </div>
            </div>
            <span className="rec_icon position-absolute" id="stop-video" style={{ display: 'none' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
              </svg>
            </span>
          </div>
        </section>
        {/*end-video-section*/}
        {/*start-teamobject-section*/}

        <section className="team_subject_sec d-lg-block d-none bg-light-gray slick_silder_button">
          <div className="container-fluid px-lg-5">
            <div className="single-item">
              <Slider {...settings}>

                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">We are a global team of subject-qualified teachers and specialized organizations offering a wide range of high-demand programs to all ages</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team1.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Our team encourages and supports passions, creativity, and interests in a wide range of high demand subjects.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team2.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">We will help you to achieve educational and professional goals and objectives.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team3.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Our programs promote initiative, leadership, teamwork and time management.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team4.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Our team helps to develop skills, knowledge, and talents to adapt to the rapidly changing global environment.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team5.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">We offer interactive, fun, live online classes for any age</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team6.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Explore your creativity and passions by joining wide range of programs offered for all ages</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team8.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Have fun learning and be engaged in activities with learners from all over the world</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team7.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Learn from professional qualified instructors from all over the world.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team10.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Join clubs and competitions of wide range of subjects offered by instructors from all over the world</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team9.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">We offer customized classes for individual learners and small groups</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team11.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">You are in charge of selecting the class as per your requirement.</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-side">
                  <div className="row ">
                    <div className="col-lg-6 mb-lg-0 mb-5 left position-relative">
                      <img src="./assets/images/gobal_teams/gobal_team12.png" alt="gobal_team" className="img-fluid w-100 pe-lg-5" />
                      <img src="./assets/images/shap/dots_shap.png" alt="shap" className="left_shap" />
                    </div>
                    <div className="col-lg-6 right d-flex justify-content-center align-items-center position-relative">
                      <div className="goal_ctn">
                        <p className="text-capitalize">Join health and wellness classes to help you achieve your fitness goals</p>
                      </div>
                      <div className="botto_shap">
                        <img src="./assets/images/shap/dots_shap.png" alt="shap" />
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>
        {/*end-teamobject-section*/}
        {/*start-need-help-find-class-teacher*/}
        <section className="find-cls-teacher bg-purple d-lg-block d-none">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 mb-lg-0 mb-5 left">
                <h2 className="fw-bold text-white text-capitalize mb-4 poppins">Need help with finding a class or teacher?</h2>
                <p className="fw-bold text-white mb-3">Teachers that match your requirements will contact you.</p>
                <button className="btn-theme bg-yellow text-dark fw-600 d-inline-block" onClick={() => navigateToRequestClass()}>Request a class</button>
              </div>
              <div className="col-lg-5 right">
                <div className="video_boxs position-relative">
                  <video id="video" className loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                    <source src={'https://studentscholars.s3.amazonaws.com/video/Find a teacher.mp4'} type="video/mp4" />
                  </video>
                  <div className="height_qul d-md-block d-none  position-absolute">
                    <img src="./assets/images/height_edu.png" alt="height_edu" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end-need-help-find-class-teacher*/}
        {/*start-slider*/}
        <section className="explore_new_class padd-pt-100 padd-100 bg-light-gray slick_silder_button">
          <div className="container-fluid px-lg-5">
            <div className="title mb-4">
              <h2 className="text-capitalize">Explore New Classes</h2>
            </div>
            <div className="">
              {exploreClass.length <= 0 ? (
                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>No Classes Listed Yet, Please Check Again Later</h4>
              ) : (

                <Slider {...explore_classes}>
                  {exploreClass && exploreClass?.map((view_data, index) => {
                    return (
                      <div key={index} className>
                        <div className="single-box">
                          <div className="class_image_box">
                            <div className="class_img position-relative">
                              <div className="video-container">
                                {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                {/* <video id="video" className loop width="100%" controls="hide" >
                                  <source src={view_data.video_file} type="video/mp4" />
                                </video> */}
                                <video id="video" loop width="100%" src={view_data?.video_file} controls />

                              </div>
                              {token ? (
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                  <label></label>
                                </div>
                              ) : null}
                              <div className="d-flex bottom-info align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <Link to={token ? "/teacher-profile-details" : "/login"} state={view_data.teacher_id}>

                                    <div className="d-flex align-items-center">
                                      <img
                                        src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                        alt="user1"
                                      />
                                      <span className="tech_nm ms-2">{view_data.full_name}</span>

                                      {/* <span className="ms-2 det_flag_class">{view_data?.country_image}</span> */}
                                      <img
                                        src={view_data.country_image}
                                        alt={`${view_data.country_image
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-2 det_flag_class"
                                      />
                                    </div>
                                  </Link>
                                  {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                </div>
                                <div className="star_rat">
                                  <span>
                                    <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                    </svg>
                                  </span>
                                  <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="class_room_info">
                            <div className="tag mb-3">
                              <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                              {localStorage.getItem('Tid') != view_data?.teacher_id && token ?
                                <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                : <div></div>
                              }
                            </div>
                            <Link to={token && type === "above_18"
                              ? view_data.type === "In-Person"
                                ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                              : token && type === "below_18"
                                ? view_data.type === "In-Person"
                                  ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                  : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : token && type === "teacher"
                                  ? view_data.type === "In-Person"
                                    ? "/class_detail_onsite"
                                    : "/class_detail"
                                  : token && type === "organization"
                                    ? view_data.type === "In-Person"
                                      ? "/onsite-class-detail"
                                      : "/class-details"
                                    : "/login"} state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}>
                              <div className="class_title mb-4">
                                <p>{view_data?.title}</p>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                  <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                  <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                  <span className="fw-600">Monday To Friday</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>

                                  <span className="fw-600">{view_data?.currency_code} {view_data?.currency_symbol}{view_data?.price}</span>

                                </div>
                              </div>
                            </Link>
                            <div className="button">
                              <a style={{ cursor: 'pointer' }}
                                className={`fw-500 btn-theme d-block text-center ${type === 'learner' ? 'disabled' : ''}`}
                                data-bs-toggle={token && type && type !== 'learner' ? "modal" : ""}
                                data-bs-target={token && type && type !== 'learner' ? "#subscription_modal_1" : ""}
                                onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                              >
                                Subscribe
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </Slider>
              )}
            </div>
          </div>
        </section>
        {/*end-slider*/}
        {/*start-need-help-find-class-teacher-for-mobile-screen*/}
        <section className="find-cls-teacher bg-purple d-lg-none d-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 mb-lg-0 mb-5 left">
                <h2 className="fw-bold text-white text-capitalize mb-4 poppins">Need help with finding a class or teacher?</h2>
                <p className="fw-bold text-white mb-3">Teachers that match your requirements will contact you.</p>
                <a href="#" className="btn-theme bg-yellow text-dark fw-600 d-inline-block">Request a class</a>
              </div>
              <div className="col-lg-5 right">
                <div className="video_boxs position-relative">
                  <video id="video" className loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                    <source src={'https://studentscholars.s3.amazonaws.com/video/Find a teacher.mp4'} type="video/mp4" />
                  </video>
                  <div className="height_qul d-md-block d-none  position-absolute">
                    <img src="./assets/images/height_edu.png" alt="height_edu" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <CommonClassSubject />

        <section className="custom_banner padd-100 padd-pt-100 pt-lg-0 bg-light-gray">
          <div className="container">
            <div className="bg-light-yellow banne_inner_width">
              <div className="d-lg-flex align-items-center">
                <div className="left">
                  <div className="banner_img">
                    <img src="./assets/images/banner/quiz.png" alt="quiz" />
                  </div>
                </div>
                <div className="right">
                  <h2 className="mb-3 text-capitalize">Select A Category For Quiz</h2>
                  <p className="fw-500">Gain Access to Quizzes Offered For Any Subject or Age</p>
                  <div className="button mt-4">
                    <button className="btn-theme arrow bg-purple d-inline-block" onClick={() => navigateToQuiz()}>
                      Start Quiz Now
                      <span className="ms-2">
                        <svg width={30} height={26} viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M29.5205 11.8344L18.6864 1.00009C18.3772 0.690827 17.965 0.521072 17.5255 0.521072C17.0855 0.521072 16.6735 0.691071 16.3643 1.00009L15.3806 1.98399C15.0716 2.29277 14.9013 2.7052 14.9013 3.14495C14.9013 3.58446 15.0716 4.0108 15.3806 4.31958L21.701 10.6539H1.62072C0.71536 10.6539 0 11.3627 0 12.2683V13.6593C0 14.5649 0.71536 15.3451 1.62072 15.3451H21.7727L15.3808 21.7148C15.0718 22.0241 14.9016 22.4253 14.9016 22.865C14.9016 23.3043 15.0718 23.7114 15.3808 24.0204L16.3645 25.0011C16.6738 25.3104 17.0857 25.4789 17.5257 25.4789C17.9652 25.4789 18.3774 25.3082 18.6867 24.9989L29.5207 14.1649C29.8307 13.8546 30.0012 13.4405 30 13.0002C30.001 12.5585 29.8307 12.1441 29.5205 11.8344Z" fill="white" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end-banner*/}
        {/*start-slider*/}
        <section className="explore_new_class padd-100 bg-light-gray slick_silder_button d-lg-block d-none">
          <div className="container-fluid px-lg-5">
            <div className="title mb-4">
              <h2 className="text-capitalize">Join classes starting within 24 hours</h2>
            </div>
            <div className=" class_slider">
              {hoursClass.length <= 0 ? (
                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>No Classes Listed Yet, Please Check Again Later</h4>
              ) : (
                <Slider {...within_24_hours_classes}>
                  {hoursClass && hoursClass?.map((view_data, index) => {
                    return (
                      <div key={index} className>
                        <div className="single-box">
                          <div className="class_image_box">
                            <div className="class_img position-relative">
                              <div className="video-container">
                                {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                <video id="video" loop width="100%" src={view_data?.video_file} controls />


                              </div>
                              {token ? (
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                  <label></label>
                                </div>
                              ) : null}
                              <div className="d-flex bottom-info align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <Link to={token ? "/teacher-profile-details" : "/login"} state={view_data.teacher_id}>
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                        alt="user1"
                                      />
                                      <span className="tech_nm ms-2">{view_data.full_name}</span>
                                      <img
                                        src={view_data.country_image}
                                        alt={`${view_data.country_image
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-2 det_flag_class"
                                      />

                                    </div>
                                  </Link>
                                  {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                </div>
                                <div className="star_rat">
                                  <span>
                                    <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                    </svg>
                                  </span>
                                  <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="class_room_info">
                            <div className="tag mb-3">
                              <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                              {localStorage.getItem('Tid') != view_data?.teacher_id && token ?
                                <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                : <div></div>
                              }
                            </div>
                            <Link to={token && type === "above_18"
                              ? view_data.type === "In-Person"
                                ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                              : token && type === "below_18"
                                ? view_data.type === "In-Person"
                                  ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                  : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : token && type === "teacher"
                                  ? view_data.type === "In-Person"
                                    ? "/class_detail_onsite"
                                    : "/class_detail"
                                  : token && type === "organization"
                                    ? view_data.type === "In-Person"
                                      ? "/onsite-class-detail"
                                      : "/class-details"
                                    : "/login"} state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}>
                              <div className="class_title mb-4">
                                <p>{view_data?.title}</p>

                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                  <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                  <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                  <span className="fw-600">Monday To Friday</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                  <span className="fw-600">{view_data?.currency_code} {view_data?.currency_symbol}{view_data?.price}</span>

                                </div>
                              </div>
                            </Link>

                            <div className="button">
                              <a style={{ cursor: 'pointer' }}
                                className={`fw-500 btn-theme d-block text-center ${type === 'learner' ? 'disabled' : ''}`}
                                data-bs-toggle={token && type && type !== 'learner' ? "modal" : ""}
                                data-bs-target={token && type && type !== 'learner' ? "#subscription_modal_1" : ""}
                                onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                              >
                                Subscribe
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </Slider>

              )}
            </div>
          </div>
        </section>
        {/*end-slider*/}
        {/*start-banner-section*/}
        <section className="custom_banner padd-100 bg-light-gray">
          <div className="container">
            <div className="bg-light-green bdar_btm_green banne_inner_width">
              <div className="d-lg-flex align-items-center">
                <div className="left">
                  <div className="banner_img">
                    <img src="./assets/images/banner/requirement.png" alt="requirement" />
                  </div>
                </div>
                <div className="right">
                  <h2 className="mb-3 text-capitalize">Select a Club to fit your requirements</h2>
                  <p className="fw-500">Join A Speciality Club. Meet and Learn from People With Similar Interests From All over the world.</p>
                  <div className="button mt-4">
                    <button className="btn-theme bg-purple d-inline-block" onClick={() => navigateToClub(2)}>
                      Join Club
                      <span className="ms-2">
                        <svg width={30} height={26} viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M29.5205 11.8344L18.6864 1.00009C18.3772 0.690827 17.965 0.521072 17.5255 0.521072C17.0855 0.521072 16.6735 0.691071 16.3643 1.00009L15.3806 1.98399C15.0716 2.29277 14.9013 2.7052 14.9013 3.14495C14.9013 3.58446 15.0716 4.0108 15.3806 4.31958L21.701 10.6539H1.62072C0.71536 10.6539 0 11.3627 0 12.2683V13.6593C0 14.5649 0.71536 15.3451 1.62072 15.3451H21.7727L15.3808 21.7148C15.0718 22.0241 14.9016 22.4253 14.9016 22.865C14.9016 23.3043 15.0718 23.7114 15.3808 24.0204L16.3645 25.0011C16.6738 25.3104 17.0857 25.4789 17.5257 25.4789C17.9652 25.4789 18.3774 25.3082 18.6867 24.9989L29.5207 14.1649C29.8307 13.8546 30.0012 13.4405 30 13.0002C30.001 12.5585 29.8307 12.1441 29.5205 11.8344Z" fill="white" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end-banner*/}
        {/*start-slider*/}
        {/*under 25 dollor classes*/}
        <section className="explore_new_class padd-100 bg-light-gray slick_silder_button d-lg-block d-none">
          <div className="container-fluid px-lg-5">
            <div className="title mb-4">
              <h2 className="text-capitalize">Select classes that are under $25</h2>
            </div>
            <div className=" class_slider">
              {under25Dollor.length <= 0 ? (
                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>No Classes Listed Yet, Please Check Again Later</h4>
              ) : (
                <Slider {...under_25_dollor_classes}>
                  {under25Dollor && under25Dollor?.map((view_data, index) => {
                    return (
                      <div key={index} className>
                        <div className="single-box">
                          <div className="class_image_box">
                            <div className="class_img position-relative">
                              <div className="video-container">
                                {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                {/* <video id="video" className loop width="100%" controls="hide" >
                                  <source src={view_data.video_file} type="video/mp4" />
                                </video> */}
                                <video id="video" loop width="100%" src={view_data?.video_file} controls />

                              </div>
                              {token ? (
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                  <label></label>
                                </div>
                              ) : null}
                              <div className="d-flex bottom-info align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <Link to={token ? "/teacher-profile-details" : "/login"} state={view_data.teacher_id}>

                                    <div className="d-flex align-items-center">
                                      <img
                                        src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                        alt="user1"
                                      />
                                      <span className="tech_nm ms-2">{view_data.full_name}</span>
                                      <img
                                        src={view_data.country_image}
                                        alt={`${view_data.country_image
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-2 det_flag_class"
                                      />

                                    </div>
                                  </Link>
                                  {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                </div>
                                <div className="star_rat">
                                  <span>
                                    <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                    </svg>
                                  </span>
                                  <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                </div>
                              </div>
                              {/* <a href="#" className="play_btn">
                                <img src="./assets/images/play.png" alt="play" />
                              </a> */}
                            </div>
                          </div>
                          <div className="class_room_info">
                            <div className="tag mb-3">
                              <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                              {localStorage.getItem('Tid') != view_data?.teacher_id && token ?
                                <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                : <div></div>
                              }
                            </div>
                            <Link to={token && type === "above_18"
                              ? view_data.type === "In-Person"
                                ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                              : token && type === "below_18"
                                ? view_data.type === "In-Person"
                                  ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                  : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : token && type === "teacher"
                                  ? view_data.type === "In-Person"
                                    ? "/class_detail_onsite"
                                    : "/class_detail"
                                  : token && type === "organization"
                                    ? view_data.type === "In-Person"
                                      ? "/onsite-class-detail"
                                      : "/class-details"
                                    : "/login"} state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}>
                              <div className="class_title mb-4">
                                <p>{view_data?.title}</p>

                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                  <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                  <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                  <span className="fw-600">Monday To Friday</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                  <span className="fw-600">{view_data?.currency_code} {view_data?.currency_symbol}{view_data?.price}</span>

                                </div>
                              </div>
                            </Link>
                            <div className="button">
                              <a style={{ cursor: 'pointer' }}
                                className={`fw-500 btn-theme d-block text-center ${type === 'learner' ? 'disabled' : ''}`}
                                data-bs-toggle={token && type && type !== 'learner' ? "modal" : ""}
                                data-bs-target={token && type && type !== 'learner' ? "#subscription_modal_1" : ""}
                                onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                              >
                                Subscribe
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </Slider>
              )}
            </div>
          </div>
        </section>
        {/*end-slider*/}
        {/*start-banner-section*/}
        <section className="custom_banner padd-100 bg-light-gray">
          <div className="container">
            <div className="bg-purple bdar_btm_purple banne_inner_width">
              <div className="d-lg-flex align-items-center">
                <div className="left">
                  <div className="banner_img">
                    <img src="./assets/images/banner/requirement.png" alt="requirement" />
                  </div>
                </div>
                <div className="right">
                  <h2 className="mb-3 text-white text-capitalize poppins fw-600">Select a class to fit your requirements</h2>
                  <p className="fw-500 text-white">No contracts or upfront payments. Register for free to get started.</p>
                  <div className="button mt-4">
                    <button className="btn-theme text-dark fw-600 bg-yellow d-inline-block" onClick={() => navigateToClub(1)}>
                      Find My Class
                      <span className="ms-2">
                        <svg width={30} height={26} viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M29.5205 11.8344L18.6864 1.00009C18.3772 0.690827 17.965 0.521072 17.5255 0.521072C17.0855 0.521072 16.6735 0.691071 16.3643 1.00009L15.3806 1.98399C15.0716 2.29277 14.9013 2.7052 14.9013 3.14495C14.9013 3.58446 15.0716 4.0108 15.3806 4.31958L21.701 10.6539H1.62072C0.71536 10.6539 0 11.3627 0 12.2683V13.6593C0 14.5649 0.71536 15.3451 1.62072 15.3451H21.7727L15.3808 21.7148C15.0718 22.0241 14.9016 22.4253 14.9016 22.865C14.9016 23.3043 15.0718 23.7114 15.3808 24.0204L16.3645 25.0011C16.6738 25.3104 17.0857 25.4789 17.5257 25.4789C17.9652 25.4789 18.3774 25.3082 18.6867 24.9989L29.5207 14.1649C29.8307 13.8546 30.0012 13.4405 30 13.0002C30.001 12.5585 29.8307 12.1441 29.5205 11.8344Z" fill="black" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end-banner*/}
        {/*start-what-say*/}
        {/* <section className="what_say padd-100 bg-light-gray"> */}
        <section className="explore_new_class padd-pt-100 padd-100 bg-light-gray slick_silder_button d-lg-block d-none">

          <div className="container">


            <div className="row justify-content-center text-center mb-5">
              <div className="col-lg-8">
                <h2 className="text-capitalize">What Students and Parents Say About Our Programs</h2>
              </div>
            </div>
            <Slider {...reviews}>
              <div className="center-div row justify-content-center text-center mb-5">
                <div className="col-lg-12">
                  <div className="stars mb-4 ">
                    <img src="./assets/images/center_div_img1.png" alt="center_div_img2" className="mb-4" />
                    {/* <h3 class="d-block poppins fw-600">Stacy</h3> */}
                  </div>
                  <div className="stars mb-3">
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#FFD700"
                          viewBox="0 0 24 24"
                          className="star-icon"
                        >
                          <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.828 1.439 8.211-7.375-3.967-7.375 3.967 1.439-8.211-6.064-5.828 8.332-1.151z" />
                        </svg>
                      ))}
                  </div>
                  <p>“Since my children started StudentScholars, they have started to ‘love’ learning. The teachers are passionate and knowledgeable about what they teach and it makes a difference.“ - Stacy, USA</p>
                </div>
              </div>
              <div className="center-div row justify-content-center text-center">
                <div className="col-lg-12">
                  <div className="stars mb-4">
                    <img src="./assets/images/center_div_img2.png" alt="center_div_img2" className="mb-4" />
                    {/* <h3 class="d-block poppins fw-600">Bernard</h3> */}
                  </div>
                  <div className="stars mb-3">
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#FFD700"
                          viewBox="0 0 24 24"
                          className="star-icon"
                        >
                          <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.828 1.439 8.211-7.375-3.967-7.375 3.967 1.439-8.211-6.064-5.828 8.332-1.151z" />
                        </svg>
                      ))}
                  </div>
                  <p>“Before I migrated to Canada in 2021, I wanted to secure a job there. StudentScholars career coach helped me to update my resume and gave me suggestions on where I could apply. It helped me boost my confidence by 100%. I found a job within the first week.I highly recommend the professional coaches” - Bernard, Singapore</p>
                </div>
              </div>
           
              <div className="center-div row justify-content-center text-center">
                <div className="col-lg-12">
                  <div className="stars mb-4">
                    <img src="./assets/images/review3.jpg" alt="center_div_img2" className="mb-4 round-image" />
                    {/* <h3 class="d-block poppins fw-600">Bernard</h3> */}
                  </div>
                  <div className="stars mb-3">
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#FFD700"
                          viewBox="0 0 24 24"
                          className="star-icon"
                        >
                          <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.828 1.439 8.211-7.375-3.967-7.375 3.967 1.439-8.211-6.064-5.828 8.332-1.151z" />
                        </svg>
                      ))}
                  </div>
                  <p>“Great flexibility on scheduling and a wide range of programs. Teachers spend time with each kid individually and bring out the best in them” - Yu, Canada</p>
                </div>
              </div>
            
              <div className="center-div row justify-content-center text-center">
                <div className="col-lg-12">
                  <div className="stars mb-4">
                    <img src="./assets/images/review4.jpg" alt="center_div_img2" className="mb-4 round-image" />
                    {/* <h3 class="d-block poppins fw-600">Bernard</h3> */}
                  </div>
                  <div className="stars mb-3">
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#FFD700"
                          viewBox="0 0 24 24"
                          className="star-icon"
                        >
                          <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.828 1.439 8.211-7.375-3.967-7.375 3.967 1.439-8.211-6.064-5.828 8.332-1.151z" />
                        </svg>
                      ))}
                  </div>
                  <p>“My son had a wonderful experience with his Math and Accounting tutors for his university programs who were knowledgeable, and helped my son to succeed. They were also very flexible with their time and I would highly recommend it.” - Natasha, USA</p>
                </div>
              </div>

          
              <div className="center-div row justify-content-center text-center">
                <div className="col-lg-12">
                  <div className="stars mb-4">
                    <img src="./assets/images/review5.jpg" alt="center_div_img2" className="mb-4 round-image" />
                    {/* <h3 class="d-block poppins fw-600">Bernard</h3> */}
                  </div>
                  <div className="stars mb-3">
                    {Array(5)
                      .fill(0)
                      .map((_, index) => (
                        <svg
                          key={index}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="#FFD700"
                          viewBox="0 0 24 24"
                          className="star-icon"
                        >
                          <path d="M12 .587l3.668 7.431 8.332 1.151-6.064 5.828 1.439 8.211-7.375-3.967-7.375 3.967 1.439-8.211-6.064-5.828 8.332-1.151z" />
                        </svg>
                      ))}
                  </div>
                  <p>“I highly recommend this school! My daughter is in advanced French and I can see her progressing very well. The teacher is professional, friendly and understands how to teach kids well. Classes are well planned out, making it not only fun but very educational. The school is professional, explaining everything you need to know about which classes you are interested in and most importantly they get right back to you if you have any questions. Professional, well operated business that focuses on learning for your child. StudentScholars is doing it right!” - Mat, Australia</p>
                </div>
              </div>
            </Slider>
          </div>
        </section>
        {/*end-what-say*/}
        {/*start-slider*/}
        <section className="explore_new_class padd-pt-100 padd-100 bg-light-gray slick_silder_button d-lg-block d-none">
          <div className="container-fluid px-lg-5">
            <div className="title mb-4">
              <h2 className="text-capitalize">Expand your career opportunities</h2>
            </div>
            <div className=""> 
              {expandCareer.length <= 0 ? (
                <h4 className="d-flex justify-content-center" style={{ "color": "#570861" }}>No Classes Listed Yet, Please Check Again Later</h4>
              ) : (
                <Slider {...expand_carrer_opportuninty}>
                  {expandCareer && expandCareer?.map((view_data, index) => {
                    return (
                      <div key={index} className>
                        <div className="single-box">
                          <div className="class_image_box">
                            <div className="class_img position-relative">
                              <div className="video-container">
                                {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                {/* <video id="video" className loop width="100%" controls="hide" >
                                  <source src={view_data.video_file} type="video/mp4" />
                                </video> */}
                                <video id="video" loop width="100%" src={view_data?.video_file} controls />

                              </div>
                              {token ? (
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                  <label></label>
                                </div>
                              ) : null}
                              <div className="d-flex bottom-info align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <Link to={token ? "/teacher-profile-details" : "/login"} state={view_data.teacher_id}>

                                    <div className="d-flex align-items-center">
                                      <img
                                        src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                        alt="user1"
                                      />
                                      <span className="tech_nm ms-2">{view_data.full_name}</span>
                                      <img
                                        src={view_data.country_image}
                                        alt={`${view_data.country_image
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-2 det_flag_class"
                                      />

                                    </div>
                                  </Link>
                                  {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                </div>
                                <div className="star_rat">
                                  <span>
                                    <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                    </svg>
                                  </span>
                                  <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                </div>
                              </div>
                              {/* <a href="#" className="play_btn">
                                <img src="./assets/images/play.png" alt="play" />
                              </a> */}
                            </div>
                          </div>
                          <div className="class_room_info">
                            <div className="tag mb-3">
                              <a href="#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                              {localStorage.getItem('Tid') != view_data?.teacher_id && token ?
                                <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                : <div></div>
                              }
                            </div>
                            <Link to={token && type === "above_18"
                              ? view_data.type === "In-Person"
                                ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                              : token && type === "below_18"
                                ? view_data.type === "In-Person"
                                  ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                  : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : token && type === "teacher"
                                  ? view_data.type === "In-Person"
                                    ? "/class_detail_onsite"
                                    : "/class_detail"
                                  : token && type === "organization"
                                    ? view_data.type === "In-Person"
                                      ? "/onsite-class-detail"
                                      : "/class-details"
                                    : "/login"} state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}>
                              <div className="class_title mb-4">
                                <p>{view_data?.title}</p>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                  <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                  <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                  <span className="fw-600">Monday To Friday</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                  <span className="fw-600">{view_data?.currency_code} {view_data?.currency_symbol}{view_data?.price}</span>

                                </div>
                              </div>
                            </Link>
                            <div className="button">
                              <a style={{ cursor: 'pointer' }}
                                className={`fw-500 btn-theme d-block text-center ${type === 'learner' ? 'disabled' : ''}`}
                                data-bs-toggle={token && type && type !== 'learner' ? "modal" : ""}
                                data-bs-target={token && type && type !== 'learner' ? "#subscription_modal_1" : ""}
                                onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                              >
                                Subscribe
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </Slider>
              )}
            </div>
          </div>
        </section>
        {/*end-slider*/}
        {/*start-teamobject-section*/}
        {/* <section class="team_subject_sec feat-org bg-light-gray ">
	<div class="container"> 
		<div class="single-items">
			<div class="inner-side">
				<div class="row align-items-center">
					<div class="col-lg-5 mb-lg-0 mb-4 left position-relative">
						<div class="video_boxs">
							<video id="video" class="" loop  width="100%" controls="hide" poster="./assets/images/video_image1.png">
								<source src="./assets/video/videoplayback.mp4" type="video/mp4">
							</video>
						</div>
						<img src="./assets/images/shap/dots_shap.png" alt="shap" class="left_shap">
					</div>
					<div class="col-lg-7 px-3 right d-flex justify-content-center align-items-center position-relative">
						<div class="goal_ctn">
							<h3 class="text-purple mb-3">Featured Organization</h3>
							<h2 class="mb-4">Try Programs by ‘The Educators’</h2>
							<p class="fs-5 mb-4">We are a team of qualified educators offering a variety of online programs for students all over the world and onsite programs to students in Markham, ON Canada.</p>
							<a href="#" class="btn-theme bg-yellow d-inline-block">Explore Programs
								<span class="ms-2">
									<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_194_722)">
											<path d="M29.5205 13.8343L18.6864 3.00002C18.3772 2.69075 17.965 2.521 17.5255 2.521C17.0855 2.521 16.6735 2.69099 16.3643 3.00002L15.3806 3.98391C15.0716 4.29269 14.9013 4.70512 14.9013 5.14488C14.9013 5.58439 15.0716 6.01072 15.3806 6.3195L21.701 12.6538H1.62072C0.71536 12.6538 0 13.3626 0 14.2682V15.6592C0 16.5648 0.71536 17.345 1.62072 17.345H21.7727L15.3808 23.7147C15.0718 24.024 14.9016 24.4252 14.9016 24.865C14.9016 25.3042 15.0718 25.7113 15.3808 26.0203L16.3645 27.001C16.6738 27.3103 17.0857 27.4788 17.5257 27.4788C17.9652 27.4788 18.3774 27.3081 18.6867 26.9988L29.5207 16.1648C29.8307 15.8545 30.0012 15.4404 30 15.0002C30.001 14.5585 29.8307 14.1441 29.5205 13.8343Z" fill="#131313"/>
										</g>
										<defs>
										<clipPath id="clip0_194_722">
										<rect width="30" height="30" fill="white"/>
										</clipPath>
										</defs>
									</svg>
								</span>
							</a>
							<div class="botto_shap position-absolute">
								<img src="./assets/images/shap/dots_shap.png" alt="shap">
							</div>
						</div>
					</div>
				</div>
			</div> 
		</div>
	</div>
</section> */}
        {/*end-teamobject-section*/}
        {/*start-slider*/}
        <section className="competitions-section explore_new_class py-5 bg-purple slick_silder_button slick_btn_dots">
          <div className="container-fluid px-lg-5">
            <div className="title mb-4">
              <h2 className="text-white text-capitalize">Upcoming and competitions</h2>
            </div>
            <div className="competitions_slider">
              {upcomingEvent.length <= 0 ? (
                <h4 className="d-flex justify-content-center text-white ">No Classes Listed Yet, Please Check Again Later</h4>
              ) : (
                <Slider {...competitions}>
                  {
                    upcomingEvent?.map((view_data, index) => (

                      <div key={index} className>
                        <div className="single-box">
                          <div className="class_image_box">
                            <div className="class_img position-relative">
                              <div className="video-container">
                                {/* <img src="./assets/images/knowledge.png" alt="class1" class="img-fluid w-100 h-100"> */}
                                {/* <video id="video" className loop width="100%" controls="hide" >
                                  <source src={view_data.video_file} type="video/mp4" />
                                </video> */}
                                <video id="video" loop width="100%" src={view_data?.video_file} controls />

                              </div>
                              {token ? (
                                <div className="toggle like">
                                  <input type="checkbox" defaultChecked={view_data.is_favourite} onChange={() => handleFavorite(view_data.id)} />
                                  <label></label>
                                </div>
                              ) : null}
                              <div className="d-flex bottom-info align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                  <Link to={token ? "/teacher-profile-details" : "/login"} state={view_data.teacher_id}>

                                    <div className="d-flex align-items-center">
                                      <img
                                        src={view_data.profile_image ? view_data?.profile_image : "./assets/images/NicePng_watsapp-icon-png_9332131.png"}
                                        alt="user1"
                                      />
                                      <span className="tech_nm ms-2">{view_data.full_name}</span>
                                      <img
                                        src={view_data.country_image}
                                        alt={`${view_data.country_image
                                          } flag`}
                                        style={{
                                          width: '23px',
                                          height: '15px',
                                          objectFit: 'cover',
                                          marginRight: '8px',

                                        }}
                                        className="ms-2 det_flag_class"
                                      />

                                    </div>
                                  </Link>
                                  {/* <span className="tech_nm ms-2">{view_data.full_name}</span> */}
                                </div>
                                <div className="star_rat">
                                  <span>
                                    <svg width={22} height={20} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M12.3787 0.703125C12.1716 0.273438 11.7341 0 11.2537 0C10.7732 0 10.3396 0.273438 10.1287 0.703125L7.61695 5.87109L2.00758 6.69922C1.53883 6.76953 1.1482 7.09766 1.00367 7.54688C0.859138 7.99609 0.976326 8.49219 1.31226 8.82422L5.38258 12.8516L4.42164 18.543C4.34351 19.0117 4.53883 19.4883 4.92554 19.7656C5.31226 20.043 5.82398 20.0781 6.24586 19.8555L11.2576 17.1797L16.2693 19.8555C16.6912 20.0781 17.2029 20.0469 17.5896 19.7656C17.9763 19.4844 18.1716 19.0117 18.0935 18.543L17.1287 12.8516L21.199 8.82422C21.5349 8.49219 21.656 7.99609 21.5076 7.54688C21.3591 7.09766 20.9724 6.76953 20.5037 6.69922L14.8904 5.87109L12.3787 0.703125Z" fill="#FDC840" />
                                    </svg>
                                  </span>
                                  <span className="fw-600">{view_data.teacher_ratings}<span className="text-gray fw-500">({view_data.teacher_review})</span></span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="class_room_info">
                            <div className="tag mb-3">
                              <a href="!#" className="px-3 bg-yellow d-inline-block" tabIndex={0}>{view_data.main_category}</a>
                              {localStorage.getItem('Tid') != view_data?.teacher_id && token ?
                                <button style={{ border: '1px solid #FFC924', borderRadius: '10px', }} className="px-3 ms-2  bg-purple text-white border-purple d-inline-block" onClick={() => handleFollowUnfollow(view_data.teacher_id)} >{view_data.is_follow === 1 ? "Following" : "Follow Me"}</button>
                                : <div></div>
                              }
                            </div>
                            <Link to={token && type === "above_18"
                              ? view_data.type === "In-Person"
                                ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                              : token && type === "below_18"
                                ? view_data.type === "In-Person"
                                  ? `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                  : `/class_detail/${view_data.id}?teacher_id=${view_data.teacher_id}`
                                : token && type === "teacher"
                                  ? view_data.type === "In-Person"
                                    ? "/class_detail_onsite"
                                    : "/class_detail"
                                  : token && type === "organization"
                                    ? view_data.type === "In-Person"
                                      ? "/onsite-class-detail"
                                      : "/class-details"
                                    : "/login"} state={{ id: view_data?.id, teacher_id: view_data?.teacher_id }}>
                              <div className="class_title mb-4">
                                <p>{view_data?.title}</p>

                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Time</span>
                                  <span className="fw-600">{view_data.start_time} - {view_data.end_time}</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Age</span>
                                  <span className="fw-600">{view_data.min_age}-{view_data.max_age} Age</span>
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col-8">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">class Day</span>
                                  <span className="fw-600">Monday To Friday</span>
                                </div>
                                <div className="col-4">
                                  <span className="d-block mb-2 fw-600 text-gray45 text-capitalize">Price</span>
                                  <span className="fw-600">{view_data?.currency_code} {view_data?.currency_symbol}{view_data?.price}</span>

                                </div>
                              </div>
                            </Link>
                            <div className="button">
                              <a style={{ cursor: 'pointer' }}
                                className={`fw-500 btn-theme d-block text-center ${type === 'learner' ? 'disabled' : ''}`}
                                data-bs-toggle={token && type && type !== 'learner' ? "modal" : ""}
                                data-bs-target={token && type && type !== 'learner' ? "#subscription_modal_1" : ""}
                                onClick={() => type !== 'learner' && handleSubscribe(view_data)}
                              >
                                Subscribe
                              </a>

                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Slider>
              )}
            </div>
          </div>
        </section>
        {/*end-slider*/}
        {/*start*/}
        {/* <section className="meet-gobal-team bg-light-gray d-lg-block d-none">
          <div className="container-fluid">
            <div className="row mb-lg-4 mb-5 justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 className="text-capitalize">Meet Our Global Team</h2>
              </div>
            </div>
            <Slider {...meet_our_team}>
              {meetTeam && meetTeam?.length > 0 ? (
                meetTeam.map((view_data, index) => (

                  <div key={index} className>
                    <div className='row'>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div className="global_box">
                          <div className="glbal_in position-relative">
                            <div className="glbal_info">
                              <div className="box_name position-relative">
                                <img src={view_data.main_category_image} alt="image" />
                                <span>{view_data.category_name}</span>
                                <img src="./assets/images/shap/dots_shap.png" alt="dots_shap" className="global_shap" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="">
                  <p>Data Not Found</p>
                </div>
              )}
            </Slider>
    
          </div>
        </section> */}
        <section className="explore_new_class padd-pt-100 padd-100 bg-light-gray slick_silder_button d-lg-block d-none">
          <div className="container-fluid px-lg-5">
            <div className="row mb-lg-4 mb-5 justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 className="text-capitalize">Meet Our Global Team</h2>
              </div>
            </div>
            <div className="">
              <Slider {...meet_our_team}>
                {meetTeam && meetTeam?.length > 0 ? (
                  meetTeam.map((view_data, index) => (

                    <div key={index} >
                      <div className='row mt-5'>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mt-4 mx-5">
                          <div className="global_box">
                            <div className="glbal_in position-relative">
                              <div className="glbal_info">
                                <div className="box_name position-relative">
                                  <img src={view_data.profile_image} alt="image" />
                                  <span>{view_data.full_name}</span>
                                  <img src="./assets/images/shap/dots_shap.png" alt="dots_shap" className="global_shap" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>Data Not Found For Global Team</p>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </section>
        {/*end*/}
        {/*start-need-help-find-class-teacher*/}
        <section className="find-cls-teacher join_our_section bg-yellow position-relative">
          <img src="./assets/images/shap/dots_shap_white.png" alt="dots_shap_white" className="right position-absolute" />
          <img src="./assets/images/shap/dots_shap_white.png" alt="dots_shap_white" className="left position-absolute" />
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 join_left mb-lg-0 mb-5">
                <h2 className="fw-bold mb-4 text-capitalize">Join Our Global Team of Educators</h2>
                <p className="fw-500 mb-3">Interested in joining our qualified global team of teachers and specialized organizations?</p>
                <button className="btn-theme bg-purple d-inline-block text-capitalize" onClick={() => navigateToJoin()}>get started</button>
              </div>
              <div className="col-lg-5 right">
                <div className="video_boxs">
                  <video id="video" className loop width="100%" controls="hide" poster="./assets/images/video_image.png">
                    <source src={'https://studentscholars.s3.amazonaws.com/video/Teacher intro - homepage new (1) (1).mp4'} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end-need-help-find-class-teacher*/}
        {/*start*/}
        <section className="know-section bg-light-gray d-lg-block d-none">
          <div className="container">
            <div className="card">
              <div className="row align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-5 left">
                  <h2 className="mb-4 text-capitalize">Sharing Our <span className="text-purple">Knowledge</span></h2>
                  <p className="mb-4">At StudentScholars, our passion is education. Subscribe to our newsletter to find out about the latest news and classes.</p>
                  <button className="btn-theme bg-yellow d-inline-block fw-bold text-dark" onClick={handleShow}>Subscribe To Our Newsletter</button>
                  {showEmail ?
                    <form className="custom_form mt-4" onSubmit={handleSubmit(onSubmit)}>
                      <label className="mb-2">Email ID :</label>
                      <div className='row'>
                        <div className="form-group mb-4 col-6">

                          <input type='email' className='form-group' placeholder='Enter Your Email ID' {...register("email")} />
                          {/* {errors5.class && <p>{errors5.class.message}</p>} */}
                          {errors?.email && <p className="mt-2 font-bold col-red">
                            <span style={{ color: "red" }}>{errors?.email?.message}</span>
                          </p>}

                        </div>
                        <div className='col-4 ms-5'>
                          <button type="submit" className="btn-theme bg-yellow text-center fw-bold">Subscribe</button>
                        </div>
                      </div>
                    </form>
                    : null}
                </div>
                <div className="col-lg-6 right">
                  <img src="./assets/images/knowledge.png" alt="knowledge" />
                </div>

              </div>
            </div>
          </div>
        </section>
        {/*end*/}
        {/*start*/}
        <section className="student_join_us padd-pt-100 pt-lg-0 bg-light-gray">
          <div className="container">
            <div className="row mb-5 justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mb-2 text-capitalize">More than 1k+ students join us</h2>
                <p>Trusted by branded companies around the world</p>
              </div>
            </div>
            <div className="row justify-content-center align-items-center padd-100">
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo1.png" alt="logo1" />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo2.png" alt="logo2" />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo3.png" alt="logo3" />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo4.png" alt="logo4" />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo5.png" alt="logo5" />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 col-6 mb-lg-0 mb-4">
                <div className="join_img">
                  <img src="./assets/images/Join_us_logo/logo6.png" alt="logo6" />
                </div>
              </div>
            </div>
            <h2 className="text-center mb-5">Awards</h2>
            <div className="row padd-100 awards_sec justify-content-center align-items-center text-center">
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="awards1_img">
                  <img src="./assets/images/Join_us_logo/awards1.png" alt="awards1" />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="awards1_img">
                  <img src="./assets/images/Join_us_logo/awards2.png" alt="awards2" />
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="awards1_img">
                  <img src="./assets/images/s_award.jpeg" alt="awards2" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*end*/}
        {/*start*/}
        <section className="news-section bg-light-gray padd-100 slick_silder_button position-relative">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-8">
                <h2 className="text-capitalize">Find out about our latest news</h2>
                <p className="fs-5">Here are some of our favorite articles:</p>
              </div>
            </div>
            <div className="news_box news_boxslider">
              <Slider {...news}>

                {latestNews && latestNews?.length > 0 ? (
                  latestNews.map((view_data, index) => (
                    <div className="slick-slideshow__slide" key={index}>
                      <div className="news_info bg-white">
                        {Array.isArray(view_data?.blog_media) && view_data.blog_media.length > 0 ? (
                          view_data.blog_media[0].type === "video" ? (
                            <video
                              controls
                              className="w-100 img-fluid border-radius-10"
                              style={{ height: "300px", objectFit: "cover" }}
                            >
                              <source src={view_data.blog_media[0].media} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={view_data.blog_media[0].media}
                              alt="blog"
                              className="w-100 img-fluid border-radius-10"
                              style={{ height: "300px", objectFit: "cover" }}
                            />
                          )
                        ) : (
                          <div>No media available</div>
                        )}
                        <div className="nw_ctn">
                          <span className="text-purple mb-2">{view_data.formatted_date}</span>
                          <p className="mb-4 fw-600">{view_data.title}</p>
                          <div className="d-lg-flex justify-content-center">
                            <button className="btn-theme btn-prple-border" onClick={() => handleNewsDetails(view_data.id)} >See more details</button >
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>
                    <p>Data Latest News Found</p>
                  </div>
                )}
              </Slider>
            </div>

          </div>
        </section>
        {/*end*/}
      </main>
      {token && (<>
        <SubscribeModal1 data={subscribeModal1Data} handleSubscriptionAmount={(val) => setTotalSubscriptionAmount(val)} />
        <SubscribeModal2 />
        <SubscribeModal3 data={subscribeModal1Data} totalSubscriptionAmount={totalSubscriptionAmount} activity_type={"class"} />
      </>)
      }

      <Footer />
    </>
  )
}
