import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { teacher_add_assetment } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import { Helmet } from 'react-helmet';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'quill-better-table/dist/quill-better-table.css';
import BetterTable from 'quill-better-table';

// Register BetterTable with Quill
Quill.register({
  'modules/better-table': BetterTable
}, true);

const Editor = ({ content, onChange }) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['image'],
      ['clean'],
      ['better-table'] // Add the table module here
    ]
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    // 'link',
    'image',
    'better-table' // Add the table format here
  ];

  return (
    <ReactQuill
      value={content}
      onChange={onChange}
      modules={modules}
      formats={formats}
      theme="snow"
      placeholder="Write something..."
      style={{ borderRadius: '10px' }}
    />
  );
};

export default function OAddAssessment({ isLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);
  const location = useLocation();
  var id = location?.state;

  const schema = yup.object().shape({
    topic: yup.string().required("Please enter assessment topic")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Assessment topic cannot consist of only spaces',
        (value) => value && value.trim().length > 0
      ),
    description: yup.string().required("Please enter assessment description"),
    due_date: yup.string().required('Please select due date'),
    due_time: yup.string().required('Please select due time'),
    score: yup.number()
      .typeError("Score must be a valid number")
      .required("Please enter score")
      .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
      .test(
        'no-only-whitespace',
        'Score cannot consist of only spaces',
        (value) => value !== undefined && value !== null && value.toString().trim().length > 0
      )
      .min(1, 'Score Points must be at least 1')
      .max(100, 'Score Points must be at most 100'),
    hours: yup.string().test(
      'duration-validation',
      'Duration cannot be 0 hours and 0 minutes',
      function (value) {
        const minutes = this.parent.minutes;
        return !(value === "0" && minutes === "0");
      }
    ),
    minutes: yup.string().test(
      'duration-validation',
      'Duration cannot be 0 hours and 0 minutes',
      function (value) {
        const hours = this.parent.hours;
        return !(hours === "0" && value === "0");
      }
    )
  });

  const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });

  const [profileImageState, setProfileImageState] = useState('')
  const [AttachmentData, setAttachmentData] = useState([[], [], [], [], [], []]);
  const [description, setDescription] = useState('');
  const [attachmentOrder, setAttachmentOrder] = useState([]);

  const handleImageChange = async (image, fileIndex, type) => {
    isLoader(true)
    if (image != null) {
      TOAST_SUCCESS("Your attachment has been uploaded!");
    }
    var imageName = await uploadImageOnAWS(image, "assesment_attachements");
    var temp_arr = AttachmentData;
    // const temp_arr = Array(6).fill("");
    temp_arr[fileIndex] = { attachments: imageName, attachment_type: type }
    isLoader(false)
    // AttachmentData[fileIndex] = image
    setAttachmentData(temp_arr)
    if (!attachmentOrder.includes(fileIndex)) {
      setAttachmentOrder([...attachmentOrder, fileIndex]);
    }
    setValue("attachments_data", setAttachmentData);
    const bufferImage = await convertToBase64(image);
    setProfileImageState(bufferImage);
    clearErrors("attachments_data");
  }

  const handleDescriptionChange = (value) => {
    const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
    setDescription(isEmptyDescription ? '' : value);
    setValue('description', isEmptyDescription ? '' : value);
    clearErrors('description');
  };


  let onSubmit = async (data) => {
    isLoader(true)
    let assessment_data = {
      activity_id: id.id,
      slot_id: id?.slot,
      activity_type: id.class,
      topic: data.topic,
      description: data.description,
      due_date: data.due_date,
      due_time: data.due_time,
      duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
      total_score: data.score,
    }
    assessment_data.attachment = {
      activity_type: "assetment",
      attachments: AttachmentData.filter(item => item !== '')
    }
    teacher_add_assetment(assessment_data).then((response) => {

      if (response?.code == 1) {
        isLoader(false)
        TOAST_SUCCESS(response.message);
        navigate('/organization-tech-detail', {
          state: { id: id.id, class: id.class, type_class: "Online", slot: id?.slot }
        })
      } else {
        isLoader(false)
        TOAST_ERROR(response.message);
      }
    });
    reset();
  }

  const handleDeleteAttachment = (index) => {
    const newAttachments = [...AttachmentData];
    newAttachments[index] = [];
    setAttachmentData(newAttachments);
    setAttachmentOrder(attachmentOrder.filter(i => i !== index));
  };

  return (
    <>
      <JqueryLoad />
      <OHeader_Dashboard />
      <Helmet>
        <style>
          {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
        </style>

      </Helmet>
      <main>
        <div className="dashboard-app d-lg-flex">
          <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
            <div className="align-items-center justify-content-between d-flex">
              <div className="d-flex align-items-center">
                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                  <i className="fa fa-bars" />
                </a>
                <a href="#" className="d-lg-none d-block brand-logo">
                  <img src="./assets/images/logo.png" alt="logo" />
                </a>
              </div>
            </div>
          </header>
          <div className="class-content">
            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
              <span>
                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                </svg>
                <span className="ms-2">Back</span>
              </span></a>
            <div className="row">
              <div className="col-xl-8">
                {/*start*/}
                <div className="mb-4">
                  <h2 className="mb-2 poppins fw-bold">Add Assessment Information</h2>
                </div>
                {/*end*/}
                {/*start*/}
                <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                  <div className="form-group mb-4">
                    <label className="form-label">Add Assessment Topic</label>
                    <div className="position-relative class_title">

                      <input type="text" {...register("topic")} maxLength={50} name="topic" className="form-control" placeholder id />
                      <span className="position-absolute word_count">{50 - (watch("topic")?.split('')?.length ?? 0)} Words</span>

                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.topic?.message}</span></p>
                    </div>
                  </div>
                  {/* <div className="form-group mb-4">
                          <label className="form-label">Add Assessment Description</label>
                          <div className="position-relative class_summary">

                            <textarea className="form-control" {...register("description")} name="description" rows={7} placeholder />
                            <span className="position-absolute word_count">
                              {(watch("description") ? watch("description")?.length : 0)} Words
                            </span>
                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                          </div>
                        </div> */}
                  <div className="form-group mb-4">
                    <label className="form-label">Add Assessment Description</label>
                    <div className="position-relative class_summary">
                      <Editor
                        {...register("description")}
                        content={description}
                        onChange={handleDescriptionChange}
                      />
                      <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                    </div>
                  </div>
                  <div className="form-group mb-4 attach_box">
                    <label className="form-label">Attachments</label>
                    <div className="position-relative">
                      {/* <textarea className="form-control" name rows={7} value={getAttachmentFileNames()} disabled /> */}
                      <div className="textarea-wrapper">
                        <textarea
                          type="text"
                          className="form-control"
                          rows={7}
                          // value={
                          //     attachmentData[index]?.attachments?.map((item, idx) => (
                          //         item.attachment ? item.attachment.split('/').pop() : ''
                          //     )).join(', ') || ''
                          // }
                          readOnly
                        />
                        <div className="attachments-preview p-2">
                          {attachmentOrder.map((index) => {
                            const item = AttachmentData[index];
                            if (item && item.attachments) {
                              return (
                                <div key={index} className="attachment-item">
                                  <span>{item.attachments}</span>
                                  <button onClick={() => handleDeleteAttachment(index)} className="btn-close">×</button>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      </div>
                      <div className="att_icon_list position-absolute">
                        <ul className="d-flex align-items-center justify-content-end">
                          <li>
                            <label htmlFor={`attach1`}>
                              <a>
                                <img src="./assets/images/attach1.png" alt="attach1" />
                              </a>
                            </label>
                            <input
                              type="file"
                              id={`attach1`}
                              onInput={(e) => {
                                const file = e.target.files[0];
                                const fileType = file.type.startsWith('image')
                                  ? 'image'
                                  : file.type.startsWith('video')
                                    ? 'video'
                                    : file.type === 'application/pdf'
                                      ? 'pdf'
                                      : file.type ===
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                        ? 'docx'
                                        : 'xlsx'; // For Excel
                                handleImageChange(file, 0, fileType);
                              }}
                              {...register("attachments")}
                              accept="image/*,video/*,application/pdf,.docx,.xlsx"
                              className="file-input"
                              style={{ visibility: 'hidden', display: 'none' }}
                            />
                          </li>
                          <li>
                            <label htmlFor='attach2'>
                              <a >
                                <img src="./assets/images/attach2.png" alt="attach2" />
                              </a>
                            </label>
                            <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                          </li>
                          <li>
                            <label htmlFor='attach3'>
                              <a>
                                <img src="./assets/images/attach3.png" alt="attach3" />
                              </a>
                              <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                            </label>
                          </li>
                          <li>
                            <label htmlFor='attach4'>
                              <a>
                                <img src="./assets/images/attach4.png" alt="attach4" />
                              </a>
                              <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                            </label>
                          </li>
                          <li>
                            <label htmlFor='attach5'>
                              <a >
                                <img src="./assets/images/attach5.png" alt="attach5" />
                              </a>
                              <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                            </label>
                          </li>
                          <li>
                            <label htmlFor='attach6'>
                              <a>
                                <img src="./assets/images/attach6.png" alt="attach6" />
                              </a>
                              <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group mb-4">
                        <label className="form-label">Assessment Due Date</label>
                        <input type="date" {...register("due_date")} name="due_date" min={currentDate} className="form-control" placeholder id />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_date?.message}</span></p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group mb-4">
                        <label className="form-label">Assessment Due Time</label>
                        <input type="time" {...register("due_time")} name="due_time" className="form-control" placeholder id />
                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.due_time?.message}</span></p>
                      </div>
                    </div>
                  </div>
                  <h3 className="mb-3">Assesment duration</h3>
                  <div className="d-md-flex mb-4 align-items-center">
                    <p className="fs-6  mb-md-0 mb-3">Expected Time spent On Assesment Hours</p>
                    <div className="d-md-flex ms-3">
                      <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                        <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                          <option selected>0</option>
                          <option >1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                        <span className="ms-3">Minutes</span>
                      </div>
                      <div className="ms-md-3">
                        <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                          <option selected>0 </option>
                          <option>5 </option>
                          <option>10 </option>
                          <option>15 </option>
                          <option>20 </option>
                          <option>25 </option>
                          <option>30 </option>
                          <option>35 </option>
                          <option>40 </option>
                          <option>45 </option>
                          <option>50 </option>
                          <option>55 </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {(errors.hours && errors.minutes) && (
                    <p className="text-danger mb-3">Duration cannot be 0 hours and 0 minutes</p>
                  )}
                  <h3 className="mb-3">Assesment Score</h3>
                  <div className="form-group mb-4">
                    <label className="form-label">total score for this assesment</label>
                    <input type="number" {...register("score")} name="score" className="ms-3" placeholder id />
                    <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.score?.message}</span></p>

                  </div>
                  {/* <div className="enviorment_div mb-5 align-items-center d-md-flex">
                          <div className="env_img">
                            <img src="./assets/images/enviorment.png" alt="enviorment" />
                          </div>
                          <div className="env_text">
                            <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                          </div>
                        </div> */}
                  <div className="button">
                    {/* <Link to="/teach-detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Assessment</Link> */}
                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                      Create Assessment
                    </button>
                  </div>
                </form>
                {/*end*/}
              </div>
            </div>
          </div>
        </div>
      </main>
      <OFooter_Dashboard />
    </>
  )
}
