import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { blogswise_details, get_faq_video_details } from "../../../api/apiHandler";

import BackToPrevious from "../../../components/backToPrevious";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import { encryptURL } from "../../../api/apiClient";

export default function LandingPageFaqVideoDetails({isLoader}) {
  const navigate = useNavigate();

  const location = useLocation();
  var video_id = location?.state;
  console.log("video_id :", video_id);
  var [videoData, setVideoData] = useState([]);
  console.log("videoData :", videoData);

  const VideoDetails = () => {
    get_faq_video_details({ id: video_id }).then((resposnse) => {
      console.log("details", resposnse);

      if (resposnse?.code == 1) {
        setVideoData(resposnse?.data);
      } else {
        setVideoData([]);
        console.log("Data Not Found");
      }
    });
  };
  const handleHeaderData = (data) => {
    console.log('data :', data);
    if (!localStorage.getItem('PAtoken')) {
      localStorage.setItem('isLandingPage', true)
    }
    const queryParams = new URLSearchParams({
      main_category: data.SelectedCategory,
      sub_category: data.subCategory,
      is_landingpage: true
    }).toString();



    const encryptedURL = encryptURL(`/search_class?${queryParams}`);
    console.log('encryptedURL :', encryptedURL);
    // return
    navigate(encryptedURL);
  };

  useEffect(() => {
		window.scrollTo(0, 0)
    VideoDetails();
  }, []);
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>

      <Header sendDataToHome={handleHeaderData} isLoader={isLoader}/>

      <main>
        {/* <div className="dashboard-app d-lg-flex"> */}

        <div className="container mt-3">
          <div className>
            <BackToPrevious />
            <div>
              {/*start*/}
              <div className="mb-5">{videoData.length > 0 && <h2 className="mb-2 poppins">{videoData[0].title}</h2>}</div>
              {/*end*/}
              {/*start*/}
              <div className="row blogs_detail">
                <div className="col-lg-12 mb-4">
                  <div className="certi_box bg-transparent shadow-none">
                    <div className="certi_images mb-3 position-relative text-center bg-transparent p-0 border-bottom-0">
                      <div className="blog_img border-radius-10">
                        {videoData.length > 0 && (
                          <>
                            {videoData[0].media_type === "video" && videoData[0].media ? (
                              <video id="video" className="video-js vjs-default-skin" loop width="100%" controls poster="">
                                <source src={videoData[0].media} type="video/mp4" />
                              </video>
                            ) : (
                              videoData[0].media && <img src={videoData[0].media} alt="Blog Image" />
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="certi_ctn border-top border-dark px-0">
                      <div className="dwnload_btm d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="dwnl_img">
                            {videoData.length > 0 && <img src={videoData[0].profile_image} alt="center_div_img2" className="rounded-circle" />}
                          </div>
                          <div className="ms-3">{videoData.length > 0 && <span className="font-14 fw-bold">{videoData[0].admin_name}</span>}</div>
                        </div>
                        <div className>
                          {videoData.length > 0 && (
                            <>
                              <span>{videoData[0].formatted_date}</span> • <span>{videoData[0].formatted_time} ago</span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*end*/}
              {/*start*/}
              <div className>
                {/*start-content*/}
                {videoData.length > 0 && <p>{videoData[0].description}</p>}
                {/*end-content*/}
                {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                  <div className="row mb-5 middle_contn">
                    <div className="col-xl-6 col-lg-6 col-md-6 mb-md-0 mb-4">
                      <div className="certi_box bg-transparent shadow-none">
                        <div className="certi_images position-relative text-center bg-transparent p-0 border-bottom-0">
                          <div className="blog_img">
                            <img src="./assets/images/blog2.png" alt="blog1" className="w-100 img-fluid border-radius-10" />
                            <a href="#" className="play_btn_op1" tabIndex={0}>
                              <img src="./assets/images/play.png" alt="play" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6">
                      <div className="certi_box bg-transparent shadow-none">
                        <div className="certi_images text-center bg-transparent p-0 border-bottom-0">
                          <div className="blog_img">
                            <img src="./assets/images/blog2.png" alt="blog1" className="w-100 img-fluid border-radius-10" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                {/*start-content*/}
                {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                  <p className="mb-4 fs-6">
                    Optimize for search land it in region today shall be a cloudy day, thanks to blue sky thinking, we can now deploy our new ui to
                    the cloud . Unlock meaningful moments of relaxation forcing function , or weaponize the data. At the end of the day you better eat
                    a reality sandwich before you walk back in that boardroom, and onward and upward, productize the deliverables and focus on the
                    bottom line, but what do you feel you would bring to the table if you were hired for this position. This vendor is incompetent
                    mumbo jumbo, optimize for search, yet run it up the flagpole
                  </p>
                  <p className="mb-4 fs-6">
                    You better eat a reality sandwich before you walk back in that boardroom can we jump on a zoom through the lens of product launch,
                    so sacred cow pulling teeth put your feelers out. We've bootstrapped the model your work on this project has been really
                    impactful, overcome key issues to meet key milestones, optics, for we just need to put these last issues to bed, or disband the
                    squad but rehydrate as needed. Cross pollination across our domains we need to build it so that it scales.
                  </p> */}
                {/*end-content*/}
                {/*start-content*/}
                {/* <h3 className="mb-4 poppins text-dif-black">Natural light is a highly sought-after feature in any space</h3>
                  <p className="mb-4 fs-6">
                    Optimize for search land it in region today shall be a cloudy day, thanks to blue sky thinking, we can now deploy our new ui to
                    the cloud . Unlock meaningful moments of relaxation forcing function , or weaponize the data. At the end of the day you better eat
                    a reality sandwich before you walk back in that boardroom, and onward and upward, productize the deliverables and focus on the
                    bottom line, but what do you feel you would bring to the table if you were hired for this position. This vendor is incompetent
                    mumbo jumbo, optimize for search, yet run it up the flagpole
                  </p>
                  <p className="mb-4 fs-6">
                    You better eat a reality sandwich before you walk back in that boardroom can we jump on a zoom through the lens of product launch,
                    so sacred cow pulling teeth put your feelers out. We've bootstrapped the model your work on this project has been really
                    impactful, overcome key issues to meet key milestones, optics, for we just need to put these last issues to bed, or disband the
                    squad but rehydrate as needed. Cross pollination across our domains we need to build it so that it scales.
                  </p> */}
                {/*end-content*/}
              </div>
              {/*end*/}
            </div>
            {/*end*/}
          </div>
        </div>
        {/* </div> */}
      </main>
      <Footer />
    </>
  );
}
