import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get_user_details } from "../../api/apiHandler";
// import * as API from '../../utils/api.service';

export const getUserDetailsApiCall = createAsyncThunk("userDetails", async (submitData, { dispatch }) => {
    
console.log('submitData :', submitData);
    try {
        dispatch(setLoader(true));
        const response = await get_user_details(submitData);
        if (response?.code == 1) {
            return response?.data?.[0];
        } else {
             ;
        }
        dispatch(setLoader(false))
    } catch (error) {
        throw error;
    }
});

const initialState = {
    isLoading: false,
    socket: null,
    audioRef: null,
    userDetails: {
        data: null,
        error: null,
    },
    isModalOpen: {
        modalType: "",
        isOpen: false,
        data: null,
    },
    isOffcanvasOpen: {
        canvasType: "",
        isOpen: false,
        data: null,
        isEdit: false
    },
    isToastOpen: {
        toastType: "",
        isOpen: false,
        data: null,
    },
}

const masterSlice = createSlice({
    name: 'MASTER',
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.isLoading = action.payload;
        },
        setModalStatus: (state, action) => {
            const { modalType, isOpen, data } = action.payload;
            state.isModalOpen.modalType = modalType;
            state.isModalOpen.isOpen = isOpen;
            state.isModalOpen.data = data;
        },
        setOffcanvasStatus: (state, action) => {
            const { canvasType, isOpen, data, isEdit } = action.payload;
            state.isOffcanvasOpen.canvasType = canvasType;
            state.isOffcanvasOpen.isOpen = isOpen;
            state.isOffcanvasOpen.data = data;
            state.isOffcanvasOpen.isEdit = isEdit;
        },
        setToastStatus: (state, action) => {
            const { toastType, isOpen, data } = action.payload;
            state.isToastOpen.toastType = toastType;
            state.isToastOpen.isOpen = isOpen;
            state.isToastOpen.data = data;
        },
        setUserLanguage: (state, action) => {
            state.userLanguage = action.payload;
        },
        setUserRoleType: (state, action) => {
            state.userRoleType = action.payload;
        },
        setDashboardCard: (state, action) => {
            state.dashboardCard = action.payload;
        },
        setCustomizeDashboardCard: (state, action) => {
            state.customizeDashboardCard = action.payload;
        },
        setEmployeeTasksPermission: (state, action) => {
            state.employeeTasksPermission = action.payload;
        },
        setEmployeeLeadsPermission: (state, action) => {
            state.employeeLeadsPermission = action.payload;
        },
        setEmployeeTeamsPermission: (state, action) => {
            state.employeeTeamsPermission = action.payload;
        },
        setEmployeeOrganizationsPermission: (state, action) => {
            state.employeeOrganizationsPermission = action.payload;
        },
        setSocket: (state, action) => {
            state.socket = action.payload;
        },
        setAudioRef: (state, action) => {
            state.audioRef = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserDetailsApiCall.fulfilled, (state, action) => {
                state.userDetails.data = action.payload;
            })
            .addCase(getUserDetailsApiCall.rejected, (state, action) => {
                state.userDetails.error = action.error.message;
            });
    },
});

export const { setLoader, setModalStatus, setToastStatus, setOffcanvasStatus, setUserLanguage, setUserRoleType, setEmployeeTasksPermission, setEmployeeLeadsPermission, setEmployeeTeamsPermission, setEmployeeOrganizationsPermission, setCustomizeDashboardCard, setDashboardCard, setSocket, setAudioRef } = masterSlice.actions;
export default masterSlice.reducer;