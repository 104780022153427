import React, { useState, useEffect, forwardRef } from 'react'
import { useImperativeHandle } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad'
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { disscussion_data, disscussion_edit_data } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_SUCCESS, TOAST_ERROR } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import OHeader_Dashboard from '../../OInclude/OHeader_Dashboard';
import OFooter_Dashboard from '../../OInclude/OFooter_Dashboard';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = forwardRef(({ value, onChange }, ref) => {
    const quillRef = React.useRef();

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.focus();
        }
    }, []);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (quillRef.current) {
                quillRef.current.focus();
            }
        },
        getEditor: () => {
            return quillRef.current?.getEditor();
        }
    }));

    return (
        <ReactQuill
            ref={quillRef}
            value={value || ''}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px' }}
        />
    );
});

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];
export default function OEditDisscussion({ isLoader }) {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);

    const location = useLocation();
    const navigate = useNavigate();
    const [slot, setSlot] = useState([])
    var edit_id = location?.state;

    const schema = yup.object().shape({
        topic: yup.string().required("Please enter discussion topic")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Discussion topic cannot consist of only spaces',
                (value) => value && value.trim().length > 0
            ),
        description: yup.string().required("Please enter discussion description"),
        // status: yup.string().required("Discussion status is required"),
        open_date: yup.string().required("Please select open date"),
        open_time: yup.string().required("Please select open time"),
        close_date: yup.string().required("Please select close date"),
        close_time: yup.string().required("Please select close time"),
        hours: yup.string().test(
            'duration-validation',
            'Duration cannot be 0 hours and 0 minutes',
            function (value) {
                const minutes = this.parent.minutes;
                return !(value === "0" && minutes === "0");
            }
        ),
        minutes: yup.string().test(
            'duration-validation',
            'Duration cannot be 0 hours and 0 minutes',
            function (value) {
                const hours = this.parent.hours;
                return !(hours === "0" && value === "0");
            }
        ),
        score: yup.number()
            .typeError("Please enter valid score")
            .required("Please enter score")
            .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
            .test(
                'no-only-whitespace',
                'Score cannot consist of only spaces',
                (value) => value !== undefined && value !== null && value.toString().trim().length > 0
            )
            .min(1, 'Score Points must be at least 1')
            .max(100, 'Score Points must be at most 100'),
    });

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const [lessonData, setLessonData] = useState([])
    var [classTitleLength, setclassTitleLength] = useState(0);
    const [getOpenDate, setGetOpenDate] = useState("");
    const [description, setDescription] = useState('');
    const [attachmentFileName, setAttachmentFileName] = useState('');
    const [currentAttachment, setCurrentAttachment] = useState(null);
    const [isNewAttachment, setIsNewAttachment] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const SelectOpenDate = (d) => {
        setGetOpenDate(d)
    }

    const disscussion_lessonlist = () => {
        disscussion_data({ discussion_id: edit_id }).then((resposnse) => {
            console.log('disscussion_data :', resposnse);
            if (resposnse?.code == 1) {
                const data = resposnse?.data?.[0];
                if (data?.attachments?.length) {
                    const formattedAttachments = data.attachments.map(att => ({
                        attachments: att.attchments,
                        attachment_type: att.attachment_type
                    }));
                    setAttachments(formattedAttachments);
                }

                // Set initial attachment if it exists
                if (data?.attachments?.[0]) {
                    setCurrentAttachment({
                        attachments: data.attachments[0].attchments,
                        attachment_type: data.attachments[0].attachment_type
                    });
                    // Extract and set filename from URL
                    const fileName = data.attachments[0].attchments.split('/').pop();
                    setAttachmentFileName(fileName);
                }
                setIsNewAttachment(false); // Reset new attachment flag

                setDescription(data?.description || '');

                setclassTitleLength(data?.topic.length)
                setGetOpenDate(data?.open_date)
                reset({
                    // topic: resposnse?.data?.[0]?.topic,
                    description: data?.description,
                    status: data?.status,
                    open_date: data?.open_date,
                    open_time: data?.open_time,
                    close_date: data?.close_date,
                    close_time: data?.close_time,
                    attachments_data: data?.attachments,
                    hours: data?.duration?.split(' ')?.[0],
                    minutes: data?.duration?.split(' ')?.[2],
                    score: data?.score,
                });
                setLessonData(data)
                setSlot(data?.slot_id)
            } else {

            }
        });
    }

    let classTitleData = (e) => {
        setclassTitleLength(0)
    }

    useEffect(() => {
        disscussion_lessonlist();
    }, [])

    const handleImageChange = async (image, type) => {
        if (!image) return;
        try {
            TOAST_SUCCESS("Your attachment has been uploaded!");
            isLoader(true);
            const imageName = await uploadImageOnAWS(image, "assesment_attachements");
            isLoader(false);

            setAttachments(prev => [...prev, {
                attachments: imageName,
                attachment_type: type
            }]);

            clearErrors("attachments");
        } catch (error) {
            TOAST_ERROR("Failed to upload attachment");
        }
    };

    const handleFileInput = (e, type) => {
        const file = e.target.files[0];
        if (file) {
            handleImageChange(file, type);
        }
    };

    const handleDescriptionChange = (value) => {
        const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
        const validatedValue = isEmptyDescription ? '' : value;
        setDescription(validatedValue);
        setValue('description', validatedValue);
        clearErrors('description');
    };

    let onSubmit = async (data) => {
        isLoader(true)
        let image = data?.attachments_data
        if (image instanceof Blob) image = await uploadImageOnAWS(image, "class_image");
        const formattedAttachments = attachments.map(att => ({
            attchments: att.attachments.split('/').pop(), // This gets just the filename
            attachment_type: att.attachment_type
        }));
        let discussion_data = {
            discussion_id: edit_id,

            topic: data.topic,
            description: data.description,
            // attachments: image,
            status: data.status,
            open_date: data.open_date,
            open_time: data.open_time,
            close_date: data.close_date,
            close_time: data.close_time,
            duration: data.hours + " Hour" + (data.hours != 1 ? "s " : " ") + data.minutes + " Minutes",
            score: data.score,
            attachment: {
                activity_type: "discussion",
                attachments: formattedAttachments
            }
        }
        disscussion_edit_data(discussion_data).then((response) => {
            if (response?.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(response.message);
                navigate('/organization-tech-detail', {
                    state: { id: lessonData.activity_id, class: lessonData.activity_type, type_class: "Online", slot: slot }
                })
            } else {
                isLoader(false)
                TOAST_ERROR(response.message);
            }
        });
        reset();
    }

    const handleDeleteAttachment = (index) => {
        setAttachments(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <>
            <JqueryLoad />
            <OHeader_Dashboard />
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                            <span>
                                <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                </svg>
                                <span className="ms-2">Back</span>
                            </span></a>
                        <div className="row">
                            <div className="col-xl-8">
                                {/*start*/}
                                <div className="mb-4">
                                    <h2 className="mb-2 poppins fw-bold">Edit Discussion Information</h2>
                                </div>
                                {/*end*/}
                                {/*start*/}
                                <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                    <div className="form-group mb-4">
                                        <label className="form-label">Add Discussion Topic</label>
                                        <div className="position-relative class_title">

                                            <input type="text" {...register("topic")} maxLength={50} name="topic" className="form-control" placeholder id defaultValue={lessonData.topic} onKeyUp={(e) => classTitleData(e.target.value)} />
                                            <span className="position-absolute word_count">
                                                {Math.max((0, 50 - (classTitleLength + ((watch("lesson_topic")?.split('')?.length ?? 0)))))} Words
                                            </span>
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.topic?.message}</span></p>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Add Discussion Description</label>
                                        <div className="position-relative class_summary">
                                            <Editor
                                                value={description}
                                                onChange={handleDescriptionChange}
                                            />
                                            <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.description?.message}</span></p>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4 attach_box">
                                        <label className="form-label">Add Attachments</label>
                                        <div className="position-relative">
                                            <div className="textarea-wrapper">
                                                <textarea
                                                    className="form-control"
                                                    rows={7}
                                                    // value={attachmentFileName}
                                                    readOnly
                                                />
                                                <div className="attachments-preview p-2">
                                                    {attachments.map((attachment, index) => {
                                                        const fileName = attachment.attachments.split('/').pop();
                                                        return (
                                                            <div key={index} className="attachment-item">
                                                                <span>{fileName}</span>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleDeleteAttachment(index)}
                                                                    className="btn-close"
                                                                >
                                                                    ×
                                                                </button>
                                                            </div>
                                                        );
                                                    })}
                                                </div>

                                            </div>
                                            <div className="att_icon_list position-absolute">
                                                <ul className="d-flex align-items-center justify-content-end">
                                                    <li>
                                                        <label htmlFor={`attach1`}>
                                                            <a>
                                                                <img src="./assets/images/attach1.png" alt="attach1" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id={`attach1`}
                                                            onInput={(e) => {
                                                                const file = e.target.files[0];
                                                                const fileType = file.type.startsWith('image')
                                                                    ? 'image'
                                                                    : file.type.startsWith('video')
                                                                        ? 'video'
                                                                        : file.type === 'application/pdf'
                                                                            ? 'pdf'
                                                                            : file.type ===
                                                                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                                                                ? 'docx'
                                                                                : 'xlsx'; // For Excel
                                                                handleImageChange(file, fileType);
                                                            }}
                                                            {...register("attachments")}
                                                            accept="image/*,video/*,application/pdf,.docx,.xlsx"
                                                            className="file-input"
                                                            style={{ visibility: 'hidden', display: 'none' }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach2'>
                                                            <a>
                                                                <img src="./assets/images/attach2.png" alt="attach2" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach2'
                                                            onChange={(e) => handleFileInput(e, 'video')}
                                                            accept="video/*"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach3'>
                                                            <a>
                                                                <img src="./assets/images/attach3.png" alt="attach3" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach3'
                                                            onChange={(e) => handleFileInput(e, 'image')}
                                                            accept="image/*"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach4'>
                                                            <a>
                                                                <img src="./assets/images/attach4.png" alt="attach4" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach4'
                                                            onChange={(e) => handleFileInput(e, 'docx')}
                                                            accept=".docx"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach5'>
                                                            <a>
                                                                <img src="./assets/images/attach5.png" alt="attach5" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach5'
                                                            onChange={(e) => handleFileInput(e, 'xlsx')}
                                                            accept=".xlsx"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                    <li>
                                                        <label htmlFor='attach6'>
                                                            <a>
                                                                <img src="./assets/images/attach6.png" alt="attach6" />
                                                            </a>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id='attach6'
                                                            onChange={(e) => handleFileInput(e, 'pdf')}
                                                            accept=".pdf"
                                                            className="file-input"
                                                            style={{ "visibility": "hidden", "display": "none" }}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="form-label">Discussion Status</label>
                                        <input type="text" {...register("status")} name="status" className="form-control" placeholder id />
                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.status?.message}</span></p>

                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Discussion Open Date</label>
                                                <input type="date" {...register("open_date")} name="open_date" min={currentDate} onChange={(e) => SelectOpenDate(e.target.value)} className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.open_date?.message}</span></p>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Discussion Open Time</label>
                                                <input type="time" {...register("open_time")} name="open_time" className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.open_time?.message}</span></p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Discussion Close Date</label>
                                                <input type="date" {...register("close_date")} name="close_date" min={getOpenDate} className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.close_date?.message}</span></p>

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Discussion Close Time</label>
                                                <input type="time" {...register("close_time")} name="close_time" className="form-control" placeholder id />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.close_time?.message}</span></p>

                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mb-3">Discussion duration</h3>
                                    <div className="d-md-flex mb-4 align-items-center">
                                        <p className="fs-6  mb-md-0 mb-3">Expected Time spent On Discussion Hours</p>
                                        <div className="d-md-flex ms-3">
                                            <div className="d-md-flex mb-md-0 mb-3 align-items-center">
                                                <select className="form-select mb-md-0 mb-3 w-auto pe-5 rounded-pill" {...register("hours")} name="hours" aria-label="Default select example">
                                                    <option value={0}>0 </option>
                                                    <option value={1}>1 </option>
                                                    <option value={2}>2 </option>
                                                    <option value={3}>3 </option>
                                                    <option value={4}>4 </option>
                                                </select>
                                                <span className="ms-3">Minutes</span>
                                            </div>
                                            <div className="ms-md-3">
                                                <select className="form-select w-auto pe-5 rounded-pill" {...register("minutes")} name="minutes" aria-label="Default select example">
                                                    <option value={0}>0 </option>
                                                    <option value={5}>5 </option>
                                                    <option value={10}>10 </option>
                                                    <option value={15}>15 </option>
                                                    <option value={20}>20 </option>
                                                    <option value={25}>25 </option>
                                                    <option value={30}>30 </option>
                                                    <option value={35}>35 </option>
                                                    <option value={40}>40 </option>
                                                    <option value={45}>45 </option>
                                                    <option value={45}>50 </option>
                                                    <option value={45}>55 </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mb-3">Discussion Score</h3>
                                    <div className="form-group mb-4">
                                        <label className="form-label">total score for this Discussion</label>
                                        <input type="text" {...register("score")} name="score" className="ms-3" placeholder id />
                                        <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.score?.message}</span></p>

                                    </div>
                                    {/* <div className="enviorment_div mb-5 align-items-center d-md-flex">
                                                    <div className="env_img">
                                                        <img src="./assets/images/enviorment.png" alt="enviorment" />
                                                    </div>
                                                    <div className="env_text">
                                                        <p className="text-500">Help save the envionrment. Cut down on printing. Instead please use Google docs, sheets and slides which is freely available to you.</p>
                                                    </div>
                                                </div> */}
                                    <div className="button">
                                        {/* <Link to="/teach-detail" className="btn-theme bg-yellow d-inline-block fw-600">Create Discussion</Link> */}
                                        <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                            Edit Discussion
                                        </button>
                                    </div>
                                </form>
                                {/*end*/}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <OFooter_Dashboard />
        </>
    )
}
