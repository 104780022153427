import React from "react";

export default function StripePrivacy() {
  return (
    <div className="safety-container">
      <div className="safety-header">
        <h1 className="safety-title">Your Privacy & Security Matter</h1>
        <p className="safety-subtitle">
          Learn how we and Stripe ensure your payment details are secure.
        </p>
      </div>
      <div className="safety-list container">
        <ol className="safety-items">
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Encrypted Transactions:</span> Your
              payment details are shielded with state-of-the-art encryption.
              This means that whenever you make a purchase, your information is
              transformed into a secret code that only our system and Stripe can
              decipher.
            </p>
          </li>
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Trusted Security Standards:</span> Stripe
              meets the highest level of security standards set by the payment
              card industry. This certification ensures that your payment data
              is handled with the utmost care and meets stringent security
              requirements.
            </p>
          </li>
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Easy and Secure Account Access:</span>{" "}
              We've added extra layers of protection to your account. With
              two-factor authentication, you'll need to confirm your identity
              using a unique code sent to your phone whenever you log in, adding
              an extra shield against unauthorized access.
            </p>
          </li>
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Smart Fraud Protection:</span> Our
              systems are continuously scanning for any suspicious activity. If
              anything seems off, we'll be alerted immediately, allowing us to
              act swiftly to protect your account and prevent any potential
              fraud.
            </p>
          </li>
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Your Data, Your Control:</span> Your
              payment information is yours alone. With tokenization, we don't
              store your card details directly. Instead, a secure token is used
              for transactions, keeping your sensitive data safe from prying
              eyes.
            </p>
          </li>
          <li className="safety-item">
            <div className="arrow-wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#6a0dad"
                className="arrow-icon"
              >
                <path d="M12 2L22 12L12 22L10.6 20.6L17.2 14H2V10H17.2L10.6 3.4L12 2Z" />
              </svg>
            </div>
            <p>
              <span className="fw-600">Regular Safety Checks:</span> We're
              always on guard to keep your information secure. Stripe undergoes
              regular security audits by independent experts to ensure we're
              meeting the highest security standards and keeping your data safe.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
}
