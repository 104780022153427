import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TOAST_ERROR, TOAST_WARNING } from "../../../../utils/common.service";
import { meeting_details, subscribe_class } from "../../../../api/apiHandler";

import Swal from "sweetalert2";

const SubscribeModal2 = ({ data, totalSubscriptionAmount, activity_type, learnerData, isLoader }) => {
  const [isChecked, setIsChecked] = useState(false);
  console.log('data :', data);
  console.log('totalSubscriptionAmount :', totalSubscriptionAmount);
  const navigate = useNavigate();
  const modalRef = useRef();

  // class schedule list
  // console.log(data, 'SubscribeModal3');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const USER_NAME = localStorage.getItem("MAname");
  const USER_ID = localStorage.getItem("PAid");
  const USER_TYPE = localStorage.getItem("userType");
  const [available_Classes, set_available_Classes] = useState([]);

  const available_classes_Details = () => {
    // console.log(data.id,'newid');

    meeting_details({ activity_id: data?.id, activity_type: activity_type, length: data?.length }).then((resposnse) => {
      if (resposnse?.code == 1) {
        set_available_Classes(resposnse?.data);
      } else {
        set_available_Classes([]);
      }
    });
  };

  useEffect(() => {
    available_classes_Details()
  }, [])




  const onSubmit = async (value) => {
    if (!isChecked) {
      TOAST_WARNING("Please check the box to proceed.");
      return
    } else {
      setIsChecked(false); // Reset isChecked to false after proceeding
    }
    let paymentBody = {
      activity_id: data?.id,
      meeting_id: Array.isArray(data?.meeting_id) ? data.meeting_id.sort((a, b) => a - b) : data?.meeting_id,
      activity_type,
      total_amount: totalSubscriptionAmount?.amount,
      tax_charge: totalSubscriptionAmount?.tax_charge,
      is_recurring: value.isChecked ? 1 : 0,
      teacher_id: data.teacher_id,
      learnerData: learnerData,
      user_currency_code: data?.currency_code,
      coupan_id: totalSubscriptionAmount?.coupon_id,
      amount: data.price_per_meeting,
      slot_id: data?.slot_id
    };


    console.log('paymentBody', paymentBody);
    // return
    isLoader(true)
    if (USER_TYPE === "above_18") paymentBody.user_id = USER_ID;
    else paymentBody.learner_id = totalSubscriptionAmount?.learners[0];
    console.log(paymentBody);
    const { code: payment_code, message: payment_message, data: payment_data } = await subscribe_class(paymentBody);
    console.log('payment_data :', payment_data?.session);
    console.log('payment_data :', payment_data);
    console.log('payment_message :', payment_message);
    console.log('payment_code :', payment_code);


    console.log(payment_message, 'errorr');

    if (payment_code == 1) {
      isLoader(false)
      // TOAST_SUCCESS("Transaction Successfull!");
      reset();
      window.open(payment_data?.session, '_blank')
      if (modalRef.current) {
        modalRef.current.setAttribute("data-bs-dismiss", "modal");
        modalRef.current.click();
        setTimeout(() => {
          modalRef.current.removeAttribute("data-bs-dismiss");
        }, 100);
      }
      Swal.close();
      // if (activity_type != 'quiz') {

      //   navigate('/class-requirement', { state: { activity_id: data?.id, activity_type: activity_type, title: data?.title, learnerData: USER_TYPE === "above_18" ? [{ id: USER_ID, learner_name: USER_NAME }] : learnerData, data: payment_data[0] } });
      // }
    } else {
      TOAST_ERROR(payment_message)
      isLoader(false);
    }


    try {
    } catch (err) {
      TOAST_ERROR(err.message);
      // isLoader(false);

    }
  };

  const closeModals = () => {
    // Close payment modal
    if (modalRef.current) {
      modalRef.current.click();
    }

    // Close any active Swal modal
    if (Swal.isVisible()) {
      Swal.close();
    }
  };

  const handleCancel = () => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure you want to cancel the payment?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        confirmButton: 'btn-theme space',
        cancelButton: 'btn-theme bg-yellow space'
      },
      buttonsStyling: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {

        reset();
        setIsChecked(false)
        if (modalRef.current) {
          modalRef.current.setAttribute("data-bs-dismiss", "modal");
          modalRef.current.click();
          setTimeout(() => {
            modalRef.current.removeAttribute("data-bs-dismiss");
          }, 100);
        }
        Swal.close();
      }
    });
  };

  return (
    <div className="modal fade" id="subscription_modal_2" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-5">
              <h2 className="mb-3 fs-4 text-uppercase">Safety &amp; Class Policies</h2>
              <p className="text-gray text-capitalize">
              StudentScholars consider learner and teacher safety as our Top Priority. Promoting a safe and positive learning environment depends very much on the joint efforts and understanding of learners, parents/guardians, and teachers. Therefore, we kindly request to please draw your attention to the follow the ‘Safety and Class Polices’ :
              </p>
            </div>
            <div className="px-lg-5">
              <div className="row align-items-center online_cls_poli">
                <div className="col-lg-5 mb-lg-0 mb-4 text-center">
                  <div className="online_poli_img">
                    <img src={`/assets/images/onlineclass_policies.png`} alt="onlineclass_policies" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <h3 className="mb-3 text-uppercase">Online Class Policies:</h3>
                  <ol>
                    <li>
                      <p>
                        <span className="fw-600">Familiarize Using the Platform:</span> StudentScholars website is user friendly and easy to navigate.
                        However, we urge learners to watch the training videos in the FAQ section to get a better understanding of the website
                        features available to you. Please remember <b>not to</b> copy the video link and join from outside the platform classroom. If
                        you do so, you may be unsubscribed from the class.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Learner Behaviour:</span> We want to create a pleasant environment for all users. Please be
                        respectful towards the teacher and other learners. Bullying is not tolerated and may result in the learner being blocked from
                        the website.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Posting Reviews:</span> It is helpful if you could leave a review regarding the class. Our key
                        priority and concern at StudentScholars is to continue to be a unique and safe place for all learners.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Environment:</span> Learners should try their best to be in a quiet environment with no distractions
                        while in class.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Parent/Guardian Supervision:</span> If the learners are young, we ask parents/guardians to please
                        supervise while they attend class, until you’re confident they can participate safely and respectfully on their own with
                        absolutely no interference to any ongoing class. We encourage parents/guardians to stay off-screen as much as possible, so if
                        your learner needs hands-on assistance or supervision, please reach out to the teacher before class to let them know. Parents
                        may listen into a class at a reasonable distance from their learner or watch the recording later, but it will be against
                        StudentScholars policies for a parent/guardian to listen in or watch a class from a separate device.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Confirming Identity:</span> Learners should join the class each time with their video enabled to
                        allow the teacher to confirm the identity. This can be a quick check-in at the beginning of each class meeting and is a
                        requirement to promote classroom safety.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Sharing Personal Information:</span> For user safety, <b>do not</b> share or ask for personal
                        information from teachers or other learners. Personal information can include but not limited to, a full name, birth date,
                        personal email address, address, or phone number, social media information.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Class Recordings:</span> All classes are recorded for educational purposes only. Learners have an
                        option to review the class material covered after the class. It is against our policy for anyone to copy, record or share the
                        video recordings.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">Feedback:</span> StudentScholars has a feedback form easily accessible from the user account. You can
                        give us feedback and a customer support team member will get back to you.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span className="fw-600">No Directly Hiring:</span> You should not privately hire teachers or organizations. If we suspect
                        such an incident, StudentScholars at its discretion has the right to terminate the user from the website.
                      </p>
                    </li>
                  </ol>
                  <div className="text-end my-4">
                    {/* <a href="#" className="text-purple fw-600 fs-6">
                      Learn More
                    </a> */}
                  </div>
                </div>
              </div>
              <div className="custom_form border-radius-10 bg-gray check_polices">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      checked={isChecked}

                      onClick={(e) => setIsChecked(e.target.checked)}
                      className="form-check-input rounded-1 pointer"
                      type="checkbox"
                      defaultValue
                      // value={isChecked}
                      id="invalidCheck"
                    />
                    <label className="form-check-label ps-3 fs-5" htmlFor="invalidCheck">
                      I consent to the class policies
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="custom_form">

            <div className="modal-footer justify-content-between">
              <a ref={modalRef} className="text-purple d-block pointer" onClick={handleCancel} >
                Cancel
              </a>

              <button className="btn-theme bg-yellow mb-3 d-block">
                {/* Pay CA $22.00 CAD Pay */}Pay {data?.currency_symbol ? data.currency_symbol : '$'}{totalSubscriptionAmount?.amount ? parseFloat(totalSubscriptionAmount.amount).toFixed(2) : '0.00'} {data?.currency_code ? data.currency_code : 'USD'}
              </button>
              {/* <a
                className="btn-theme bg-yellow mb-xl-0 mb-3 d-inline-block pointer"
                data-bs-dismiss={isChecked ? "modal" : ""}
                data-bs-toggle={isChecked ? "modal" : ""}
                data-bs-target={isChecked ? "#subscription_modal_3" : ""}
                onClick={() => {
                  if (!isChecked) {
                    TOAST_WARNING("Please check the box to proceed.");
                  } else {
                    setIsChecked(false); // Reset isChecked to false after proceeding
                  }
                }}
              >
                Next
              </a> */}
            </div>
          </form>
        </div>
      </div>
    </div >
  );
};

export default SubscribeModal2;
