import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import JqueryLoad from '../../../../JqueryLoad/JqueryLoad';
import THeader_Dashboard from '../../Include/THeader_Dashboard';
import TFooter_Dashboard from '../../Include/TFooter_Dashboard';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { teacher_edit_quiz_question, teacher_quiz_question_details } from '../../../../api/apiHandler';
import { convertToBase64, TOAST_ERROR, TOAST_SUCCESS } from '../../../../utils/common.service';
import { uploadImageOnAWS } from '../../../aws/Service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Helmet } from 'react-helmet';

const Editor = ({ content, onChange }) => {
    return (
        <ReactQuill
            value={content}
            onChange={onChange}
            modules={Editor.modules}
            formats={Editor.formats}
            theme="snow"
            placeholder="Write something..."
            style={{ borderRadius: '10px'}}
        />
    );
};

Editor.modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link', 'image'],
        ['clean'],
    ],
};

Editor.formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
];

export default function EditQuestion({isLoader}) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation();

    const pathname = location.pathname;
    var edit_question_id = pathname.split('/').pop();

    const navigate = useNavigate();
    const [answerType, setAnswerType] = useState("");
    console.log('answerType :', answerType);

    let schema;
    if (answerType == "Single") {
        schema = yup.object().shape({
            question: yup.string()
                .required("Please enter question")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
               ,
            answer1: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer2: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer3: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer4: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            ans_exp: yup.string().required("Please enter answer explanation")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer explanation cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                ,
            que_points: yup.number()
                .typeError("Question Points must be a valid number")
                .required("Please enter question points")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question points cannot consist of only spaces',
                    (value) => value !== undefined && value !== null && value.toString().trim().length > 0
                )
                .min(1, 'Question Points must be at least 1')
                .max(100, 'Question Points must be at most 100'),

        });
    } else if (answerType == "Multiple") {
        schema = yup.object().shape({
            question: yup.string()
                .required("Please enter question")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
               ,
            answer1: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer2: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer3: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer4: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            ans_exp: yup.string().required("Please enter answer explanation")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer explanation cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                ,
            que_points: yup.number()
                .typeError("Question Points must be a valid number")
                .required("Please enter question points")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question points cannot consist of only spaces',
                    (value) => value !== undefined && value !== null && value.toString().trim().length > 0
                )
                .min(1, 'Question Points must be at least 1')
                .max(100, 'Question Points must be at most 100'),
        });
    } else {
        schema = yup.object().shape({
            question: yup.string()
                .required("Please enter question")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
               ,
            answer1: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            answer2: yup.string().required("Please enter answer")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                   ,
            ans_exp: yup.string().required("Please enter answer explanation")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Answer explanation cannot consist of only spaces',
                    (value) => value && value.trim().length > 0
                )
                ,
            que_points: yup.number()
                .typeError("Question Points must be a valid number")
                .required("Please enter question points")
                .test('no-emoji', 'Emojis are not allowed', (value) => !/[\uD800-\uDFFF]/.test(value))
                .test(
                    'no-only-whitespace',
                    'Question points cannot consist of only spaces',
                    (value) => value !== undefined && value !== null && value.toString().trim().length > 0
                )
                .min(1, 'Question Points must be at least 1')
                .max(100, 'Question Points must be at most 100'),
        });
    }

    const { register, handleSubmit, setValue, clearErrors, reset, watch, control, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const handleAnswerTypeChange = (e) => {
        setAnswerType(e.target.value);
        reset({
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: ''
        });
    };

    const [selectedRadio, setSelectedRadio] = useState(null);
    const [answerExplanation, setAnswerExplanation] = useState('');

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setSelectedRadio(value); // Set the selected radio value to the state
    };

    const answerValues = {
        answer1: selectedRadio === '1' ? '1' : '0',
        answer2: selectedRadio === '2' ? '1' : '0',
        answer3: selectedRadio === '3' ? '1' : '0',
        answer4: selectedRadio === '4' ? '1' : '0',
    };

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
        checkbox4: false,
    });

    const handleCheckboxChange = (name) => {
        setSelectedCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [name]: !prevCheckboxes[name],
        }));
    };

    const answerValues1 = {
        answer1: selectedCheckboxes.checkbox1 ? '1' : '0',
        answer2: selectedCheckboxes.checkbox2 ? '1' : '0',
        answer3: selectedCheckboxes.checkbox3 ? '1' : '0',
        answer4: selectedCheckboxes.checkbox4 ? '1' : '0',
    };

    const [selectedRadio1, setSelectedRadio1] = useState(null);
    const [tempState, setTempState] = useState('')
    const [tempDesState, setDesTempState] = useState('')

    const handleRadioChange1 = (event) => {
        setSelectedRadio1(event.target.value); // Set the selected radio value to the state
    };

    var answerValues2 = {
        answer1: selectedRadio1 === '1' ? '1' : '0',
        answer2: selectedRadio1 === '2' ? '1' : '0',
    };

    var [data, setData] = useState(null);
    console.log('data :', data);

    useEffect(() => {
        isLoader(true)
        teacher_quiz_question_details({ "question_id": edit_question_id }).then((response) => {
            console.log('teacher_quiz_question_details :', response);
            if (response?.code == 1) {
                isLoader(false)
                if (response?.data?.question?.que_type == "TrueFalse") {
                    const correctAnswerIndex = response?.data.options.findIndex((option) => option.is_ans === 1);
                    setSelectedRadio1(correctAnswerIndex >= 0 ? (correctAnswerIndex + 1).toString() : '0');
                    // Update answerValues2 based on selectedRadio1
                    var updatedAnswerValues2 = {
                        answer1: selectedRadio1 === '1' ? '1' : '0',
                        answer2: selectedRadio1 === '2' ? '1' : '0',
                    };
                }
                else if (response?.data?.question?.que_type == "Single") {
                    const correctAnswerIndexSingle = response?.data.options.findIndex((option) => option.is_ans === 1);
                    if (correctAnswerIndexSingle >= 0) {
                        const selectedRadioValue = correctAnswerIndexSingle + 1;
                        setSelectedRadio(selectedRadioValue.toString());
                        // Update answerValues based on selectedRadio
                        var updatedAnswerValues = {
                            answer1: selectedRadioValue === 1 ? '1' : '0',
                            answer2: selectedRadioValue === 2 ? '1' : '0',
                            answer3: selectedRadioValue === 3 ? '1' : '0',
                            answer4: selectedRadioValue === 4 ? '1' : '0',
                        };
                    }
                }
                else if (response?.data?.question?.que_type === "Multiple") {
                    const correctAnswerIndicesMultiple = response?.data.options.reduce((acc, option, index) => {
                        if (option.is_ans === 1) {
                            acc.push(index + 1);
                        }
                        return acc;
                    }, []);
                    if (correctAnswerIndicesMultiple.length > 0) {
                        // Handle the case where there are correct answers
                        setSelectedCheckboxes({
                            checkbox1: correctAnswerIndicesMultiple.includes(1),
                            checkbox2: correctAnswerIndicesMultiple.includes(2),
                            checkbox3: correctAnswerIndicesMultiple.includes(3),
                            checkbox4: correctAnswerIndicesMultiple.includes(4),
                        });
                        // Update answerValues1 based on selectedCheckboxes
                        const answerValues1 = {
                            answer1: selectedCheckboxes.checkbox1 ? '1' : '0',
                            answer2: selectedCheckboxes.checkbox2 ? '1' : '0',
                            answer3: selectedCheckboxes.checkbox3 ? '1' : '0',
                            answer4: selectedCheckboxes.checkbox4 ? '1' : '0',
                        };
                    }
                }
                setData(response?.data)
                setAnswerType(response?.data?.question?.que_type)
                setValue("attachments_data", response?.data?.question?.attachment);
                setValue("que_type", response?.data?.question?.que_type);
                setValue("attachment_type", response?.data?.question?.attachment_type);
                setValue("attachments_des_data", response?.data?.question?.description_image);
                setValue("attachments_des_type", response?.data?.question?.description_type);
                setValue("ans_exp", response?.data?.question?.answer_explanation);
                setAnswerExplanation(response?.data?.question?.answer_explanation)
                setTempState(response?.data?.question?.attachment)
                setDesTempState(response?.data?.question?.description_image)
            } else {
                isLoader(false)
                TOAST_ERROR(response?.message);
            }
        });
    }, []);

    const handleDescriptionChange = (value) => {
        // Check if the value contains only whitespace characters
        const isEmptyDescription = /^\s*<p><br><\/p>\s*$/i.test(value);
        // If it's empty, set the description to an empty string
        setAnswerExplanation(isEmptyDescription ? '' : value);
        setValue('ans_exp', isEmptyDescription ? '' : value);
        clearErrors('ans_exp');
    };

    const [profileImageState, setProfileImageState] = useState('')
    const [desState, setDesState] = useState('')


    const handleImageChange = async (image, fileIndex, type) => {
        setTempState(image?.name)

        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        setValue("attachments_data", image);
        setValue("attachment_type", type);

        const bufferImage = await convertToBase64(image);
        setProfileImageState(bufferImage);
        clearErrors("attachments_data");
    }

    const handleAttachmentChange = async (image, fileIndex, type) => {
        setDesTempState(image?.name)

        if (image != null) {
            TOAST_SUCCESS("Your attachment has been uploaded!");
        }
        setValue("attachments_des_data", image);
        setValue("attachments_des_type", type);
        const bufferImage = await convertToBase64(image);
        setDesState(bufferImage);
        clearErrors("attachments_des_data");
    }

    let onSubmit = async (editData) => {
        isLoader(true)

        let image = editData?.attachments_data
        if (image instanceof Blob) image = await uploadImageOnAWS(editData?.attachments_data, "class_image");
        let des_image = editData?.attachments_des_data
        if (des_image instanceof Blob) des_image = await uploadImageOnAWS(editData?.attachments_des_data, "Quiz_attachment_des");
        //         console.log('des_image :', des_image);
        // console.log("attachments_type",editData.attachments_type)
        let submitData = {
            // teacher_id : 
            question_id: edit_question_id,
            question: editData.question,
            attachment: image,
            que_type: answerType,
            attachment_type: (editData.attachments_type != undefined && editData.attachments_type != null ? editData.attachments_type : data?.question?.attachment_type),
            answer_explanation: answerExplanation,
            points: editData.que_points,
            description_image: des_image,
            description_type: (editData.attachments_des_type != undefined && editData.attachments_des_type != null ? editData.attachments_des_type : data?.question?.description_type),
        }

        // var option_ids = data.options[1].option_id
        if (answerType == "Single") {
            submitData.question_options = [
                {
                    "option_id": data.options[0].option_id,
                    "name": editData.answer1,
                    "is_ans": answerValues.answer1
                },
                {
                    "option_id": data.options[1].option_id,
                    "name": editData.answer2,
                    "is_ans": answerValues.answer2
                },
                {
                    "option_id": data.options[2].option_id,
                    "name": editData.answer3,
                    "is_ans": answerValues.answer3
                },
                {
                    "option_id": data.options[3].option_id,
                    "name": editData.answer4,
                    "is_ans": answerValues.answer4
                }
            ]
        } else if (answerType == "Multiple") {
            submitData.question_options = [
                {
                    "option_id": data.options[0].option_id,
                    "name": editData.answer1,
                    "is_ans": answerValues1.answer1
                },
                {
                    "option_id": data.options[1].option_id,
                    "name": editData.answer2,
                    "is_ans": answerValues1.answer2
                },
                {
                    "option_id": data.options[2].option_id,
                    "name": editData.answer3,
                    "is_ans": answerValues1.answer3
                },
                {
                    "option_id": data.options[3].option_id,
                    "name": editData.answer4,
                    "is_ans": answerValues1.answer4
                }
            ]
        } else {
            submitData.question_options = [
                {
                    "option_id": data.options[0].option_id,
                    "name": editData.answer1,
                    "is_ans": answerValues2.answer1
                },
                {
                    "option_id": data.options[1].option_id,
                    "name": editData.answer2,
                    "is_ans": answerValues2.answer2
                }
            ]
        }

        console.log('submitData :', submitData);
        // questionDetail.push(submitData)
        teacher_edit_quiz_question(submitData).then((res) => {
            if (res.code == 1) {
                isLoader(false)
                TOAST_SUCCESS(res.message);
                navigate('/question_list');
            } else {
                isLoader(false)
                TOAST_ERROR(res.message);
            }
        });
        reset();
    };

    if (data == null) return <></>

    return (
        <>
            <JqueryLoad />
            <THeader_Dashboard />
            <Helmet>
                <style>
                    {`
          .form-control:disabled, .form-control[readonly] {
            background-color: #ffffff;
            opacity: 1;
        }.
          `}
                </style>

            </Helmet>
            <main>
                <div className="dashboard-app d-lg-flex">
                    <header className="dashboard-toolbar d-lg-none d-flex justify-content-between">
                        <div className="align-items-center justify-content-between d-flex">
                            <div className="d-flex align-items-center">
                                <a href="#!" className="menu-toggle align-items-center d-lg-none d-flex">
                                    <i className="fa fa-bars" />
                                </a>
                                <a href="#" className="d-lg-none d-block brand-logo">
                                    <img src="./assets/images/logo.png" alt="logo" />
                                </a>
                            </div>
                        </div>
                    </header>
                    <div className="class-content">
                        <div className>
                            {/*start*/}
                            <a onClick={() => navigate(-1)} className="mb-4 arrow_left mb-3 d-block" >
                                <span>
                                    <svg width={10} height={18} viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z" fill="#131313" />
                                    </svg>
                                    <span className="ms-2">Back</span>
                                </span></a>
                            {/*end*/}
                            {/*start*/}
                            <div className>
                                <div className="row mb-4">
                                    <div className="col-xl-7 d-sm-flex align-items-center justify-content-between">
                                        <h3 className="text-purple mb-sm-0 mb-3">Setup Your Online Quiz</h3>
                                        <span className="text-purple text-end d-block fw-600">question 2/2</span>
                                    </div>
                                </div>
                                <h3 className="mb-4">Add Questions</h3>
                                <div className="row">
                                    <div className="col-xl-7">
                                        <form onSubmit={handleSubmit(onSubmit)} className="custom_form">
                                            <div className="form-group mb-4">
                                                <label className="form-label">Type Your Question </label>
                                                <textarea type="text" defaultValue={data.question.question} className="form-control" name="question" {...register("question")} rows={7} />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.question?.message}</span></p>
                                            </div>

                                            <div className="form-group mb-4 attach_box">
                                                <label className="form-label">Attachments</label>
                                                <div className="position-relative">
                                                    <textarea type="text" className="form-control" name rows={7}  value={tempState} disabled />
                                                    <div className="att_icon_list position-absolute">
                                                        <ul className="d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <label htmlFor="attach1">
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach1.png"} alt="attach1" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach1' onInput={(e) => {
                                                                    const file = e.target.files[0];
                                                                    const fileType = file.type.startsWith('image') ? 'image' : 'video';
                                                                    handleImageChange(file, 0, fileType);
                                                                }} {...register("attachments")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach2'>
                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach2.png"} alt="attach2" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach2' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach3'>
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach3.png"} alt="attach3" />
                                                                    </a>
                                                                    <input type="file" id='attach3'  {...register("attachments")} accept="image/*" onInput={(e) => handleImageChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach4'>
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach4.png"} alt="attach4" />
                                                                    </a>
                                                                    <input type="file" id='attach4' {...register("attachments")} accept=".docx" onInput={(e) => handleImageChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>

                                                                <label htmlFor='attach5'>

                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach5.png"} alt="attach5" />
                                                                    </a>
                                                                    <input type="file" id='attach5' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach6'>

                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach6.png"} alt="attach6" />
                                                                    </a>
                                                                    <input type="file" id='attach6' {...register("attachments")} onInput={(e) => handleImageChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label className="mb-2">Answer Type: Single Answer ,Multiple answers ,True or False</label>
                                                <select  {...register("que_type")} onChange={handleAnswerTypeChange} className="form-select" aria-label="Default select example" >
                                                    <option value="Single" selected>Single</option>
                                                    <option value="Multiple">Multiple</option>
                                                    <option value="TrueFalse">True/False</option>

                                                </select>
                                            </div>
                                            {answerType === "Single" && (
                                                <>

                                                    {data.options.map((option_data, index) => {

                                                        return (

                                                            <div key={index} className="form-group mb-4">
                                                                <label className="form-label">Ans {index + 1}
                                                                    <label className="radio-button-container">Select As Correct answer
                                                                        <input type="radio" name="radio" id={`radiotrue${index}`} value={index + 1} defaultChecked={option_data.is_ans == 1 ? true : false}
                                                                            onChange={handleRadioChange} />
                                                                        <span className="checkmarks" />
                                                                    </label>
                                                                </label>
                                                                <input type="text" className="form-control" {...register(`answer${index + 1}`)} defaultValue={option_data.options} name={`answer${index + 1}`} placeholder id />
                                                                <p className='mt-2 font-bold col-red'>
                                                                    <span style={{ color: 'red' }}>
                                                                        {errors[`answer${index + 1}`]?.message}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        )
                                                    })}

                                                </>
                                            )}


                                            {answerType === "Multiple" && (
                                                <>
                                                    {data.options.map((option_data, index) => {

                                                        return (
                                                            <div className="form-group mb-4">
                                                                <label className="form-label">Ans {index + 1}
                                                                    <label className="radio-button-container">Select As Correct answer
                                                                        <input
                                                                            type="checkbox"
                                                                            name={`checkbox${index + 1}`}
                                                                            // checked={selectedCheckboxes[`checkbox${index + 1}`]}
                                                                            defaultChecked={option_data.is_ans == 1 ? true : false}
                                                                            id={`checkbox${index + 1}`} value={index + 1}
                                                                            onChange={() => handleCheckboxChange(`checkbox${index + 1}`)}
                                                                        />                                <span className="checkmarks" />
                                                                    </label>
                                                                </label>
                                                                <input type="text" {...register(`answer${index + 1}`)} name={`answer${index + 1}`} defaultValue={option_data.options} className="form-control" placeholder id />
                                                                <p className='mt-2 font-bold col-red'>
                                                                    <span style={{ color: 'red' }}>
                                                                        {errors[`answer${index + 1}`]?.message}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            )}



                                            {answerType === "TrueFalse" && (
                                                <>
                                                    {data.options.map((option_data, index) => {
                                                        if (index < 2) {
                                                            return (
                                                                <div className="form-group mb-4" key={index}>
                                                                    <label className="form-label">Ans {index + 1}
                                                                        <label className="radio-button-container">Select As Correct answer
                                                                            <input
                                                                                type="radio"
                                                                                name="radio"
                                                                                id={`radiotrue${index}`}
                                                                                value={index + 1}
                                                                                defaultChecked={option_data.is_ans == 1}
                                                                                onChange={handleRadioChange1}
                                                                            />
                                                                            <span className="checkmarks" />
                                                                        </label>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        {...register(`answer${index + 1}`)}
                                                                        defaultValue={option_data.options}
                                                                        name={`answer${index + 1}`}
                                                                        className="form-control"
                                                                        placeholder
                                                                    />
                                                                    <p className='mt-2 font-bold col-red'>
                                                                        <span style={{ color: 'red' }}>
                                                                            {errors[`answer${index + 1}`]?.message}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                        return null; // Return null if index is 2 or greater
                                                    })}
                                                </>
                                            )}


                                            <div className="form-group mb-4">
                                                <label className="form-label">Answer explanation </label>
                                                {/* <textarea type="text" defaultValue={data.question.answer_explanation} className="form-control" {...register("ans_exp")} name="ans_exp" rows={8} /> */}
                                                <Editor
                                                    {...register("ans_exp")}
                                                    content={answerExplanation}
                                                    onChange={handleDescriptionChange}
                                                />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.ans_exp?.message}</span></p>

                                            </div>
                                            <div className="form-group mb-4 attach_box">
                                                <label className="form-label">Description Attachments</label>
                                                <div className="position-relative">
                                                    <textarea type="text" className="form-control" name rows={7} value={tempDesState} disabled />
                                                    <div className="att_icon_list position-absolute">
                                                        <ul className="d-flex align-items-center justify-content-end">
                                                            <li>
                                                                <label htmlFor="attach_des_1">
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach1.png"} alt="attach_des_1" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_1' onInput={(e) => {
                                                                    const file_des = e.target.files[0];
                                                                    const fileType_des = file_des.type.startsWith('image') ? 'image' : 'video';
                                                                    handleAttachmentChange(file_des, 0, fileType_des);
                                                                }} {...register("attachments_des")} accept="image/*,video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_2'>
                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach2.png"} alt="attach_des_2" />
                                                                    </a>
                                                                </label>
                                                                <input type="file" id='attach_des_2' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 1, "video")} accept="video/*" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_3'>
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach3.png"} alt="attach_des_3" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_3'  {...register("attachments_des")} accept="image/*" onInput={(e) => handleAttachmentChange(e.target.files[0], 2, "image")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_4'>
                                                                    <a>
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach4.png"} alt="attach_des_4" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_4' {...register("attachments_des")} accept=".docx" onInput={(e) => handleAttachmentChange(e.target.files[0], 3, "docx")} className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                            <li>

                                                                <label htmlFor='attach_des_5'>

                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach5.png"} alt="attach_des_5" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_5' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 4, "xlsx")} accept=".xlsx" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />

                                                                </label>
                                                            </li>
                                                            <li>
                                                                <label htmlFor='attach_des_6'>

                                                                    <a >
                                                                        <img src={process.env.PUBLIC_URL + "/assets/images/attach6.png"} alt="attach_des_6" />
                                                                    </a>
                                                                    <input type="file" id='attach_des_6' {...register("attachments_des")} onInput={(e) => handleAttachmentChange(e.target.files[0], 5, "pdf")} accept=".pdf" className="file-input" style={{ "visibility": "hidden", "display": "none" }} />
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Question Points</label>
                                                <input type="text" defaultValue={data.question.points} className="form-control" placeholder id {...register("que_points")} name="que_points" />
                                                <p className='mt-2 font-bold col-red'><span style={{ color: 'red' }}>{errors.que_points?.message}</span></p>

                                            </div>
                                            <div className="row mt-5">
                                                <div className="col-lg-5">

                                                    <button className="btn-theme bg-yellow text-center fw-600 d-block" >
                                                        Edit Question
                                                    </button>
                                                </div>

                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                            {/*end*/}
                        </div>
                    </div>
                </div>
            </main>
            <TFooter_Dashboard />
        </>
    )
}
